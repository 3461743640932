import React from 'react';
import axiosNext from 'utils/axios';
import userAxios from 'utils/userAxios';
import { setSnackbarError, setSnackbarSuccess, setAuthFields } from 'appRedux/actions';
import { requestProfile } from 'appRedux/owner/profile/thunks';
import { alert, Box, Typography } from 'components/library';
import { fm } from 'utils/string';
import { requestProfile as requestEmployeeProfile } from 'appRedux/employee/profile/thunk';
import { get } from 'utils/lodash';
import { userRoles } from 'utils/enum';

export const requestGoogleSignIn = (idToken, history) => async (dispatch) => {
  try {
    const body = {
      user_login_detail: {
        id_token: idToken,
      },
    };
    const response = await axiosNext.post('/api/v3/user_login_details/omniauth_callback/signin', body);
    const { user } = response.data.data;
    userAxios.defaults.headers.Authorization = `Bearer ${user.token}`;
    dispatch(setAuthFields({
      userToken: user.token,
    }));
    localStorage.setItem('user_token', user.token);
    history.push('/companies');
  } catch (err) {
    if (get('response.data.errors[0].details.code')(err)) {
      const errorCode = get('response.data.errors[0].details.code')(err);
      alert(
        <Box>
          <Typography>{fm('1012')}</Typography>
          <Typography>{fm('go_to_setting_google_account')}</Typography>
        </Box>,
        { title: fm('google_account', 'Google account') },
      );
      return dispatch(setSnackbarError(errorCode));
    }
    dispatch(setSnackbarError('snackbar_google_signin_error'));
  }
};

export const requestAttachGoogleAccount = (idToken) => async (dispatch, getState) => {
  try {
    const { auth: { role } } = getState();
    const body = {
      user_login_detail: {
        id_token: idToken,
      },
    };
    await axiosNext.post('/api/v3/user_login_details/omniauth_callback/attach', body);
    if (role === userRoles.employee) {
      dispatch(requestEmployeeProfile());
    } else {
      dispatch(requestProfile());
    }
    dispatch(setSnackbarSuccess('snackbar_google_account_attached'));
  } catch (err) {
    if (get('response.data.errors[0].details.code')(err)) {
      const errorCode = get('response.data.errors[0].details.code')(err);
      return dispatch(setSnackbarError(errorCode));
    }
    dispatch(setSnackbarError('snackbar_google_attach_error'));
  }
};

export const requestRemoveGoogleAccount = () => async (dispatch, getState) => {
  try {
    const { auth: { role } } = getState();
    await axiosNext.post('/api/v3/user_login_details/omniauth_callback/detach');
    dispatch(setSnackbarSuccess('snackbar_google_account_removed'));
    if (role === userRoles.employee) {
      dispatch(requestEmployeeProfile());
    } else {
      dispatch(requestProfile());
    }
  } catch (err) {
    dispatch(setSnackbarError('snackbar_google_remove_error'));
  }
};
