import { SET_MAPS_FIELDS } from '../actions';

const initialState = {
  activeTab: 0,
};

function mapReducer (state = initialState, action) {
  switch (action.type) {
    case SET_MAPS_FIELDS:
      return {
        ...state,
        ...action.payload,
      };

    default: return state;
  }
}

export default mapReducer;
