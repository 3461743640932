import labels from './labels.json';
import snackbar from './snackbar.json';
import messages from './messages.json';
import validation from './validation.json';
import errorCodes from './errorCodes.json';
import buttons from './buttons.json';
import news from './news.json';
import eboka from './eboka.json';
import alert from './alert.json';

/*
 * import notifications from './notifications';
 * import buttons from './buttons';
 */

export default {
  ...eboka,
  ...errorCodes,
  ...labels,
  ...snackbar,
  ...messages,
  ...validation,
  ...buttons,
  ...news,
  ...alert,
};
