import { createSlice } from '@reduxjs/toolkit';
import {
  requestCustomerInvoiceOrders,
  requestPendingCustomerInvoices,
  requestOrderInvoices,
} from './thunk';

const overtime = createSlice({
  name: 'invoices',
  initialState: {
    records: [],
    orders: {},
    record: {},
    selectedIds: {},
    history: { records: [] },
  },
  reducers: {
    setInvoicesFields (state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(requestPendingCustomerInvoices.fulfilled, (state, action) => ({
      ...state,
      records: action.payload.records,
    }));
    builder.addCase(requestCustomerInvoiceOrders.fulfilled, (state, action) => ({
      ...state,
      orders: {
        ...state.orders,
        [action.payload.customerId]: action.payload.records,
      },
    }));
    builder.addCase(requestOrderInvoices.fulfilled, (state, action) => ({
      ...state,
      history: {
        ...state.history,
        records: action.payload.records,
      },
    }));
  },
});

const { reducer, actions } = overtime;

export const { setInvoicesFields } = actions;

export default reducer;
