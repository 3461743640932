import * as Yup from 'yup';
import { fm } from 'utils/string';

const loginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email(fm('invalid_email', 'Invalid email address'))
    .required(fm('email_required', 'Email is required')),
  password: Yup.string()
    .min(8, fm('password_min_length', 'Password must be at least 8 characters'))
    .required(fm('password_required', 'Password is required')),
});

export default loginValidationSchema;
