import { makeStyles } from 'components/library';

const useStyles = makeStyles({
  container: {
    height: '100vh',
    position: 'relative',
    overflow: 'hidden',
  },
  content: {
    height: '100%',
  },
  image: {
    position: 'absolute',
    top: '90%',
    marginLeft: '5%',
  },
});

export default useStyles;
