import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setConfirmModalFields } from 'appRedux/actions';
import { Grid, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Button } from 'components/library';
import { CheckCircleIcon } from 'components/library/icons';
import { fm } from 'utils/string';

export default function ConfirmModal () {
  const dispatch = useDispatch();
  const confirmModal = useSelector((state) => state.confirmModal);
  const { title, message, open = false, callBack } = confirmModal;

  const onClickCancel = () => {
    dispatch(setConfirmModalFields({ reset: true }));
  };

  const onClickConfirm = () => {
    if (callBack) {
      callBack();
    }
    onClickCancel();
  };

  return (
    <Dialog
      open={open}
      onClose={onClickCancel}
      aria-labelledby="confirm-dialog-slide"
      aria-describedby="confirm-dialog-slide-description"
      id="confirmation-dialog"
      fullWidth
    >
      <DialogTitle id="confirm-dialog-title">
        <Grid container alignItems="center">
          <CheckCircleIcon className="mr-1" color="info" /> {title}
        </Grid>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="confirm-dialog-slide-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions className="spacing-2">
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Button
              variant="text"
              onClick={onClickCancel}
              size="medium"
              id="confirmation-dialog-close"
            >
              {fm('close', 'Close')}
            </Button>
            <Button
              onClick={onClickConfirm}
              size="medium"
              id="confirmation-dialog-confirm"
            >
              {fm('confirm', 'Confirm')}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
