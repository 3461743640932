import React from 'react';
import PropTypes from 'prop-types';
import de from 'date-fns/locale/de';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import { convertTimeStringToLocalString, getStandardDate, formatStrings, formatDate } from 'utils/dateTime';
import classNames from 'classnames';

export default function TimeInput(props) {
  const {
    onChange,
    inputSize,
    min,
    max,
    value = '',
  } = props;

  const onChangeTime = (newValue, error) => {
    if (newValue instanceof Date && !error.validationError) {
      const newTime = formatDate(newValue, formatStrings.defaulTime);
      onChange(newTime);
    } else {
      onChange(null);
    }
  };

  const classes = classNames(
    'form-control',
    `${inputSize}`,
    props.className,
  );

  const time = value ? convertTimeStringToLocalString(value, getStandardDate(), formatStrings.defaulTime) : null;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
      <TimeField
        {...props}
        value={time && new Date(time)}
        onChange={onChangeTime}
        placeholder="--:--"
        className={classes}
        format="HH:mm"
        maxTime={max}
        minTime={min}
      />
    </LocalizationProvider>
  );
}

TimeInput.defaultProps = {
  size: 'small',
  variant: 'outlined',
  fullWidth: true,
  type: 'text',
  InputLabelProps: { shrink: true },
};

TimeInput.propTypes = {
  size: PropTypes.string,
  variant: PropTypes.string,
  fullWidth: PropTypes.bool,
  type: PropTypes.string,
  InputLabelProps: PropTypes.shape({}),
  defaultValue: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  inputSize: PropTypes.string,
  min: PropTypes.string,
  max: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  helperText: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isReadonly: PropTypes.bool,
  onChange: PropTypes.func,
};
