export const getFormattedCustomersRequestBody = (details) => ({
  start_date: details.startDate,
  end_date: details.endDate,
  ...(details.customerArea && { customer_area: details.customerArea.title }),
  ...(details.customer && { customer_id: details.customer.id }),
  ...(details.customerZipcode && { zip_code: details.customerZipcode.title }),
});

export const getFormattedCustomerDataForRequest = (details) => ({
  customer: {
    zip_code: details.zipCode,
    latitude: details.latitude,
    longitude: details.longitude,
    address: details.address,
    area: details.area,
    country: details.country,
  },
});

export const getFormattedOrderFilterParams = (filters) => ({
  customer_id: filters.customerId,
  start_date: filters.startDate,
  end_date: filters.endDate,
});
