import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import OTPInput from 'components/library/Input/OtpInput';
import ErrorBoundary from 'components/common/ErrorBoundary';
import LoadingButton from 'components/common/LoadingButton';
import { useHistory } from 'react-router-dom';
import { requestCheckEmailVerificationOtp, requestEmailVerificationOtp } from 'appRedux/thunks/auth';
import { FormattedMessage } from 'react-intl';
import color from 'utils/colors';
import { fm } from 'utils/string';
import cx from 'classnames';
import { DraftsOutlinedIcon } from 'components/library/icons';
import { Container, Grid, Typography, Box } from 'components/library';

const EmailVerification = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [otp, setOtp] = useState('');
  const [wait, setWait] = useState(20);
  const verificationEmail = sessionStorage.getItem('verification_email');
  const { fsip } = useSelector((state) => state.auth);
  const resendOtpEnabled = wait === 0;

  useEffect(() => {
    let timer;
    if (wait > 0) {
      timer = setTimeout(() => { setWait(wait - 1); }, 1000);
    }
    return () => clearTimeout(timer);
  }, [wait]);

  const handleOtpChange = (newOtp) => {
    setOtp(newOtp);
  };

  const onClickResendEmail = () => {
    if (!resendOtpEnabled) return;
    setWait(20);
    dispatch(requestEmailVerificationOtp(verificationEmail));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(requestCheckEmailVerificationOtp({ email: verificationEmail, otp }, history));
  };

  return (
    <ErrorBoundary>
      <Container component="main" maxWidth="xs">
        <form onSubmit={handleSubmit}>
          <Grid container alignItems="center" alignContent="center" spacing={2} className="mt-20">
            <Grid item xs={12}>
              <Grid container justifyContent="center">
                <Box p={1} border={2} borderRadius={2} borderColor={color.borderLightGray}>
                  <DraftsOutlinedIcon fontSize="medium" />
                </Box>
              </Grid>
            </Grid>
            <Grid xs={12} item>
              <Typography component="h1" variant="h5" align="center">
                {fm('email_verification')}
              </Typography>
              <Typography mt={1} align="center">
                <FormattedMessage
                  id="sent_code_to"
                  values={{ email: <b>{verificationEmail}</b> }}
                />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <OTPInput onChange={handleOtpChange} />
            </Grid>
            <Grid item xs={12}>
              <LoadingButton fullWidth type="submit" id="login-form-submit" loading={fsip}>
                {fm('continue', 'Continue')}
              </LoadingButton>
            </Grid>
            <Grid item xs={12}>
              <Box textAlign="center">
                <Typography variant="caption">{fm('not_recieved_email')}</Typography>
                <Typography
                  ml={1}
                  color={resendOtpEnabled ? 'primary' : 'disabled'}
                  className={cx('underline', resendOtpEnabled ? 'pointer' : '')}
                  variant="caption"
                  onClick={onClickResendEmail}
                >
                  {fm('click_to_resend')}
                </Typography>
                {!resendOtpEnabled && (
                  <Typography variant="caption">
                    {' '} ({fm('wait_to_send', undefined, { seconds: wait })})
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
        </form>
      </Container>
    </ErrorBoundary>
  );
};

export default EmailVerification;
