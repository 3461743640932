import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  requestTimeWorkCode,
  requestOtherWorkCode,
  requestTimeWorkCodes,
  requestUpdateOtherWorkCode,
  requestOtherWorkCodes,
  requestCreateTimeWorkCode,
  requestCreateOtherWorkCode,
  requestDeleteTimeWorkCode,
  requestDeleteOtherWorkCode,
  requestUpdateTimeWorkCode,
  requestUpdateTimeWorkCodeActiveStatus,
} from './thunk';

const workCode = createSlice({
  name: 'workCode',
  initialState: {
    record: {},
    records: [],
    refreshAllWorkCodes: {},
    openedWorkCodeId: null,
    workCodeDialogError: null,
    showTimeWorkCodeDialog: false,
    showOtherWorkCodeDialog: false,
  },
  reducers: {
    setWorkCodeFields (state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(requestTimeWorkCode.fulfilled, requestOtherWorkCode.fulfilled),
      (state, action) => ({
        ...state,
        record: action.payload.record,
      }),
    );
    builder.addMatcher(
      isAnyOf(
        requestUpdateOtherWorkCode.fulfilled,
        requestDeleteTimeWorkCode.fulfilled,
        requestDeleteOtherWorkCode.fulfilled,
        requestUpdateTimeWorkCodeActiveStatus.fulfilled,
      ), (state, action) => ({
        ...state,
        ...action.payload,
      }),
    );
    builder.addMatcher(
      isAnyOf(requestTimeWorkCodes.fulfilled, requestOtherWorkCodes.fulfilled),
      (state, action) => ({
        ...state,
        records: action.payload.records,
      }),
    );
    builder.addMatcher(
      isAnyOf(
        requestCreateTimeWorkCode.fulfilled,
        requestCreateOtherWorkCode.fulfilled,
        requestUpdateTimeWorkCode.fulfilled,
        requestUpdateOtherWorkCode.fulfilled,
      ),
      (state, action) => ({
        ...state,
        ...action.payload,
        fsip: false,
      }),
    );
  },
});

const { reducer, actions } = workCode;

export const { setWorkCodeFields } = actions;

export default reducer;
