import * as Yup from 'yup';
import { fm, deepTrim } from 'utils/string';

const smsSenderNameSchema = Yup.object().shape({
  senderName: Yup.string()
    // must contain atleast one character A-Z
    // User can't type phonenumber or numbers only Twillio doesnt accept that
    .matches(/^(?=.*[a-zA-Z])(?=.*[a-zA-Z0-9])([a-zA-Z0-9 ]{1,11})$/m, fm('profile.sms_sender.name.requirment'))
    .required(fm('profile.sms_sender.name')),
});

export const validateSmsSenderName = async (obj) => {
  await smsSenderNameSchema.validate(obj); // eslint-disable-line
  return deepTrim(obj);
};

const validateBreakTimeSchema = Yup.object().shape({
  breakDuration: Yup.number().required(fm('profile_break_time_setting')).test(
    'breakDuration',
    fm('profile_break_time_smaller'),
    // eslint-disable-next-line func-names
    function (breakDuration) {
      return this.parent.breakLimit > breakDuration;
    },
  ),
});

export const validateBreakTime = async (obj) => {
  await validateBreakTimeSchema.validate(obj);
  return deepTrim(obj);
};
