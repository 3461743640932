import { createSlice } from '@reduxjs/toolkit';
import { requestProductModulesSuggestions } from './thunk';

const productModules = createSlice({
  name: 'productModules',
  initialState: {
    records: [],
    module: {},
  },
  reducers: {
    setProductModuleFields (state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(requestProductModulesSuggestions.fulfilled, (state, action) => ({
      ...state,
      records: action.payload.records,
    }));
  },
});

const { reducer, actions } = productModules;

export const { setProductModuleFields } = actions;

export default reducer;
