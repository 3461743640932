import {
  SET_EMPLOYEE_VIEW_TIMEMANAGEMENT_FIELDS,
  SET_EMPLOYEE_VIEW_SHIFT_TIMES_FIELDS,
  SET_EMPLOYEE_VIEW_OVERTIME_FIELDS,
} from '../actions';

const initialState = {
  timeManagement: {
    dayShift: {},
  },
  shiftTimes: {
    records: {},
  },
  overTime: {
    timeCodes: {},
    records: [],
  },
};

function employeeView (state = initialState, action) {
  switch (action.type) {
    case SET_EMPLOYEE_VIEW_TIMEMANAGEMENT_FIELDS:
      return {
        ...state,
        timeManagement: {
          ...state.timeManagement,
          ...action.payload,
        },
      };

    case SET_EMPLOYEE_VIEW_SHIFT_TIMES_FIELDS:
      return {
        ...state,
        shiftTimes: {
          ...state.shiftTimes,
          ...action.payload,
        },
      };

    case SET_EMPLOYEE_VIEW_OVERTIME_FIELDS:
      return {
        ...state,
        overTime: {
          ...state.overTime,
          ...action.payload,
        },
      };

    default:
      return state;
  }
}

export default employeeView;
