import { useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { setApplicationFields } from 'appRedux/actions';
import { connect } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';

function BreakpointsBaseline (props) {
  const theme = useTheme();

  const breakpointsMap = {
    onlyXl: useMediaQuery(theme.breakpoints.only('xl')),
    onlyLg: useMediaQuery(theme.breakpoints.only('lg')),
    onlyMd: useMediaQuery(theme.breakpoints.only('md')),
    onlySm: useMediaQuery(theme.breakpoints.only('sm')),
    onlyXs: useMediaQuery(theme.breakpoints.only('xs')),
    xlUp: useMediaQuery(theme.breakpoints.up('xl')),
    xlDown: useMediaQuery(theme.breakpoints.down('xl')),
    lgUp: useMediaQuery(theme.breakpoints.up('lg')),
    lgDown: useMediaQuery(theme.breakpoints.down('lg')),
    mdUp: useMediaQuery(theme.breakpoints.up('md')),
    mdDown: useMediaQuery(theme.breakpoints.down('md')),
    smUp: useMediaQuery(theme.breakpoints.up('sm')),
    smDown: useMediaQuery(theme.breakpoints.down('sm')),
    xsUp: useMediaQuery(theme.breakpoints.up('xs')),
    xsDown: useMediaQuery(theme.breakpoints.down('xs')),
  };

  useEffect(() => {
    props.setApplicationFields({ breakpoints: breakpointsMap });
  }, Object.values(breakpointsMap));

  return null;
}

BreakpointsBaseline.propTypes = {
  setApplicationFields: PropTypes.func.isRequired,
};

const mapDispatchToProps = { setApplicationFields };

export default connect(null, mapDispatchToProps)(BreakpointsBaseline);
