import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Container,
  Grid,
  Typography,
} from 'components/library';
import signUser from 'images/fortnox-f-logo.png';
import Logo from 'images/Logo-bos.png';
import { fm } from 'utils/string';
import { reviews } from './reviewsData';
import useStyles from './styles';

const SignupInfo = () => {
  const classes = useStyles();
  const [currentReview, setCurrentReview] = useState(0);
  const intervalRef = useRef(null); // Reference for the interval

  const resetInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current); // Clear the existing interval
    }

    // Set a new interval to automatically change reviews every 3 seconds
    intervalRef.current = setInterval(() => {
      setCurrentReview((prevReview) => (prevReview + 1) % reviews.length);
    }, 3000);
  };

  // Set up the initial interval on component mount
  useEffect(() => {
    resetInterval();

    return () => clearInterval(intervalRef.current); // Cleanup the interval on component unmount
  }, []);

  // Function to handle dot click and reset the interval
  const handleDotClick = (index) => {
    setCurrentReview(index); // Set the clicked review
    resetInterval(); // Reset the interval to prevent immediate change
  };

  // Function to render dots for review indicators
  const renderDots = () => (
    <Box display="flex" justifyContent="center" mt={2}>
      {reviews.map((review, index) => (
        <Box
          key={review.id}
          mx={0.5}
          width={10}
          height={10}
          borderRadius="50%"
          bgcolor={currentReview === index ? 'black' : 'grey'}
          onClick={() => handleDotClick(index)} // Handle dot click
          className="pointer"
        />
      ))}
    </Box>
  );

  return (
    <Container component="main" maxWidth="md" sx={{ height: '100%' }}>
      <Grid container spacing={2} my={4} mx={2} className={classes.messageContainer}>
        <Grid item xs={12}>
          <Grid container justifyContent="center">
            <img
              height={42}
              width="auto"
              src={Logo}
              alt="Fortnox Logo"
            />
          </Grid>
        </Grid>
        <Grid xs={12}>
          <Typography component="span" align="center" variant="h5">
            <Box className={classes.black}>
              {`"${reviews[currentReview].text}"`}
            </Box>
          </Typography>
          <Box display="flex" justifyContent="center" mb={2} mt={4}>
            <img
              height={64}
              width="auto"
              src={signUser}
              alt="avatar"
            />
          </Box>
          <Typography component="span" align="center" variant="h6">
            <Box my={1}>
              {fm('fortnox_user')}
            </Box>
          </Typography>
          {renderDots()}
        </Grid>
      </Grid>
    </Container>
  );
};

export default SignupInfo;
