import { SET_HANDLED_RECURRING_BOOKINGS_FIELDS } from '../actions';

const initialState = {
  filters: {
    advancedFilter: 'current',
    customerId: null,
    repeatEvery: null,
    interval: null,
    days: [],
  },
  records: [],
};

function recurrings (state = initialState, action) {
  switch (action.type) {
    case SET_HANDLED_RECURRING_BOOKINGS_FIELDS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

export default recurrings;
