import React, { useMemo } from 'react';
import MuiPopover from '@mui/material/Popover';
import { func, shape, oneOfType, string } from 'prop-types';

const verticalOriginValues = ['top', 'center', 'bottom'];
const horizontalOriginValues = ['left', 'center', 'right'];

const origins = verticalOriginValues.reduce((result, verticalValue) => {
  horizontalOriginValues.forEach((horizontalValue) => {
    const verticalPart = `vertical${verticalValue.charAt(0).toUpperCase() + verticalValue.slice(1)}`;
    const horizontalPart = `Horizontal${horizontalValue.charAt(0).toUpperCase() + horizontalValue.slice(1)}`;
    const key = `${verticalPart}${horizontalPart}`;
    result[key] = { vertical: verticalValue, horizontal: horizontalValue };
  });
  return result;
}, {});

export default function Popover (props) {
  const { onEnter, children, TransitionProps, anchorOrigin, transformOrigin, ...restProps } = props;

  const transitionProps = useMemo(() => ({
    onEnter,
    ...TransitionProps,
  }), [onEnter, TransitionProps]);

  const anchorOriginObject = typeof anchorOrigin === 'string'
    ? origins[anchorOrigin]
    : anchorOrigin;
  const transformOriginObject = typeof transformOrigin === 'string'
    ? origins[transformOrigin]
    : transformOrigin;

  return (
    <MuiPopover
      TransitionProps={transitionProps}
      disableScrollLock
      anchorOrigin={anchorOriginObject}
      transformOrigin={transformOriginObject}
      {...restProps}
    >
      {children}
    </MuiPopover>
  );
}

Popover.propTypes = {
  onEnter: func,
  TransitionProps: shape({}),
  children: func,
  anchorOrigin: oneOfType(shape({}), string),
  transformOrigin: oneOfType(shape({}), string),
};
