import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box } from 'components/library';
import useStyles from './styles';

export default function RegisterAccount ({ mt = 10, children }) {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Grid
        container
        justifyContent="space-between"
        direction="row-reverse"
        className={classes.content}
        mt={mt}
      >
        {children}
      </Grid>
    </Box>
  );
}

RegisterAccount.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  mt: PropTypes.number,
};
