import { SET_TAGS_FIELDS } from '../actions';

const initialState = {
  records: [],
  tagsPopoverAnchorEl: null,
  editTagsDialogError: null,
};

function tags (state = initialState, action) {
  switch (action.type) {
    case SET_TAGS_FIELDS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

export default tags;
