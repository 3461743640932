import axios from 'utils/Api';
import { omit } from 'utils/lodash';
import camelize from 'camelize';
// import { fm } from 'utils/string';
import { multiStateGet } from 'appRedux/reducers/utility';
import {
  setFilters,
  setSnackbarError,
  setSuperAdminInvoicesFields,
  // setSnackbarSuccess,
} from 'appRedux/actions';
import {
  getFormattedInvoicesQueryParams,
  getFormattedInvoicesListData,
  getFormattedCreateInvoicesRequestBody,
} from './selector';

export const requestInvoices = (name) => async (dispatch, getState) => {
  const { filters } = getState();
  try {
    const invoicesFilters = multiStateGet(name, filters);
    const params = getFormattedInvoicesQueryParams(invoicesFilters);
    const response = await axios.get('/api/v3/super_admin/invoices', { params });
    const { records, ...rest } = getFormattedInvoicesListData(response.data.data);
    dispatch(setSuperAdminInvoicesFields({
      records,
      companyIdInvoiceItemsMap: {},
      selectedCustomerIds: [],
      expandedRowsIndexes: [],
    }));
    dispatch(setFilters({ ...rest, name }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar.superAdmin.requestInvoice.error'));
  }
};

export const requestInvoicesHistory = (name) => async (dispatch, getState) => {
  const { filters } = getState();
  try {
    const invoicesFilters = multiStateGet(name, filters);
    const params = getFormattedInvoicesQueryParams(invoicesFilters);
    const response = await axios.get('/api/v3/super_admin/invoices/invoiced_customers', { params });
    const { records: historyRecords, ...rest } = getFormattedInvoicesListData(response.data.data);
    dispatch(setSuperAdminInvoicesFields({ historyRecords }));
    dispatch(setFilters({ ...rest, name }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar.superAdmin.requestInvoice.error'));
  }
};

export const requestCompanyInvoiceItems = (name, companyId) => async (dispatch, getState) => {
  const { superAdmin: { invoices }, filters } = getState();
  try {
    const invoicesFilters = multiStateGet(name, filters);
    const params = omit(['page', 'per_page'])(getFormattedInvoicesQueryParams(invoicesFilters));
    params.company_id = companyId;
    const response = await axios.get('/api/v3/super_admin/invoices/details', { params });
    const records = camelize(response.data.data);
    const companyIdInvoiceItemsMap = { ...invoices.companyIdInvoiceItemsMap, [companyId]: records };
    dispatch(setSuperAdminInvoicesFields({ companyIdInvoiceItemsMap }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar.superAdmin.requestInvoice.error'));
  }
};

export const requestCreateCompanyInvoices = (details) => async (dispatch) => {
  try {
    // args = selectedCustomerIds, companyIdInvoiceItemsMap, invoiceDetails, filters
    const requestBody = getFormattedCreateInvoicesRequestBody(details);
    await axios.post('/api/v3/super_admin/invoices/send_mass_invoice', requestBody);
    dispatch(setSuperAdminInvoicesFields({ refreshAllCompanies: {} }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar.superAdmin.requestInvoice.error'));
  }
};

export const requestUpdateAllInvoicesStatus = () => async (dispatch) => {
  try {
    await axios.get('/api/v3/super_admin/invoices/fetch_all');
    dispatch(setSuperAdminInvoicesFields({ invoicesStatusUpdateInProgress: true }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar.superAdmin.requestInvoice.error'));
  }
};
