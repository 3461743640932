import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from 'components/library/TablePagination';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableBodyContent from './TableBody';
import SelectAllHeaderCell from './SelectAllHeaderCell';
import SortingOptionsPopoverContent from './SortingOptionsPopoverContent';
import { rowsPerPageOptions, getLabelDisplayedRows, labelRowsPerPage, tableLocalization } from './tableUtils';

export default function MaterialTable (props) {
  const { columns = [], pagination, PaginationLeftComponent } = props;
  const { order, orderBy } = pagination;

  let paddingTableCell;

  if (props.showRowCheckbox) {
    paddingTableCell = <SelectAllHeaderCell {...props} />;
  } else if (props.rowType === 'collapsible') {
    paddingTableCell = <TableCell />;
  } else {
    paddingTableCell = null;
  }

  const handleChangePage = useCallback((event, newPage) => {
    props.handleChangePage(event, newPage + 1);
  }, []);

  const paginationColspanPadding = useMemo(
    () => (
      props.showRowCheckbox && props.hideSelectAllCheckbox && props.rowType === 'collapsible'
        ? 2 : 1
    ),
    [],
  );

  const createSortHandler = (property) => (event) => {
    props.onRequestSort(event, property);
  };

  const getLabelDisplayedRowsMsg = useCallback((b) => getLabelDisplayedRows(b, pagination), [pagination]);

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="sticky table" size="small" dense table localization={tableLocalization}>
          <TableHead>
            <TableRow>
              {paddingTableCell}
              {columns.map((column) => (
                <TableCell key={`table-column-${column.id}`} style={{ minWidth: column.minWidth }}>
                  {column.showSortIcon ? (
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : 'asc'}
                      onClick={createSortHandler(column)}
                    >
                      {column.label}
                    </TableSortLabel>
                  ) : column.label}
                  {column.showMenuIcon && (
                    <SortingOptionsPopoverContent
                      sortingOptions={column.sortingOptions}
                      name={props.name}
                    />
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableBodyContent {...props} />
          </TableBody>
          {props.showPagination && (
            <TableFooter>
              <TableRow>
                <TableCell colSpan={columns.length + paginationColspanPadding}>
                  <TablePagination
                    rowsPerPageOptions={rowsPerPageOptions}
                    component="div"
                    colSpan={columns.length + paginationColspanPadding}
                    count={pagination.totalRecords}
                    rowsPerPage={pagination.perPage}
                    page={pagination.page - 1}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={props.handleChangeRowsPerPage}
                    labelDisplayedRows={getLabelDisplayedRowsMsg}
                    labelRowsPerPage={labelRowsPerPage}
                  />
                </TableCell>
              </TableRow>
            </TableFooter>
          )}
        </Table>

      </TableContainer>
      <div className="d-flex space-between white">
        <PaginationLeftComponent />
      </div>
    </>
  );
}

MaterialTable.propTypes = {
  pagination: PropTypes.shape({
    totalRecords: PropTypes.number,
    page: PropTypes.number,
    perPage: PropTypes.number,
    order: PropTypes.string,
    orderBy: PropTypes.string,
  }),
  showPagination: PropTypes.bool,
  rowType: PropTypes.string,
  showRowCheckbox: PropTypes.bool,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleChangeRowsPerPage: PropTypes.func,
  handleChangePage: PropTypes.func,
  PaginationLeftComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.string]),
  onRequestSort: PropTypes.func,
  name: PropTypes.string,
  hideSelectAllCheckbox: PropTypes.bool,
};

MaterialTable.defaultProps = {
  showPagination: true,
  pagination: {},
  showRowCheckbox: false,
  rowType: 'normal',
  PaginationLeftComponent: () => <div />,
};
