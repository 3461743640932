/* eslint-disable camelcase */
import axios from 'utils/Api';
import camelize from 'utils/camelize';
import { multiStateGet } from 'appRedux/reducers/utility';
import { batch } from 'react-redux';
import { setSuperAdminCustomersFields, setSnackbarSuccess, setSnackbarError, setFilters } from 'appRedux/actions';
import { getFormattedCompanyCustomerReqBody, getFormattedCustomersData } from './selector';

export const requestCreateCompanyCustomer = (details, cb) => async (dispatch) => {
  try {
    const company = getFormattedCompanyCustomerReqBody(details);
    await axios.post('/api/v3/super_admin/registered_customers', company);
    batch(() => {
      dispatch(setSuperAdminCustomersFields({ refreshAllCustomers: {} }));
      dispatch(setSnackbarSuccess('snackbar_customer_updated'));
    });
    cb();
  } catch (err) {
    dispatch(setSnackbarError('snackbar_superAdmin_createCustomer_error'));
  }
};

export const requestCompanyCustomers = (name) => async (dispatch, getState) => {
  const { filters } = getState();
  try {
    const { perPage: per_page, page, query } = multiStateGet(name, filters);
    const params = { per_page, page, q: query };
    const response = await axios.get('/api/v3/super_admin/registered_customers', { params });
    const { records, ...restParams } = getFormattedCustomersData(response.data.data);
    batch(() => {
      dispatch(setSuperAdminCustomersFields({ records }));
      dispatch(setFilters({ ...restParams, name }));
    });
  } catch (err) {
    dispatch(setSnackbarError('snackbar_superAdmin_createCustomer_error'));
  }
};

export const requestCompanyCustomer = (customerId) => async (dispatch) => {
  try {
    const response = await axios.get(`/api/v3/super_admin/registered_customers/${customerId}`);
    const customer = camelize(response.data.data, true);
    batch(() => {
      dispatch(setSuperAdminCustomersFields({ customer }));
    });
  } catch (err) {
    dispatch(setSnackbarError('snackbar_superAdmin_createCustomer_error'));
  }
};

export const requestUpdateCompanyCustomer = ({ id, ...customer }) => async (dispatch) => {
  try {
    const reqBody = getFormattedCompanyCustomerReqBody(customer);
    await axios.put(`/api/v3/super_admin/registered_customers/${id}`, reqBody);
    dispatch(setSuperAdminCustomersFields({ refreshAllCustomers: {} }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar_superAdmin_createCustomer_error'));
  }
};

export const requestDeleteCompanyCustomer = (customerId) => async (dispatch) => {
  try {
    await axios.delete(`/api/v3/super_admin/registered_customers/${customerId}`);
    dispatch(setSuperAdminCustomersFields({ refreshAllCustomers: {}, showCustomerModal: false }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar_superAdmin_createCustomer_error'));
  }
};

export const requestExportCompanyCustomerToFortnox = (customerId) => async (dispatch) => {
  try {
    await axios.put(`/api/v3/super_admin/registered_customers/${customerId}/export`);
    dispatch(setSuperAdminCustomersFields({ refreshAllCustomers: {} }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar_superAdmin_createCustomer_error'));
  }
};

export const requestFetchCompanyCustomerFromFortnox = (fortnox_id) => async (dispatch, getState) => {
  const { superAdmin: { customers: { customer: prevCustomer = {} } } } = getState();
  try {
    const params = { fortnox_id };
    const response = await axios.get('/api/v3/super_admin/registered_customers/fetch_from_fortnox', { params });
    const fetchedCustomer = { ...camelize(response.data.data, true), id: prevCustomer.id, fortnoxStatus: true };
    const customer = { ...prevCustomer, ...fetchedCustomer };
    dispatch(setSuperAdminCustomersFields({ customer }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar_superAdmin_createCustomer_error'));
  }
};

export const requestGetCompanyInvoices = (customerId) => async (dispatch) => {
  try {
    await axios.get(`/api/v3/super_admin/registered_customers/${customerId}/get_invoices`);
    dispatch(setSuperAdminCustomersFields({ refreshAllCustomers: {} }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar_superAdmin_createCustomer_error'));
  }
};
