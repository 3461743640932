import React from 'react';
import PropTypes, { node, shape } from 'prop-types';
import MaterialDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import Box from '@mui/material/Box';

export { default as DialogActions } from '@mui/material/DialogActions';
export { default as DialogContentText } from '@mui/material/DialogContentText';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"], [class*="MuiButtonBase-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export function DialogTitle (props) {
  return (
    <MaterialDialogTitle style={{ cursor: 'move' }} {...props} id="draggable-dialog-title">
      {props.children}
    </MaterialDialogTitle>
  );
}

DialogTitle.propTypes = {
  children: PropTypes.node,
};

export default function CustomDialog (props) {
  return (
    <Dialog
      open={props.open}
      scroll={props.scroll}
      fullWidth={props.fullWidth}
      maxWidth={props.maxWidth}
      onClose={props.onClose}
      fullScreen={props.fullScreen}
      sx={props.sx}
      PaperProps={props.PaperProps}
      TransitionProps={{
        onEntered: props.onEntered,
        onEnter: props.onEnter,
        onExit: props.onExit,
        onExited: props.onExited,
      }}
      PaperComponent={PaperComponent}
    >
      {props.children}
    </Dialog>
  );
}

CustomDialog.defaultProps = {
  open: false,
};

CustomDialog.propTypes = {
  open: PropTypes.bool,
  scroll: PropTypes.string,
  onExit: PropTypes.func,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
  onEntered: PropTypes.func,
  sx: PropTypes.shape({}),
  PaperProps: PropTypes.shape({}),
  children: PropTypes.node,
  fullWidth: PropTypes.bool,
  fullScreen: PropTypes.bool,
  maxWidth: PropTypes.string,
  onClose: PropTypes.func,
};

export function DialogContent (props) {
  return (
    <MuiDialogContent>
      <Box {...props.boxProps}>
        {props.children}
      </Box>
    </MuiDialogContent>
  );
}

DialogContent.defaultProps = {
  boxProps: {
    mt: 0.7,
  },
};

DialogContent.propTypes = {
  children: node,
  boxProps: shape({}),
};
