import { SET_OPEN_API_FIELDS } from 'appRedux/actions';

const initialState = {
  records: [],
};

function openApi (state = initialState, action) {
  switch (action.type) {
    case SET_OPEN_API_FIELDS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

export default openApi;
