import {
  SET_REPORTS_FIELDS,
  SET_REPORTS_BOOKINGS_FIELDS,
  SET_REPORTS_EMPLOYEES_FIELDS,
  SET_EMPLOYEE_HOUR_REPORT_FIELDS,
} from '../actions';

const initialState = {
  activeTab: 0,
  bookings: {
    records: [],
  },
  employees: {
    records: [],
  },
  employeeHourReport: {},
};

function reports (state = initialState, action) {
  switch (action.type) {
    case SET_REPORTS_FIELDS:
      return {
        ...state,
        ...action.payload,
      };

    case SET_REPORTS_BOOKINGS_FIELDS:
      return {
        ...state,
        bookings: {
          ...state.bookings,
          ...action.payload,
        },
      };

    case SET_REPORTS_EMPLOYEES_FIELDS:
      return {
        ...state,
        employees: {
          ...state.employees,
          ...action.payload,
        },
      };
    case SET_EMPLOYEE_HOUR_REPORT_FIELDS:
      return {
        ...state,
        employeeHourReport: {
          ...state.employeeHourReport,
          ...action.payload,
        },
      };

    default:
      return state;
  }
}

export default reports;
