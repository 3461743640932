import React from 'react';
import { fm } from 'utils/string';
import BankidLogo from 'images/BankID_logo.svg';
import { Avatar } from 'components/library';
import {
  DoneAllIcon,
  FolderOpenIcon,
  MailOutlineIcon,
  NotificationsNoneIcon,
  SmsIcon,
  VisibilityIcon,
  CloseIcon,
  DoneIcon,
} from 'components/library/icons';

export const proposalModalTabsTypes = {
  detail: 0,
  tagsAndNotes: 1,
  attachment: 2,
  textLogo: 4,
  cancellationPolicy: 5,
  companyPolicy: 6,
  activity: 3,
  signature: 7,
};

export const sendProposalTabsTypes = {
  email: 0,
  sms: 1,
};

export const proposalStatusTypes = {
  draft: 'draft',
  sent: 'sent',
  approved: 'approved',
  cancelled: 'cancelled',
  signed: 'signed',
};

export const houseworkType = {
  no_housework: 'None',
  rut: 'Rut',
  rot: 'Rot',
};

export const proposalModalTabs = [
  {
    title: fm('proposal', 'Proposal'),
  },
  {
    title: fm('notes', 'Notes'),
  },
  {
    title: fm('attachment', 'Attach'),
  },
  {
    title: fm('activity', 'Activity'),
  },
  {
    title: fm('cancellation_policy', 'Cancellation Policy'),
  },
  {
    title: fm('company_policy', 'Company Policy'),
  },
];

export const oneMB = '1048576';

export const proposalActivityIcons = {
  1: <FolderOpenIcon />,
  2: <FolderOpenIcon />,
  3: <VisibilityIcon />,
  5: <DoneAllIcon />,
  6: <CloseIcon />,
  7: <DoneAllIcon />,
  8: <DoneIcon />,
  9: <CloseIcon />,
  10: <DoneAllIcon />,
  11: <MailOutlineIcon />,
  12: <SmsIcon />,
  13: <NotificationsNoneIcon />,
  14: <Avatar src={BankidLogo} />,
};

export const proposalActivityMessages = {
  1: fm('proposal_created'),
  2: fm('proposal_updated'),
  3: fm('proposal_viewed'),
  5: fm('proposal_owner_approved'),
  6: fm('proposal_owner_canceled'),
  7: fm('proposal_owner_signed'),
  8: fm('proposal_customer_approved'),
  9: fm('proposal_customer_canceled'),
  10: fm('proposal_customer_signed'),
  11: fm('proposal_sent_email'),
  12: fm('proposal_sent_sms'),
  13: fm('proposal_sent_both'),
  14: fm('proposal_customer_signed_bankid'),
};
