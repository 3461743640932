import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

export default function ErrorMessage (props) {
  return props.text
    ? <Typography color="error" variant="caption">{props.text}</Typography>
    : null;
}

ErrorMessage.propTypes = {
  text: PropTypes.string,
};
