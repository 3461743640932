import { get, pick, omit } from 'utils/lodash';
import { names } from 'utils/enum';
import { multiStateSet } from '../utility';
import { SET_FILTERS, RESET_FILTERS, SET_INITIAL_FILTERS } from '../../actions';
import initialState from './initialState';

/*
 * const initialState = {
 *   names: {
 *     allBookings: {
 *       filters: '',
 *     },
 *     etc: {
 *     },
 *   },
 * };
 */

const omitFieldsBeforeSaving = omit(['query']);
const omitNames = omit([names.notifications]);

const getInitialState = () => {
  try {
    const savedFilters = omitNames(JSON.parse(localStorage.getItem('filters')).names);
    if (savedFilters) {
      return Object.keys(initialState.names).reduce((result, name) => {
        result.names[name] = { ...initialState.names[name], ...savedFilters[name] };
        return result;
      }, { names: {} });
    }
    return initialState;
  } catch (err) {
    return initialState;
  }
};

const saveFiltersToLocalStorage = (filtersState) => {
  const stateToSave = Object.keys(filtersState.names).reduce((result, name) => {
    result.names[name] = omitFieldsBeforeSaving(filtersState.names[name]);
    return result;
  }, { names: {} });
  localStorage.setItem('filters', JSON.stringify(stateToSave));
};

const filters = (state = getInitialState(), action) => {
  switch (action.type) {
    case SET_INITIAL_FILTERS: {
      const updatedState = { ...state, ...action.payload };
      return updatedState;
    }

    case SET_FILTERS: {
      const updatedFilters = multiStateSet(action.payload.name, state, action.payload);
      saveFiltersToLocalStorage(updatedFilters);
      return updatedFilters;
    }

    case RESET_FILTERS: {
      const prevState = get(`names.${action.name}`)(state);
      const updatedFilters = {
        ...state,
        names: {
          ...state.names,
          [action.name]: {
            ...initialState.names[action.name],
            ...pick(['totalRecords'])(prevState),
          },
        },
      };
      localStorage.setItem('filters', JSON.stringify(updatedFilters));
      return updatedFilters;
    }

    default:
      return state;
  }
};

export default filters;
