import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Snackbar, Alert } from 'components/library';
import { FormattedMessage } from 'react-intl';
import { resetSnackbar } from 'appRedux/actions';

const anchorOrigin = { vertical: 'bottom', horizontal: 'center' };

function CustomizedSnackbars () {
  const dispatch = useDispatch();
  const snackbar = useSelector((state) => state.snackbar);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    dispatch(resetSnackbar());
  };

  const checkTranslation = (text) => (text === snackbar.message ? snackbar.message : text);

  return (
    <Snackbar open={snackbar.open} autoHideDuration={5000} onClose={handleClose} anchorOrigin={anchorOrigin}>
      <Alert onClose={handleClose} severity={snackbar.severity}>
        {snackbar.message ? (
          <FormattedMessage id={snackbar.message}>
            {checkTranslation}
          </FormattedMessage>
        ) : null}
      </Alert>
    </Snackbar>
  );
}

export default CustomizedSnackbars;
