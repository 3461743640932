import { createSlice } from '@reduxjs/toolkit';
import { fm } from 'utils/string';
import {
  requestSubCompanySignIn,
  requestIntegratedCompanies,
  requestIntegrateSubCompany,
} from './thunk';

const companyIntegration = createSlice({
  name: 'companyIntegration',
  initialState: {
    subCompany: {},
    integrations: {
      subCompany: [],
      mainCompany: [],
    },
  },
  reducers: {
    setCompanyIntegrationFields (state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(requestSubCompanySignIn.fulfilled, (state, action) => ({
      ...state,
      subCompany: {
        ...state.subCompany,
        ...action.payload,
      },
      fsip: false,
      showSubCompanyConfirmationDialog: true,
      showNewCompanySignupDialog: false,
      signInError: null,
    }));
    builder.addCase(requestIntegratedCompanies.fulfilled, (state, action) => ({
      ...state,
      ...action.payload,
    }));
    builder.addCase(requestIntegrateSubCompany.fulfilled, (state) => ({
      ...state,
      fsip: false,
      showSubCompanyConfirmationDialog: false,
    }));
    builder.addCase(requestSubCompanySignIn.rejected, (state, action) => ({
      ...state,
      fsip: false,
      signInError: fm(action.payload),
    }));
  },
});

const { reducer, actions } = companyIntegration;

export const { setCompanyIntegrationFields } = actions;

export default reducer;
