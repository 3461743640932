import { createSlice } from '@reduxjs/toolkit';
import { getStandardDate, add, formatDate, formatStrings } from 'utils/dateTime';
import { requestBookings, requestBooking } from './thunk';

const profile = createSlice({
  name: 'booking',
  initialState: {
    filters: {
      startDate: getStandardDate(),
      endDate: formatDate(add(getStandardDate(), { days: 6 }), formatStrings.filtersDate),
      supervisorOnly: false,
    },
    records: {},
    record: {},
  },
  reducers: {
    setBookingFilters: (state, action) => ({
      ...state,
      filters: {
        ...state.filters,
        ...action.payload,
      },
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(requestBookings.fulfilled, (state, action) => ({
      ...state,
      filters: { ...state.filters, ...action.payload.filters },
      workingTimeThisWeek: action.payload.workingTimeThisWeek,
      records: action.payload.filters.page !== 1
        ? { ...state.records, ...action.payload.records }
        : action.payload.records,
    }));
    builder.addCase(requestBooking.fulfilled, (state, action) => ({
      ...state,
      record: action.payload,
    }));
  },
});

const { reducer, actions } = profile;

export const { setBookingFilters } = actions;

export default reducer;
