import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { requestUserCompanies, requestCompanyToken } from 'appRedux/thunks/auth';
import Logo from 'images/Logo-bos.png';
import {
  Grid,
  Box,
  AvatarImg,
  Typography,
  Radio,
  Button,
} from 'components/library';
import Loader from 'components/library/Loader';
import { useHistory } from 'react-router-dom';
import { fm } from 'utils/string';
import { colors } from 'utils/colors';
import { StyledBox } from './styledComponent';

const UserCompanies = (props) => {
  const { currentCompanyId, openedFromModal, closeModal } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedCompany, setSelectedCompany] = useState(null);
  const fsip = useSelector((state) => state.auth.fsip);
  const userToken = useSelector((state) => state.auth.userToken);
  const userCompanies = useSelector((state) => state.auth.userCompanies) || [];

  useEffect(() => {
    if (userCompanies.length) {
      if (currentCompanyId) {
        const currentCompany = userCompanies.find((company) => company.id === currentCompanyId);
        setSelectedCompany(currentCompany);
        return;
      }
      setSelectedCompany(userCompanies[0]);
    }
  }, [userCompanies]);

  useEffect(() => {
    if (!userToken) {
      return history.replace('/login');
    }
    dispatch(requestUserCompanies(history));
  }, []);

  const handleClickContinue = () => {
    if (currentCompanyId === selectedCompany.id) return closeModal();
    dispatch(requestCompanyToken(selectedCompany, history, openedFromModal));
    if (openedFromModal) {
      closeModal();
    }
  };

  const handleSelectCompany = (company) => {
    if (company.id === selectedCompany.id) return;
    setSelectedCompany(company);
  };

  if (userCompanies.length <= 1) return <Loader />;

  return (
    <StyledBox openedFromModal={openedFromModal}>
      <Box
        p={5}
        bgcolor={colors.lightBlue}
        borderRadius={1}
        boxShadow={2}
      >
        {fsip && <Loader />}
        <Box width="fit-content" mx="auto" mb={2}>
          <img
            height={30}
            width="auto"
            src={Logo}
            alt="Bokning och Schema Online Logo"
          />
        </Box>
        <Box sx={{ opacity: fsip && '20%' }}>
          <Box mb={4}>
            <Typography fontSize={24} fontWeight={700}>{fm('select_company')}</Typography>
            <Typography fontSize={16} mt={1.5}>{fm('select_company_text')}</Typography>
          </Box>
          <Grid container display="flex" flexDirection="column" gap={4} mb={4}>
            {userCompanies.map((company) => (
              <Grid item>
                <Box mb={1}>
                  <Typography fontSize={16} fontWeight={700}>{company.name}</Typography>
                </Box>
                <Box
                  p={1}
                  display="flex"
                  alignContent="center"
                  justifyContent="space-between"
                  border="1px solid"
                  borderColor={colors.grey1}
                  bgcolor={colors.white}
                  borderRadius={2}
                >
                  <Box display="flex" alignItems="center" gap={2}>
                    <AvatarImg
                      key={company.userName}
                      name={company.userName}
                      width={4}
                      height={4}
                      fontSize={12}
                      useUserAuthAxios
                      src={company.userProfileLogo}
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    />
                    <Box>
                      <Typography fontSize={16}>{company.userName}</Typography>
                      <Typography fontSize={12}>{company.userRole}</Typography>
                    </Box>
                  </Box>
                  <Box>
                    <Radio
                      color="primary"
                      checked={company.id === selectedCompany?.id}
                      onClick={() => handleSelectCompany(company)}
                    />
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
          <Button
            width="100%"
            size="medium"
            onClick={handleClickContinue}
            backgroundColor={colors.darkBlue}
            borderRadius={1}
          >
            {fm('continue', 'Continue')}
          </Button>
        </Box>
      </Box>
    </StyledBox>
  );
};

export default UserCompanies;

UserCompanies.propTypes = {
  currentCompanyId: PropTypes.number,
  openedFromModal: PropTypes.bool,
  closeModal: PropTypes.func,
};
