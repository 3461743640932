export const getFormattedCompanyItemReqBody = (item) => ({
  export: item.export,
  registered_article: {
    title: item.title,
    description: item.description,
    unit: item.unit,
    vat: item.vat,
    per_unit: Number(item.perUnit) || undefined,
    account_number: item.accountNumber,
    housework_type: item.houseworkType,
    item_type: Number(item.itemType),
    discount: Number(item.discount) || undefined,
    discount_type: Number(item.discountType),
    is_active: item.isActive,
    fortnox_id: item.fortnoxId,
  },
});
