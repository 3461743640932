import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid } from 'components/library';
import Wrapper from 'components/common/LoginSignUp/Wrapper';
import RegistrationForm from './RegistrationForm';
import SignupInfo from './SignupInfo';

export default function RegisterAccount (props) {
  const isLgDown = useSelector((state) => state.application.breakpoints.lgDown);

  return (
    <Wrapper mt={0}>
      <Grid item xs={12} paddingTop={4} lg={6}>
        <RegistrationForm
          history={props.history}
          location={props.location}
        />
      </Grid>
      {!isLgDown && (
      <Grid item xs={12} lg={6}>
        <SignupInfo />
      </Grid>
      )}
    </Wrapper>
  );
}

RegisterAccount.propTypes = {
  history: PropTypes.shape({}),
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};
