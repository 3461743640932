import { createAsyncThunk } from '@reduxjs/toolkit';
import { setSnackbarError, setSnackbarSuccess, setFilters } from 'appRedux/actions';
import { multiStateGet } from 'appRedux/reducers/utility';
import { names } from 'utils/enum';
import { get } from 'utils/lodash';
import snakify from 'utils/snakify';

export const requestSubCompanySignIn = createAsyncThunk(
  'companyIntegration/signIn',
  async (details, { extra, dispatch, rejectWithValue }) => {
    try {
      const response = await extra.axios.post('/api/v3/company_integrations/sub_company_sign_in', details);
      dispatch(setSnackbarSuccess('snackbar_sign_in_successful'));
      return response.data.data;
    } catch (err) {
      if ((get('response.data.errors[0].details.code')(err))) {
        return rejectWithValue(get('response.data.errors[0].details.code')(err));
      }
      dispatch(setSnackbarError('snackbar_sign_in_failed'));
      return rejectWithValue(get('response.data.errors[0].details.message')(err));
    }
  },
);

export const requestIntegratedCompanies = createAsyncThunk(
  'companyIntegration/getCompanies',
  async (details, { extra, dispatch, rejectWithValue }) => {
    try {
      const response = await extra.axios.get('/api/v3/company_integrations/company_integration_list');
      let { mainCompany, subCompany } = response.data.data.integrations;
      mainCompany = mainCompany.map((company) => ({ ...company, type: 'mainCompany' }));
      subCompany = subCompany.map((company) => ({ ...company, type: 'subCompany' }));
      return { integrations: { mainCompany, subCompany } };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_integrated_companies_failed'));
      return rejectWithValue(err.response.data);
    }
  },
);

export const requestIntegrateSubCompany = createAsyncThunk(
  'companyIntegration/integrateSubCompany',
  async (details, { extra, dispatch, rejectWithValue, getState }) => {
    const { filters } = getState();
    try {
      const { companyIds } = multiStateGet(names.calendar, filters);
      const body = { sub_company_token: details.token };
      const response = await extra.axios.post('/api/v3/company_integrations/integrate_company', body);
      dispatch(setSnackbarSuccess('snackbar_company_integration_successful'));
      dispatch(requestIntegratedCompanies());
      window.location.reload();
      dispatch(setFilters({ companyIds: [...companyIds, details.companyId], name: names.calendar }));
      return response.data.data;
    } catch (err) {
      dispatch(setSnackbarError('snackbar_integration_failed'));
      return rejectWithValue(err.response.data);
    }
  },
);

export const requestSwitchCompany = createAsyncThunk(
  'companyIntegration/getSubCompanyToken',
  async (companyId, { extra, dispatch, getState, rejectWithValue }) => {
    const { auth } = getState();
    try {
      const params = { company_id: companyId };
      const response = await extra.axios.get('/api/v3/company_integrations/switch_company', { params });
      const { token } = response.data.data;
      localStorage.setItem('main_company_token', auth.token);
      localStorage.setItem('token', token);
      window.location.reload();
    } catch (err) {
      dispatch(setSnackbarError('snackbar_integrated_companies_failed'));
      return rejectWithValue(err.response.data);
    }
  },
);

export const requestRemoveCompanyIntegration = createAsyncThunk(
  'companyIntegration/removeCompanyIntegration',
  async (data, { extra, dispatch, getState }) => {
    const { filters } = getState();
    const params = {
      company_integration_id: data.companyIntegrationId,
      type: snakify(data.type),
    };
    try {
      const { companyIds } = multiStateGet(names.calendar, filters);
      await extra.axios.delete('/api/v3/company_integrations/remove_integration', { data: params });
      dispatch(setSnackbarSuccess('integration_removed_successfully'));
      dispatch(requestIntegratedCompanies());
      dispatch(setFilters({
        companyIds: data.type === 'subCompany' ? companyIds.filter((id) => id !== data.companyId) : companyIds,
        name: names.calendar,
      }));
      window.location.reload();
    } catch (err) {
      dispatch(setSnackbarError('snackbar_remove_integration_error'));
    }
  },
);

export const requestSwitchToMainCompany = createAsyncThunk(
  'companyIntegration/switchToMainCompany',
  async (_, { getState }) => {
    const { auth } = getState();
    localStorage.setItem('token', auth.mainCompanyToken);
    localStorage.removeItem('main_company_token');
    window.location.reload();
  },
);
