import { SET_USER_GROUPS_FIELDS } from '../actions';

const initialState = {
  records: [],
  userGroup: {},
  showUserGroupDialog: false,
};

const userGroups = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_GROUPS_FIELDS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default userGroups;
