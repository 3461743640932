import axios from 'axios';
import get from 'lodash/fp/get';
import urls from 'utils/urls';
import camelize from 'camelize';
import store from 'appRedux/store/index';
import { setLoading } from 'appRedux/actions';
import { userSignOut } from 'appRedux/thunks/auth';
import * as Sentry from '@sentry/react';

let apiRequestsPending = 0;

const userAxios = axios.create({

  baseURL: urls.getApiBaseUrl(),

  headers: {
    Authorization: `Bearer  ${localStorage.getItem('user_token')}`,
    'Content-Type': 'application/json',
  },
});

function onError (error) {
  apiRequestsPending -= 1;
  if (apiRequestsPending === 0) {
    store.dispatch(setLoading(false));
  }

  if (get('response.status')(error) === 401) {
    store.dispatch(userSignOut());
  } else {
    Sentry.captureException(error, { extra: get('response.data')(error) });
  }
  return Promise.reject(error);
}

userAxios.interceptors.request.use((config) => {
  store.dispatch(setLoading(true));
  apiRequestsPending += 1;
  return config;
}, onError);

// Add a response interceptor
userAxios.interceptors.response.use((response) => {
  apiRequestsPending -= 1;
  if (apiRequestsPending === 0) {
    store.dispatch(setLoading(false));
  }
  response.data.data = camelize(response.data.data);
  return response;
}, onError);

export default userAxios;
