import { SET_SERVICES, SET_SERVICES_FIELDS } from '../actions';

const initialState = {
  records: [],
  selectedServiceIds: [],
  service: {},
};

function services (state = initialState, action) {
  switch (action.type) {
    case SET_SERVICES:
      return {
        ...state,
        ...action.payload,
      };
    case SET_SERVICES_FIELDS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

export default services;
