import get from 'lodash/fp/get';
import camelize from 'camelize';

export const getFormattedPaginationFields = (snakedResponse = {}) => {
  const response = camelize(snakedResponse);
  const pages = Math.ceil(response.totalRecords / Number(response.perPage)) || 0;
  return {
    pages,
    page: Number(response.page) || 1,
    ...(response.page > pages && { page: pages }),
    perPage: Number(response.perPage) || 10,
    totalRecords: response.totalRecords || 0,
  };
};

export const parseJson = (d, defaultValue = null) => {
  try {
    return d ? camelize(JSON.parse(d)) : defaultValue;
  } catch (err) {
    return d || defaultValue;
  }
};

export const getFilterCustomerId = get('customer.id');
export const getFilterEmployeeId = get('employee.id');
export const getFilterServiceId = get('service.id');
export const getFilterTagId = get('tag.id');
export const getFilterSupervisorId = get('supervisor.id');
export const getFilterCompanyId = get('company.id');
export const getFilterUserId = get('user.id');
export const getFilterTag = get('tag.title');

export const getId = (length) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) { // eslint-disable-line
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
