import { fm } from 'utils/string';
import filterChipsConfig from 'components/FiltersCommon/filterChipsConfig';

export const tabsData = [
  { title: fm('overview') },
  { title: fm('payroll') },
  { title: fm('summary') },
];

export const timeManagementFilterChips = [
  filterChipsConfig.startDate,
  filterChipsConfig.endDate,
  filterChipsConfig.bookingStatus,
  filterChipsConfig.employee,
  filterChipsConfig.codeType,
  filterChipsConfig.approved,
  filterChipsConfig.shiftType,
  filterChipsConfig.timeCodes,
  filterChipsConfig.userActiveStatus,
  filterChipsConfig.skills,
  filterChipsConfig.employeePunches,
];

export const timeManagementTabs = {
  overview: 0,
  payroll: 1,
  summary: 2,
};

export const shiftType = {
  viewAll: 'view_all',
  booking: 'booking',
  shiftTime: 'shift_time',
};

export const employeeTimeTypes = {
  bookingTime: 'BookingTime',
  internalTime: 'InternalTime',
};

export const timeManagementTabsTypes = [
  {
    title: fm('salary'),
  },
  {
    title: fm('overview'),
  },
  {
    title: fm('punch_in_out'),
  },
];

export const timeManagementNextTabs = {
  salary: 0,
  punchInOut: 2,
  overview: 1,
};

// Function to calculate the distance
export const calculateDistance = (lat1, lon1, lat2, lon2) => {
  const R = 6371; // Radius of the Earth in kilometers

  // Function to convert degrees to radians
  const deg2rad = (deg) => deg * (Math.PI / 180);

  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lon2 - lon1);

  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
    + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2))
    * Math.sin(dLon / 2) * Math.sin(dLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c; // Distance in kilometers
  return distance;
};
