import { getFormattedPaginationFields } from 'appRedux/utility';
import { getUnixTimeInHoursMinutes } from 'utils/dateTime';
import { groupBy } from 'utils/lodash';

export const getFormattedOpenShiftRequestBody = (details) => ({
  order_id: details.orderId,
  open_shift: {
    id: details.id,
    requires_approval: details.requiresApproval || false,
    available_slots: details.availableSlots,
    all_shift_recipients: details.allAvailableEmployees ? false : details.allShiftRecipients,
    all_available_employees: details.allAvailableEmployees ?? false,
    open_shift_recipients_attributes: details.allShiftRecipients
      ? undefined : details.openShiftRecipients.map((emp) => ({
        id: emp.id,
        employee_id: emp.employeeId,
        _destroy: emp.destroy,
      })),
  },
});

export const getFormattedEmployeeRequestsData = (records) => {
  const data = groupBy('status')(records);
  return { ...data };
};

export const getFormattedOpenShiftListData = (response) => ({
  ...getFormattedPaginationFields(response),
  records: (response.records || []).map((record) => ({
    ...record,
    startTime: record.startTime ? getUnixTimeInHoursMinutes(record.startTime) : null,
    endTime: record.endTime ? getUnixTimeInHoursMinutes(record.endTime) : '',
  })),
});
