import { createAsyncThunk } from '@reduxjs/toolkit';
import { setFilters, setSnackbarError, setSnackbarSuccess } from 'appRedux/actions';
import { getFormattedListData } from 'appRedux/utility';
import {
  getFormattedProductUpdateFilterParams,
  getFormattedProductUpdateRequestBody,
  getFormattedProductUpdateData,
  getFormattedProductUpdateListData,
  getFormattedProductUpdateCompaniesListData,
} from './selector';

export const requestProductUpdatesRecords = createAsyncThunk(
  'productUpdates/fetchRecords',
  async (name, { extra, dispatch, getState }) => {
    try {
      const { filters } = extra.getState(name, getState);
      const params = getFormattedProductUpdateFilterParams(filters);
      const response = await extra.axios.get('/api/v3/product_updates', { params });
      const { records, ...nextFilters } = getFormattedProductUpdateListData(response.data.data.productUpdates);
      dispatch(setFilters({ ...nextFilters, name }));
      return { records };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_product_update_get_error'));
    }
  },
);

export const requestSuperAdminProductUpdatesRecords = createAsyncThunk(
  'productUpdates/fetchSuperAdminRecords',
  async (name, { extra, dispatch, getState }) => {
    try {
      const { filters } = extra.getState(name, getState);
      const params = getFormattedProductUpdateFilterParams(filters);
      const response = await extra.axios.get('/api/v3/super_admin/product_updates', { params });
      const { records, ...nextFilters } = getFormattedListData(response.data.data.productUpdates);
      dispatch(setFilters({ ...nextFilters, name }));
      return { records };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_product_update_get_error'));
    }
  },
);

export const requestCreateProductUpdateRecord = createAsyncThunk(
  'productUpdate/createProductUpdate',
  async (details, { extra, dispatch }) => {
    try {
      const requestBody = getFormattedProductUpdateRequestBody(details);
      const response = await extra.axios.post('/api/v3/super_admin/product_updates', requestBody);
      dispatch(setSnackbarSuccess('snackbar_product_update_created'));
      return response.data.data;
    } catch (err) {
      dispatch(setSnackbarError('snackbar_product_update_create_error'));
    }
  },
);

export const requestUpdateProductUpdateRecord = createAsyncThunk(
  'productUpdate/updateProductUpdate',
  async (details, { extra, dispatch }) => {
    try {
      const requestBody = getFormattedProductUpdateRequestBody(details);
      const response = await extra.axios.put(`/api/v3/super_admin/product_updates/${details.id}`, requestBody);
      dispatch(setSnackbarSuccess('snackbar_product_update_updated'));
      return response.data.data;
    } catch (err) {
      dispatch(setSnackbarError('snackbar_product_update_error'));
    }
  },
);

export const requestSuperAdminProductUpdateRecord = createAsyncThunk(
  'productUpdate/fetchSuperAdminRecord',
  async (productUpdateId, { extra, dispatch }) => {
    try {
      const response = await extra.axios.get(`/api/v3/super_admin/product_updates/${productUpdateId}`);
      const formattedData = getFormattedProductUpdateData(response.data.data.productUpdate);
      return { record: formattedData };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_product_update_fatch_failed'));
    }
  },
);

export const requestProductUpdateRecord = createAsyncThunk(
  'productUpdate/fetchRecord',
  async (productUpdateId, { extra, dispatch }) => {
    try {
      const response = await extra.axios.get(`/api/v3/product_updates/${productUpdateId}`);
      const formattedData = getFormattedProductUpdateData(response.data.data.productUpdate);
      return { record: formattedData };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_product_update_fatch_failed'));
    }
  },
);

export const requestDeleteProductUpdateRecords = createAsyncThunk(
  'productUpdate/deleteRecords',
  async (selectedProductUpdateIds, { extra, dispatch }) => {
    try {
      const body = { product_update_ids: selectedProductUpdateIds };
      await extra.axios.delete('/api/v3/super_admin/product_updates/bulk_delete', { data: body });
      dispatch(setSnackbarSuccess('snackbar_product_update_deleted'));
    } catch (err) {
      dispatch(setSnackbarError('snackbar_product_update_delete_failed'));
    }
  },
);

export const requestDeleteProductUpdateRecord = createAsyncThunk(
  'productUpdate/deleteRecord',
  async (productUpdateId, { extra, dispatch }) => {
    try {
      await extra.axios.delete(`/api/v3/super_admin/product_updates/${productUpdateId}`);
      dispatch(setSnackbarSuccess('snackbar_product_update_deleted'));
    } catch (err) {
      dispatch(setSnackbarError('snackbar_product_update_delete_failed'));
    }
  },
);

export const requestMarkReadProductUpdateRecord = createAsyncThunk(
  'productUpdate/markReadProductUpdate',
  async (productUpdateId, { extra, dispatch }) => {
    try {
      await extra.axios.put(`/api/v3/product_updates/${productUpdateId}/mark_read_product_update`);
      dispatch(setSnackbarSuccess('snackbar_product_update_read_success'));
    } catch (err) {
      dispatch(setSnackbarError('snackbar_product_update_error'));
    }
  },
);

export const requestMarkReadAllProductUpdatesRecords = createAsyncThunk(
  'productUpdate/markReadProductUpdates',
  async (productUpdateIds, { extra, dispatch }) => {
    try {
      const requestBody = { product_update_ids: productUpdateIds };
      await extra.axios.put('/api/v3/product_updates/mark_read_product_updates', requestBody);
      dispatch(setSnackbarSuccess('snackbar_product_update_read_success'));
    } catch (err) {
      dispatch(setSnackbarError('snackbar_product_update_error'));
    }
  },
);

export const requestSearchProductUpdateCompanies = createAsyncThunk(
  'productUpdates/fetchCompanyRecords',
  async (details, { extra, dispatch, getState }) => {
    try {
      const { filters } = extra.getState(details.name, getState);
      const params = getFormattedProductUpdateFilterParams(filters);
      const response = await extra.axios.get(
        `/api/v3/super_admin/product_updates/${details.productUpdateId}/companies_viewed`,
        { params },
      );
      const { records, ...nextFilters } = getFormattedProductUpdateCompaniesListData(
        response.data.data.companiesViewed,
      );
      dispatch(setFilters({ ...nextFilters, name: details.name }));
      return { records };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_product_update_get_error'));
    }
  },
);
