import { addMethod, string } from 'yup';
import { emojisRegex, fm } from 'utils/string';

export function init () {
  addMethod(string, 'shouldNotContainEmoji', function format(message) {
    return this.test('shouldNotContainEmoji', message, function (value) { // eslint-disable-line
      const { path, createError } = this;

      if (value && emojisRegex.test(value)) {
        return createError({
          path,
          message: message ?? fm('emoji.not.allowed'),
        });
      }

      return true;
    });
  });
}

export default { init };
