/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import useApplication from 'hooks/application';

export default function Footer (props) {
  const { isMobile } = useApplication();
  const { children, fullWidth } = props;

  const styles = useMemo(() => ({
    footer: {
      position: 'fixed',
      display: 'flex',
      alignItems: 'center',
      height: isMobile ? undefined : '56px',
      bottom: 0,
      background: '#FAFAFA',
      width: '100%',
      right: 0,
      boxShadow: '0 -5px 40px rgba(0,0,0,0.1)',
      zIndex: 1000,
      padding: '0px 8px',
    },
  }), [isMobile]);

  return (
    <footer style={styles.footer}>
      {children}
    </footer>
  );
}

Footer.propTypes = {
  children: PropTypes.shape({}),
  fullWidth: PropTypes.bool,
};
