import {
  SET_SMS_FIELDS,
  SET_SMS_HISTORY_FIELDS,
  SET_SMS_SCHEDULE_FIELDS,
  SET_SMS_TEMPLATE_FIELDS,
  SET_BOOKING_SMS_HISTORY_FIELDS,
} from '../actions';

const initialState = {
  activeTab: 0,
  schedule: {},
  history: {},
  templates: {},
  bookingSmsHistory: {
    records: { email: [], sms: [] },
    bookingSequenceNum: null,
  },
};

function sms (state = initialState, action) {
  switch (action.type) {
    case SET_SMS_FIELDS:
      return {
        ...state,
        ...action.payload,
      };

    case SET_SMS_HISTORY_FIELDS:
      return {
        ...state,
        history: {
          ...state.history,
          ...action.payload,
        },
      };

    case SET_SMS_SCHEDULE_FIELDS:
      return {
        ...state,
        schedule: {
          ...state.schedule,
          ...action.payload,
        },
      };

    case SET_SMS_TEMPLATE_FIELDS:
      return {
        ...state,
        templates: {
          ...state.templates,
          ...action.payload,
        },
      };

    case SET_BOOKING_SMS_HISTORY_FIELDS:
      return {
        ...state,
        bookingSmsHistory: {
          ...state.bookingSmsHistory,
          ...action.payload,
        },
      };

    default:
      return state;
  }
}

export default sms;
