import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Box, List, ListItem, ListItemText, Typography, Chip, Link, IconButton } from 'components/library';
import { formatDateToDuration, formatDate, formatStrings } from 'utils/dateTime';
import bosLogo from 'images/Logo-bos.png';
import urls from 'utils/urls';
import { fm } from 'utils/string';
import { ArrowBackIosIcon } from 'components/library/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  listItem: {
    borderBottom: '1px solid #e0e0e0',
    marginBottom: theme.spacing(2),
    borderRadius: theme.spacing(1),
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  },
  listItemText: {
    '& .MuiTypography-root': {
      marginBottom: theme.spacing(1),
    },
  },
}));

export const updateTypeChipColorMap = {
  Feature: 'primary',
  Bug: 'secondary',
  Enhancement: '',
  Other: '',
};

// eslint-disable-next-line max-len
const bugReportEmailBody = 'Bug%20Report%20Template%0A%0A%23%23%20Description%0A%0A%0A%23%23%20Steps%20to%20Reproduce%0A%0A1.%20%0A2.%20%0A3.%20%0A%0A%23%23%20Expected%20Behavior%0A%0A%0A%23%23%20Actual%20Behavior%0A%0A%0A%23%23%20Environment%0A%0A-%20Operating%20System%3A%20%0A-%20Browser%3A%20%0A-%20Device%3A%20%0A%0A%23%23%20Additional%20Information%0A%0A%0A%23%23%20Screenshots%0A%0A%0A%23%23%20Reproducible%20Demo%0A%0A';

export default function AppUpdatesList (props) {
  const classes = useStyles();
  const [updates, setUpdates] = useState([]);

  useEffect(() => {
    fetch(`changelog-prod.json?${Date.now()}`)
      .then((response) => response.json())
      .then((data) => setUpdates(data.sort((d) => d.timestamp).reverse()));
  }, []);

  return (
    <div className={classes.root}>
      <Box
        className="bg-white"
        component="header"
        display="flex"
        position="sticky"
        top={0}
        alignItems="center"
        borderBottom="1px solid #f2f2f2"
        height={60}
        zIndex={5}
      >
        <Box ml={4} onClick={props.history.goBack}>
          <IconButton><ArrowBackIosIcon /></IconButton>
        </Box>
        <Box
          className="pointer"
          onClick={() => props.history.push('/')}
          component="img"
          src={bosLogo}
          alt="header-logo"
          width="120px"
          ml={4}
        />
      </Box>
      {updates.length === 0 ? (
        <Typography variant="body1">No updates available.</Typography>
      ) : (
        <Box mx={5}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="end"
            position="sticky"
            top={60}
            height={80}
            zIndex={5}
            className="bg-white"
          >
            <Box>
              <Typography variant="h5">{fm('News_and_updates', 'News and updates')}</Typography>
            </Box>
            <Box display="flex" flexDirection="column" mr={4} alignItems="end">
              <Link
                color="error"
                className="pointer"
                href={`mailto:info@bokningoschema.se?subject=Bug report&body=${bugReportEmailBody}`}
              >
                Report an issue?
              </Link>
              <Link
                color="primary"
                className="pointer"
                href={urls.appRequestFeatureUrl()}
                target="_blank"
                rel="noopener noreferrer"
              >
                Request a feature?
              </Link>
            </Box>
          </Box>
          <List dense>
            {updates.map((update) => (
              <ListItem key={update.version} className={classes.listItem}>
                <ListItemText
                  primary={
                    <>
                      <Typography title={formatDate(update.timestamp, formatStrings.dateDashTime)} variant="caption">
                        {formatDateToDuration(update.timestamp)}
                      </Typography><Typography variant="h6">{update.title}</Typography>
                    </>
                  }
                  secondary={
                    <>
                      <Box display="flex" flexDirection="column">
                        <Box display="flex" color="#fff" my={1}>
                          <Box mr={2}>
                            <Chip
                              color={updateTypeChipColorMap[update.updateType]}
                              size="small"
                              label={update.updateType}
                            />
                          </Box>
                          <Box mr={2}>
                            <Chip size="small" label={update.module} />
                          </Box>
                        </Box>
                      </Box>
                      <Box mt={1} color="#000" width="80%">
                        {update.description.split('\n').map((para, paraIdx) => (
                          <Typography key={paraIdx.toString()} variant="body2">
                            {para}
                          </Typography>
                        ))}
                      </Box>
                      <Typography variant="caption">
                        {update.version}
                      </Typography>
                    </>
                  }
                  classes={{ root: classes.listItemText }}
                />
              </ListItem>
            ))}
          </List>
        </Box>
      )}
    </div>
  );
}

AppUpdatesList.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func,
  }),
};
