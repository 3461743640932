import React from 'react';

const Order = () => (
  <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="23" cy="23" r="23" fill="#8FBABA" />
    <path d="M31 26C31 25.4477 30.5523 25 30 25C29.4477 25 29 25.4477 29 26V28H27C26.4477 28 26 28.4477 26 29C26 29.5523 26.4477 30 27 30H29V32C29 32.5523 29.4477 33 30 33C30.5523 33 31 32.5523 31 32V30H33C33.5523 30 34 29.5523 34 29C34 28.4477 33.5523 28 33 28H31V26Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M27 12C27.5523 12 28 12.4477 28 13V14.0016C28.4755 14.0049 28.891 14.0147 29.2518 14.0442C29.8139 14.0901 30.3306 14.1887 30.816 14.436C31.5686 14.8195 32.1805 15.4314 32.564 16.184C32.8113 16.6694 32.9099 17.1861 32.9558 17.7482C33 18.2894 33 18.9537 33 19.7587V24.2455C33 24.386 32.886 24.5 32.7455 24.5C32.6546 24.5 32.5713 24.4511 32.522 24.3747C31.9879 23.5476 31.0579 23 30 23C28.3431 23 27 24.3431 27 26C25.3431 26 24 27.3431 24 29C24 30.6569 25.3431 32 27 32C27 32.1376 27.0093 32.273 27.0272 32.4057C27.1317 33.1784 27.1839 33.5648 27.1358 33.6863C27.0784 33.8313 27.0519 33.8617 26.916 33.9381C26.8021 34.0021 26.5286 34.0024 25.9815 34.003C23.5739 34.0055 21.1663 34 18.7587 34C17.9537 34 17.2894 34 16.7482 33.9558C16.1861 33.9099 15.6694 33.8113 15.184 33.564C14.4314 33.1805 13.8195 32.5686 13.436 31.816C13.1887 31.3306 13.0901 30.8139 13.0442 30.2518C13 29.7106 13 29.0463 13 28.2413V19.7587C13 18.9537 13 18.2894 13.0442 17.7482C13.0901 17.1861 13.1887 16.6694 13.436 16.184C13.8195 15.4314 14.4314 14.8195 15.184 14.436C15.6694 14.1887 16.1861 14.0901 16.7482 14.0442C17.109 14.0147 17.5245 14.0049 18 14.0016V13C18 12.4477 18.4477 12 19 12C19.5523 12 20 12.4477 20 13V14H26V13C26 12.4477 26.4477 12 27 12ZM18 16.0018V17C18 17.5523 18.4477 18 19 18C19.5523 18 20 17.5523 20 17V16H26V17C26 17.5523 26.4477 18 27 18C27.5523 18 28 17.5523 28 17V16.0018C28.4455 16.0049 28.7954 16.0136 29.089 16.0376C29.5274 16.0734 29.7516 16.1383 29.908 16.218C30.2843 16.4097 30.5903 16.7157 30.782 17.092C30.8617 17.2484 30.9266 17.4726 30.9624 17.911C30.9992 18.3611 31 18.9434 31 19.8V20H15V19.8C15 18.9434 15.0008 18.3611 15.0376 17.911C15.0734 17.4726 15.1383 17.2484 15.218 17.092C15.4097 16.7157 15.7157 16.4097 16.092 16.218C16.2484 16.1383 16.4726 16.0734 16.911 16.0376C17.2046 16.0136 17.5545 16.0049 18 16.0018Z" fill="white" />
  </svg>

);

export default Order;
