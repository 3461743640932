import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import DateSelector from 'components/common/DateSelector';
import { useFormik } from 'formik';
import MaterialInputSuggestions, { objectTypes } from 'components/InputWithSuggestions/FilterMaterialInput';
import useFilters from 'components/FiltersCommon/useFilters';
import { Grid, TimeInput, Checkbox, Typography } from 'components/library';
import { fm } from 'utils/string';
import { weekDaysShort, weekDays as weekDaysEnum } from 'utils/enum';
import { addMonths, addYears, dayDifference, getStandardDate } from 'utils/dateTime';
import useUpdateEffect from 'components/common/updateEffect';
import validationSchema from './ValidationSchema';

export const employeeAvailabilityFiltersWeekDays = [
  { value: 1, label: weekDaysShort[0].label, textValue: weekDaysEnum[0].value },
  { value: 2, label: weekDaysShort[1].label, textValue: weekDaysEnum[1].value },
  { value: 3, label: weekDaysShort[2].label, textValue: weekDaysEnum[2].value },
  { value: 4, label: weekDaysShort[3].label, textValue: weekDaysEnum[3].value },
  { value: 5, label: weekDaysShort[4].label, textValue: weekDaysEnum[4].value },
  { value: 6, label: weekDaysShort[5].label, textValue: weekDaysEnum[5].value },
  { value: 0, label: weekDaysShort[6].label, textValue: weekDaysEnum[6].value },
];

function EmployeeHourReportDrawerFilters (props) {
  const { filters, setFilters } = useFilters(props.name);

  const { values, handleChange, touched, email, errors, ...formik } = useFormik({
    validationSchema,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: filters,
    onSubmit: props.getRecords,
  });

  const lessThenWeekDatesSelected = dayDifference(values.startDate, values.endDate) < 6;

  useUpdateEffect(() => {
    if (lessThenWeekDatesSelected && values.weekDays?.length > 0) {
      return formik.setValues({ ...values, weekDays: [] });
    }
    formik.handleSubmit();
    setFilters(values);
  }, [
    values.startDate,
    values.endDate,
    values.startTime,
    values.endTime,
    values.weekDays,
    values.includeAbsenceTime,
    values.includeHolidayRequest,
    values.skill,
  ]);

  const onChangeDateTime = useCallback((value, name) => {
    formik.setFieldValue(name, value);
  }, [formik.setFieldValue]);

  const onChangeCheckbox = useCallback((event) => {
    const { name, checked } = event.target;
    formik.setFieldValue(name, checked);
  }, [formik.setFieldValue]);

  const onSelectSkills = useCallback((skill) => {
    formik.setFieldValue('skill', skill);
  }, [formik.setFieldValue]);

  const onClickWeekDay = useCallback((event) => {
    const { weekDays: prevWeekDays = [] } = filters;
    const value = Number(event.target.value);
    const weekDaysValue = prevWeekDays.filter((day) => day !== value) || [];
    if (weekDaysValue.length === prevWeekDays.length) weekDaysValue.push(value);
    formik.setFieldValue('weekDays', weekDaysValue);
  }, [filters.weekDays]);

  return (
    <Grid container spacing={2} alignContent="center" alignItems="center">
      <Grid container item xs={12} spacing={2} mt={0.2}>
        <Grid item xs={6}>
          <DateSelector
            showWeekNumber
            labelId="startDate"
            defaultLabel="Start Date"
            accessor="startDate"
            value={values.startDate}
            onChange={(d) => formik.setFieldValue('startDate', getStandardDate(d))}
            minDate={addMonths(values.endDate, -6)}
            maxDate={values.endDate}
            error={Boolean(errors.startDate)}
            helperText={errors.startDate}
          />
        </Grid>
        <Grid item xs={6}>
          <DateSelector
            showWeekNumber
            labelId="endDate"
            defaultLabel="End Date"
            accessor="endDate"
            value={values.endDate}
            onChange={(d) => formik.setFieldValue('endDate', getStandardDate(d))}
            minDate={values.startDate}
            maxDate={addMonths(values.startDate, 6)}
            error={Boolean(errors.endDate)}
            helperText={errors.endDate}
          />
        </Grid>
        <Grid item xs={6}>
          <TimeInput
            size="small"
            id="start-time"
            name="startTime"
            value={values.startTime}
            onChange={(t) => onChangeDateTime(t, 'startTime')}
            label={fm('booking.modal.startTime', 'Start time')}
            error={Boolean(errors.startTime)}
            helperText={errors.startTime}
          />
        </Grid>
        <Grid item xs={6}>
          <TimeInput
            size="small"
            id="end-time"
            name="endTime"
            value={values.endTime}
            onChange={(t) => onChangeDateTime(t, 'endTime')}
            label={fm('booking.modal.endTime', 'End time')}
            error={Boolean(errors.endTime)}
            helperText={errors.endTime}
          />
        </Grid>
        <Grid item xs={12}>
          <MaterialInputSuggestions
            label={fm('skills')}
            onSelect={onSelectSkills}
            object={values.skill}
            objectType={objectTypes.skills}
            placeholder={fm('search_skills', 'Search skills')}
          />
        </Grid>
        <Grid item xs={12}>
          {employeeAvailabilityFiltersWeekDays.map((day) => (
            <Checkbox
              value={day.value}
              disabled={lessThenWeekDatesSelected}
              checked={values.weekDays?.includes(day.value)}
              onChange={onClickWeekDay}
              label={day.label}
            />
          ))}
        </Grid>
        <Grid item xs={12}>
          <Typography>
            {fm('include_in_search')}
          </Typography>
          <Checkbox
            name="includeHolidayRequest"
            onChange={onChangeCheckbox}
            checked={filters.includeHolidayRequest}
            label={fm('holiday_request')}
          />
          <Checkbox
            name="includeAbsenceTime"
            onChange={onChangeCheckbox}
            checked={filters.includeAbsenceTime}
            label={fm('absence_time')}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

EmployeeHourReportDrawerFilters.propTypes = {
  filters: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    employee: PropTypes.shape({}),
    weekDay: PropTypes.arrayOf(PropTypes.string),
  }),
  getRecords: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export default EmployeeHourReportDrawerFilters;
