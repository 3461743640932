import { add, getStandardDate } from 'utils/dateTime';
import {
  SET_CUSTOMERS,
  SET_CUSTOMERS_FIELDS,
  SET_CUSTOMER_REQUESTS_FIELDS,
  SET_CUSTOMER_KEY_HISTORY_FIELDS,
  SET_CUSTOMER_BOOKING_FIELDS,
  SET_CUSTOMER_BOOKING_FILTERS,
  SET_CUSTOMER_RECURRING_BOOKING_FIELDS,
  SET_CUSTOMER_RECURRING_BOOKING_FILTERS,
  SET_CUSTOMER_INVOICE_HISTORY_FIELDS,
  SET_CUSTOMER_INVOICE_HISTORY_FILTERS,
  SET_CUSTOMER_DOCUMENTS_FIELDS,
} from '../actions';

const getInitialFilters = (startDate, endDate) => ({
  startDate,
  endDate,
  page: 1,
  perPage: 10,
  totalRecords: 0,
});

const initialState = {
  customers: [],
  openedFromBooking: null,
  fortnoxCustomer: null,
  customerActivity: null,
  requests: {
    records: [],
  },
  keyHistory: {
    records: [],
  },
  bookings: {
    records: [],
    filters: getInitialFilters(getStandardDate(), getStandardDate(add(new Date(), { months: 1 }))),
    activeTab: 0,
  },
  recurringBookings: {
    records: [],
    filters: getInitialFilters(getStandardDate(), getStandardDate(add(new Date(), { months: 1 }))),
  },
  invoiceHistory: {
    records: [],
    filters: getInitialFilters(getStandardDate(add(new Date(), { months: -1 })), getStandardDate()),
  },
  customerDocuments: {
    showDocumentsDialog: false,
    records: [],
    imageViewer: {},
  },
  showCustomerImportStatus: false,
  seletedLogs: {},
};

function customers (state = initialState, action) {
  switch (action.type) {
    case SET_CUSTOMERS:
      return {
        ...state,
        ...action.payload,
      };

    case SET_CUSTOMERS_FIELDS:
      return {
        ...state,
        ...action.payload,
      };

    case SET_CUSTOMER_REQUESTS_FIELDS:
      return {
        ...state,
        requests: {
          ...state.customerRequests,
          ...action.payload,
        },
      };

    case SET_CUSTOMER_KEY_HISTORY_FIELDS:
      return {
        ...state,
        keyHistory: {
          ...state.keyHistory,
          ...action.payload,
        },
      };

    case SET_CUSTOMER_BOOKING_FIELDS:
      return {
        ...state,
        bookings: {
          ...state.bookings,
          ...action.payload,
        },
      };

    case SET_CUSTOMER_BOOKING_FILTERS:
      return {
        ...state,
        bookings: {
          ...state.bookings,
          filters: {
            ...state.bookings.filters,
            ...action.payload,
          },
        },
      };

    case SET_CUSTOMER_RECURRING_BOOKING_FIELDS:
      return {
        ...state,
        recurringBookings: {
          ...state.recurringBookings,
          ...action.payload,
        },
      };

    case SET_CUSTOMER_RECURRING_BOOKING_FILTERS:
      return {
        ...state,
        recurringBookings: {
          ...state.recurringBookings,
          filters: {
            ...state.recurringBookings.filters,
            ...action.payload,
          },
        },
      };

    case SET_CUSTOMER_INVOICE_HISTORY_FIELDS:
      return {
        ...state,
        invoiceHistory: {
          ...state.invoiceHistory,
          ...action.payload,
        },
      };

    case SET_CUSTOMER_INVOICE_HISTORY_FILTERS:
      return {
        ...state,
        invoiceHistory: {
          ...state.invoiceHistory,
          filters: {
            ...state.invoiceHistory.filters,
            ...action.payload,
          },
        },
      };

    case SET_CUSTOMER_DOCUMENTS_FIELDS:
      return {
        ...state,
        customerDocuments: {
          ...state.customerDocuments,
          ...action.payload,
        },
      };

    default:
      return state;
  }
}

export default customers;
