import camelize from 'camelize';
import { getStandardDate } from 'utils/dateTime';
import { getFormattedPaginationFields } from 'appRedux/selectors/utility';

export const getFormattedCustomersData = (response) => ({
  ...getFormattedPaginationFields(response),
  records: camelize(response.records),
});

export const getFormattedCompanyCustomerReqBody = (company) => ({
  export: company.export,
  registered_customer: {
    customer_number: company.identificationNum,
    name: company.name,
    address: company.address,
    address2: company.address2,
    zip_code: company.zipCode,
    area: company.area,
    phone: company.phone,
    email: company.email,
    invoice_email: company.email,
    contact_person: company.contactPerson,
    invoice_delivery: company.invoiceDelivery ? Number(company.invoiceDelivery) : 1, // 1 email 2 print 3 e-invoice
    owner_id: company.userId,
    company_id: company.id,
    fortnox_id: company.fortnoxId,
    fortnox_status: company.fortnoxStatus,
    // { trial: 1, paid: 2, deactivated: 3, trial_expired: 4 }
    status: company.hasOwnProperty('status') ? Number(company.status) : 3,
    registration_date: company.createdAt ? getStandardDate(company.createdAt) : undefined,
    payment_term: company.paymentTerm ?? 'days30',
    vat_included: company.vatIncluded,
  },
});
