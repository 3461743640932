import React from 'react';
import { Box } from 'components/library';
import { fm } from 'utils/string';

const boxSx = { '&:hover': { backgroundColor: '#f5f5f5 !important' } };

export default function customerOption (listItemProps, value) {
  return (
    <Box
      {...listItemProps}
      width="100%"
      component="li"
      flexDirection="column"
      justifyContent="flex-start"
      sx={boxSx}
    >
      <Box width="100%" fontSize={14} fontWeight="bold">{value.name}</Box>
      <Box
        width="100%"
        title={`${value.address}, ${value.zipCode}`}
        textOverflow="ellipsis"
        overflow="hidden"
        fontSize={12}
        display="flex"
      >
        {value.address || ''}{value.zipCode ? ',' : ''} {value.zipCode || ''} {value.area || ''}
      </Box>
      {Boolean(value[value.bookkeepingIntegrationFieldName]) && (
        <Box fontSize={12} width="100%">
          {fm('integration_id')}: {value[value.bookkeepingIntegrationFieldName]}
        </Box>
      )}
    </Box>
  );
}
