import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  setSnackbarError,
  setSnackbarSuccess,
  setFilters,
} from 'appRedux/actions';
import { requestProfile } from 'appRedux/owner/profile/thunks';
import { requestStorageStats } from 'appRedux/owner/profile/storage/thunk';
import { getFormattedProfileDocuments } from './selector';

export const requestFileManagerDocuments = createAsyncThunk(
  'fileManager/fetchDocuments',
  async (name, { extra, dispatch, getState }) => {
    try {
      const { filters } = extra.getState(name, getState);
      const params = { page: filters.page, per_page: filters.perPage, query: filters.query };
      const response = await extra.axios.get('/api/v3/company/documents', { params });
      const { records, ...rest } = getFormattedProfileDocuments(response.data.data);
      dispatch(setFilters({ ...rest, name }));
      return { records };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_profile_document_fetch_error'));
    }
  },
);

export const requestUploadFileManagerDocument = createAsyncThunk(
  'fileManager/uploadDocument',
  async (filesToUpload, { extra, dispatch, getState }) => {
    try {
      const { fileManager: { records: prevRecords = [] } } = getState();
      const records = prevRecords.slice();
      const formData = new FormData();
      for (const file of filesToUpload.filesToUpload) { // eslint-disable-line
        records.push({ url: URL.createObjectURL(file), filename: file.name });
        formData.append('documents[]', file);
      }
      const { data } = await extra.axios.post('/api/v3/company/documents/upload', formData);
      dispatch(requestStorageStats());
      dispatch(setSnackbarSuccess('document_uploaded_successfully'));
      return { records: data.data.attachedDocuments };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_profile_document_upload_error'));
    }
  },
);

export const requestDeleteFileManagerDocuments = createAsyncThunk(
  'overtime/deleteDocument',
  async (ids, { extra, dispatch }) => {
    try {
      const params = { document_ids: ids };
      await extra.axios.delete('/api/v3/company/documents/bulk_delete', { params });
      dispatch(setSnackbarSuccess('document_deleted_successfully'));
    } catch (err) {
      dispatch(setSnackbarError('snackbar_profile_document_delete_error'));
    }
  },
);

export const requestAttachProfilePicture = createAsyncThunk(
  'overtime/attachDocument',
  async (id, { extra, dispatch, getState }) => {
    const { fileManager: { documentType } } = getState();
    try {
      const params = { document_type: documentType };
      await extra.axios.put(`/api/v3/company/documents/${id}/attach`, params);
      dispatch(setSnackbarSuccess('document_attached_successfully'));
      dispatch(requestProfile());
    } catch (err) {
      dispatch(setSnackbarError('snackbar_profile_document_attach_error'));
    }
  },
);

export const requestDeleteProfileImage = createAsyncThunk(
  'overtime/deleteDocument',
  async (_, { extra, dispatch }) => {
    try {
      await extra.axios.delete('/api/v3/company/documents/delete_profile_picture');
      dispatch(setSnackbarSuccess('document_deleted_successfully'));
      dispatch(requestProfile());
    } catch (err) {
      dispatch(setSnackbarError('snackbar_profile_document_delete_error'));
    }
  },
);
