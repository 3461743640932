import * as Yup from 'yup';
import { getFormattedTemplateRequestBody } from './selector';

const smsTemplateSchema = Yup.object().shape({
  templateDescription: Yup.string()
    .required('company.password.required'),
});

export const validatedAndGetFormattedSmsTemplate = async (template) => {
  await smsTemplateSchema.validate(template);
  return getFormattedTemplateRequestBody(template);
};
