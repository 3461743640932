import { createAsyncThunk } from '@reduxjs/toolkit';
import { setProfile, setSnackbarError } from 'appRedux/actions';
import { get } from 'utils/lodash';
import { getFormattedProfile } from './selector';

export const requestProfile = createAsyncThunk(
  'profile/getProfile',
  async (details, { extra, dispatch }) => {
    try {
      const response = await extra.axios.get('/api/v1/profile/details');
      const profile = getFormattedProfile(response.data.data);
      dispatch(setProfile(profile));
    } catch (err) {
      dispatch(setProfile({ companyActive: get('response.data.errors[0].details.company_active')(err) }));
      dispatch(setSnackbarError('snackbar.profile.gettingProfile.error'));
    }
  },
);
