import React from 'react';
import { styled } from '@mui/material/styles';
import MuiChip from '@mui/material/Chip';

const StyledChip = styled(MuiChip)(
  ({ theme, gap, padding, paddingX, paddingY, borderRadius, backgroundColor, border, borderWidth, borderColor }) => ({
    borderStyle: 'solid',
    ...(gap && { gap: theme.spacing(gap) }),
    ...(padding && { padding: theme.spacing(padding) }),
    ...(paddingX && { paddingLeft: theme.spacing(paddingX), paddingRight: theme.spacing(paddingX) }),
    ...(paddingY && { paddingTop: theme.spacing(paddingY), paddingBottom: theme.spacing(paddingY) }),
    ...(borderRadius && { borderRadius: theme.spacing(borderRadius) }),
    ...(backgroundColor && { backgroundColor }),
    ...(border && { border }),
    ...(borderColor && { borderColor }),
    ...(borderWidth && { borderWidth: theme.spacing(borderWidth) }),
  }),
);

export default function Chip(props) {
  return <StyledChip {...props} />;
}
