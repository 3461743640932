import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { employeeColors } from 'utils/colors';
import AvatarGroup from '@mui/material/AvatarGroup';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import Avatar from './Avatar';
import styles from './styles.module.scss';

const classes = {
  root: styles.avatar,
  avatar: styles.avatar,
};

const anchorOrigin = {
  vertical: 'bottom',
  horizontal: 'center',
};

const transformOrigin = {
  vertical: 'top',
  horizontal: 'center',
};

const avatarGroupSlotProps = { additionalAvatar: { classes } };

export default function Avatars ({ data, idKey, nameKey, colorKey, name, color, max, source, sources, sx }) {
  const [anchorElem, setAnchorElem] = useState(false);

  const toggleAnchorElem = useCallback((event) => {
    if (data.length <= max) return;
    setAnchorElem(anchorElem ? null : event.target);
  }, [anchorElem]);

  const getAvatars = (dataObj) => (
    <Avatar
      classes={classes}
      key={`${dataObj[nameKey]}-${dataObj[idKey]}`}
      color={dataObj[colorKey]}
      name={dataObj[nameKey]}
      src={sources && sources[dataObj.id]?.map((obj) => obj.url)}
    />
  );

  return !data ? <Avatar classes={classes} sx={sx} name={name} color={color} src={source} /> : (
    <>
      <AvatarGroup max={max} onClick={toggleAnchorElem} slotProps={avatarGroupSlotProps}>
        {data.map(getAvatars)}
      </AvatarGroup>
      {data.length > max && (
        <Popover
          open={Boolean(anchorElem)}
          anchorEl={anchorElem}
          onClose={toggleAnchorElem}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
        >
          <Box display="flex" p={1}>
            {data.map(getAvatars)}
          </Box>
        </Popover>
      )}
    </>
  );
}

Avatars.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  nameKey: PropTypes.string,
  colorKey: PropTypes.string,
  name: PropTypes.string,
  color: PropTypes.string,
  idKey: PropTypes.string,
  max: PropTypes.number,
  sources: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  source: PropTypes.string,
  sx: PropTypes.shape({}),
};

Avatars.defaultProps = {
  color: employeeColors.grey,
};
