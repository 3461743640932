import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { getSelectColumnsElem } from 'components/library/Table/tableUtils';

export default function NormalRow (props) {
  const { columns, dataElementKey, row } = props;

  const handleRowClick = useCallback((rowData) => (!props.onRowClick ? undefined : (event) => {
    if (!['TR', 'TD'].includes(event.target.tagName)) return;
    props.onRowClick(rowData);
  }), [props.data]);

  return (
    <TableRow onClick={handleRowClick(row)} hover role="checkbox" tabIndex={-1} key={dataElementKey}>
      {props.showRowCheckbox && getSelectColumnsElem(props, row)}
      {columns.map((column) => {
        const { content } = props.getCellContent(column, row);
        return (
          <TableCell key={`${dataElementKey}-${column.id}`} align={column.align}>
            {content}
          </TableCell>
        );
      })}
    </TableRow>
  );
}

NormalRow.propTypes = {
  onRowClick: PropTypes.func,
  getCellContent: PropTypes.func,
  showRowCheckbox: PropTypes.bool,
  columns: PropTypes.arrayOf(PropTypes.shape({})),
  dataElementKey: PropTypes.string,
  data: PropTypes.shape({}),
  row: PropTypes.shape({}),
};
