import { createAsyncThunk } from '@reduxjs/toolkit';
import { setFilters, setSnackbarError } from 'appRedux/actions';
import {
  getFormattedWeeklyWorkPlan,
  getFormattedWeeklyWorkPlansListData,
  getFormattedWeeklyWorkPlansQueryParam,
  planFormattedData,
} from 'appRedux/owner/weeklyWorkPlans/selectors';
import { getFormattedListData } from 'appRedux/utility';
import { get } from 'utils/lodash';
import { formattedPlans } from './selectors';

export const fetchWeeklyWorkPlans = createAsyncThunk(
  'weeklyWorkPlans/fetchWeeklyWorkPlans',
  async (name, { getState, extra, dispatch, rejectWithValue }) => {
    try {
      const { filters } = extra.getState(name, getState);
      const query = getFormattedWeeklyWorkPlansQueryParam({ ...filters, name });
      const response = await extra.axios.get('/api/v3/weekly_work_plans', { params: query });
      const plans = get('data.data.weeklyWorkPlans')(response) || {};
      const { records, ...rest } = getFormattedListData(plans);
      const formattedRecords = records.map((record) => formattedPlans(record));
      dispatch(setFilters({ ...rest, name }));
      return { records: formattedRecords };
    } catch (err) {
      dispatch(setSnackbarError('snackbar.schedules.gettingSchedules.error'));
      return rejectWithValue('Error fetching weekly work plans');
    }
  },
);

export const searchWeeklyWorkPlans = createAsyncThunk(
  'weeklyWorkPlans/searchWeeklyWorkPlans',
  async ({ searchQuery, name }, { extra, dispatch, rejectWithValue }) => {
    try {
      const response = await extra.axios.get('/api/v3/weekly_work_plans', { params: { query: searchQuery } });
      return {
        ...getFormattedWeeklyWorkPlansListData(response.data.data.weeklyWorkPlans),
        name,
      };
    } catch (err) {
      dispatch(setSnackbarError('snackbar.schedules.gettingSchedules.error'));
      return rejectWithValue('Error searching weekly work plans');
    }
  },
);

export const fetchWeeklyWorkPlan = createAsyncThunk(
  'weeklyWorkPlans/fetchWeeklyWorkPlan',
  async (planId, { extra, dispatch, rejectWithValue }) => {
    try {
      const response = await extra.axios.get(`/api/v3/weekly_work_plans/${planId}`);
      return response.data.data;
    } catch (err) {
      dispatch(setSnackbarError('snackbar.schedules.gettingSchedule.error'));
      return rejectWithValue('Error fetching weekly work plan');
    }
  },
);

export const createWeeklyWorkPlan = createAsyncThunk(
  'weeklyWorkPlans/createWeeklyWorkPlan',
  async (data, { extra, dispatch, rejectWithValue }) => {
    try {
      const body = planFormattedData(data);
      const response = await extra.axios.post('/api/v3/weekly_work_plans', body);
      return getFormattedWeeklyWorkPlan(response.data.data);
    } catch (err) {
      if (err.name === 'ValidationError') {
        return rejectWithValue({ [err.path]: err.message });
      }
      dispatch(setSnackbarError('snackbar.schedules.createSchedule.error'));
      return rejectWithValue('Error creating weekly work plan');
    }
  },
);

export const updateWeeklyWorkPlan = createAsyncThunk(
  'weeklyWorkPlans/updateWeeklyWorkPlan',
  async (data, { extra, dispatch, rejectWithValue }) => {
    try {
      const body = planFormattedData(data);
      const response = await extra.axios.put(`/api/v3/weekly_work_plans/${data.sequenceNum}`, body);
      return getFormattedWeeklyWorkPlan(response.data.data);
    } catch (err) {
      if (err.name === 'ValidationError') {
        return rejectWithValue({ [err.path]: err.message });
      }
      dispatch(setSnackbarError('snackbar.schedule.updateSchedule.error'));
      return rejectWithValue('Error updating weekly work plan');
    }
  },
);

export const deleteWeeklyWorkPlan = createAsyncThunk(
  'weeklyWorkPlans/deleteWeeklyWorkPlan',
  async ({ planId }, { extra, dispatch, rejectWithValue }) => {
    try {
      await extra.axios.delete(`/api/v3/weekly_work_plans/${planId}`);
      return planId;
    } catch (err) {
      dispatch(setSnackbarError('snackbar.schedule.delete.error'));
      return rejectWithValue('Error deleting weekly work plan');
    }
  },
);
