export const getFormattedAreaRequestBody = (details) => ({
  area: {
    title: details.title,
    area_users_attributes: details.areaUsers?.map((user) => ({
      user_id: user.userId,
      id: user.areaUsersId,
      _destroy: user.destroy,
    })),
  },
});
