import React, { useEffect, useState } from 'react';
import { formatDate, formatStrings } from 'utils/dateTime';

export default function Clock () {
  const [value, setValue] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(
      () => setValue(new Date()),
      1000,
    );

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <span>
      {formatDate(value, formatStrings.punchTimeFormat)}
    </span>
  );
}
