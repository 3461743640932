import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, Button, Box, Typography, DialogActions, Paper, Avatar } from 'components/library';
import { BrowserUpdatedIcon } from 'components/library/icons';
import FileSaver from 'file-saver';
import axios from 'utils/axios';
import { fm } from 'utils/string';
import ScanQR from './ScanQR';
import ProposalSignedMessage from '../ProposalSigned';

export default function BankIdDialog ({ proposal }) {
  const { proposalSignature } = proposal;
  const [open, setOpen] = useState(false);

  const onClickSign = () => {
    setOpen(true);
  };

  const downloadPdf = async () => {
    const url = window.location.href;
    const params = { token: url.split('/').pop() };
    const response = await axios.get('/api/v3/proposals/signed_pdf', { params });
    fetch(`data:application/pdf;base64,${response.data.data.pdfFile.content}`)
      .then((res) => res.blob())
      .then((blob) => FileSaver.saveAs(blob, response.data.data.pdfFile.name));
  };

  if (proposalSignature?.name && !proposalSignature?.staled) {
    return (
      <Box width="100%" display="flex" alignItems="center" justifyContent="space-between">
        <Box />
        <ProposalSignedMessage signeeName={proposalSignature?.name} bankid />
        <Box>
          <Button onClick={downloadPdf}>{fm('download_proposal', 'Ladda ner offert')}</Button>
        </Box>
      </Box>
    );
  }

  if (!open) {
    return (
      <Button
        onClick={onClickSign}
        startIcon={proposalSignature?.staled ? <BrowserUpdatedIcon /> : undefined}
      >
        {fm('accept_offer')}
      </Button>
    );
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open maxWidth="sm" fullWidth>
      <DialogContent>
        <Paper elevation={1}>
          <Box>
            <Typography variant="h4" mb={2}>
              {fm('sign_with_bankid')}
            </Typography>
            <ScanQR />
          </Box>
          <Box display="flex" justifyContent="end">
            <Button onClick={handleClose}>{fm('close')}</Button>
          </Box>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Box mt={2} width="100%" display="flex" flexDirection="column" alignItems="center">
          <Typography variant="caption">&copy; Bird Vision AB 2024</Typography>
          <Typography variant="caption">Denna anslutning är krypterad</Typography>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

BankIdDialog.propTypes = {
  proposal: PropTypes.shape({
    proposalSignature: PropTypes.shape({
      name: PropTypes.string,
      staled: PropTypes.bool,
    }),
  }),
};
