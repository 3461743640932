import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { fm } from 'utils/string';
import CustomTableRow from './TableRow';

export default function TableBodyContent (props) {
  const { data = [], keyField = 'id', columns = [] } = props;

  return data.length ? data.map((row, rowIndex) => (
    <CustomTableRow
      key={`table-row-${row.id}`}
      row={row}
      keyField={keyField}
      getSubtableData={props.getSubtableData}
      columns={columns}
      onRowClick={props.onRowClick}
      getCellContent={props.getCellContent}
      showRowCheckbox={props.showRowCheckbox}
      rowType={props.rowType}
      index={rowIndex}
      expanded={props.expanded}
      onExpandedChange={props.onExpandedChange}
      subtableColumns={props.subtableColumns}
      getSubtableCellContent={props.getSubtableCellContent}
      onSubtableRowClick={props.onSubtableRowClick}
      showSubtablePagination={props.showSubtablePagination}
      getSubtablePagination={props.getSubtablePagination}
      onChangeSubtablePagination={props.onChangeSubtablePagination}
    />)) : (
      <TableRow>
        <TableCell colSpan={12}>
          <Grid container>
            <Grid item xs={12}>
              <Box textAlign="center">
                <Typography id="table_total_records_label" variant="caption">
                  {fm('no_records_found')}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
  );
}

TableBodyContent.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  keyField: PropTypes.string,
  getSubtableData: PropTypes.func,
  onRowClick: PropTypes.func,
  rowType: PropTypes.string,
  getCellContent: PropTypes.func,
  showRowCheckbox: PropTypes.bool,
  expanded: PropTypes.shape({}),
  onExpandedChange: PropTypes.func,
  subtableColumns: PropTypes.shape([]),
  onSubtableRowClick: PropTypes.func,
  showSubtablePagination: PropTypes.bool,
  getSubtableCellContent: PropTypes.func,
  getSubtablePagination: PropTypes.func,
  onChangeSubtablePagination: PropTypes.func,
  columns: PropTypes.shape([]),
};
