import { names, openShiftStatusTypes } from 'utils/enum';
import add from 'date-fns/add';
import { calendarView } from 'components/Calendar/BigCalendar/utility';
import { mapView } from 'components/OrderLocationMap/utility';
import { reportsTabTypes, reportTypes } from 'views/Reports/utility';
import { resourceTypes, eventsOrderTypes } from 'components/Calendar/Scheduler/utility';
import {
  addDays,
  formatDate,
  formatStrings,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  getStandardDate,
  addMonths,
} from 'utils/dateTime';

const pagination = { page: 1, perPage: 10 };
const notificationPagination = { page: 1, perPage: 5 };
// today's date
const startDate = formatDate(new Date(), formatStrings.filtersDate);
// today's date + 1 month
const endDate = formatDate(add(new Date(), { months: 1 }), 'yyyy-MM-dd');

const viewAll = 'view_all';

export default {
  names: {
    [names.home]: {
      startDate: getStandardDate(startOfMonth(new Date())),
      endDate: getStandardDate(endOfMonth(new Date())),
    },
    [names.bookings]: {
      startDate,
      endDate,
      bookingStatus: viewAll,
      publishStatus: viewAll,
      ...pagination,
    },
    [names.orders]: {
      startDate,
      endDate,
      orderStatus: viewAll,
      publishStatus: viewAll,
      projectOrders: viewAll,
      employees: [],
      ...pagination,
    },
    [names.projects]: {
      ...pagination,
      active: true,
      nonRecurring: viewAll,
    },
    [names.projectOrders]: {
      startDate,
      endDate,
      updated: undefined,
      ...pagination,
    },
    [names.projectActivity]: {
      ...pagination,
    },
    [names.recurringBookings]: {
      startDate,
      endDate,
      advanceFilter: 'view_all',
      ...pagination,
    },
    [names.bookingsReports]: {
      startDate,
      endDate,
      bookingStatus: viewAll,
      ...pagination,
    },
    [names.bookingProjectMigratedHistory]: {
      startDate,
      endDate,
      ...pagination,
    },
    [names.bookingProjectMigrationRecurringBookings]: {
      startDate,
      endDate: getStandardDate(addMonths(new Date(), 6)),
      ...pagination,
    },
    [names.reportsNextOrders]: {
      startDate,
      endDate,
      orderStatus: viewAll,
      ...pagination,
    },
    [names.reportsNextEmployees]: {
      startDate,
      endDate,
      ...pagination,
    },
    [names.reportsNext]: {
      startDate,
      endDate,
      activeTab: reportsTabTypes.timeReport,
      reportSortBy: reportTypes.employee,
      ...pagination,
    },
    [names.invoicesReports]: {
      startDate,
      endDate,
      bookingStatus: viewAll,
      ...pagination,
    },
    [names.customersReports]: {
      startDate,
      endDate,
      bookingStatus: viewAll,
      ...pagination,
    },
    [names.employeesReports]: {
      startDate,
      endDate,
      bookingStatus: viewAll,
      ...pagination,
    },
    [names.employeeReports]: {
      ...pagination,
    },
    [names.calendar]: {
      startDate: formatDate(startOfWeek(new Date()), 'yyyy-MM-dd'),
      endDate: formatDate(endOfWeek(new Date()), 'yyyy-MM-dd'),
      view: calendarView.schedulerWeek,
      resourceType: resourceTypes.employee,
      bookingStatus: [],
      showRequests: true,
      showPunchTime: true,
      showTimeReports: true,
      showIntervalEvents: true,
      showDeactivatedEmployees: false,
      order: 'name',
      orderBy: 'asc',
      eventsOrderBy: eventsOrderTypes.time,
      publishStatus: viewAll,
      invoiceStatus: viewAll,
      companyIds: [],
    },
    [names.smartScheduling]: {
      startDate: formatDate(startOfWeek(new Date()), 'yyyy-MM-dd'),
      endDate: formatDate(endOfWeek(new Date()), 'yyyy-MM-dd'),
      showCustomerName: true,
      order: 'asc',
      orderBy: 'employeeName',
      ...pagination,
    },
    [names.customers]: {
      customersListName: names.activeCustomers,
      ...pagination,
    },
    [names.users]: {
      usersListName: names.activeUsers,
      ...pagination,
    },
    [names.availableEmployees]: {
      ...pagination,
      startDate: getStandardDate(),
      endDate: getStandardDate(),
      startTime: '08:00',
      endTime: '12:00',
    },
    [names.superAdminSMS]: {
      startDate: formatDate(startOfMonth(add(new Date(), { months: -1 })), 'yyyy-MM-dd'),
      endDate: formatDate(endOfMonth(add(new Date(), { months: -1 })), 'yyyy-MM-dd'),
      ...pagination,
    },
    [names.superAdminProposalSignature]: {
      startDate: formatDate(startOfMonth(add(new Date(), { months: -1 })), 'yyyy-MM-dd'),
      endDate: formatDate(endOfMonth(add(new Date(), { months: -1 })), 'yyyy-MM-dd'),
      ...pagination,
    },
    [names.invoices]: {
      // start date is 30 days in past
      startDate: formatDate(add(new Date(), { months: -1 }), 'yyyy-MM-dd'),
      endDate: startDate,
      bookingStatus: 'completed',
      bookingPrice: 'hasItem',
      ...pagination,
    },
    [names.invoicesNext]: {
      // start date is 30 days in past
      startDate: formatDate(add(new Date(), { months: -1 }), 'yyyy-MM-dd'),
      endDate: startDate,
      bookingPrice: 'hasItem',
      ...pagination,
    },
    [names.employeeAvailability]: {
      startDate: formatDate(startOfWeek(new Date()), 'yyyy-MM-dd'),
      endDate: formatDate(endOfWeek(new Date()), 'yyyy-MM-dd'),
      startTime: '08:00',
      endTime: '12:00',
      skill: {},
    },
    [names.superAdminWebLogs]: {
      startDate,
      endDate: startDate,
      ...pagination,
    },
    [names.invoicesNextHistory]: {
      startDate: formatDate(add(new Date(), { months: -1 }), 'yyyy-MM-dd'),
      endDate: startDate,
      ...pagination,
    },
    [names.invoiceHistory]: {
      startDate: formatDate(add(new Date(), { months: -1 }), 'yyyy-MM-dd'),
      endDate: startDate,
      ...pagination,
    },
    [names.timeManagementPunches]: {
      startDate: formatDate(add(new Date(), { months: -1 }), 'yyyy-MM-dd'),
      endDate: startDate,
      codeType: viewAll,
      shiftType: viewAll,
      approved: viewAll,
      bookingStatus: viewAll,
      timeCodes: [],
      userActiveStatus: viewAll,
      ...pagination,
    },
    [names.timeManagementNextOverview]: {
      startDate: formatDate(add(new Date(), { months: -1 }), 'yyyy-MM-dd'),
      endDate: startDate,
      codeType: viewAll,
      shiftType: viewAll,
      approved: viewAll,
      bookingStatus: viewAll,
      timeCodes: [],
      userActiveStatus: viewAll,
      ...pagination,
    },
    [names.timeManagementSalary]: {
      startDate: formatDate(add(new Date(), { months: -1 }), 'yyyy-MM-dd'),
      endDate: startDate,
      codeType: viewAll,
      shiftType: viewAll,
      approved: viewAll,
      bookingStatus: viewAll,
      timeCodes: [],
      userActiveStatus: viewAll,
      requestFor: 'Fortnox',
      ...pagination,
    },
    [names.timeManagementSalaryEmployee]: {
      startDate: formatDate(add(new Date(), { months: -1 }), 'yyyy-MM-dd'),
      endDate: startDate,
      ...pagination,
    },
    [names.timeManagementOverview]: {
      startDate: formatDate(add(new Date(), { months: -1 }), 'yyyy-MM-dd'),
      endDate: startDate,
      codeType: viewAll,
      shiftType: viewAll,
      approved: viewAll,
      bookingStatus: viewAll,
      timeCodes: [],
      userActiveStatus: viewAll,
      ...pagination,
    },
    [names.timeManagementPayroll]: {
      startDate,
      endDate: startDate,
      codeType: viewAll,
      shiftType: viewAll,
      approved: viewAll,
      bookingStatus: viewAll,
      timeCodes: [],
      userActiveStatus: viewAll,
      requestFor: 'Fortnox',
      ...pagination,
    },
    [names.timeManagementSummary]: {
      startDate,
      endDate: startDate,
      codeType: viewAll,
      shiftType: viewAll,
      approved: viewAll,
      bookingStatus: viewAll,
      timeCodes: [],
      userActiveStatus: viewAll,
      ...pagination,
    },
    [names.timeReportList]: {
      startDate,
      endDate: startDate,
      ...pagination,
    },
    [names.requests]: {
      startDate,
      endDate: formatDate(add(new Date(), { months: 3 }), formatStrings.filtersDate),
      requestStatus: viewAll,
      requestType: viewAll,
      ...pagination,
    },
    [names.shiftRequests]: {
      startDate,
      endDate,
      ...pagination,
    },
    [names.smsSchedule]: {
      startDate,
      endDate,
      ...pagination,
    },
    [names.superAdminBookingsFailed]: {
      startDate: formatDate(add(new Date(), { months: -1 }), 'yyyy-MM-dd'),
      endDate: startDate,
      ...pagination,
    },
    [names.superAdminBookingsInProgress]: {
      startDate: formatDate(add(new Date(), { months: -1 }), 'yyyy-MM-dd'),
      endDate: startDate,
      ...pagination,
    },
    [names.smsHistory]: {
      startDate: formatDate(add(new Date(), { months: -1 }), 'yyyy-MM-dd'),
      endDate: startDate,
      ...pagination,
    },
    [names.history]: {
      // start date is 30 days in past
      startDate: formatDate(add(new Date(), { days: -1 }), 'yyyy-MM-dd'),
      endDate: startDate,
      program: viewAll,
      ...pagination,
    },
    [names.notifications]: notificationPagination,
    [names.fortnoxAccountsList]: pagination,
    [names.services]: { ...pagination, isActive: true },
    [names.userGroups]: pagination,
    [names.items]: pagination,
    [names.predefinedChecklists]: pagination,
    [names.employeeSchedules]: {
      startDate,
      ...pagination,
    },
    [names.employeeOpenShifts]: {
      ...pagination,
      status: [openShiftStatusTypes.available, openShiftStatusTypes.awaiting],
    },
    [names.openShiftsEmployeesList]: {
      ...pagination,
    },
    [names.employeeshiftTimes]: {
      startDate: formatDate(startOfWeek(new Date()), formatStrings.filtersDate),
      endDate: formatDate(addDays(startOfWeek(new Date()), 7), formatStrings.filtersDate),
    },
    [names.storage]: {
      startDate,
      endDate,
      ...pagination,
    },
    [names.superAdminStorage]: pagination,
    [names.weeklyWorkPlans]: pagination,
    [names.keys]: pagination,
    [names.timeWorkCodes]: {
      ...pagination,
      codeType: 'view_all',
    },
    [names.customerRequestsPopover]: {
      ...pagination,
      requestStatus: 'view_all',
    },
    [names.orderCustomerRequestsPopover]: {
      ...pagination,
      requestStatus: 'view_all',
    },
    [names.recurringBookingModal]: {
      startDate,
      endDate,
      ...pagination,
    },
    [names.superAdminMobileAppLoginHistory]: {
      startDate: formatDate(add(new Date(), { months: -1 }), 'yyyy-MM-dd'),
      endDate: startDate,
      email: '',
      ...pagination,
    },
    [names.employeeHourReportDrawer]: {
      date: startDate,
      bookingStatus: 'active',
    },
    [names.superAdminInvoices]: {
      startDate: getStandardDate(startOfMonth(new Date())),
      endDate: getStandardDate(endOfMonth(new Date())),
      ...pagination,
    },
    [names.bookingItems]: {
      startDate,
      endDate,
      customerType: viewAll,
      ...pagination,
    },
    [names.redDays]: {
      startDate,
      ...pagination,
    },
    [names.timeManagementEmployeeBookingTimeSummary]: {
      startDate,
      endDate,
      codeType: viewAll,
      shiftType: viewAll,
      approved: viewAll,
      bookingStatus: viewAll,
      timeCodes: [],
      userActiveStatus: viewAll,
      ...pagination,
    },
    [names.timeManagementEmployeeInternalTimeSummary]: {
      startDate,
      endDate,
      codeType: viewAll,
      shiftType: viewAll,
      approved: viewAll,
      bookingStatus: viewAll,
      timeCodes: [],
      userActiveStatus: viewAll,
      ...pagination,
    },
    [names.fileManager]: pagination,
    [names.customerDocuments]: pagination,
    [names.allCustomersDocuments]: pagination,
    [names.employeeDocuments]: pagination,
    [names.allUsersDocuments]: pagination,
    [names.superAdminOpenApiIntegration]: pagination,
    [names.proposals]: {
      orderBy: 'id',
      order: 'desc',
      signedStatus: viewAll,
      ...pagination,
    },
    [names.proposalTemplates]: pagination,
    [names.proposalActivity]: pagination,
    [names.ebokaBookings]: {
      startDate,
      endDate,
      ...pagination,
    },
    [names.customerLogs]: {
      ...pagination,
      perPage: 3,
    },
    [names.extendableBookings]: {
      ...pagination,
      activeTab: 0,
      extendableStatus: 'extendable',
      startDate: getStandardDate(addMonths(new Date(), -3)),
      endDate: getStandardDate(addDays(new Date(), -1)),
    },
    [names.unmigratedBookingsRecurrences]: {
      ...notificationPagination,
    },
    [names.productUpdates]: {
      ...pagination,
      published: viewAll,
      status: viewAll,
      severity: viewAll,
      updateType: viewAll,
    },
    [names.productUpdatesCompanies]: {
      ...pagination,
    },
    [names.skills]: pagination,
    [names.customerLocationMap]: {
      startDate: getStandardDate(startOfMonth(new Date())),
      endDate: getStandardDate(endOfMonth(new Date())),
      view: mapView.month,
    },
  },
};
