import { createSlice } from '@reduxjs/toolkit';
import { requestMigrateBookingToOrder } from './thunk';

const bookingOrderMigration = createSlice({
  name: 'bookingOrderMigration',
  initialState: {
    showSingleBookingMigrateDialog: false,
  },
  reducers: {
    setBookingOrderMigrationFields (state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(requestMigrateBookingToOrder.fulfilled, (state) => ({
      ...state,
      fsip: false,
      showSingleBookingMigrateDialog: false,
    }));
  },
});

const { reducer, actions } = bookingOrderMigration;

export const { setBookingOrderMigrationFields } = actions;

export default reducer;
