import camelize from 'camelize';
import { getFormattedPaginationFields, getFilterCompanyId } from 'appRedux/selectors/utility';

export const getFormattedInvoicesListData = (response) => ({
  ...getFormattedPaginationFields(response),
  records: camelize(response.records),
});

export const getFormattedInvoicesQueryParams = (filters) => ({
  start_date: filters.startDate,
  end_date: filters.endDate,
  company_id: getFilterCompanyId(filters),
  page: filters.page,
  per_page: filters.perPage,
  status: filters.invoiceStatus === 'view_all' ? undefined : filters.invoiceStatus,
});

export const getFormattedCreateInvoicesRequestBody = ({
  selectedCustomerIds,
  companyIdInvoiceItemsMap,
  invoiceDetails,
  filters,
}) => ({
  company_ids: selectedCustomerIds,
  invoice_date: invoiceDetails.invoiceDate,
  include_month: invoiceDetails.includeMonth,
  include_product: invoiceDetails.includeProduct,
  start_date: filters.startDate,
  end_date: filters.endDate,
  updated_data: selectedCustomerIds.reduce((companyItems, companyId) => {
    if (companyIdInvoiceItemsMap[companyId]) {
      companyItems[companyId] = companyIdInvoiceItemsMap[companyId];
    }
    return companyItems;
  }, {}),
});
