import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { fm } from 'utils/string';
import { MoreVertIcon } from 'components/library/icons';
import useFilters from 'components/FiltersCommon/useFilters';
import Divider from '@mui/material/Divider';
import { Popover, Grid, Box, Typography } from '@mui/material';

const SortingOptionsPopoverContent = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { setFilters: setItemFilters } = useFilters(props.name);

  const onClickType = (value) => {
    setItemFilters({ orderValue: value, page: 1, name: props.name });
    setAnchorEl(null);
  };

  return (
    <>
      <MoreVertIcon
        fontSize="small"
        onClick={(e) => setAnchorEl(e.target)}
        className="cursor-pointer"
      />
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Grid container justifyContent="center" direction="column">
          <Grid item>
            <Box m={1} textAlign="center">
              <Typography
                variant="subtitle1"
              >
                {fm('sort_by', 'Sort by')}
              </Typography>
            </Box>
            <Divider />
          </Grid>
          {props.sortingOptions.map((option) => (
            <Grid
              item
              key={option.value}
              className="px-2 py-1 cursor-pointer"
              onClick={() => onClickType(option.value)}
            >
              <Typography
                variant="body1"
              >
                {option.label}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Popover>
    </>
  );
};

export default SortingOptionsPopoverContent;

SortingOptionsPopoverContent.propTypes = {
  name: PropTypes.string,
  sortingOptions: PropTypes.shape([]),
};
