import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { get } from 'utils/lodash';
import { fm } from 'utils/string';
import {
  requestOvertimeSettings,
  requestDeleteOvertimeSettings,
  requestCreateOvertimeSetting,
  requestUpdateOvertimeSetting,
  requestOvertimeSetting,
  requestOvertimeRedDays,
} from './thunk';

const overtime = createSlice({
  name: 'overtime',
  initialState: {
    overtime: {},
    redDays: [],
    selectedOvertimeId: null,
  },
  reducers: {
    setOvertimeFields (state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(requestOvertimeSetting.fulfilled, (state, action) => ({
      ...state,
      overtime: action.payload.overtime,
    }));
    builder.addCase(requestOvertimeSettings.fulfilled, (state, action) => ({
      ...state,
      records: action.payload.records,
    }));
    builder.addCase(requestDeleteOvertimeSettings.fulfilled, (state) => ({
      ...state,
      selectedOvertimeId: null,
      showOvertimeDialog: false,
      refreshAllOvertimes: {},
    }));
    builder.addCase(requestOvertimeRedDays.fulfilled, (state, action) => ({
      ...state,
      redDays: action.payload.redDays,
    }));
    builder.addMatcher(
      isAnyOf(requestCreateOvertimeSetting.fulfilled, requestUpdateOvertimeSetting.fulfilled),
      (state) => ({
        ...state,
        openedOvertimeId: null,
        showOvertimeDialog: false,
        overtime: {},
        errorMessage: null,
        refreshAllOvertimes: {},
      }),
    );
    builder.addMatcher(
      isAnyOf(requestCreateOvertimeSetting.rejected, requestUpdateOvertimeSetting.rejected),
      (state, action) => ({
        ...state,
        errorMessage: get('response.data.errors[0].details.code')(action.payload) === '7001' && fm('7001'),
      }),
    );
  },
});

const { reducer, actions } = overtime;

export const { setOvertimeFields } = actions;

export default reducer;
