import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import color from 'utils/colors';
import { grey } from '@mui/material/colors';
import { withStyles } from '@mui/styles';

const CustomIconButton = withStyles(() => ({
  root: {
    cursor: (props) => props.cursor,
    fontSize: (props) => props.fontSize,
    lineHeight: (props) => props.lineHeight,
    letterSpacing: (props) => props.letterSpacing,
    display: (props) => props.display,
    textDecoration: (props) => props.textDecoration,
    outline: (props) => props.outline,
    textTransform: (props) => props.textTransform,
    padding: (props) => props.padding,
    backgroundColor: (props) => props.backgroundColor,
    color: `${grey[700]} !important`,
    '&:hover': {
      color: `${color.primary} !important`,
    },
  },
  startIcon: 'ml-0',
}))((props) => <Button {...props} />);

function IconButtonText (props) {
  return (
    <CustomIconButton
      disableRipple={props.disableRipple}
      {...props}
    >
      {props.children}
    </CustomIconButton>
  );
}

export default IconButtonText;

IconButtonText.defaultProps = {
  cursor: 'pointer !important',
  fontSize: '14px !important',
  lineHeight: '1.29 !important',
  letterSpacing: '-0.2px !important',
  display: 'flex !important',
  textDecoration: 'none !important',
  outline: 'none !important',
  textTransform: 'capitalize !important',
  padding: '0px !important',
  backgroundColor: 'none !important',
  disableRipple: true,
};

IconButtonText.propTypes = {
  children: PropTypes.string,
  cursor: PropTypes.string,
  fontSize: PropTypes.string,
  lineHeight: PropTypes.string,
  letterSpacing: PropTypes.string,
  display: PropTypes.string,
  textDecoration: PropTypes.string,
  outline: PropTypes.string,
  textTransform: PropTypes.string,
  padding: PropTypes.string,
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  disableRipple: PropTypes.bool,
};
