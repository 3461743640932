import axios from 'utils/axios';
import {
  setBookingsFields,
  setSnackbarError,
  setSnackbarSuccess,
} from 'appRedux/actions';
import snakify from 'utils/snakify';
import { getFormattedPreviewBooking } from './selector';

export const requestEmailPreview = (bookingId) => async (dispatch) => {
  try {
    const response = await axios.get(`/api/v3/bookings/${bookingId}/preview`);
    const previewBooking = getFormattedPreviewBooking(response.data.data.booking);
    dispatch(setBookingsFields({ previewBooking }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar.booking.getBooking.error'));
  }
};

export const requestBookingEmailToCustomer = (bookingId, data) => async (dispatch) => {
  try {
    await axios.post(`/api/v1/bookings/${bookingId}/send_email`, snakify(data));
    dispatch(setSnackbarSuccess('snackbar.booking.email.sent'));
  } catch (err) {
    dispatch(setSnackbarError('snackbar.booking.email.error'));
  }
};

export const requestBookingEmailToCustomers = (bookingIds, data) => async (dispatch) => {
  try {
    const requestBody = snakify({ bookings: bookingIds, ...data });
    await axios.post('/api/v3/bookings/send_bulk_email', requestBody);
    dispatch(setSnackbarSuccess('snackbar.booking.email.sent'));
  } catch (err) {
    dispatch(setSnackbarError('snackbar.booking.email.error'));
  }
};

export const requestSendTestEmail = (bookingSequenceNum, emailAddress, data) => async (dispatch) => {
  try {
    await axios.post(
      `/api/v1/bookings/${bookingSequenceNum}/send_test_email`,
      snakify({ emailAddress, ...data }),
    );
    dispatch(setSnackbarSuccess('snackbar.booking.email.sent'));
  } catch (err) {
    dispatch(setSnackbarError('snackbar.booking.email.error'));
  }
};
