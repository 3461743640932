import axios from 'utils/Api';
import axiosNext from 'utils/axios';
import { names } from 'utils/enum';
import camelize from 'camelize';
import { multiStateGet } from 'appRedux/reducers/utility';
import { setSuperAdminRedDayFields, setSnackbarError, setFilters } from 'appRedux/actions';
import { getFormattedListData } from 'appRedux/utility';

const Name = names.redDays;

export const requestRedDays = (name = Name) => async (dispatch, getState) => {
  const { filters } = getState();
  try {
    const { page, perPage } = multiStateGet(name, filters);
    const params = { page, per_page: perPage };
    const response = await axiosNext.get('/api/v3/super_admin/public_holidays', { params });
    const { records, ...paginationFields } = getFormattedListData(response.data.data);
    dispatch(() => {
      dispatch(setSuperAdminRedDayFields({ records }));
      dispatch(setFilters({ ...paginationFields, name }));
    });
  } catch (err) {
    dispatch(setSnackbarError('snackbar.superAdmin.gettingRedDays.error'));
  }
};

export const requestRedDay = (redDayId) => async (dispatch) => {
  try {
    const response = await axios.get(`/api/v3/super_admin/public_holidays/${redDayId}`);
    const { records } = camelize(response.data.data);
    dispatch(setSuperAdminRedDayFields({ records }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar.superAdmin.gettingRedDays.error'));
  }
};

export const requestCreateRedDay = (data) => async (dispatch) => {
  try {
    const reqBody = {
      public_holiday: {
        name: data.name,
        start_date: data.startDate,
      },
    };
    await axios.post('/api/v3/super_admin/public_holidays', reqBody);
    dispatch(setSuperAdminRedDayFields({ redDay: {}, showRedDayModal: false }));
    dispatch(requestRedDays());
  } catch (err) {
    dispatch(setSnackbarError('snackbar.superAdmin.createRedDay.error'));
  }
};

export const requestUpdateRedDay = (data) => async (dispatch) => {
  try {
    const reqBody = {
      public_holiday: {
        name: data.name,
        start_date: data.startDate,
      },
    };
    await axios.put(`/api/v3/super_admin/public_holidays/${data.id}`, reqBody);
    dispatch(setSuperAdminRedDayFields({ redDay: {}, showRedDayModal: false }));
    dispatch(requestRedDays());
  } catch (err) {
    dispatch(setSnackbarError('snackbar.superAdmin.updateRedDay.error'));
  }
};

export const requestDeleteRedDay = (redDayId) => async (dispatch) => {
  try {
    await axios.delete(`/api/v3/super_admin/public_holidays/${redDayId}`);
    dispatch(requestRedDays());
  } catch (err) {
    dispatch(setSnackbarError('snackbar.superAdmin.updateRedDay.error'));
  }
};
