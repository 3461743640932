import { setInfoModalFields } from 'appRedux/actions';
import { fm } from 'utils/string';
import { get } from 'utils/lodash';

export const getFormattedPaginationCamelizedFields = (response = {}) => ({
  page: Number(response.page) || 1,
  perPage: Number(response.perPage) || 10,
  totalRecords: response.totalRecords || 0,
  pages: Math.ceil(response.totalRecords / Number(response.perPage)) || 0,
});

export const getNotificationMessage = (error, type) => {
  if (!error || !Object.keys(error).length) return `Error in ${type}`;

  if (typeof error === 'string') return error;

  return `${Object.keys(error)[0].replace(/_/ug, ' ')} ${typeof error[Object.keys(error)[0]] === 'string'
    ? error[Object.keys(error)[0]] : error[Object.keys(error)[0]][0]}`;
};

export const getSmsDescription = (smsText, stringObject) => {
  Object.entries(stringObject).map(([key, value]) => {
    smsText = smsText.replaceAll(key, value);
    return smsText;
  });
  return smsText;
};

export const getStringForJsonBookingNotes = (notes) => {
  // if (typeof notes !== 'string') debugger;
  return (
    notes && typeof notes === 'object'
      ? notes.blocks.map((block) => `<p>${block.data.text}</p>`).join(' ')
      : notes
  );
};

export const handleFortnoxEndpointError = (err, dispatch) => {
  // error code 3003 when fortnox token expired
  if (get('response.data.errors[0].details.code')(err) !== '3003') return;
  dispatch(setInfoModalFields({
    message: fm(get('response.data.errors[0].details.code')(err)),
    open: true,
    title: 'Fortnox',
  }));
};
