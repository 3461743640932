import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Typography, Grid, Button } from 'components/library';

const Errors = () => (
  <Container maxWidth="md">
    <Grid container justifyContent="center">
      <Typography variant="h6">404: The page you are looking for isn’t here</Typography>
      <Typography variant="body2">
        You either tried some shady route or you came here by mistake. Whichever it is, try using the navigation.
      </Typography>
      <Button variant="text"><Link to="/">&larr; Gå till startsida</Link></Button>
    </Grid>
  </Container>
);

export default Errors;
