import { createSlice } from '@reduxjs/toolkit';
import { requestOpenShifts, requestOpenShift } from './thunk';

const openShifts = createSlice({
  name: 'openShifts',
  initialState: {
    records: [],
    openShift: {},
  },
  reducers: {
    setOpenShiftFields: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(requestOpenShifts.fulfilled, (state, action) => ({
      ...state,
      records: action.payload.records,
    }));
    builder.addCase(requestOpenShift.fulfilled, (state, action) => ({
      ...state,
      ...action.payload,
    }));
  },
});

const { reducer, actions } = openShifts;

export const { setOpenShiftFields } = actions;

export default reducer;
