import React from 'react';
import { fm } from 'utils/string';
import { bookingFlexiblePriceType, bookingTimeTypes } from 'utils/enum';
import {
  timeStringToFloat,
  epochTimeToFloat,
  getUnixTimeInHoursMinutes,
  getDifferenceBetweenTime,
  getHourMinutesInUnixTime,
} from 'utils/dateTime';
import { getItemCost } from './PriceTab/getTotalCosts';

const checklist = (
  <span className="d-flex">
    {fm('booking.modal.checklist')}
  </span>
);

export const getInvoiceTime = (startTime, endTime, breakTime) => {
  if (!endTime || !startTime) return;
  return timeStringToFloat(
    getDifferenceBetweenTime(
      getDifferenceBetweenTime(endTime, startTime), (breakTime || '00:00'),
    ),
  );
};

export const getBookingItemsWithQuantityCalculated = (booking, bookingEmployees) => {
  const { flexiblePriceType, bookingItems = [] } = booking;

  if (flexiblePriceType !== bookingFlexiblePriceType.perEmployeeHourly) return bookingItems;
  const employeeInvoiceHourTotal = bookingEmployees.reduce(
    (res, acc) => (acc.destroy ? res : res + Number(acc.invoiceTime)),
    0,
  ) || 1;

  return bookingItems.map((item) => {
    if (!item.perEmployee) return item;
    const updatedItem = { ...item, quantity: employeeInvoiceHourTotal };
    return { ...updatedItem, ...getItemCost(updatedItem, booking) };
  });
};

export const bookingRepeatType = { week: 'week', month: 'month' };

export const bookingModalTabs = [
  {
    title: fm('booking.modal.detail', 'Booking'),
  },
  {
    title: fm('booking.modal.price', 'Price'),
  },
  {
    title: checklist,
  },
];

export const houseworkTypeEmptyHouseWorkOption = 'empty_housework';

export const rutHouseWorkOptions = [
  'cleaning',
  'moving_services',
  'it_services',
  'textile_clothing',
  'snow_plowing',
  'gardening',
  'baby_sitting',
  'other_care',
  'other_cost',
  'furnishing',
  'home_maintenance',
];

export const rotHouseWorkOptions = [
  'construction',
  'electricity',
  'glass_metal_work',
  'ground_drainage_work',
  'masonry',
  'painting_wallpapering',
  'hvac',
  'major_appliance_repair',
];

export const houseworkTypes = {
  1: 'construction',
  2: 'electricity',
  3: 'glass_metal_work',
  4: 'ground_drainage_work',
  5: 'masonry',
  6: 'painting_wallpapering',
  7: 'hvac',
  8: 'cleaning',
  9: 'textile_clothing',
  11: 'snow_plowing',
  12: 'gardening',
  13: 'baby_siting',
  14: 'other_care',
  15: 'tutoring',
  16: 'it_services',
  17: 'other_cost',
  18: 'major_appliance_repair',
  19: 'moving_services',
  20: 'furnishing',
  21: 'home_maintenance',
};

export const getDefaultEmployeeEndtime = (startTime) => {
  const startTimeEpoch = getHourMinutesInUnixTime(startTime);
  const fourHoursEpoch = getHourMinutesInUnixTime('04:00');
  const lastPossibleEndTimeEpoch = getHourMinutesInUnixTime('23:59');
  const endTime = startTimeEpoch + fourHoursEpoch;
  return getUnixTimeInHoursMinutes(endTime > lastPossibleEndTimeEpoch
    ? lastPossibleEndTimeEpoch : endTime);
};

export const getEmployeeFieldsInitializedForBooking = (employee, booking) => {
  const startTime = booking.startTime || '08:00';
  const endTime = booking.endTime || getDefaultEmployeeEndtime(startTime);
  const breakTime = '00:00';
  const paidTime = getDifferenceBetweenTime(getDifferenceBetweenTime(endTime, startTime), breakTime);

  return {
    name: employee.name,
    userGroupTitle: employee.userGroupTitle,
    userGroupId: employee.userGroupId,
    colorCode: employee.colorCode,
    employeeId: employee.id || employee.userId,
    sequenceNum: employee.sequenceNum,
    ...(booking.timeType === bookingTimeTypes.specificTime && {
      startTime,
      endTime,
      breakTime,
      paidTime,
      invoiceTime: booking.flexiblePriceType === bookingFlexiblePriceType.perEmployeeHourly
        ? epochTimeToFloat(getHourMinutesInUnixTime(paidTime)) : null,
    }),
    ignoreOvertime: false,
  };
};

export const getEmployeesTotalWorkingTime = (employees) => (
  getUnixTimeInHoursMinutes((employees || []).reduce((tot, emp) => {
    tot += emp.destroy ? 0 : getHourMinutesInUnixTime(emp.paidTime);
    return tot;
  }, 0)) || '00:00'
);

export const houseworkTypeOptions = {
  none: 'None',
  rut: 'Rut',
  rot: 'Rot',
};

// eslint-disable-next-line no-useless-escape
export const bookingNotesAllowedCharsRegex = /[^\p{L}0-9><|:.;,*'?=(){}\/+\-!#%&@$ \n\t]/gui;
