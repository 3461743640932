import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';

const TimeInput = (props) => {
  const {
    onChange,
    value = '',
    min,
    max,
  } = props;

  const [time, setTime] = useState('');

  useEffect(() => {
    if ((time === '' && value) || (props.value === undefined && time)) setTime(value);
  }, [value]);

  const testMax = (hours, mins) => {
    if (!max) return false;
    const [maxHours = '00', maxMins = '00'] = max.split(':');
    const maxTime = setMinutes(setHours(new Date(0), Number(maxHours)), Number(maxMins));
    const currentTime = setMinutes(setHours(new Date(0), Number(hours)), Number(mins));
    return isAfter(currentTime, maxTime);
  };

  const testMin = (hours, mins) => {
    if (!min) return false;
    const [minHours = '00', minMins = '00'] = min.split(':');
    const maxTime = setMinutes(setHours(new Date(0), Number(minHours)), Number(minMins));
    const currentTime = setMinutes(setHours(new Date(0), Number(hours)), Number(mins));
    return isBefore(currentTime, maxTime);
  };

  const onBlur = (e) => {
    let enteredValue = e.target.value;
    if (enteredValue.length === 1) {
      enteredValue = `0${enteredValue}:00`;
    } else if (enteredValue.length === 2) {
      enteredValue = `${enteredValue}:00`;
    } else if (enteredValue.length === 3) {
      enteredValue = `${enteredValue.replace(':', '')}:00`;
    }
    const [hours = '00', mins = '00'] = enteredValue.split(':');
    const isTimeAfterMax = testMax(hours, mins);
    const isTimeBeforeMin = testMin(hours, mins);

    if (!enteredValue.includes(':') || isTimeAfterMax || isTimeBeforeMin) {
      setTime('');
      return onChange(undefined, e);
    }
    setTime(enteredValue);
    onChange(enteredValue, e);
  };

  const handleChange = (e) => {
    let timeString = e.target.value.replace((/[:]*/ug), '');
    if (time.length < timeString.length && timeString.length === 2) {
      timeString = `${timeString.substr(0, 2)}${timeString[2] !== ':' ? ':' : ''}${timeString.substr(2, 3)}`;
      setTime(timeString);
    } else {
      setTime(e.target.value);
    }
  };

  const error = Boolean(props.error);

  return (
    <TextField
      {...props}
      value={time}
      onChange={handleChange}
      onBlur={onBlur}
      placeholder="--:--"
      InputLabelProps={props.InputLabelProps}
      error={error}
    />
  );
};

TimeInput.defaultProps = {
  size: 'small',
  variant: 'outlined',
  fullWidth: true,
  type: 'text',
  InputLabelProps: { shrink: true },
};

TimeInput.propTypes = {
  size: PropTypes.string,
  variant: PropTypes.string,
  fullWidth: PropTypes.bool,
  type: PropTypes.string,
  InputLabelProps: PropTypes.shape({}),
  error: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  min: PropTypes.string,
  max: PropTypes.string,
};

export default TimeInput;
