import { formatDate, formatStrings, timeStringToFloat } from 'utils/dateTime';
import { getFormattedPaginationFields } from 'appRedux/selectors/utility';

export const getFormattedProjectResponse = (response) => ({
  ...getFormattedPaginationFields(response),
  records: (response.records || []).map((record) => ({
    ...record,
    projectEmployees: record.projectEmployees.map((emp) => ({
      ...emp,
      name: emp.employee && emp.employee.name,
      colorCode: emp.employee && emp.employee.colorCode,
    })),
    service: {
      ...record.service,
      colorCode: record.service.color.code,
      colorCodeId: record.service.color.id,
    },
  })),
});

const formatOrderEmployees = (orderEmployee) => {
  const startTime = orderEmployee.startTime ? new Date(orderEmployee.startTime.split('+')[0]) : null;
  const endTime = orderEmployee.endTime ? new Date(orderEmployee.endTime.split('+')[0]) : null;
  return {
    ...orderEmployee,
    startTime: startTime ? formatDate(startTime, formatStrings.defaulTime) : null,
    endTime: endTime ? formatDate(endTime, formatStrings.defaulTime) : null,
    invoiceTime: orderEmployee.invoiceTime ? timeStringToFloat(orderEmployee.invoiceTime) : null,
    breakTime: orderEmployee.breakTime ?? null,
  };
};

export const getFormattedOrderResponse = (response) => ({
  ...getFormattedPaginationFields(response),
  records: (response.records || []).map((record) => ({
    ...record,
    orderEmployees: (record.orderEmployees || []).map(formatOrderEmployees),
    id: record.orderId,
    sequenceNum: record.orderSequenceNum,
    virtual: !record.orderId,
  })),
});

export const getFormattedCustomerProjectFilterParams = (filters = {}, customerId) => ({
  start_date: filters.startDate,
  end_date: filters.endDate,
  per_page: filters.perPage,
  page: filters.page,
  customer_id: customerId,
});
