import React from 'react';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { get } from 'utils/lodash';
import { fm } from 'utils/string';
import { WarningRoundedIcon } from 'components/library/icons';
import {
  setInfoModalFields,
  setFilters,
  setSnackbarError,
  setSnackbarSuccess,
} from 'appRedux/actions';
import { FormattedMessage } from 'react-intl';
import {
  getFormattedTimeCodeList,
  getFormattedTimeCodesRequestParams,
  getTimeCodeRequestBody,
  getOtherCodeRequestBody,
} from './selector';

export const requestCreateTimeWorkCode = createAsyncThunk(
  'workCode/createTimeWorkCode',
  async (details, { extra, dispatch }) => {
    try {
      const requestBody = getTimeCodeRequestBody(details);
      await extra.axios.post('/api/v3/time_codes', requestBody);
      dispatch(setSnackbarSuccess('snackbar_work_code_created'));
      return {
        showTimeWorkCodeDialog: false,
        openedWorkCodeId: null,
        record: {},
        refreshAllWorkCodes: {},
      };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_work_code_create_error'));
    }
  },
);

export const requestCreateOtherWorkCode = createAsyncThunk(
  'workCode/createOtherWorkCode',
  async (details, { extra, dispatch }) => {
    try {
      const requestBody = getOtherCodeRequestBody(details);
      await extra.axios.post('/api/v3/salary_codes', requestBody);
      dispatch(setSnackbarSuccess('snackbar_work_code_created'));
      return {
        showOtherWorkCodeDialog: false,
        openedWorkCodeId: null,
        record: {},
        refreshAllWorkCodes: {},
      };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_work_code_create_error'));
    }
  },
);

export const requestUpdateTimeWorkCode = createAsyncThunk(
  'workCode/createTimeWorkCode',
  async (details, { extra, dispatch }) => {
    try {
      const requestBody = getTimeCodeRequestBody(details);
      await extra.axios.put(`/api/v3/time_codes/${details.id}`, requestBody);
      dispatch(setSnackbarSuccess('snackbar_work_code_updated'));
      return {
        showTimeWorkCodeDialog: false,
        openedWorkCodeId: null,
        record: {},
        refreshAllWorkCodes: {},
      };
    } catch (err) {
      if (get('response.data.errors')(err)) {
        return { workCodeDialogError: fm(get('response.data.errors[0].details.code')(err)) };
      }
      dispatch(setSnackbarError('snackbar_work_code_update_error'));
    }
  },
);

export const requestDeleteTimeWorkCode = createAsyncThunk(
  'workCode/deleteTimeWorkCode',
  async (timeCodeId, { extra, dispatch }) => {
    try {
      await extra.axios.delete(`/api/v3/time_codes/${timeCodeId}`);
      dispatch(setSnackbarSuccess('snackbar_work_code_deleted'));
      return {
        showTimeWorkCodeDialog: false,
        openedWorkCodeId: null,
        record: {},
        refreshAllWorkCodes: {},
      };
    } catch (err) {
      if (get('response.data.errors')(err)) {
        return { workCodeDialogError: fm(get('response.data.errors[0].details.code')(err)) };
      }
      dispatch(setSnackbarError('snackbar_work_code_delete_error'));
    }
  },
);

export const requestDeleteOtherWorkCode = createAsyncThunk(
  'workCode/deleteOtherWorkCode',
  async (timeCodeId, { extra, dispatch }) => {
    try {
      await extra.axios.delete(`/api/v3/salary_codes/${timeCodeId}`);
      dispatch(setSnackbarSuccess('snackbar_work_code_deleted'));
      return {
        showOtherWorkCodeDialog: false,
        openedWorkCodeId: null,
        record: {},
        refreshAllWorkCodes: {},
      };
    } catch (err) {
      if (get('response.data.errors')(err)) {
        return { workCodeDialogError: fm(get('response.data.errors[0].details.code')(err)) };
      }
      dispatch(setSnackbarError('snackbar_work_code_delete_error'));
    }
  },
);

export const requestTimeWorkCode = createAsyncThunk(
  'workCode/fetchTimeWorkCode',
  async (id, { extra, dispatch }) => {
    try {
      const response = await extra.axios.get(`/api/v3/time_codes/${id}`);
      return { record: response.data.data };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_work_code_fetch_error'));
    }
  },
);

export const requestOtherWorkCode = createAsyncThunk(
  'workCode/fetchOtherWorkCode',
  async (id, { extra, dispatch }) => {
    try {
      const response = await extra.axios.get(`/api/v3/salary_codes/${id}`);
      return { record: response.data.data };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_work_code_fetch_error'));
    }
  },
);

export const requestTimeWorkCodes = createAsyncThunk(
  'workCode/fetchTimeWorkCodes',
  async (name, { extra, dispatch, getState }) => {
    try {
      const { filters } = extra.getState(name, getState);
      const params = getFormattedTimeCodesRequestParams(filters);
      const response = await extra.axios.get('/api/v3/time_codes', { params });
      const { records, ...rest } = getFormattedTimeCodeList(response.data.data);
      dispatch(setFilters({ ...rest, name }));
      return { records };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_work_code_time_codes_error'));
    }
  },
);

export const requestOtherWorkCodes = createAsyncThunk(
  'workCode/fetchOtherWorkCodes',
  async (name, { extra, dispatch, getState }) => {
    try {
      const { filters } = extra.getState(name, getState);
      const params = { page: filters.page, per_page: filters.perPage, query: filters.query };
      const response = await extra.axios.get('/api/v3/salary_codes', { params });
      const { records, ...rest } = getFormattedTimeCodeList(response.data.data);
      dispatch(setFilters({ ...rest, name }));
      return { records };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_work_code_time_codes_error'));
    }
  },
);

export const requestUpdateTimeWorkCodeActiveStatus = createAsyncThunk(
  'workCode/updateTimeCodeActiveStatus',
  async ({ id, isActive }, { extra, dispatch }) => {
    try {
      const reqBody = { time_code: { is_active: isActive } };
      await extra.axios.put(`/api/v3/time_codes/${id}`, reqBody);
      return { refreshAllWorkCodes: {} };
    } catch (err) {
      if (get('response.status')(err) === 400) {
        return dispatch(setInfoModalFields({
          message: (
            <FormattedMessage
              values={{ warningIcon: <WarningRoundedIcon color="error" /> }}
              id="timeCode.info.deactivate.message"
              defaultMessage="{warningIcon} Time code is linked with a time report request, please delete it first."
            />
          ),
          open: true,
          title: <FormattedMessage id="service.info.deactivate.timecode.title" defaultMessage="Inaktivera tidskod" />,
        }));
      }
      dispatch(setSnackbarError('snackbar_work_code_update_error'));
    }
  },
);

export const requestUpdateOtherWorkCode = createAsyncThunk(
  'workCode/updateOtherCodeActiveStatus',
  async ({ id, ...details }, { extra, dispatch }) => {
    try {
      const reqBody = getOtherCodeRequestBody(details);
      await extra.axios.put(`/api/v3/salary_codes/${id}`, reqBody);
      return {
        showOtherWorkCodeDialog: false,
        openedWorkCodeId: null,
        record: {},
        refreshAllWorkCodes: {},
      };
    } catch (err) {
      if (get('response.status')(err) === 400) {
        return dispatch(setInfoModalFields({
          message: (
            <FormattedMessage
              values={{ warningIcon: <WarningRoundedIcon color="error" /> }}
              id="timeCode.info.deactivate.message"
              defaultMessage="{warningIcon} Salary code is linked with a salary report request, please delete it first."
            />
          ),
          open: true,
          title: <FormattedMessage id="service.info.deactivate.timecode.title" defaultMessage="Inaktivera tidskod" />,
        }));
      }
      dispatch(setSnackbarError('snackbar_work_code_update_error'));
    }
  },
);

export const requestBulkUpdateTimeAndSalaryCode = createAsyncThunk(
  'workCode/bulkUpdateCode',
  async (id, { extra, dispatch }) => {
    try {
      await extra.axios.put('/api/v3/salary_codes/bulk_update_salary_codes');
      dispatch(setSnackbarSuccess('snackbar_work_code_update_in_progress'));
      return {
        refreshAllWorkCodes: {},
      };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_work_code_update_error'));
    }
  },
);
