import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Button } from 'components/library';

function LoadingButton (props) {
  return (
    <Button
      onClick={props.onClick}
      size={props.size}
      startIcon={props.loading ? <CircularProgress size={24} color="inherit" /> : props.startIcon}
      disabled={props.disabled || props.loading}
      type={props.type}
      fullWidth={props.fullWidth}
      id={props.id}
    >
      {props.children}
    </Button>
  );
}

LoadingButton.propTypes = {
  children: PropTypes.node,
  startIcon: PropTypes.node,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  fullWidth: PropTypes.bool,
};

export default LoadingButton;
