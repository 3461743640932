import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  requestSkills,
  requestSkill,
  requestCreateSkill,
  requestUpdateSkill,
  requestDeleteSkill,
  requestDeleteSkills,
} from './thunk';

const skills = createSlice({
  name: 'skills',
  initialState: {
    records: [],
    skill: {},
    refreshAllSkills: {},
    showSkillDialog: false,
    selectedSkillIds: [],
    openedSkillId: null,
    fsip: false,
  },
  reducers: {
    setSkillFields (state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(requestSkill.fulfilled, (state, action) => ({
      ...state,
      skill: action.payload.record,
    }));
    builder.addCase(requestSkills.fulfilled, (state, action) => ({
      ...state,
      records: action.payload.records,
    }));
    builder.addCase(requestDeleteSkill.fulfilled, (state) => ({
      ...state,
      refreshAllSkills: {},
      showSkillDialog: false,
      skill: {},
      openedSkillId: null,
    }));
    builder.addCase(requestDeleteSkills.fulfilled, (state) => ({
      ...state,
      refreshAllSkills: {},
      selectedSkillIds: [],
    }));
    builder.addMatcher(
      isAnyOf(requestCreateSkill.fulfilled, requestUpdateSkill.fulfilled),
      (state) => ({
        ...state,
        openedSkillId: null,
        showSkillDialog: false,
        skill: {},
        refreshAllSkills: {},
        fsip: false,
      }),
    );
  },
});

const { reducer, actions } = skills;

export const { setSkillFields } = actions;

export default reducer;
