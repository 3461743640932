import * as Yup from 'yup';
import { fm } from 'utils/string';
import { getHourMinutesInUnixTime } from 'utils/dateTime';

const EmployeeAvailabilityQueryParamsSchema = Yup.object().shape({
  startDate: Yup.string().required(fm('start_date_required'))
    .test(
      'startDate',
      fm('start_date_must_be_greater_then_end_date'),
      function (startDate) { // eslint-disable-line
        const { endDate } = this.parent;
        return new Date(endDate).getTime() >= new Date(startDate).getTime();
      },
    ),
  endDate: Yup.string().required(fm('end_date_required')),
  startTime: Yup.string().required(fm('start_time_required'))
    .test(
      'startTime',
      fm('start_time_must_be_greater_then_end_time'),
      function (startTime) { // eslint-disable-line
        const { endTime } = this.parent;
        return getHourMinutesInUnixTime(endTime) > getHourMinutesInUnixTime(startTime);
      },
    ),
  endTime: Yup.string().required(fm('end_time_required')),
});

export default EmployeeAvailabilityQueryParamsSchema;
