import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { confirmable } from 'react-confirm';
import { fm } from 'utils/string';

function ConfirmDialog ({ show, proceed, confirmation, options }) {
  const {
    title = fm('confirm'),
    acceptButtonLabel = fm('proceed'),
    closeButtonLabel = fm('close'),
    afterCloseFn,
  } = options;

  const handleClose = () => {
    proceed(false);
    if (afterCloseFn) {
      afterCloseFn();
    }
  };

  const handleAccept = () => {
    proceed(true);
  };

  return (
    <Dialog open={show} onClose={handleClose} id="confirmation-dialog">
      <DialogTitle>
        {title}
      </DialogTitle>
      <DialogContent>
        {confirmation}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} id="confirmation-dialog-close">
          {closeButtonLabel}
        </Button>
        <Button onClick={handleAccept} variant="contained" id="confirmation-dialog-confirm">
          {acceptButtonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmDialog.propTypes = {
  show: PropTypes.bool,
  proceed: PropTypes.func,
  confirmation: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  options: PropTypes.shape({
    title: PropTypes.string,
    closeButtonLabel: PropTypes.string,
    acceptButtonLabel: PropTypes.string,
    afterCloseFn: PropTypes.func,
  }),
};

export default confirmable(ConfirmDialog);
