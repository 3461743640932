import { createSlice /* , isAnyOf */ } from '@reduxjs/toolkit';
import { requestEbokaBookings, requestEbokaBooking, requestUpdateEbokaBookingStatus } from './thunk';

const ebokaBookings = createSlice({
  name: 'eboka/bookings',
  initialState: {
    records: [],
    record: {},
    openBooking: false,
    openCreateBookingMapping: false,
    refreshAllEbokaBookings: {},
  },
  reducers: {
    setEbokaBookingsFields (state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(requestEbokaBookings.fulfilled, (state, action) => ({
      ...state,
      records: action.payload.records,
    }));
    builder.addCase(requestEbokaBooking.fulfilled, (state, action) => ({
      ...state,
      record: action.payload.record,
    }));
    builder.addCase(requestUpdateEbokaBookingStatus.fulfilled, (state, action) => ({
      ...state,
      record: { ...state.record, status: action.payload.status },
    }));
    // builder.addMatcher(
    //   isAnyOf(),
    //   (state) => ({ ...state }),
    // );
  },
});

const { reducer, actions } = ebokaBookings;

export const { setEbokaBookingsFields } = actions;

export default reducer;
