export const getFormattedMessagesForCustomer = (messages) => messages.map((message) => ({
  ...message,
  companyId: message.company_id,
  createdAt: message.created_at,
  isReadByAdmin: message.is_read_by_admin,
  isReadByCustomer: message.is_read_by_customer,
  proposalId: message.proposal_id,
  updatedAt: message.updated_at,
  userId: message.user_id,
}));
