import { createSlice } from '@reduxjs/toolkit';
import { getStandardDate, add } from 'utils/dateTime';
import { requestCustomerProjects, requestCustomerOrders } from './thunk';

const getInitialFilters = (startDate, endDate) => ({
  startDate,
  endDate,
  page: 1,
  perPage: 10,
  totalRecords: 0,
});

const customers = createSlice({
  name: 'customers',
  initialState: {
    projects: {
      records: [],
      filters: getInitialFilters(getStandardDate(), getStandardDate(add(new Date(), { months: 1 }))),
    },
    orders: {
      records: [],
      filters: getInitialFilters(getStandardDate(), getStandardDate(add(new Date(), { months: 1 }))),
    },
  },
  reducers: {
    setCustomersNextFields (state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
    setCustomerProjectFilters (state, action) {
      return {
        ...state,
        projects: {
          ...state.projects,
          filters: {
            ...state.projects.filters,
            ...action.payload,
          },
        },
      };
    },
    setCustomerOrderFilters (state, action) {
      return {
        ...state,
        orders: {
          ...state.orders,
          filters: {
            ...state.orders.filters,
            ...action.payload,
          },
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(requestCustomerProjects.fulfilled, (state, action) => ({
      ...state,
      projects: {
        ...state.projects,
        ...action.payload,
      },
    }));
    builder.addCase(requestCustomerOrders.fulfilled, (state, action) => ({
      ...state,
      orders: {
        ...state.orders,
        ...action.payload,
      },
    }));
  },
});

const { reducer, actions } = customers;

export const { setCustomersNextFields, setCustomerOrderFilters, setCustomerProjectFilters } = actions;

export default reducer;
