import { createAsyncThunk } from '@reduxjs/toolkit';
import snakify from 'utils/snakify';
import { pick, get } from 'utils/lodash';
import { setFilters, setSnackbarError, setSnackbarSuccess } from 'appRedux/actions';
import { getStandardDate } from 'utils/dateTime';
import { parseJson } from 'utils/helpers';
import {
  getFilterCustomerId,
  getFilterServiceId,
  getFormattedListData,
  getFormattedPaginationFields,
} from '../../utility';

const getFilters = (filters) => ({
  ...snakify(pick(['page', 'perPage', 'startDate', 'endDate'])(filters)),
  customer_id: getFilterCustomerId(filters),
  service_id: getFilterServiceId(filters),
});

export const requestRecurringBookingsToMigrate = createAsyncThunk(
  'bookingProjectMigration/fetchRecurringBookings',
  async (name, { extra, dispatch, getState }) => {
    try {
      const { filters } = extra.getState(name, getState);
      const query = { params: getFilters(filters) };
      const response = await extra.axios.get('/api/v3/bookings/unmigrated_bookings', query);
      const { records, ...pagination } = getFormattedListData(response.data.data);
      dispatch(setFilters({ ...pagination, name }));
      return { records };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_booking_project_migration_fetch_bookings_failed'));
      return { records: [] };
    }
  },
);

export const requestMigratedBookingsHistory = createAsyncThunk(
  'bookingProjectMigration/fetchMigratedBookingsHistory',
  async (name, { extra, dispatch, getState }) => {
    try {
      const { filters } = extra.getState(name, getState);
      const query = { params: getFilters(filters) };
      const response = await extra.axios.get('/api/v3/bookings/project_migrated_history', query);
      const { records, ...pagination } = getFormattedListData(response.data.data);
      dispatch(setFilters({ ...pagination, name }));
      return { records };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_booking_project_migration_fetch_bookings_failed'));
      return { records: [] };
    }
  },
);

export const requestMigrateBookingToProject = createAsyncThunk(
  'bookingProjectMigration/createRecurringBookingProject',
  async (data, { extra, dispatch, rejectWithValue }) => {
    try {
      const requestBody = snakify(data);
      await extra.axios.post('/api/v3/projects/migrate_booking_project', requestBody);
      dispatch(setSnackbarSuccess('snackbar_booking_project_created'));
      return { record: {} };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_booking_project_migration_failed'));
      return rejectWithValue();
    }
  },
);

export const requestUnmigratedBookingRecurrences = createAsyncThunk(
  'unmigratedBookingRecurrences',
  async ({ endDate, parentBookingId, name }, { extra, getState, rejectWithValue, dispatch }) => {
    try {
      const { filters } = extra.getState(name, getState);
      const parameters = {
        start_date: getStandardDate(),
        end_date: endDate,
        per_page: filters.perPage,
        page: filters.page || 1,
      };
      const response = await extra.axios.get(
        `/api/v3/bookings/${parentBookingId}/recurrences`,
        { params: parameters },
      );
      if (get('data.data.recurrences.records')(response)) {
        dispatch(setFilters({ ...getFormattedPaginationFields(get('data.data.recurrences')(response)), name }));
        return {
          records: get('data.data.recurrences.records')(response).map(
            (d) => ({ ...d, employeeData: parseJson(d.employeeData) }),
          ),
        };
      }
    } catch (err) {
      return rejectWithValue();
    }
  },
);
