import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { fm } from 'utils/string';
import NormalRow from './Normal';
import Collapsible from './Collapsible';

const selectColumn = { id: 'select', label: fm('service.table.select', 'Select') };

export default function TableRow (props) {
  const Row = useMemo(() => ({ collapsible: Collapsible, normal: NormalRow }[props.rowType]), [props.rowType]);

  const dataElementKey = props.row[props.keyField];

  return (
    <Row
      {...props}
      dataElementKey={dataElementKey}
      selectColumn={selectColumn}
    />
  );
}

TableRow.defaultProps = {
  rowType: 'normal',
  expanded: {},
};

TableRow.propTypes = {
  row: PropTypes.shape({}),
  keyField: PropTypes.number,
  rowType: PropTypes.string,
  expanded: PropTypes.shape({}),
};
