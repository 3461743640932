/* eslint-disable camelcase */
import axios from 'utils/Api';
import camelize from 'camelize';
import { multiStateGet } from 'appRedux/reducers/utility';
import { batch } from 'react-redux';
import { setFilters, setSuperAdminItemsFields, setSnackbarSuccess, setSnackbarError } from 'appRedux/actions';
import { getFormattedCompanyItemReqBody } from './selector';

export const requestCreateCompanyItem = (details) => async (dispatch) => {
  try {
    const company = getFormattedCompanyItemReqBody(details);
    await axios.post('/api/v3/super_admin/registered_articles', company);
    batch(() => {
      dispatch(setSuperAdminItemsFields({ refreshAllItems: {}, showItemModal: false }));
      dispatch(setSnackbarSuccess('snackbar_item_created'));
    });
  } catch (err) {
    dispatch(setSnackbarError('snackbar_superAdmin_createItem_error'));
  }
};

export const requestCompanyItems = (name) => async (dispatch, getState) => {
  const { filters } = getState();
  try {
    const { perPage: per_page, page } = multiStateGet(name, filters);
    const params = { per_page, page };
    const response = await axios.get('/api/v3/super_admin/registered_articles', { params });
    const records = camelize(response.data.data);
    batch(() => {
      dispatch(setSuperAdminItemsFields({ records }));
      dispatch(setFilters({ perPage: 10, page: 1, pages: 1, totalRecords: records.length, name }));
    });
  } catch (err) {
    dispatch(setSnackbarError('snackbar_superAdmin_createItem_error'));
  }
};

export const requestCompanyItem = (itemId) => async (dispatch) => {
  try {
    const response = await axios.get(`/api/v3/super_admin/registered_articles/${itemId}`);
    const item = camelize(response.data.data);
    batch(() => {
      dispatch(setSuperAdminItemsFields({ item }));
    });
  } catch (err) {
    dispatch(setSnackbarError('snackbar_superAdmin_createItem_error'));
  }
};

export const requestUpdateCompanyItem = ({ id, ...item }) => async (dispatch) => {
  try {
    const reqBody = getFormattedCompanyItemReqBody(item);
    await axios.put(`/api/v3/super_admin/registered_articles/${id}`, reqBody);
    dispatch(setSuperAdminItemsFields({ refreshAllItems: {}, showItemModal: false }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar_superAdmin_createItem_error'));
  }
};

export const requestDeleteCompanyItem = (itemId) => async (dispatch) => {
  try {
    await axios.delete(`/api/v3/super_admin/registered_articles/${itemId}`);
    dispatch(setSuperAdminItemsFields({ refreshAllItems: {}, showItemModal: false }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar_superAdmin_createItem_error'));
  }
};

export const requestFetchCompanyItemFromFortnox = (fortnox_id) => async (dispatch, getState) => {
  const { superAdmin: { items: { item: prevItem = {} } } } = getState();
  try {
    const params = { fortnox_id };
    const response = await axios.get('/api/v3/super_admin/registered_articles/fetch_from_fortnox', { params });
    const fetchedItem = { ...camelize(response.data.data), id: prevItem.id, fortnoxStatus: true };
    const item = { ...prevItem, ...fetchedItem };
    dispatch(setSuperAdminItemsFields({ item }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar_superAdmin_createItem_error'));
  }
};
