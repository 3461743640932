import React from 'react';
import { ThemeProvider, Grid, Typography, Footer, Box, CircularProgress } from 'components/library';
import { LinkOffIcon } from 'components/library/icons';
import ErrorBoundary from 'components/common/ErrorBoundary';
import { fm } from 'utils/string';
import { proposalTheme } from './proposalTheme';
import Header from './CustomerProposal/Header/Header';
import ProposalDetails from './CustomerProposal/ProposalDetails';
import usePublicProposal from './proposalData';

const Proposal = () => {
  const { loading, error, company, proposal, documents, requestUpdateProposalFromCustomer } = usePublicProposal();

  if (loading) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" height="100vh">
        <CircularProgress size={60} />
      </Box>
    );
  }

  return (
    <ThemeProvider theme={proposalTheme}>
      <ErrorBoundary>
        {error ? (
          <Box display="flex" alignItems="center" height="100vh">
            <Grid container justifyContent="center">
              <Grid item xs={12}>
                <Box display="flex" justifyContent="center">
                  <LinkOffIcon fontSize="large" />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" flexDirection="column" alignItems="center" m={2} textAlign="center">
                  <Typography variant="h6">
                    {fm('Oops! we couldn\'t locate the page.')}
                  </Typography>
                  <Box my={1} />
                  <Typography variant="body1">
                    {fm('It appears the link is broken or the proposal has been removed or expired.')}
                  </Typography>
                  <Box mt={1} />
                  <Typography>
                    {fm('Consider trying different link, or reach out to the service provider for more info.')}
                  </Typography>
                </Box>
              </Grid>
              <Footer>
                <Box width="100%" display="flex" justifyContent="center">
                  <Typography variant="caption">Powered by: Bokning & Schema Online</Typography>
                </Box>
              </Footer>
            </Grid>
          </Box>
        ) : (
          <>
            <Header company={company} proposal={proposal} />
            <ProposalDetails
              company={company}
              proposal={proposal}
              documents={documents}
              requestUpdateProposalFromCustomer={requestUpdateProposalFromCustomer}
            />
          </>
        )}
      </ErrorBoundary>
    </ThemeProvider>
  );
};

export default Proposal;
