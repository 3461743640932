import { SET_KEYS_FIELDS } from '../actions';

const initialState = {
  records: [],
  selectedKeySequenceNums: [],
};

function keys (state = initialState, action) {
  switch (action.type) {
    case SET_KEYS_FIELDS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

export default keys;
