import {
  getStandardDate,
  addMonths,
  addWeeks,
  addDays,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
} from 'utils/dateTime';

export const mapView = {
  day: 'schedulerDay',
  week: 'schedulerWeek',
  month: 'schedulerMonth',
};

export const getDatesForView = (view, date, offset) => {
  if (view === mapView.month) {
    return {
      startDate: getStandardDate(startOfMonth(offset ? addMonths(date, offset) : date)),
      endDate: getStandardDate(endOfMonth(offset ? addMonths(date, offset) : date)),
    };
  }
  if (view === mapView.week) {
    return {
      startDate: getStandardDate(startOfWeek(offset ? addWeeks(date, offset) : date)),
      endDate: getStandardDate(endOfWeek(offset ? addWeeks(date, offset) : date)),
    };
  }
  return {
    startDate: getStandardDate(offset ? addDays(date, offset) : date),
    endDate: getStandardDate(offset ? addDays(date, offset) : date),
  };
};

export const getDatesForActions = (view, date, action) => {
  if (action === 'NEXT') {
    return getDatesForView(view, date, 1);
  }
  return getDatesForView(view, date, -1);
};
