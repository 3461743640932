import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Container, Grid, Card, CardContent, Typography } from 'components/library';
import { fm } from 'utils/string';
import { appRoutes } from 'utils/enum';
import { userSignOut } from 'appRedux/thunks/auth';

export default function CompanyDeactivated () {
  const dispatch = useDispatch();
  const history = useHistory();
  const { companyActive } = useSelector((state) => state.profile);

  useEffect(() => {
    if (companyActive) history.replace(appRoutes.home);
  }, []);

  const onClickSignout = () => {
    dispatch(userSignOut());
  };

  return (
    <Container maxWidth="md">
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <Card>
          <CardContent>
            <Typography>
              {fm('account_deactivate_title')}
            </Typography>
            <Typography>
              {fm('account_deactivate_description')}
            </Typography>
            <Link to="/" onClick={onClickSignout}>
              {fm('account_deactivate_signIn')}
            </Link>
          </CardContent>
        </Card>
      </Grid>
    </Container>
  );
}
