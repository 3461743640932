import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { openOrderRecipientsTabsTypes } from 'components/Order/utility';
import {
  requestCreateOpenShiftForOrder,
  requestOpenShiftEmployeeRequests,
  requestUpdateOpenShiftForOrder,
  requestApproveEmployeesForOpenShift,
  requestOpenShiftsCount,
  requestOpenShifts,
  requestEmployeesForOpenShifts,
} from './thunk';

const openShifts = createSlice({
  name: 'openShifts',
  initialState: {
    fsip: false,
    records: [],
    activeTab: openOrderRecipientsTabsTypes.invitations,
    openShiftClaimIds: [],
    employeeRequests: {
      Denied: [],
      Awaiting: [],
      Requested: [],
    },
    employees: {
      records: [],
    },
  },
  reducers: {
    setOpenShiftFields: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(requestOpenShiftEmployeeRequests.fulfilled, (state, action) => ({
      ...state,
      employeeRequests: {
        ...state.employeeRequests,
        ...action.payload,
      },
    }));
    builder.addCase(requestApproveEmployeesForOpenShift.fulfilled, (state) => ({
      ...state,
      fsip: false,
      showOpenShiftDialog: false,
      openShiftClaimIds: [],
    }));
    builder.addCase(requestOpenShiftsCount.fulfilled, (state, action) => ({
      ...state,
      ...action.payload,
    }));
    builder.addCase(requestOpenShifts.fulfilled, (state, action) => ({
      ...state,
      records: action.payload.records,
    }));
    builder.addCase(requestEmployeesForOpenShifts.fulfilled, (state, action) => ({
      ...state,
      employees: {
        ...state.employees,
        records: action.payload.records,
      },
    }));
    builder.addMatcher(
      isAnyOf(requestCreateOpenShiftForOrder.fulfilled,
        requestUpdateOpenShiftForOrder.fulfilled,
        requestApproveEmployeesForOpenShift.fulfilled),
      (state) => ({
        ...state,
        showOpenShiftDialog: false,
        fsip: false,
        employeeRequests: {
          Denied: [],
          Awaiting: [],
          Requested: [],
        },
      }),
    );
  },
});

const { reducer, actions } = openShifts;

export const { setOpenShiftFields } = actions;

export default reducer;
