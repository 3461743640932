import { createAsyncThunk } from '@reduxjs/toolkit';
import { setFilters, setSnackbarError, setSnackbarSuccess, setUsersDepartmentFields } from 'appRedux/actions';
import { getFormattedListData } from 'appRedux/utility';
import { getFormattedDepartmentRequestBody } from './selector';

export const requestCreateDepartment = createAsyncThunk(
  'detpartment/createDepartment',
  async (details, { extra, dispatch }) => {
    try {
      const requestBody = getFormattedDepartmentRequestBody(details);
      const response = await extra.axios.post('/api/v3/departments', requestBody);
      dispatch(setSnackbarSuccess('snackbar_department_created'));
      return response.data.data;
    } catch (err) {
      dispatch(setSnackbarError('snackbar_department_create_failed'));
    }
  },
);

export const requestUpdateDepartment = createAsyncThunk(
  'detpartment/updateDepartment',
  async (details, { extra, dispatch }) => {
    try {
      const requestBody = getFormattedDepartmentRequestBody(details);
      await extra.axios.put(`/api/v3/departments/${details.sequenceNum}`, requestBody);
      dispatch(setSnackbarSuccess('snackbar_department_updated'));
    } catch (err) {
      dispatch(setSnackbarError('snackbar_department_update_failed'));
    }
  },
);

export const requestDepartment = createAsyncThunk(
  'detpartment/fetchDepartment',
  async (departmentSequenceNum, { extra, dispatch }) => {
    try {
      const response = await extra.axios.get(`/api/v3/departments/${departmentSequenceNum}`);
      return { record: response.data.data };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_department_fatch_failed'));
    }
  },
);

export const requestDepartments = createAsyncThunk(
  'detpartment/fetchDepartments',
  async (name, { extra, dispatch, getState }) => {
    try {
      const { filters } = extra.getState(name, getState);
      const params = { page: filters.page, per_page: filters.perPage };
      const response = await extra.axios.get('/api/v3/departments', { params });
      const { records, ...nextFilters } = getFormattedListData(response.data.data);
      dispatch(setFilters({ ...nextFilters, name }));
      return { records };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_department_list_failed'));
    }
  },
);

export const requestDepartmentsForUsers = createAsyncThunk(
  'detpartment/fetchDepartmentsForUsers',
  async (companyId, { extra, dispatch, getState }) => {
    try {
      const headers = extra.getHeadersForSubCompany(companyId, getState);
      const response = await extra.axios.get('/api/v3/departments', { headers });
      dispatch(setUsersDepartmentFields({ records: response.data.data.records }));
    } catch (err) {
      dispatch(setSnackbarError('snackbar_department_list_failed'));
    }
  },
);

export const requestDeleteDepartment = createAsyncThunk(
  'detpartment/deleteDepartment',
  async (departmentSequenceNum, { extra, dispatch }) => {
    try {
      await extra.axios.delete(`/api/v3/departments/${departmentSequenceNum}`);
      dispatch(setSnackbarSuccess('snackbar_department_deleted'));
    } catch (err) {
      dispatch(setSnackbarError('snackbar_department_delete_failed'));
    }
  },
);
