import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'utils/axios';
import { injectIntl } from 'react-intl';
import { Box, Button, CircularProgress, QrCode, Typography } from 'components/library';
import { useComponentDidMount, useUpdateEffect } from 'hooks';
import { get } from 'utils/lodash';
import { useSelector } from 'react-redux';
import { CheckCircleIcon } from 'components/library/icons';
import { fm } from 'utils/string';

const token = window.location.href.split('/').pop();

function ScanQR (props) {
  const pingCheckCollectStatusRef = useRef();
  const scanningTimeoutRef = useRef();
  const isMobile = useSelector((state) => state.application.isMobile);
  const [signingType, setSigningType] = useState('qr');
  const [signData, setSignData] = useState(null);
  const [qrCode, setQRCode] = useState(null);
  const [statusHintCode, setStatusHintCode] = useState(null);

  const clearTimeoutCall = () => clearInterval(pingCheckCollectStatusRef.current);

  const handleCollectResult = (response) => {
    setStatusHintCode(response.hintCode);
    if (['failed', 'complete'].includes(response.status)) {
      clearTimeoutCall();
      setSignData((prevState) => ({ ...prevState, status: response.status }));
      setTimeout(() => { window.location.reload(); }, 2000);
    } else {
      pingCheckCollectStatusRef.current = setTimeout(checkCollectStatus, 1000);
    }

    setQRCode(response.status === 'pending' ? response.qrCode : null);
  };

  const checkCollectStatus = async () => {
    try {
      const params = { token, order_ref: signData.orderRef };
      const response = await axios.post('/api/v3/proposals/collect_sign', params);
      handleCollectResult(response.data.data);
      const nextRequestTime = Math.abs(scanningTimeoutRef.current - new Date()) + 1500;
      if (response.data.data.statusHintCode === 'outstandingTransaction' && nextRequestTime > 30000) {
        setSignData({ status: 'timeout' });
      }
      if (response.data.data.statusHintCode !== 'outstandingTransaction' && nextRequestTime > 60000) {
        setSignData({ status: 'timeout' });
      }
    } catch (err) {
      if (get('response.status')(err) === 500) {
        clearTimeoutCall();
      }
      console.log(err);
    }
  };

  const cancelSign = async () => {
    try {
      const params = { token, order_ref: signData.orderRef };
      await axios.post('/api/v3/proposals/cancel_sign', params);
    } catch (err) {
      console.log(err);
    }
  };

  useUpdateEffect(() => {
    if (signData.orderRef) {
      pingCheckCollectStatusRef.current = setTimeout(checkCollectStatus, 1000);
    }
  }, [signData?.orderRef]);

  useEffect(() => () => {
    if (pingCheckCollectStatusRef.current) {
      cancelSign();
      clearTimeoutCall();
    }
  }, []);

  useComponentDidMount(() => {
    const requestCreateSignOrder = async () => {
      try {
        const params = { token };
        const response = await axios.post('/api/v3/proposals/init_sign', params);
        scanningTimeoutRef.current = new Date();
        setQRCode(response.data.data.qrCode);
        setSignData(response.data.data);
      } catch (err) {
        cancelSign();
        clearTimeoutCall();
        console.log(err);
      }
    };
    requestCreateSignOrder();
  });

  const onClickBankOnDevice = () => {
    const { autoStartToken } = signData;
    setSigningType(isMobile ? 'mobile' : 'desktop');
    window.location.href = `bankid:///?autostarttoken=${autoStartToken}&redirect=null`;
  };

  const hintMessage = props.intl.messages.hasOwnProperty(`hintcode-${signingType}-${statusHintCode}`)
    ? props.intl.formatMessage({ id: `hintcode-${signingType}-${statusHintCode}` })
    : props.intl.formatMessage({ id: `hintcode-${statusHintCode}` });

  switch (signData?.status) {
    case 'complete':
      return (
        <Box display="flex" justifyContent="center" alignItems="center" my={2}>
          <CheckCircleIcon color="success" />
          <Typography variant="h6" ml={2}>{fm('Document Signed')}</Typography>
        </Box>
      );
    case 'failed':
      return (
        <Box display="flex" justifyContent="center" my={2}>
          <Typography variant="h6" color="red">{fm('Signing failed')}</Typography>
        </Box>
      );
    case 'timeout':
      return (
        <Box display="flex" justifyContent="center">
          <Typography variant="h5">{fm('Timeout: Close the dialog and try again!')}</Typography>
        </Box>
      );
    default:
      return (
        <Box>
          {qrCode && (
            <Box display="flex" flexDirection="column" alignItems="center" my={3} mx={5}>
              {statusHintCode === 'outstandingTransaction' && (
                <>
                  <Typography mb={1}> {fm('Accept and sign the document with BankID')}</Typography>
                  <Box justifyContent="center" my={2} mx={2}>
                    <Typography component="span">
                      1. {fm('Open the BankID app on your mobile.')}
                    </Typography>
                    <Typography>
                      2. {fm('Tap the QR symbol in the BankID app.')}
                    </Typography>
                    <Typography>
                      3. {fm('Aim the camera at the QR code in this box.')}
                    </Typography>

                  </Box>
                  <QrCode qrCode={qrCode} />
                </>
              )}
              <Box display="flex">
                {statusHintCode !== 'outstandingTransaction' && (
                  <Box mx={4}>
                    <CircularProgress />
                  </Box>
                )}
                {statusHintCode && <Typography>{hintMessage}</Typography>}
              </Box>
            </Box>
          )}
          {statusHintCode === 'outstandingTransaction' && (
            <Box display="flex" justifyContent="center" my={1}>
              <Button variant="text" onClick={onClickBankOnDevice}>
                {fm('Start on same device')}
              </Button>
            </Box>
          )}
        </Box>
      );
  }
}

ScanQR.propTypes = {
  intl: PropTypes.shape({
    messages: PropTypes.shape({}),
    formatMessage: PropTypes.func,
  }),
};

export default injectIntl(ScanQR);
