import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import snakify from 'utils/snakify';
import { pick } from 'utils/lodash';
import { getFilterCompanyId, getFormattedListData } from 'appRedux/utility';
import { setFilters, setSnackbarError } from 'appRedux/actions';

export const requestSuperadminProposalSignaureRecords = createAsyncThunk(
  'superadmin/fetchSuperAdminProposalSignatures',
  async (name, { extra, dispatch, getState, rejectWithValue }) => {
    try {
      const { filters } = extra.getState(name, getState);
      const params = {
        ...snakify(pick(['page', 'perPage', 'startDate', 'endDate'])(filters)),
        company_id: getFilterCompanyId(filters),
      };
      const response = await extra.axios.get('/api/v3/super_admin/proposal_signature', { params });
      const { records, ...paginationFields } = getFormattedListData(response.data.data);
      dispatch(setFilters({ ...paginationFields, name }));
      return { records };
    } catch (err) {
      dispatch(setSnackbarError('failed_to_fetch_proposal_signatuire_history'));
      return rejectWithValue(err);
    }
  },
);

const proposalSignature = createSlice({
  name: 'proposalSignature',
  initialState: { records: [] },
  reducers: {
    setSuperadminProposalSignatureFields (state, action) {
      return { ...state, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(requestSuperadminProposalSignaureRecords.fulfilled, (state, action) => ({
      ...state,
      records: action.payload.records,
    }));
  },
});

const { reducer, actions } = proposalSignature;

export const { setSuperadminProposalSignatureFields } = actions;

export default reducer;
