import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import { confirmable } from 'react-confirm';
import { fm } from 'utils/string';

function AlertDialog ({ show, proceed, confirmation, options }) {
  const {
    title = fm('alert'),
    confirmButtonLabel = fm('ok'),
  } = options;

  const handleAccept = () => {
    proceed(true);
  };

  return (
    <Dialog open={show} onClose={handleAccept}>
      <DialogTitle>
        {title}
      </DialogTitle>
      <DialogContent>
        {typeof confirmation === 'function' ? confirmation({ proceed }) : confirmation}
      </DialogContent>
      <DialogActions>
        <Box>
          <Button onClick={handleAccept}>
            {confirmButtonLabel}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

AlertDialog.propTypes = {
  show: PropTypes.bool,
  proceed: PropTypes.func,
  confirmation: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  options: PropTypes.shape({
    title: PropTypes.string,
    confirmButtonLabel: PropTypes.string,
  }),
};

export default confirmable(AlertDialog);
