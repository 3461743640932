import React from 'react';
import { Typography, Grid, Box } from 'components/library';
import { fm } from 'utils/string';

export default function NotAuthorized () {
  return (
    <Grid container justifyContent="center">
      <Typography>
        <Box mt={1}>
          {fm('not_permitted_to_view_message')}
        </Box>
      </Typography>
    </Grid>
  );
}
