import React, { useState, useEffect, useRef } from 'react';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import {
  Typography,
  Grid,
  IconButton,
  Box,
  Drawer,
  ComposedTextField,
  Button,
  Container,
} from 'components/library';
import { fm } from 'utils/string';
import { CloseIcon, BlockIcon } from 'components/library/icons';
import { formatDate } from 'utils/dateTime';
import { colors } from 'utils/colors';
import DeleteMessageDialog from './DeleteMessageDialog';
import validationSchema from './validationSchema';

const DrawerDetails = (props) => {
  const [messageIdForDelete, setMessageIdForDelete] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [messageCharacterLength, setMessageCharacterLength] = useState(0);
  const messageListRef = useRef();
  const bottomRef = useRef();
  const {
    openDrawer,
    closeDrawerHandler,
    messages,
    messageForAdmin,
    getBackGroundColorForMessage,
    onPostMessage,
    onDeleteMessage,
    company,
    proposal,
  } = props;

  const onSubmitHandler = () => {
    onPostMessage(values.message);
    setFieldValue('message', '');
  };

  const onDeleteMessageHandler = () => {
    onDeleteMessage(messageIdForDelete);
    setOpenDialog(false);
  };

  const openDialogHandler = (message) => {
    if (messageForAdmin) {
      if (!message.userId || message.content === null) return;
      setOpenDialog(true);
      setMessageIdForDelete(message.id);
    } else {
      if (message.userId || message.content === null) return;
      setOpenDialog(true);
      setMessageIdForDelete(message.id);
    }
  };

  const closeDialogHandler = () => {
    setOpenDialog(false);
  };

  const { values, handleChange, handleSubmit, setFieldValue, errors } = useFormik({
    initialValues: {
      message: '',
    },
    validationSchema,
    onSubmit: onSubmitHandler,
  });

  useEffect(() => {
    if (openDrawer) {
      bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages, openDrawer]);

  useEffect(() => {
    setMessageCharacterLength(values.message.length);
  }, [values]);

  const getWidthOfDrawer = () => {
    const screenSize = window.innerWidth;
    if (screenSize > 425 && screenSize <= 768) return screenSize * 0.5;
    if (screenSize > 768) return screenSize * 0.3;
    return screenSize;
  };

  // Method to get left or right side
  const getSidesOfMessage = (message) => {
    if (messageForAdmin) {
      return message.userId ? 'flex-end' : 'flex-start';
    }
    return message.userId ? 'flex-start' : 'flex-end';
  };

  return (
    <Drawer anchor="right" open={openDrawer} onClose={closeDrawerHandler} sx={{ zIndex: 1300 }}>
      <Box
        width={getWidthOfDrawer()}
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Container>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            direction="row"
            xs={12}
          >
            <Grid item>
              <Typography variant="h6" fontWeight="fontWeightBold">
                {fm('messages')}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton onClick={closeDrawerHandler}>
                <CloseIcon fontSize="small" color={colors.grayLight} />
              </IconButton>
            </Grid>
          </Grid>
          <Box mt={3} maxHeight={window.innerHeight * 0.6} overflow="auto" ref={messageListRef}>
            {messages.length ? (
              messages.map((message) => (
                <Box
                  mt={2}
                  key={message.id}
                  onClick={() => openDialogHandler(message)}
                  display="flex"
                >
                  <Grid container justifyContent={getSidesOfMessage(message)}>
                    <Grid item xs={11}>
                      <Box
                        display="flex"
                        alignItems="center"
                        bgcolor={getBackGroundColorForMessage(message)}
                        borderRadius={5}
                      >
                        <Box m={2}>
                          <Typography
                            variant="body1"
                            color={!message.content ? 'textSecondary' : ''}
                          >
                            {!message.content && (
                              <Box component="span" pr={1}>
                                <BlockIcon />
                              </Box>
                            )}
                            {message.content
                              ? message.content
                              : fm('deleted_message')}
                          </Typography>
                        </Box>
                      </Box>
                      <Typography variant="caption" color="textSecondary">
                        <Box component="span" pl={2}>
                          {formatDate(message.createdAt, 'yyyy-MM-dd HH:mm')}
                          {'  | '}
                          {message.userId
                            ? company.contactPerson
                            : proposal.customerName}
                        </Box>
                      </Typography>
                    </Grid>
                  </Grid>
                  <div ref={bottomRef} />
                </Box>
              ))
            ) : (
              <Typography variant="body1" align="center" color="textSecondary">
                {fm('no_messages_found')}
              </Typography>
            )}
          </Box>
          <DeleteMessageDialog
            openDialog={openDialog}
            closeDialogHandler={closeDialogHandler}
            onDeleteMessageHandler={onDeleteMessageHandler}
          />
        </Container>
        <Container>
          <Box mb={1} mt={2} borderColor={colors.grayLight} borderRadius={3}>
            <ComposedTextField
              size="small"
              id="message"
              name="message"
              fullWidth
              value={values.message}
              onChange={handleChange}
              error={errors.message}
              helperText={errors.message}
              label={fm('message', 'Message')}
              placeholder={fm('type_your_message')}
              multiline
              rows={3}
              maxlength={250}
            />
            <Box>
              <Typography variant="caption" color="textSecondary">
                {messageCharacterLength}/250
              </Typography>
            </Box>
            <Box mt={3}>
              <Button
                disabled={values.message.length < 1 || errors.message}
                fullWidth
                onClick={handleSubmit}
              >
                {fm('post_message')}
              </Button>
            </Box>
          </Box>
        </Container>
      </Box>
    </Drawer>
  );
};

DrawerDetails.propTypes = {
  proposal: PropTypes.shape({
    customerName: PropTypes.string,
  }),
  company: PropTypes.shape({
    contactPerson: PropTypes.string,
  }),
  messages: PropTypes.arrayOf(PropTypes.shape({})),
  messageForAdmin: PropTypes.bool,
  openDrawer: PropTypes.bool,
  closeDrawerHandler: PropTypes.func,
  getBackGroundColorForMessage: PropTypes.func,
  onPostMessage: PropTypes.func,
  onDeleteMessage: PropTypes.func,
};

export default DrawerDetails;
