/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import axios from 'utils/axios';
import userAxios from 'utils/userAxios';
import PropTypes from 'prop-types';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import { capitalize } from 'utils/string';

const getInitials = (str) => {
  if (!str) return '';
  const nameArr = str.trim().split(' ');
  return `${nameArr[0][0]}${nameArr.length > 1 ? nameArr[nameArr.length - 1][0] : ''}`.toUpperCase();
};

const StyledAvatars = styled(Avatar)(
  ({ backgroundColor }) => ({
    fontSize: '13px',
    width: '28px',
    height: '28px',
    ...(backgroundColor && { backgroundColor }),
  }),
);

export default function AvatarImg ({
  name,
  color,
  classes,
  src,
  useUserAuthAxios,
  badgeOverlap = 'circular',
  ...rest
}) {
  const [source, setSource] = useState('');

  useEffect(() => {
    async function setImageSrc () {
      try {
        if (src) {
          let response = {};
          if (useUserAuthAxios) {
            response = await userAxios.get(src);
          } else {
            response = await axios.get(src);
          }
          setSource(response.data.data.url);
        }
      } catch (err) {
        console.log('could not download image', err); // eslint-disable-line
      }
    }
    setImageSrc();
  }, []);

  return (
    <Tooltip title={capitalize(name)}>
      <Badge
        badgeContent={rest.badgeContent}
        anchorOrigin={rest.anchorOrigin}
        invisible={rest.invisible}
        overlap={badgeOverlap}
      >
        <StyledAvatars
          src={source}
          classes={classes}
          backgroundColor={color}
          badgeContent={rest.badgeContent}
          {...rest}
        >
          {getInitials(name)}
        </StyledAvatars>
      </Badge>
    </Tooltip>
  );
}

AvatarImg.propTypes = {
  name: PropTypes.string,
  color: PropTypes.string,
  classes: PropTypes.shape({}),
  src: PropTypes.shape([]),
  badgeOverlap: PropTypes.string,
  useUserAuthAxios: PropTypes.bool,
};
