import React from 'react';
import { FormattedMessage } from 'react-intl';
import qs from 'qs';
import { trim } from 'utils/lodash';

export const capitalize = (s) => s && s[0].toUpperCase() + s.slice(1);

export const parseQueryString = (str) => qs.parse(str, { ignoreQueryPrefix: true });

export const htmlStringToString = (str) => {
  try {
    return str.replace(/<\/?[^>]+(>|$)/g, '').replace(/&nbsp;/g, '');
  } catch (err) {
    console.error('htmlStringToString: input must be string');
  }
};

export let fm = (id, msg, options = {}) => <FormattedMessage id={id} defaultMessage={msg} {...options} />;

export const setFormatMessage = (formatMessage) => {
  fm = (id, msg, options = {}) => formatMessage({ id, defaultMessage: msg }, options);
};

export const deepTrim = (value) => {
  if (!value) return value;

  const mapObj = () => {
    const valueCopy = Object.assign(Array.isArray(value) ? [] : {}, value);
    for (const key in value) {
      valueCopy[key] = deepTrim(valueCopy[key]);
    }
    return valueCopy;
  };

  const mapFun = { string: trim, object: mapObj }[typeof value];
  return mapFun ? mapFun(value) : value;
};

export const emojisRegex = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/; // eslint-disable-line

export const forbiddenInputCharsRegex = /[+=||><!^"'~*?:;]/i;

export const fortnoxValidateStringRegex = /[\p{L}\’\\\x{0308}\x{030a}a-zåäöéáœæøüA-ZÅÄÖÉÁÜŒÆØ0-9 –:\.`´’,;\^¤#%§£$€¢¥©™°&\/\(\)=\+\-\*_\!?²³®½\\@\x{00a0}\n\r]*/;
