import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import MuiButton from '@mui/material/Button';

const minHeight = { large: '44px', medium: '36px', small: '24px' };

const StyledButton = styled(MuiButton)(
  ({ theme, variant, borderRadius, backgroundColor, borderWidth, borderColor, padding }) => ({
    ...(variant === 'bordered' && { border: '1px solid' }),
    ...(borderRadius && { borderRadius: theme.spacing(borderRadius) }),
    ...(padding && { padding: theme.spacing(padding) }),
    ...(backgroundColor && { backgroundColor }),
    ...(borderColor && { borderColor }),
    ...(borderWidth && { borderWidth: theme.spacing(borderWidth) }),
  }),
);

const CustomButton = withStyles(() => ({
  root: {
    textTransform: (props) => props.textTransform,
    minHeight: (props) => minHeight[props.size],
    width: (props) => props.width,
  },
}))(({ textTransform, ...rest }) => <StyledButton {...rest} />);

const Button = forwardRef((props, ref) => {
  const {
    variant = 'contained',
    textTransform = 'none !important',
    ...restProps
  } = props;

  return (
    <CustomButton
      disableRipple
      variant={variant}
      textTransform={textTransform}
      {...restProps}
      ref={ref}
    >
      {props.children}
    </CustomButton>
  );
});

Button.propTypes = {
  textTransform: PropTypes.string,
  size: PropTypes.string,
  variant: PropTypes.string,
  children: PropTypes.node,
};

export default Button;
