import { createAsyncThunk } from '@reduxjs/toolkit';
import { setFilters, setSnackbarError } from 'appRedux/actions';
import { getFormattedListData } from 'appRedux/utility';
import { getFormattedParams } from './selector';

export const requestSuperAdminInProgressBookings = createAsyncThunk(
  'area/fetchSuperAdminInProgressBookings',
  async (name, { extra, dispatch, getState }) => {
    try {
      const params = getFormattedParams(extra.getState(name, getState).filters);
      const response = await extra.axios.get('/api/v3/super_admin/settings/pending_recurring_updates', { params });
      const { records, ...paginationFields } = getFormattedListData(response.data.data);
      dispatch(setFilters({ ...paginationFields, name }));
      return { records };
    } catch (err) {
      dispatch(setSnackbarError('failed_to_fetch_update_in_progress_booking'));
    }
  },
);

export const requestSuperAdminFailedBookings = createAsyncThunk(
  'area/fetchSuperAdminFailedBookings',
  async (name, { extra, dispatch, getState }) => {
    try {
      const params = getFormattedParams(extra.getState(name, getState).filters);
      const response = await extra.axios.get('/api/v3/super_admin/settings/pending_create_bookings', { params });
      const { records, ...paginationFields } = getFormattedListData(response.data.data);
      dispatch(setFilters({ ...paginationFields, name }));
      return { records };
    } catch (err) {
      dispatch(setSnackbarError('failed_to_fetch_update_failed_booking'));
    }
  },
);

export const requestResetSuperAdminInProgressBookings = createAsyncThunk(
  'area/resetSuperAdminInProgressBookings',
  async (name, { extra, dispatch }) => {
    try {
      const response = await extra.axios.put('/api/v3/super_admin/settings/reset_pending_booking_updates');
      const { records, ...paginationFields } = getFormattedListData(response.data.data);
      dispatch(setFilters({ ...paginationFields, name }));
    } catch (err) {
      dispatch(setSnackbarError('failed_to_reset_update_in_progress_bookings'));
    }
  },
);
