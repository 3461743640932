import React from 'react';
import PropTypes from 'prop-types';
import Badge from '@mui/material/Badge';
import style from './badge.module.scss';

export default function CustomizedButtons (props) {
  const { badgeContent, children, color, variant, invisible } = props;
  return (
    <Badge
      badgeContent={badgeContent}
      color={color}
      classes={{ badge: style.badge }}
      variant={variant}
      invisible={invisible}
    >
      {children}
    </Badge>
  );
}

CustomizedButtons.propTypes = {
  badgeContent: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  children: PropTypes.node,
  color: PropTypes.string,
  variant: PropTypes.string,
  invisible: PropTypes.bool,
};
