import {
  SET_LOADING,
  SET_APPLICATION_FIELDS,
  TOGGLE_DEVICE,
  SET_APPLICATION_UI_STATE,
  RESET_APPLICATION_UI_STATE,
} from '../actions';

const initialState = {
  isLoading: false,
  isShowMantanceBanner: true,
  deviceSize: null,
  appVersion: null,
  versionData: {},
  breakpoints: {},
  uiState: {

  },
};

function application (state = initialState, action) {
  switch (action.type) {
    case SET_APPLICATION_FIELDS:
      return {
        ...state,
        ...action.payload,
      };
    case SET_APPLICATION_UI_STATE:
      return {
        ...state,
        uiState: {
          ...state.uiState,
          ...action.payload,
        },
      };
    case RESET_APPLICATION_UI_STATE:
      return {
        ...state,
        uiState: initialState.uiState,
      };
    case TOGGLE_DEVICE: {
      return {
        ...state,
        deviceSize: action.payload.size,
      };
    }
    case SET_LOADING:
      if (state.isLoading === action.isLoading) return state;
      return {
        ...state,
        isLoading: action.isLoading,
      };
    default:
      return state;
  }
}

export default application;
