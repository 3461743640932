import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { userRoleHomeRoutes } from 'utils/enum';
import NotAuthorized from 'components/common/NotAuthorized';

export default function PrivateRoute ({ component: Component, roles, authorized, ...rest }) {
  const authToken = useSelector((state) => state.auth.token);
  const userRole = useSelector((state) => state.auth.role);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!authToken) {
          // not logged in so redirect to login page with the return url
          return (
            <Redirect
              to={{ pathname: '/login', search: props.location.search, state: { from: props.location } }}
            />
          );
        }

        if (!roles.includes(userRole)) {
          return <Redirect to={{ pathname: userRoleHomeRoutes[userRole] }} />;
        }

        if (authorized === false) {
          return <NotAuthorized />;
        }

        return <Component {...props} />;
      }}
    />
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.elementType,
  authorized: PropTypes.bool,
  roles: PropTypes.arrayOf(PropTypes.string),
  permission: PropTypes.number,
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};
