import React from 'react';
import PropTypes from 'prop-types';
import { Box, Avatar, Typography } from 'components/library';
import BankidLogo from 'images/BankID_logo.svg';
import { DrawIcon } from 'components/library/icons';
import { fm } from 'utils/string';

export default function ProposalSignedMessage({ signeeName, bankid }) {
  return (
    <Box display="flex" alignItems="center" borderLeft="4px solid #183e4f" backgroundColor="#ebf7fb" px={1} py={1}>
      {bankid ? <Avatar src={BankidLogo} alt="bankid logo" /> : <DrawIcon />}
      <Typography variant="body2" px={1}>
        {fm('Proposal signed by')} <i>{signeeName}</i>
      </Typography>
    </Box>
  );
}

ProposalSignedMessage.propTypes = {
  signeeName: PropTypes.string,
  bankid: PropTypes.bool,
};
