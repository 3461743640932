import { batch } from 'react-redux';
import axios from 'utils/axios';
import bareAxios from 'axios';
import { pick } from 'utils/lodash';
import { requestStorageStats } from 'appRedux/thunks/profile';
import {
  setSnackbarError,
  setBookingDocumentsFields,
  setBookingsFields,
} from 'appRedux/actions';

export const requestBookingAttachments = (bookingId) => async (dispatch) => {
  try {
    const response = await axios.get(`/api/v3/bookings/${bookingId}/documents`);
    const { attachedDocuments: records } = response.data.data;
    dispatch(setBookingDocumentsFields({ records }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar_booking_document_fetch_error'));
  }
};

export const requestUploadBookingDocument = ({ bookingId, filesToUpload, ...restparams }) => (
  async (dispatch, getState) => {
    const { bookings: { bookingDocuments: { records: prevRecords } } } = getState();
    try {
      const records = prevRecords.slice();
      const formData = new FormData();
      for (const file of filesToUpload) { // eslint-disable-line
        // eslint-disable-next-line no-await-in-loop
        const fetchedFile = await fetch(file.url);
        // eslint-disable-next-line no-await-in-loop
        const blob = await fetchedFile.blob();
        records.push({ url: file.url, filename: file.name });
        formData.append('documents[]', blob);
      }
      dispatch(setBookingDocumentsFields({ records }));
      const params = pick(['current_upload', 'future', 'all_active'])(restparams);
      const { data } = await axios.post(`/api/v3/bookings/${bookingId}/documents/attach`, formData, { params });
      dispatch(requestStorageStats());
      batch(() => {
        dispatch(setBookingDocumentsFields({ records: data.data.attachedDocuments, showConfirmActionDialog: false }));
        dispatch(setBookingsFields({ refreshAllBookings: {} }));
      });
    } catch (err) {
      dispatch(setSnackbarError('snackbar_booking_document_upload_error'));
    }
  }
);

export const requestDeleteBookingDocument = (data) => async (dispatch, getState) => {
  // params = current_upload or all_active or future = true
  const { bookingId, documentId, ...restparams } = data;
  const { bookings: { bookingDocuments: { records: prevRecords } } } = getState();
  try {
    dispatch(setBookingDocumentsFields({ fsip: true }));
    const params = pick(['current_upload', 'future', 'all_active'])(restparams);
    await axios.delete(`/api/v3/bookings/${bookingId}/documents/${documentId}`, { params });
    const records = prevRecords.filter((record) => record.id !== documentId);
    dispatch(requestStorageStats());
    batch(() => {
      dispatch(setBookingDocumentsFields({ fsip: false, records, documentId: null, showConfirmActionDialog: false }));
      dispatch(setBookingsFields({ refreshAllBookings: {} }));
    });
  } catch (err) {
    dispatch(setBookingDocumentsFields({ fsip: false }));
    dispatch(setSnackbarError('snackbar_booking_document_delete_error'));
  }
};

export const requestOpenPdfWindow = (attachment) => async (dispatch) => {
  try {
    if (!attachment.id) {
      const response = await fetch(attachment.url);
      window.open(response.url);
    } else {
      const response = await axios(attachment.url);
      window.open(response.data.data.url);
    }
  } catch (err) {
    dispatch(setSnackbarError('snackbar_booking_document_delete_error'));
  }
};

export const requestDownloadAttachment = (attachment) => async (dispatch) => {
  try {
    const response = await axios.get(attachment.url);
    const { data } = await bareAxios({ url: response.data.data.url, method: 'GET', responseType: 'blob' });
    const url = window.URL.createObjectURL(data);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', attachment.filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
  } catch (err) {
    dispatch(setSnackbarError('snackbar_booking_document_download_error'));
  }
};

export const requestBookingIdsAttachmentsStatus = (bookings) => async (dispatch) => {
  try {
    const params = { booking_ids: bookings.map((r) => r.id) };
    const response = await axios.get('/api/v3/bookings/attachment_records', { params });
    const bookingsIdsAttachmentStatus = response.data.data.reduce((result, acc) => {
      result[acc.bookingId] = acc.attached;
      return result;
    }, {});
    dispatch(setBookingsFields({ bookingsIdsAttachmentStatus }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar_booking_document_status_error'));
  }
};
