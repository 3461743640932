import React from 'react';

const Absense = () => (
  <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="23" cy="23" r="23" fill="#ED7D58" />
    <path fillRule="evenodd" clipRule="evenodd" d="M33.7071 26.7929C34.0976 27.1834 34.0976 27.8166 33.7071 28.2071L29.2071 32.7071C28.8166 33.0976 28.1834 33.0976 27.7929 32.7071L25.7929 30.7071C25.4024 30.3166 25.4024 29.6834 25.7929 29.2929C26.1834 28.9024 26.8166 28.9024 27.2071 29.2929L28.5 30.5858L32.2929 26.7929C32.6834 26.4024 33.3166 26.4024 33.7071 26.7929Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M28 13C28 12.4477 27.5523 12 27 12C26.4477 12 26 12.4477 26 13V14H20V13C20 12.4477 19.5523 12 19 12C18.4477 12 18 12.4477 18 13V14.0016C17.5245 14.0049 17.109 14.0147 16.7482 14.0442C16.1861 14.0901 15.6694 14.1887 15.184 14.436C14.4314 14.8195 13.8195 15.4314 13.436 16.184C13.1887 16.6694 13.0901 17.1861 13.0442 17.7482C13 18.2894 13 18.9537 13 19.7587V28.2413C13 29.0463 13 29.7106 13.0442 30.2518C13.0901 30.8139 13.1887 31.3306 13.436 31.816C13.8195 32.5686 14.4314 33.1805 15.184 33.564C15.6694 33.8113 16.1861 33.9099 16.7482 33.9558C17.2894 34 17.9537 34 18.7587 34H24.326C24.9318 34 25.2347 34 25.375 33.8802C25.4967 33.7763 25.5613 33.6203 25.5487 33.4608C25.5342 33.2769 25.32 33.0627 24.8917 32.6343L24.3787 32.1213C23.2071 30.9497 23.2071 29.0503 24.3787 27.8787C25.5095 26.7478 27.3186 26.7085 28.4966 27.7608L30.8787 25.3787C31.2615 24.9959 31.7219 24.7382 32.2093 24.6055C32.5544 24.5116 32.727 24.4647 32.8055 24.4041C32.8891 24.3396 32.9219 24.2966 32.9622 24.199C33 24.1074 33 23.9716 33 23.7V19.7587C33 18.9537 33 18.2894 32.9558 17.7482C32.9099 17.1861 32.8113 16.6694 32.564 16.184C32.1805 15.4314 31.5686 14.8195 30.816 14.436C30.3306 14.1887 29.8139 14.0901 29.2518 14.0442C28.891 14.0147 28.4755 14.0049 28 14.0016V13ZM18 17V16.0018C17.5545 16.0049 17.2046 16.0136 16.911 16.0376C16.4726 16.0734 16.2484 16.1383 16.092 16.218C15.7157 16.4097 15.4097 16.7157 15.218 17.092C15.1383 17.2484 15.0734 17.4726 15.0376 17.911C15.0008 18.3611 15 18.9434 15 19.8V20H31V19.8C31 18.9434 30.9992 18.3611 30.9624 17.911C30.9266 17.4726 30.8617 17.2484 30.782 17.092C30.5903 16.7157 30.2843 16.4097 29.908 16.218C29.7516 16.1383 29.5274 16.0734 29.089 16.0376C28.7954 16.0136 28.4455 16.0049 28 16.0018V17C28 17.5523 27.5523 18 27 18C26.4477 18 26 17.5523 26 17V16H20V17C20 17.5523 19.5523 18 19 18C18.4477 18 18 17.5523 18 17Z" fill="white" />
  </svg>
);

export default Absense;
