import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TextField, Box } from '@mui/material';

const OTPInput = ({ onChange }) => {
  const [otp, setOtp] = useState(new Array(6).fill(''));
  const inputRefs = useRef([]);

  useEffect(() => {
    onChange(otp.join(''));
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  const handleChange = (element, index) => {
    if (Number.isNaN(element.value)) return;

    const newOtp = [...otp];
    newOtp[index] = element.value;
    setOtp(newOtp);

    onChange(newOtp.join(''));

    // Focus next input if current input is not empty and it's not the last input
    if (element.value && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !otp[index] && index !== 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData('text').slice(0, otp.length);
    const newOtp = pasteData.split('').map((char, index) => {
      if (!Number.isNaN(char)) {
        if (index < otp.length) {
          return char;
        }
      }
      return otp[index];
    });

    setOtp(newOtp);
    onChange(newOtp.join(''));

    // Focus the last input field
    if (newOtp.length > 0) {
      inputRefs.current[newOtp.length - 1].focus();
    }
  };

  return (
    <Box display="flex" justifyContent="center">
      {otp.map((data, index) => (
        <TextField
          key={index}
          type="text"
          inputProps={{
            maxLength: 1,
            style: { textAlign: 'center' },
          }}
          value={data}
          onChange={(e) => handleChange(e.target, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          onFocus={(e) => e.target.select()}
          inputRef={(el) => inputRefs.current[index] = el}
          onPaste={handlePaste}
          sx={{ mx: 1, width: '50px' }}
        />
      ))}
    </Box>
  );
};

export default OTPInput;

OTPInput.propTypes = {
  onChange: PropTypes.func.isRequired,
};
