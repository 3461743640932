import React from 'react';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';
import { TextField, Autocomplete, InputAdornment } from 'components/library';
import { connect } from 'react-redux';
import debounce from 'lodash/fp/debounce';
import { fm } from 'utils/string';
import { SearchIcon } from 'components/library/icons';
import { renderOptions, objectTypes, suggestionStateMapFn, mapDispatchToProps, paperComponents } from './utility';
import styles from './styles.module.scss';

const classes = { option: styles.autoCompleteSuggestionsOption };

export { objectTypes };

class SearchAutoComplete extends React.Component {
  constructor (props) {
    super(props);
    this.objectType = objectTypes[props.objectType];
    this.state = {
      text: '',
      showPopper: false,
    };
  }

  renderInput = memoize((params) => (
    <TextField
      {...params}
      variant="outlined"
      fullWidth
      size="small"
      label={this.props.label}
      helperText={this.props.helperText}
      placeholder={this.props.placeholder}
      margin="dense"
      className="mt-0"
      InputProps={{
        ...params.InputProps,
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon htmlColor="#AFB4BA" />
          </InputAdornment>
        ),
      }}
    />
  ))

  getSuggestions = debounce(500)((txt) => {
    this.props.searchText(txt);
  })

  onChangeInput = (e, text, reason) => {
    if (reason === 'input') {
      this.setState({ text });
      this.getSuggestions(text);
    }
    if (reason === 'clear') {
      this.props.onSelect({});
    }
  }

  onCloseSuggestions = () => {
    if (this.props.clearOnSelect) {
      this.resetRecords();
      this.setState({ text: '' });
    }
  }

  onChange = (event, value) => {
    if (value) {
      this.props.onSelect(value);
      this.handleClosePopper();
    } else {
      this.props.onSelect({});
    }
  };

  handleClosePopper = () => {
    this.setState((prevProps) => ({ ...prevProps, showPopper: false }));
  }

  handleOpenPopper = () => {
    this.setState((prevProps) => ({ ...prevProps, showPopper: true }));
  }

  getMemoizedAutoCompleteElements = memoize((objectType, showPopper, objectTypeData) => {
    const PaperComponent = paperComponents[objectType];
    return objectType !== objectTypes.customer ? {
      open: showPopper,
      onOpen: this.handleOpenPopper,
    } : {
      PaperComponent: (paperProps) => <PaperComponent {...paperProps} handleClosePopper={this.handleClosePopper} />,
      renderOption: { true: renderOptions[objectType] }[objectTypeData.showAdditionalInfo],
      open: showPopper || false,
      onOpen: this.handleOpenPopper,
    };
  })

  resetRecords = () => {
    this.props.resetRecords({ records: [] });
  }

  render () {
    const { text: name } = this.state;
    const { object = { name }, clearOnEscape, objectType, objectTypeData } = this.props;
    const { PaperComponent, open, onOpen, renderOption } = this.getMemoizedAutoCompleteElements(
      objectType,
      this.state.showPopper,
      objectTypeData,
    );

    return (
      <Autocomplete
        freeSolo={this.props.freeSolo}
        blurOnSelect
        open={open}
        onOpen={onOpen}
        classes={classes}
        filterOptions={this.props.filterOptions}
        loading={this.props.application.isLoading}
        loadingText={fm('autocomplete.loading.text')}
        onClose={this.onCloseSuggestions}
        disabled={this.props.disabled}
        id={`input-with-suggestions-${objectType}`}
        options={this.props.suggestions}
        value={object}
        popupIcon={null}
        renderOption={renderOption || this.props.renderOption}
        PaperComponent={PaperComponent}
        renderInput={this.renderInput}
        onInputChange={this.onChangeInput}
        getOptionLabel={this.props.getOptionLabel}
        clearOnEscape={clearOnEscape}
        noOptionsText={fm('autocomplete.no.options')}
        onChange={this.onChange}
      />
    );
  }
}

SearchAutoComplete.defaultProps = {
  suggestions: [],
  freeSolo: true,
  filterOptions: (options) => options,
  getOptionLabel: (option) => option.name || option.title || '',
};

SearchAutoComplete.propTypes = {
  filterOptions: PropTypes.func,
  objectType: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
  resetRecords: PropTypes.func.isRequired,
  searchText: PropTypes.func.isRequired,
  getOptionLabel: PropTypes.func,
  suggestions: PropTypes.arrayOf(PropTypes.shape({})),
  clearOnSelect: PropTypes.bool,
  clearOnEscape: PropTypes.bool,
  disabled: PropTypes.bool,
  freeSolo: PropTypes.bool,
  label: PropTypes.node,
  helperText: PropTypes.node,
  placeholder: PropTypes.node,
  showPopper: PropTypes.bool,
  objectTypeData: PropTypes.shape({}),
  application: PropTypes.shape({
    isLoading: PropTypes.bool,
  }),
  object: PropTypes.shape({
    name: PropTypes.string,
  }),
  open: PropTypes.bool,
  onOpen: PropTypes.func,
  renderOption: PropTypes.func,
  showCustomerInformation: PropTypes.bool,
};

export default connect(suggestionStateMapFn, mapDispatchToProps)(SearchAutoComplete);
