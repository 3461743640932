import camelize from 'camelize';
import { subDays } from 'date-fns';
import { getStringToDate } from 'utils/dateTime';
import { getStandardDate } from 'utils/helpers';
import { getFormattedPaginationFields } from 'appRedux/utility';
import { smsDataTypeAPI, smsDataType, smsTemplateType } from 'utils/enum';
import { keyBy } from 'utils/lodash';
import { getSmsDescription } from '../utility';

export const getSMSBodyForAPIRequest = (details) => ({
  all_bookings: details.all,
  future_bookings: details.future,
  scheduled_message: {
    message_text: getSmsDescription(details.customSms, smsDataTypeAPI),
    scheduled_type: details.scheduledType,
    sender_type: details.role,
    sender_id: details.id,
    send_now: details.sendSmsNow,
    scheduled: details.scheduled,
    sms_on_complete: details.smsOnComplete,
    sms_template_id: details.smsTemplateId,
  },
});

const getFormattedHistoryData = (history) => camelize(history);

export const getFormattedScheduledData = (bookingSchedule) => {
  const schedule = camelize(bookingSchedule);
  return {
    ...schedule,
    messageText: schedule.messageText && getSmsDescription(schedule.messageText, smsDataType),
    deliveryTime: schedule.startDate
      ? `${getStandardDate(
        subDays(getStringToDate(schedule.startDate, 'yyyy-MM-dd'), schedule.scheduledType),
      )} ${schedule.startTime}`
      : '-',
  };
};

export const getFormattedSmsHistoryParams = (filters) => ({
  start_date: filters.startDate,
  end_date: filters.endDate,
  page: filters.page,
  per_page: filters.perPage,
});

export const getFormattedSmsScheduleParams = (filters) => ({
  start_date: filters.startDate,
  end_date: filters.endDate,
  page: filters.page,
  per_page: filters.perPage,
});

export const getFormattedSmsHistoryListData = (response = {}) => ({
  ...getFormattedPaginationFields(response),
  records: (response.records || []).map(getFormattedHistoryData),
});

export const getFormattedSmsScheduleListData = (response = {}) => ({
  ...getFormattedPaginationFields(response),
  records: (response.records || []).map(getFormattedScheduledData),
});

export const getFormattedTemplateRequestBody = (template) => ({
  sms_template: {
    template_name: template.templateName,
    template_description: getSmsDescription(template.templateDescription, smsDataTypeAPI),
  },
  update_all: template.updateAll,
});

export const getFormattedTemplate = (template) => {
  if (!template) return null;
  return {
    ...template,
    templateDescription: getSmsDescription(template.templateDescription, smsDataType),
  };
};

export const getFormattedSmsTemplate = (records) => {
  const templateRecords = camelize(records);
  const templatesByTypeMap = keyBy('templateType')(templateRecords);
  const activeTemplates = templateRecords
    .filter((d) => d.templateType === smsTemplateType.active)
    .map(getFormattedTemplate);

  const templateData = {
    activeTemplates,
    records: {
      [smsTemplateType.proposalCreate]: getFormattedTemplate(templatesByTypeMap[smsTemplateType.proposalCreate]),
      [smsTemplateType.completed]: getFormattedTemplate(templatesByTypeMap[smsTemplateType.completed]),
      [smsTemplateType.cancelled]: getFormattedTemplate(templatesByTypeMap[smsTemplateType.cancelled]),
      [smsTemplateType.active]: activeTemplates.find((record) => record.default)
        || getFormattedTemplate(templatesByTypeMap[smsTemplateType.active]),
    },
  };

  return templateData;
};

export const getFormattedUpdatedTemplateData = (templateId, prevTemplatesData) => {
  const activeTemplates = prevTemplatesData.activeTemplates.map((record) => ({
    ...record,
    default: record.id === templateId,
  }));
  const records = {
    ...prevTemplatesData.records,
    [smsTemplateType.active]: activeTemplates.find((record) => record.default),
  };
  return { activeTemplates, records };
};
