import React from 'react';
import PropTypes from 'prop-types';
import formatting from 'utils/formatting';
import { get } from 'utils/lodash';
import { fm } from 'utils/string';
import { Grid, List, ListItem, ListItemText, Box } from 'components/library';

const ItemRow = (props) => {
  const { item, proposal, companyDetails } = props;
  const perUnitPrice = get('customer.vatIncluded')(proposal) ? item.inclVat : item.exclVat;

  return (
    <Grid container>
      <Grid item xs={12} className="border rounded mt-2">
        <List dense disablePadding>
          <ListItem>
            <ListItemText
              primary={item.title}
              secondary={
                <>
                  <Box>
                    {companyDetails.fortnoxIntegrated ? (
                      `${formatting.currency(item.perUnit, 2, 0)} / ${item.unit}
                      x ${formatting.number(item.quantity, 2, 0)}
                      = ${formatting.currency(item.perUnit * item.quantity, 2, 0)}`
                    ) : (
                      `${formatting.currency(perUnitPrice, 2, 0)} / ${item.unit}
                      x ${formatting.number(item.quantity, 2, 0)}
                      = ${formatting.currency(perUnitPrice * item.quantity, 2, 0)}`
                    )}
                  </Box>
                  {(item.isHousework && item.houseworkType) && (
                    <Box>
                      {proposal.houseworkType} - {fm(`booking.modal.${item.houseworkType}`)}
                    </Box>
                  )}
                </>
              }
            />
          </ListItem>
        </List>
      </Grid>
    </Grid>
  );
};

export default ItemRow;

ItemRow.propTypes = {
  companyDetails: PropTypes.shape({
    fortnoxIntegrated: PropTypes.bool,
  }),
  proposal: PropTypes.shape({
    houseworkType: PropTypes.string,
  }),
  item: PropTypes.shape({
    title: PropTypes.string,
    exclVat: PropTypes.number,
    inclVat: PropTypes.number,
    unit: PropTypes.number,
    quantity: PropTypes.number,
    isHousework: PropTypes.bool,
    houseworkType: PropTypes.string,
    perUnit: PropTypes.string,
  }),
};
