import React, { useState, useRef } from 'react';
import html2canvas from 'html2canvas';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Button,
  Typography,
  Box,
  ComposedTextField,
} from 'components/library';
import { proposalRequestStatusTypes } from 'utils/enum';
import { fm } from 'utils/string';

const AcceptProposalDialog = (props) => {
  const imageRef = useRef(null);
  const [name, setName] = useState(null);
  const { onClickAcceptDenyOffer } = props;

  const onCloseDialog = () => {
    props.setOpen(false);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setName(value);
  };

  const captureImage = () => {
    html2canvas(imageRef.current)
      .then((canvas) => {
        // Convert canvas to base64 encoded image
        const image = canvas.toDataURL('image/png');
        onClickAcceptDenyOffer({
          status: proposalRequestStatusTypes.signed,
          signature: image,
        });
      });
  };

  return (
    <Dialog
      open={props.open}
      fullWidth
      maxWidth="sm"
      onClose={onCloseDialog}
    >
      <DialogTitle id="booking-dialog-tab-buttons">
        {fm('sign_document')}
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="caption">
              {fm('signed_by')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box className="py-2">
              <ComposedTextField
                size="small"
                id="name"
                name="name"
                fullWidth
                value={name}
                placeholder={fm('type_name')}
                onChange={handleChange}
                label={fm('name', 'Name')}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              {fm('preview')}
            </Typography>
            <Box
              style={{ border: '1px solid #f2f2f3', textAlign: 'center' }}
              p={8}
              className="border-[#f2f2f3] h-64 bg-[#f2f2f3] rounded w-full"
              ref={imageRef}
            >
              <Typography
                variant="h2"
                className="font-family-caveat"
                color="primary"
              >
                {name}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Grid container justifyContent="flex-end">
          <Button
            variant="text"
            onClick={onCloseDialog}
          >
            {fm('close', 'Close')}
          </Button>
          <Button
            onClick={captureImage}
            disabled={!name}
          >
            {fm('sign_and_accept', 'Sign and accept')}
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default AcceptProposalDialog;

AcceptProposalDialog.propTypes = {
  onClickAcceptDenyOffer: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired,
  open: PropTypes.bool,
};
