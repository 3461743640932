import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { requestResetPassword } from 'appRedux/thunks/auth';
import { Grid, Button, Typography, Box } from 'components/library';
import { DraftsOutlinedIcon } from 'components/library/icons';
import OTPInput from 'components/library/Input/OtpInput';
import { fm } from 'utils/string';
import { setAuthFields } from 'appRedux/actions';
import { FormattedMessage } from 'react-intl';
import { appRoutes } from 'utils/enum';
import ChangePasswordLayout from './ChangePasswordLayout';

const PasswordReset = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [otp, setOtp] = useState('');
  const [wait, setWait] = useState(20);
  const email = sessionStorage.getItem('reset_password_email');
  const resendOtpEnabled = wait === 0;

  useEffect(() => {
    let timer;
    if (wait > 0) {
      timer = setTimeout(() => { setWait(wait - 1); }, 1000);
    }
    return () => clearTimeout(timer);
  }, [wait]);

  const submitForm = (event) => {
    event.preventDefault();
    dispatch(setAuthFields({ resetPasswordError: null }));
    sessionStorage.setItem('reset_password_otp', otp);
    if (otp) history.push(appRoutes.changePassword);
    event.target.reset();
  };

  const onClickResendEmail = () => {
    if (!resendOtpEnabled) return;
    setWait(20);
    dispatch(setAuthFields({ resetPasswordError: null }));
    dispatch(requestResetPassword(email, history));
  };

  const handleOtpChange = (newOtp) => {
    setOtp(newOtp);
  };

  return (
    <ChangePasswordLayout
      icon={<DraftsOutlinedIcon fontSize="medium" />}
      title={fm('password_reset', 'Password reset')}
      titleDescription={
        <FormattedMessage
          id="sent_code_to"
          values={{ email: <b>{email}</b> }}
        />
    }
    >
      <form onSubmit={submitForm}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <OTPInput onChange={handleOtpChange} />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" mt={2} fullWidth>
              {fm('continue', 'Continue')}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Box textAlign="center">
              <Typography variant="caption">{fm('not_recieved_email')}</Typography>
              <Typography
                ml={1}
                color={resendOtpEnabled ? 'primary' : 'disabled'}
                className={cx('underline', resendOtpEnabled ? 'pointer' : '')}
                variant="caption"
                onClick={onClickResendEmail}
              >
                {fm('click_to_resend')}
              </Typography>
              {!resendOtpEnabled && (
                <Typography variant="caption">
                  {' '} ({fm('wait_to_send', undefined, { seconds: wait })})
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      </form>
    </ChangePasswordLayout>
  );
};

export default PasswordReset;
