import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const MaterialCheckbox = (props) => {
  const { label, size, formControlLabelSx, ...restProps } = props;

  return label ? (
    <FormControlLabel
      sx={formControlLabelSx}
      label={label}
      control={
        <Checkbox
          {...restProps}
          size={size}
          disableRipple
        />
      }
    />
  ) : (
    <Checkbox
      sx={{ padding: restProps.padding, margin: restProps.margin }}
      {...props}
    />
  );
};

MaterialCheckbox.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disableRipple: PropTypes.bool,
  checked: PropTypes.bool,
  padding: PropTypes.number,
  size: PropTypes.string,
};

MaterialCheckbox.defaultProps = {
  checked: false,
  disableRipple: true,
  padding: 0,
  size: 'small',
};

export default MaterialCheckbox;
