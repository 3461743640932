import * as Yup from 'yup';
import { fm, deepTrim } from 'utils/string';
import { getHourMinutesInUnixTime } from 'utils/dateTime';
import {
  getFormattedRequestBodyForApiRequest,
} from './selector';

const requestSchema = Yup.object().shape({
  requestForWork: Yup.string().required(fm('request.employee.request_for')),
  endDate: Yup.string().required(fm('request.employee.end_date')),
  startTime: Yup.string()
    .required(fm('request.employee.start_time'))
    // eslint-disable-next-line func-names
    .test('startTime', fm('request.start_time_greater'), function (startTime) {
      const { endTime } = this.parent;
      return getHourMinutesInUnixTime(endTime) > getHourMinutesInUnixTime(startTime);
    }),
  endTime: Yup.string().required(fm('request.employee.end_time')),
  startDate: Yup.string()
    .required(fm('request.employee.start_date'))
    // eslint-disable-next-line func-names
    .test('startDate', fm('request.start_date_greater'), function (startDate) {
      const { endDate } = this.parent;
      if (this.parent.sequenceNum) return true;
      const d1 = new Date(startDate);
      const d2 = new Date(endDate);
      return d2.getTime() >= d1.getTime();
    }),
});

export const validateAndGetRequest = async (obj) => {
  await requestSchema.validate(obj);
  return deepTrim(getFormattedRequestBodyForApiRequest(obj));
};
