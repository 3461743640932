import { getFormattedPaginationFields } from 'appRedux/selectors/utility';

const getFormattedEmployeeRequest = (request) => ({
  ...request,
  requestForWork: request.request_for_work === true ? 'requestForWork' : 'requestForHoliday',
});

export const getFormattedEmployeeRequestsList = (response) => ({
  ...getFormattedPaginationFields(response),
  records: response.records.map(getFormattedEmployeeRequest),
});

export const getFormattedRequestBodyForApiRequest = (data) => ({
  start_date: data.startDate,
  end_time: data.endTime,
  start_time: data.startTime,
  ...(!data.sequenceNum && { end_date: data.endDate }),
  request_for_work: data.requestForWork,
  days: data.days,
  wholeday: data.wholeday,
  comment: data.comment,
});