import labels from './labels.json';
import messages from './messages.json';
import buttons from './buttons.json';
import validtion from './validation.json';
import snackbar from './snackbar.json';
import errorCodes from './errorCodes.json';
import news from './news.json';
import alert from './alert.json';

export default {
  ...labels,
  ...messages,
  ...buttons,
  ...validtion,
  ...snackbar,
  ...errorCodes,
  ...news,
  ...alert,
};
