import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  requestProductUpdatesRecords,
  requestSuperAdminProductUpdatesRecords,
  requestCreateProductUpdateRecord,
  requestUpdateProductUpdateRecord,
  requestProductUpdateRecord,
  requestSuperAdminProductUpdateRecord,
  requestDeleteProductUpdateRecords,
  requestDeleteProductUpdateRecord,
  requestMarkReadAllProductUpdatesRecords,
  requestMarkReadProductUpdateRecord,
  requestSearchProductUpdateCompanies,
} from './thunk';

const productUpdates = createSlice({
  name: 'productUpdates',
  initialState: {
    productUpdate: {},
    records: [],
    selectedProductUpdateIds: [],
    refreshAllProductUpdates: {},
    productUpdateCompanies: {
      records: [],
    },
  },
  reducers: {
    setProductUpdateFields (state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(requestProductUpdateRecord.fulfilled, (state, action) => ({
      ...state,
      productUpdate: action.payload.record,
      fsip: false,
    }));
    builder.addCase(requestSearchProductUpdateCompanies.fulfilled, (state, action) => ({
      ...state,
      productUpdateCompanies: {
        ...state.productUpdateCompanies,
        records: action.payload.records,
      },
    }));
    builder.addCase(requestProductUpdatesRecords.fulfilled, (state, action) => ({
      ...state,
      records: action.payload.records,
    }));
    builder.addCase(requestSuperAdminProductUpdateRecord.fulfilled, (state, action) => ({
      ...state,
      productUpdate: action.payload.record,
      fsip: false,
    }));
    builder.addCase(requestDeleteProductUpdateRecord.fulfilled, (state) => ({
      ...state,
      refreshAllProductUpdates: {},
      showSuperAdminProductUpdateDialog: false,
      productUpdate: {},
      selectedProductUpdateIds: [],
    }));
    builder.addCase(requestSuperAdminProductUpdatesRecords.fulfilled, (state, action) => ({
      ...state,
      records: action.payload.records,
    }));
    builder.addCase(requestDeleteProductUpdateRecords.fulfilled, (state) => ({
      ...state,
      refreshAllProductUpdates: {},
      selectedProductUpdateIds: [],
      productUpdate: {},
    }));
    builder.addMatcher(
      isAnyOf(requestMarkReadAllProductUpdatesRecords.fulfilled,
        requestMarkReadProductUpdateRecord.fulfilled),
      (state) => ({
        ...state,
        refreshAllProductUpdates: {},
        fsip: false,
      }),
    );
    builder.addMatcher(
      isAnyOf(requestCreateProductUpdateRecord.fulfilled, requestUpdateProductUpdateRecord.fulfilled),
      (state) => ({
        ...state,
        showSuperAdminProductUpdateDialog: false,
        refreshAllProductUpdates: {},
        fsip: false,
        openedProductUpdateId: null,
        productUpdate: {},
        selectedProductUpdateIds: [],
      }),
    );
    builder.addMatcher(
      isAnyOf(requestCreateProductUpdateRecord.rejected, requestUpdateProductUpdateRecord.rejected,
        requestProductUpdateRecord.rejected, requestSuperAdminProductUpdateRecord.rejected),
      (state) => ({
        ...state,
        fsip: false,
        refreshAllProductUpdates: {},
      }),
    );
  },
});

const { reducer, actions } = productUpdates;

export const { setProductUpdateFields } = actions;

export default reducer;
