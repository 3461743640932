import { createAsyncThunk } from '@reduxjs/toolkit';
import { setSnackbarError } from 'appRedux/actions';
import {
  getFormattedCustomersRequestBody,
  getFormattedCustomerDataForRequest,
  getFormattedOrderFilterParams,
} from './selector';
import { getFormattedOrderResponse } from '../orders/selector';

export const requestCustomersLocation = createAsyncThunk(
  'customerLocation/customerLocations',
  async (details, { extra, dispatch }) => {
    try {
      const query = getFormattedCustomersRequestBody(details);
      const response = await extra.axios.get('/api/v3/maps/customers', { params: query });
      return response.data.data;
    } catch (err) {
      dispatch(setSnackbarError('snackbar_get_customer_locations_failed'));
    }
  },
);

export const requestUpdateCustomersLocation = createAsyncThunk(
  'customerLocation/updateCustomerLocation',
  async (details, { extra, dispatch }) => {
    try {
      const customerBody = getFormattedCustomerDataForRequest(details);
      await extra.axios.put(`/api/v1/customers/${details.sequenceNum}`, customerBody);
      return { details };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_update_customer_location_failed'));
    }
  },
);

export const requestGetOrders = createAsyncThunk(
  'customerLocation/orders',
  async (details, { extra, dispatch }) => {
    try {
      const params = getFormattedOrderFilterParams(details);
      const response = await extra.axios.get('/api/v3/orders', { params });
      const { records } = getFormattedOrderResponse(response.data.data.orders);
      return { records };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_get_customer_orders_failed'));
    }
  },
);
