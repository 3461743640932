import { SET_COMMENT_FIELDS } from 'appRedux/actions';

const initialState = {};

function comment (state = initialState, action) {
  switch (action.type) {
    case SET_COMMENT_FIELDS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

export default comment;
