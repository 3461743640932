import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { requestResetPassword } from 'appRedux/thunks/auth';
import { TextField, Grid, Alert } from 'components/library';
import LoadingButton from 'components/common/LoadingButton';
import { FingerprintIcon } from 'components/library/icons';
import { fm } from 'utils/string';
import { setAuthFields } from 'appRedux/actions';
import ChangePasswordLayout from './ChangePasswordLayout';

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { fsip } = useSelector((state) => state.auth);
  const resetPasswordError = useSelector((state) => state.auth.resetPasswordError);
  const hasError = Boolean(resetPasswordError);

  useEffect(() => {
    dispatch(setAuthFields({ resetPasswordError: null }));
  }, []);

  const submitForm = (event) => {
    event.preventDefault();
    const email = event.target.email.value;
    if (!email) return;
    dispatch(requestResetPassword(email, history));
  };

  return (
    <ChangePasswordLayout
      icon={<FingerprintIcon fontSize="medium" />}
      title={fm('forgot_password_title', 'Forgot password?')}
      titleDescription={fm('forgot_password_description')}
    >
      <form onSubmit={submitForm}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              size="small"
              type="email"
              margin="normal"
              required
              fullWidth
              id="emailInput"
              label={fm('email', 'Email address')}
              name="email"
              autoFocus
            />
          </Grid>
          <Grid item xs={12}>
            <LoadingButton fullWidth type="submit" loading={fsip}>
              {fm('reset_password')}
            </LoadingButton>
          </Grid>
        </Grid>
      </form>
      {hasError && (
        <Grid item xs={12} mt={1}>
          <Alert severity="error">
            {resetPasswordError}
          </Alert>
        </Grid>
      )}
    </ChangePasswordLayout>
  );
};

export default ForgotPassword;
