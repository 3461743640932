import React from 'react';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import { fm } from 'utils/string';

export const rowsPerPageOptions = [5, 10, 15, 20, 30];

export const getSelectColumnsElem = (props, row) => {
  const { content, align } = props.getCellContent(props.selectColumn, row);
  return <TableCell align={align}>{content}</TableCell>;
};

export const getLabelDisplayedRows = ({ page }, { pages }) => (
  `${fm('page', 'Page')} ${page + 1} ${fm('of', 'of')} ${pages}`
);

export const labelRowsPerPage = fm('pageSize', 'Page Size');

export const tableLocalization = {
  body: {
    emptyDataSourceMessage: (
      <Typography>
        No data to display
      </Typography>
    ),
  },
};
