import * as Yup from 'yup';
import { fm, deepTrim, emojisRegex } from 'utils/string';
import { getHourMinutesInUnixTime, getDifferenceBetweenTime } from 'utils/dateTime';
import { otherCodeSalaryType, shiftTimeTypes } from 'utils/enum';
import {
  getFormattedShiftDetailsRequestBody,
  getFormattedCreateShiftRequestBody,
  getFormattedSalaryCodeReport,
  getBookingTimeUpdateFormattedApiRequestData,
  getFormattedSalaryCodeReportForUpdate,
  getFormattedCreateShiftRequestBulkBody,
} from './selector';

const salaryCodeReportSchema = Yup.object().shape({
  employee: Yup.object().shape({
    id: Yup.string().required(fm('shift.modal.employee.required')),
  }).required(fm('shift.modal.employee.required')),
  salaryCodeId: Yup.string().required(fm('shift.modal.salaryCode.required')),
  quantity: Yup.string()
    .when(
      'salaryType',
      {
        is: otherCodeSalaryType.flexible,
        then: Yup.string().required(fm('shift.modal.quantity.required')),
      },
    ),
});

const validateShiftTimeUpdate = Yup.object().shape({
  employee: Yup.object().shape({
    id: Yup.string().required(fm('shift.modal.employee.required')),
  }).required(fm('shift.modal.employee.required')),
  breakTime: Yup.string().nullable()
    .when(
      'timeCodeType',
      {
        is: shiftTimeTypes.presence,
        then: Yup.string().required(fm('timeReportModal_breakTime_required'))
          .test(
            'breakTime',
            fm('shiftModal_total_time_must_be_greater_then_total'),
            function (breakTime) { // eslint-disable-line
              const { startTime, endTime } = this.parent;
              return getHourMinutesInUnixTime(endTime)
                > getHourMinutesInUnixTime(startTime)
                + getHourMinutesInUnixTime(breakTime);
            },
          ),
      },
    ),
  workingTime: Yup.string().nullable()
    .when(
      'timeCodeType',
      {
        is: shiftTimeTypes.absence,
        then: Yup.string().required(fm('timeReportModal.absenceTime.required')),
      },
      {
        is: shiftTimeTypes.presence,
        then: Yup.string().required(fm('timeReportModal.workingTime.required')),
      },
    ),
  startTime: Yup.string().nullable()
    .when(
      'timeCodeType',
      {
        is: shiftTimeTypes.presence,
        then: Yup.string().required(fm('timeReportModal.startTime.required')),
      },
    ),
  endTime: Yup.string().nullable()
    .when(
      'timeCodeType',
      {
        is: shiftTimeTypes.presence,
        then: Yup.string().required(fm('timeReportModal.endTime.required'))
          .test(
            'endTime',
            fm('shiftModal_end_time_must_be_greater_then_start'),
            function (endTime) { // eslint-disable-line
              const { startTime } = this.parent;
              return getHourMinutesInUnixTime(startTime) < getHourMinutesInUnixTime(endTime);
            },
          ),
      },
    ),
  shiftCodeDetails: Yup.object().shape({
    timeCodeId: Yup.number().required(fm('time_code_not_selected')),
  }).required(fm('timeReportModal.timeCode.required')),
  comment: Yup.string().nullable().test(
    'comment',
    fm('comment_has_emoji_error'),
    (comment) => !emojisRegex.test(comment),
  ),
});

const validateBookingTime = Yup.object().shape({
  bookingStartTime: Yup.string().required(fm('employee_booking_time_start_time_required')).test(
    'bookingStartTime',
    fm('employee_booking_start_time_greater'),
    function (bookingStartTime) { // eslint-disable-line
      const { bookingEndTime } = this.parent;
      return getHourMinutesInUnixTime(bookingStartTime) < getHourMinutesInUnixTime(bookingEndTime);
    },
  ),
  bookingEndTime: Yup.string().required(fm('employee_booking_time_end_time_required')),
  breakTime: Yup.string().required(fm('employee_booking_time_break_time_required')).test(
    'breakTime',
    fm('employee_booking_break_time_smaller'),
    function (breakTime) { // eslint-disable-line
      const { bookingStartTime, bookingEndTime } = this.parent;
      const time = getDifferenceBetweenTime(getDifferenceBetweenTime(bookingEndTime, bookingStartTime), breakTime);
      return breakTime === '00:00' || !time.includes('-');
    },
  ),
});

export const validateAndGetFormattedShiftCreateRequestBody = async (obj, bulk) => {
  await validateShiftTimeUpdate.validate(obj);
  return bulk
    ? deepTrim(getFormattedCreateShiftRequestBulkBody(obj))
    : deepTrim(getFormattedCreateShiftRequestBody(obj));
};

export const validateAndGetFormattedShiftUpdateRequestBody = async (obj) => {
  await validateShiftTimeUpdate.validate(obj);
  return deepTrim(getFormattedShiftDetailsRequestBody(obj));
};

export const validateAndGetFormattedEmployeeBookingTimeUpdateRequestBody = async (obj) => {
  await validateBookingTime.validate(obj);
  return getBookingTimeUpdateFormattedApiRequestData(obj);
};

export const valiDateAndgetFormattedSalaryCodeReport = async (obj) => {
  await salaryCodeReportSchema.validate(obj);
  return getFormattedSalaryCodeReport(obj);
};

export const valiDateAndgetFormattedSalaryCodeReportForUpdate = async (obj) => {
  await salaryCodeReportSchema.validate(obj);
  return getFormattedSalaryCodeReportForUpdate(obj);
};
