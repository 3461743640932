import * as Yup from 'yup';
import { fm, emojisRegex } from 'utils/string';
import { shiftTimeTypes } from 'utils/enum';
import { getHourMinutesInUnixTime } from 'utils/dateTime';
import {
  getFormattedShiftTimeBody,
  getFormattedExtraTimeBody,
  getFormattedSalaryCodeUpdateApiReq,
  getFormattedSalaryCodeCreateApiReq,
  getFormattedDayShiftApproveBody,
} from './selector';

const validatingSalaryCode = Yup.object().shape({
  employeeId: Yup.string().required(fm('salary_code_employee_required')),
  salaryDetail: Yup.object().shape({ salaryCodeId: Yup.string().required(fm('salary_code_id_required')) }),
  quantity: Yup.string().required(fm('salary_code_quantity_required')),
});

export const validateAndGetFormattedSalaryCode = async (obj, isCreate) => {
  await validatingSalaryCode.validate(obj);
  return isCreate ? getFormattedSalaryCodeCreateApiReq(obj) : getFormattedSalaryCodeUpdateApiReq(obj);
};

const validatingTimeReportRegistration = Yup.object().shape({
  addedDate: Yup.date().required(fm('employee_view_shift_time_date_required')),
  workingTime: Yup.string().required(fm('total_time_required')),
  endTime: Yup.string()
    .nullable()
    .when('timeCodeType', {
      is: shiftTimeTypes.presence,
      then: Yup.string().required(fm('employee_view_shift_time_endTime_required')),
    }),
  breakTime: Yup.string()
    .nullable()
    .when('timeCodeType', {
      is: shiftTimeTypes.presence,
      then: Yup.string()
        .required(fm('employee_view_shift_time_breakTime_required'))
        // eslint-disable-next-line func-names
        .test('breakTime', fm('employeeViewShiftTimeBreakTimeGreaterThenWorkTime'), function (breakTime) {
          const { endTime, startTime } = this.parent;
          const workingTime = getHourMinutesInUnixTime(endTime) - getHourMinutesInUnixTime(startTime);
          return workingTime < 0 || workingTime > getHourMinutesInUnixTime(breakTime);
        })
        .test('breakTime', fm('timeReportBreakTimeLessThenFixed'), function(breakTime) { // eslint-disable-line
          const { endTime, startTime } = this.parent;
          const [breakTimeConfig1, breakTimeConfig2] = this.options.context;
          const workingTimeEpoch = getHourMinutesInUnixTime(endTime) - getHourMinutesInUnixTime(startTime);
          const breakTimeEpoch = getHourMinutesInUnixTime(breakTime);
          if (breakTimeConfig1.active && workingTimeEpoch >= breakTimeConfig1.breakLimit) {
            return breakTimeEpoch >= breakTimeConfig1.breakDuration;
          }
          if (breakTimeConfig2.active && workingTimeEpoch >= breakTimeConfig2.breakLimit) {
            return breakTimeEpoch >= breakTimeConfig2.breakDuration;
          }
          return true;
        }),
    }),
  startTime: Yup.string()
    .nullable()
    .when('timeCodeType', {
      is: shiftTimeTypes.presence,
      then: Yup.string()
        .required(fm('employee_view_shift_time_startTime_required'))
        // eslint-disable-next-line func-names
        .test('startTime', fm('employee_view_startTime_greater_then_endtime'), function (startTime) {
          const { endTime } = this.parent;
          return getHourMinutesInUnixTime(startTime) < getHourMinutesInUnixTime(endTime);
        }),
    }),
  shiftCodeDetails: Yup.object().shape({
    timeCodeId: Yup.string().required(fm('employee_view_shift_time_timeCode_required')),
  }),
  comment: Yup.string().nullable().test(
    'comment',
    fm('comment_has_emoji_error'),
    (comment) => !emojisRegex.test(comment),
  ),
});

export const validateAndGetFormattedShiftTime = async (obj, companyDefaults) => {
  await validatingTimeReportRegistration.validate(obj, { context: companyDefaults });
  return getFormattedShiftTimeBody(obj);
};

export const validateAndGetFormattedExtraTimeBody = async (obj, companyDefaults) => {
  await validatingTimeReportRegistration.validate(obj, { context: companyDefaults });
  return getFormattedExtraTimeBody(obj);
};

const validatingTimeReportApproveBody = Yup.object().shape({
  endTime: Yup.string().required(fm('timeReport_endTime_required')),
  breakTime: Yup.string()
    .required(fm('timeReport_breakTime_required'))
    // eslint-disable-next-line func-names
    .test('startTime', fm('timeReportBreakTimeGreaterThenWorkTime'), function (breakTime) {
      const { endTime, startTime } = this.parent;
      const workingTimeEpoch = getHourMinutesInUnixTime(endTime) - getHourMinutesInUnixTime(startTime);
      return workingTimeEpoch < 0 || getHourMinutesInUnixTime(breakTime) < workingTimeEpoch;
    }),
  startTime: Yup.string()
    .required(fm('timeReport.startTime.required'))
    // eslint-disable-next-line func-names
    .test('startTime', fm('timeReport_startTime_greater_then_endtime'), function (startTime) {
      const { endTime } = this.parent;
      return getHourMinutesInUnixTime(endTime) > getHourMinutesInUnixTime(startTime);
    }),
});

export const validateAndGetFormattedApproveTimeBody = async (obj) => {
  await validatingTimeReportApproveBody.validate(obj);
  return getFormattedDayShiftApproveBody(obj);
};
