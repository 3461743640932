import pick from 'lodash/fp/pick';

export const getFormattedUserDataForRegister = (data = {}) => ({
  user: {
    ...pick(['email', 'password', 'name'])(data),
    password_confirm: data.passwordConfirmation,
  },
  company: {
    company_name: data.companyName,
    email: data.email,
    identification_num: data.identificationNum,
    org_type: data.orgType,
  },
});

export const getFormattedContractDetailsRequestBody = (details = {}) => ({
  contract_length: details.contractLength,
  name: details.name,
  identification_num: details.identificationNum,
  email: details.email,
  address: details.address,
  zip_code: details.zipCode,
  area: details.area,
  contact_person: details.contactPerson,
  mobile: details.mobile,
});
