import React from 'react';
import MUITablePagination from '@mui/material/TablePagination';
import PropTypes from 'prop-types';
import TablePaginationActions from './TablePaginationActions';

export default function TablePagination (props) {
  const {
    component,
    count,
    page,
    onPageChange,
    onRowsPerPageChange,
    rowsPerPageOptions,
    rowsPerPage,
  } = props;

  return (
    <MUITablePagination
      id="table-pagination-container"
      ActionsComponent={TablePaginationActions}
      rowsPerPageOptions={rowsPerPageOptions}
      component={component}
      count={count}
      rowsPerPage={rowsPerPage}
      page={page - 1}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
    />
  );
}

TablePagination.propTypes = {
  count: PropTypes.number,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  component: PropTypes.string,
  page: PropTypes.number,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  rowsPerPage: PropTypes.number,
};

TablePagination.defaultProps = {
  component: 'div',
  rowsPerPageOptions: [5, 10, 15, 20, 30],
};
