import { createSlice } from '@reduxjs/toolkit';
import {
  requestFileManagerDocuments,
  requestUploadFileManagerDocument,
  requestDeleteFileManagerDocuments,
  requestAttachProfilePicture,
} from './thunk';

const fileManager = createSlice({
  name: 'fileManager',
  initialState: {
    records: [],
    selectedDocumentIds: [],
    imageViewer: {},
  },
  reducers: {
    setFileManagerFields (state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(requestFileManagerDocuments.fulfilled, (state, action) => ({
      ...state,
      ...action.payload,
    }));
    builder.addCase(requestDeleteFileManagerDocuments.fulfilled, (state) => ({
      ...state,
      refreshAllDocuments: {},
      selectedDocumentIds: [],
    }));
    builder.addCase(requestUploadFileManagerDocument.fulfilled, (state, action) => ({
      ...state,
      refreshAllDocuments: {},
      records: action.payload.records,
    }));
    builder.addCase(requestAttachProfilePicture.fulfilled, (state) => ({
      ...state,
      showFileManagerDialog: false,
      selectedDocumentIds: [],
      refreshAllDocuments: {},
    }));
  },
});

const { reducer, actions } = fileManager;

export const { setFileManagerFields } = actions;

export default reducer;
