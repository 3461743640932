import axios from 'utils/axios';
import snakify from 'utils/snakify';
import { pick } from 'utils/lodash';
import { batch } from 'react-redux';
import {
  setCustomerKeyHistoryFields,
  setKeysFields,
  setFilters,
  setSnackbarError,
  setSnackbarSuccess,
  setCustomersFields,
} from 'appRedux/actions';
import { multiStateGet } from 'appRedux/reducers/utility';
import {
  getFormattedKeyRequestBody,
  getFormattedListPageParams,
  getFormattedKeyRecords,
  getFormattedKeyData,
} from './selector';

export const requestKeys = (name) => async (dispatch, getState) => {
  const { filters } = getState();
  try {
    const params = getFormattedListPageParams(multiStateGet(name, filters));
    const response = await axios.get('/api/v3/key_managements', { params });
    const { records, ...pagination } = getFormattedKeyRecords(response.data.data);
    batch(() => {
      dispatch(setKeysFields({ records, selectedKeySequenceNums: [] }));
      dispatch(setFilters({ ...pagination, name }));
    });
  } catch (err) {
    dispatch(setSnackbarError('snackbar.keys.list_records.error'));
  }
};

export const requestKeysHistory = (keyId, filters) => async (dispatch, getState) => {
  const { keys: { keyHistory } } = getState();
  try {
    const params = snakify(pick(['page', 'perPage'])(filters));
    const response = await axios.get(`/api/v3/key_managements/${keyId}/key_logs`, { params });
    const records = filters.page > 1
      ? [...keyHistory.records, ...response.data.data.records] : response.data.data.records;
    dispatch(setKeysFields({ keyHistory: { ...response.data.data, records } }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar.keys.history.error'));
  }
};

export const requestKey = (keySeqNum) => async (dispatch) => {
  try {
    const response = await axios.get(`/api/v3/key_managements/${keySeqNum}`);
    const key = getFormattedKeyData(response.data.data);
    dispatch(setKeysFields({ key }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar.keys.list_records.error'));
  }
};

export const requestCreateKey = (formData) => async (dispatch) => {
  try {
    const requestBody = getFormattedKeyRequestBody(formData);
    dispatch(setKeysFields({ fsip: true }));
    await axios.post('/api/v3/key_managements', requestBody);
    dispatch(setKeysFields({ showKeyDialog: false, refreshAllKeys: {}, openedKeySeqNum: null }));
    dispatch(setSnackbarSuccess('snackbar.keys.create.success'));
    dispatch(setKeysFields({ fsip: false }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar.keys.create.error'));
  }
};

export const requestUpdateKey = (formData) => async (dispatch) => {
  try {
    const requestBody = getFormattedKeyRequestBody(formData);
    dispatch(setKeysFields({ fsip: true }));
    await axios.put(`/api/v3/key_managements/${formData.sequenceNum}`, requestBody);
    dispatch(setKeysFields({ showKeyDialog: false, refreshAllKeys: {}, openedKeySeqNum: null }));
    dispatch(setSnackbarSuccess('snackbar.keys.update.success'));
    dispatch(setKeysFields({ fsip: false }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar.keys.update.error'));
  }
};

export const requestDeleteKeys = (sequenceNums) => async (dispatch) => {
  try {
    const requestBody = { data: { key_management_ids: sequenceNums } };
    await axios.delete('/api/v3/key_managements/bulk_delete', requestBody);
    dispatch(setKeysFields({ refreshAllKeys: {} }));
    dispatch(setSnackbarSuccess('snackbar.keys.delete.success'));
  } catch (err) {
    dispatch(setSnackbarError('snackbar.keys.delete_records.error'));
  }
};

export const requestGetAssignKeyToCustomer = (keySeqNum) => async (dispatch, getState) => {
  const { customers: { customer = {} } } = getState();
  try {
    const response = await axios.get(`/api/v3/key_managements/${keySeqNum}`);
    const formattedKey = getFormattedKeyData(response.data.data);
    const key = { ...formattedKey, keyId: formattedKey.id, id: null };
    const keys = customer.keys ? [...customer.keys, key] : [key];
    const nextCustomer = { ...customer, keys };
    dispatch(setCustomersFields({ customer: nextCustomer }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar.keys.assign_key_customer.error'));
  }
};

export const requestCustomerKeyHistory = (customerSeqNum, filters, companyId) => async (dispatch, getState, extra) => {
  const { customers: { keyHistory } } = getState();
  try {
    const params = snakify(pick(['page', 'perPage'])(filters));
    const headers = extra.getHeadersForSubCompany(companyId, getState);
    const response = await axios.get(`/api/v3/customers/${customerSeqNum}/key_histories`, { params, headers });
    const records = filters.page > 1
      ? [...keyHistory.records, ...response.data.data.records] : response.data.data.records;
    dispatch(setCustomerKeyHistoryFields({ ...response.data.data, records }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar.keys.customer.history.error'));
  }
};

export const requestCreateCuystomerKey = (keyNumber, callback) => async (dispatch) => {
  try {
    const requestBody = snakify({ keyNumber });
    const response = await axios.post('/api/v3/key_managements', requestBody);
    callback(response.data.data);
  } catch (err) {
    dispatch(setSnackbarError('snackbar.keys.create.error'));
  }
};
