import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Grid, Container, Typography, Button, Box } from 'components/library';
import ErrorBoundary from 'components/common/ErrorBoundary';
import color from 'utils/colors';
import BosLogo from 'images/Logo-bos.png';
import { ArrowBackIcon } from 'components/library/icons';
import { fm } from 'utils/string';
import { appRoutes } from 'utils/enum';

const Layout = (props) => {
  const { children } = props;
  const history = useHistory();

  const onClickLogin = () => {
    history.push(appRoutes.login);
  };

  return (
    <ErrorBoundary>
      <Container component="main" maxWidth="xs">
        <Grid container justifyContent="center" alignItems="center" spacing={2} className="mt-20">
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <Box p={1} border={2} borderRadius={2} borderColor={color.borderLightGray}>
                {props.icon}
              </Box>
            </Grid>
          </Grid>
          <Grid xs={12} item>
            <Typography component="h1" variant="h5" align="center">
              {props.title}
            </Typography>
            <Typography mt={1} align="center">{props.titleDescription}</Typography>
          </Grid>
          <Grid item xs={12}>
            {children}
          </Grid>
          <Grid item>
            <Button
              variant="text"
              startIcon={<ArrowBackIcon />}
              color="inherit"
              onClick={onClickLogin}
            >
              {fm('back_to_login', 'Back to login')}
            </Button>
          </Grid>
        </Grid>
        <Box
          component="footer"
          p={2}
          position="absolute"
          bottom={0}
          left={0}
          right={0}
          textAlign="center"
        >
          <img
            height={30}
            width="auto"
            src={BosLogo}
            alt="Bokning och Schema Online Logo"
          />
        </Box>
      </Container>
    </ErrorBoundary>
  );
};

export default Layout;

Layout.propTypes = {
  title: PropTypes.string,
  titleDescription: PropTypes.string,
  children: PropTypes.node,
  icon: PropTypes.shape({}),
};
