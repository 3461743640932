import { SET_NOTIFICATION_FIELDS } from '../actions';

const commonOptions = {
  insert: 'top',
  container: 'top-right',
  animationIn: ['animated', 'fadeIn'],
  animationOut: ['animated', 'fadeOut'],
  dismiss: { duration: 2000 },
  dismissable: { click: true },
};

const initialState = {
  addNotification: () => { console.log('notify not initialized'); }, // eslint-disable-line
  notify (type) {
    this.addNotification({ ...type, ...commonOptions });
  },
  notifyError (title = 'Error', message = 'Error') {
    this.notify({ title, message, type: 'danger' });
  },
  setSnackbarSuccess (title = 'Error', message = 'Error') {
    this.notify({ title, message, type: 'success' });
  },
  notifyInfo (title = 'Error', message = 'Error') {
    this.notify({ title, message, type: 'info' });
  },
  notifyWarning (title = 'Error', message = 'Error') {
    this.notify({ title, message, type: 'warning' });
  },
};

function notifications (state = initialState, action) {
  switch (action.type) {
    case SET_NOTIFICATION_FIELDS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

export default notifications;
