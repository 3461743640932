import { createAsyncThunk } from '@reduxjs/toolkit';
import { get } from 'utils/lodash';
import { setSnackbarError } from 'appRedux/actions';
import {
  getFormattedCustomerProjectFilterParams,
  getFormattedProjectResponse,
  getFormattedOrderResponse,
} from './selector';

export const requestCustomerProjects = createAsyncThunk(
  'customers/fetchProjects',
  async ({ customerId, companyId }, { extra, rejectWithValue, dispatch, getState }) => {
    const { customersNext: { projects: { filters } } } = getState();
    try {
      const headers = extra.getHeadersForSubCompany(companyId, getState);
      const params = getFormattedCustomerProjectFilterParams(filters, customerId);
      const response = await extra.axios.get('/api/v3/projects', { params, headers });
      if (!response.data.data.projects) {
        return {
          filters: { ...filters, page: 1, totalRecords: 0 },
          records: [],
        };
      }
      const result = get('data.data.projects')(response);
      const { records, ...restFilters } = getFormattedProjectResponse(result);
      return {
        records, filters: { ...filters, ...restFilters },
      };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_projects_fetch_error'));
      return rejectWithValue(err);
    }
  },
);

export const requestCustomerOrders = createAsyncThunk(
  'customers/fetchOrders',
  async ({ customerId, companyId }, { extra, rejectWithValue, dispatch, getState }) => {
    const { customersNext: { orders: { filters } } } = getState();
    try {
      const headers = extra.getHeadersForSubCompany(companyId, getState);
      const params = getFormattedCustomerProjectFilterParams(filters, customerId);
      const response = await extra.axios.get('/api/v3/orders', { params, headers });
      const result = get('data.data.orders')(response);
      const { records, ...restFilters } = getFormattedOrderResponse(result);
      return {
        records, filters: { ...filters, ...restFilters },
      };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_orders_error'));
      return rejectWithValue(err);
    }
  },
);
