import React, { Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import Snackbar from 'components/Snackbar';
import Loader from 'components/library/Loader';

import PublicRoute from 'components/PublicRoute';
import ProtectedRoute from 'components/PrivateRoute';
import { userRoles, appRoutes } from 'utils/enum';
import { setFormatMessage } from 'utils/string';

// Route Views
import Login from 'views/Login';
import Register from 'views/Register';
import ForgotPassword from 'views/ChangePassword/ForgotPassword';
import PasswordReset from 'views/ChangePassword/PasswordReset';
import NewPasswordForm from 'views/ChangePassword/NewPasswordForm';
import AppUpdates from 'views/AppUpdates';
import ErrorPage from 'views/Errors';
import Proposal from 'views/Proposal';
import UserCompanies from 'views/UserCompanies';
import EmailVerification from 'views/EmailVerification';

// Global modal
import ConfirmModal from 'components/Modal/ConfirmModal';
import InfoModal from 'components/Modal/InfoModal';
import VerifyProposalPdfSignature from 'views/Proposal/VerifySignature';
import CompanyDeactivated from 'views/Contract/CompanyDeactivated';

const MainContentArea = lazy(() => import('views/MainContentArea'));

class Routes extends React.Component {
  constructor (props) {
    super(props);
    setFormatMessage(props.intl.formatMessage);
  }

  render () {
    return (
      <Suspense fallback={<Loader />}>
        <Switch>
          <PublicRoute exact path={appRoutes.proposalVerifyPdf} component={VerifyProposalPdfSignature} />
          <PublicRoute exact path={appRoutes.proposalPublic} component={Proposal} />
          <PublicRoute exact path={appRoutes.login} component={Login} />
          <PublicRoute exact path={appRoutes.register} component={Register} />
          <PublicRoute exact path={appRoutes.forgotPassword} component={ForgotPassword} />
          <PublicRoute exact path={appRoutes.passwordReset} component={PasswordReset} />
          <PublicRoute exact path={appRoutes.changePassword} component={NewPasswordForm} />
          <PublicRoute exact path={appRoutes.emailVerification} component={EmailVerification} />
          <Route exact path={appRoutes.appUpdates} component={AppUpdates} />
          <Route path={appRoutes.userCompanies} component={UserCompanies} />
          <Route path={appRoutes.companyDeactivated} component={CompanyDeactivated} />
          <ProtectedRoute path={appRoutes.home} roles={Object.values(userRoles)} component={MainContentArea} />
          <Route component={ErrorPage} />
        </Switch>
        <ConfirmModal />
        <InfoModal />
        <Snackbar />
      </Suspense>
    );
  }
}

export default injectIntl(Routes);
