import { createAsyncThunk } from '@reduxjs/toolkit';
import { setProfileStorageFields, setSnackbarError } from 'appRedux/actions';

export const requestStorageStats = createAsyncThunk(
  'storage/getStorageStats',
  async (details, { extra, dispatch }) => {
    try {
      const response = await extra.axios.get('/api/v3/company_settings/document_limit');
      const stats = response.data.data;
      dispatch(setProfileStorageFields({ stats }));
    } catch (err) {
      dispatch(setSnackbarError('snackbar_fetch_storage_stats_error'));
    }
  },
);
