import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  setSnackbarError,
  setSnackbarSuccess,
} from 'appRedux/actions';

export const requestPushNotificationSettings = createAsyncThunk(
  'pushNotification/pushNotificationSettings',
  async (name, { extra, dispatch }) => {
    try {
      const response = await extra.axios.get('/api/v3/push_notification_settings');
      return response.data.data.setting;
    } catch (err) {
      dispatch(setSnackbarError('snackbar.pushNotification.gettingItems.error'));
    }
  },
);

export const requestUpdatePushNotificationSetting = createAsyncThunk(
  'pushNotification/updatePushNotificationSettings',
  async (data, { extra, rejectWithValue, dispatch }) => {
    try {
      const body = {
        push_notification_setting: {
          id: data.id,
          module: data.module,
          sub_module: data.subModule,
          allow: data.allow,
          company_id: data.companyId,
        },
      };
      const response = await extra.axios.put(`/api/v3/push_notification_settings/${data.id}`, body);
      dispatch(setSnackbarSuccess('snackbar_push_notification_update_successfully'));
      return response.data.data.setting;
    } catch (err) {
      dispatch(setSnackbarError('snackbar_push_notification_update_error'));
      return rejectWithValue(err);
    }
  },
);

export const requestCreatePushNotificationSetting = createAsyncThunk(
  'pushNotification/createPushNotificationSettings',
  async (data, { extra, rejectWithValue, dispatch }) => {
    try {
      const body = {
        push_notification_setting: {
          module: data.module,
          sub_module: data.subModule,
          allow: true,
        },
      };
      const response = await extra.axios.post('/api/v3/push_notification_settings', body);
      const createdSetting = response.data.data.setting;
      dispatch(requestUpdatePushNotificationSetting({ ...createdSetting, allow: data.allow }));
    } catch (err) {
      dispatch(setSnackbarError('snackbar_push_notification_create_error'));
      return rejectWithValue(err);
    }
  },
);
