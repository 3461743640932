import React from 'react';
import TablePagination from '@mui/material/TablePagination';
import TablePaginationActions from './TablePaginationActions';

export default function CustomPaginationActionsTable(props) {
  return (
    <TablePagination
      ActionsComponent={TablePaginationActions}
      {...props}
    />
  );
}
