export const getFormattedProductModuleRequestBody = (body) => ({
  product_module: {
    title: body.title,
    color_id: body.colorId,
  },
});

export const getFormattedProductModulesData = (records) => records.map((record) => ({
  ...record,
  title: record.title,
  productModuleId: record.id,
}));
