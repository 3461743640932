import { multiStateGet } from 'appRedux/reducers/utility';
import { batch } from 'react-redux';
import axios from 'utils/axios';
import { getFormattedListData } from 'appRedux/utility';
import {
  setFilters,
  setSnackbarError,
  setProfileStorageFields,
} from 'appRedux/actions';

export const requestStorageRecords = (name) => async (dispatch, getState) => {
  const { filters } = getState();
  try {
    const pageFilters = multiStateGet(name, filters);
    const params = {
      start_date: pageFilters.startDate,
      end_date: pageFilters.endDate,
      per_page: pageFilters.perPage,
      page: pageFilters.page,
    };
    const response = await axios.get('/api/v3/company/booking_attachments', { params });
    const { records, ...restPagination } = getFormattedListData(response.data.data);
    batch(() => {
      dispatch(setProfileStorageFields({ records }));
      dispatch(setFilters({ ...restPagination, name }));
    });
  } catch (err) {
    dispatch(setSnackbarError('snackbar_fetch_company_attachments_error'));
  }
};

export const requestStorageStats = () => async (dispatch) => {
  try {
    const response = await axios.get('/api/v3/company_settings/document_limit');
    const stats = response.data.data;
    dispatch(setProfileStorageFields({ stats }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar_fetch_storage_stats_error'));
  }
};
