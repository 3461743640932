/* eslint-disable camelcase */
import React from 'react';
import axios from 'utils/Api';
import axiosNext from 'utils/axios';
import FileSaver from 'file-saver';
import { userRoles } from 'utils/enum';
import camelize from 'camelize';
import { multiStateGet } from 'appRedux/reducers/utility';
import { batch } from 'react-redux';
import {
  setFilters,
  setCompanyBillingInfoFields,
  setSnackbarError,
  setApplicationFields,
  // setSnackbarSuccess,
} from 'appRedux/actions';
import { Link } from 'components/library';
import { fm } from 'utils/string';
import { FormattedMessage } from 'react-intl';
import { getGlobalAlertDismissalPeriodExpired } from 'utils/cookie';
import { detectBrowser } from 'utils/browser';
import {
  getFormattedCompanyInvoicesHistoryData,
  getFormattedInvoicesReqParams,
  getFormattedInvoiceAddressRequestBody,
} from './selector';

export const requestDownloadCompanyInvoice = (invoiceId) => async (dispatch) => {
  try {
    const { data } = await axios.get(`/api/v3/company_detail/invoices/${invoiceId}/preview`);
    fetch(`data:${data.data.pdf_file.type};base64,${data.data.pdf_file.content}`)
      .then((res) => res.blob())
      .then((blob) => FileSaver.saveAs(blob, data.data.pdf_file.name));
  } catch (err) {
    dispatch(setSnackbarError('snackbar.updateInvoiceDetails.error'));
  }
};

export const requestUpdateCompanyInvoiceAddress = (details) => async (dispatch) => {
  try {
    const reqBody = getFormattedInvoiceAddressRequestBody(details);
    const response = await axios.put('/api/v3/company_detail/invoices/update_invoice_detail', reqBody);
    const invoiceAddress = camelize(response.data.data);
    dispatch(setCompanyBillingInfoFields({ invoiceAddress, updatingInvoiceDetails: false }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar.updateInvoiceDetails.error'));
  }
};

export const requestCompanyInvoiceAddressDetails = (company_id) => async (dispatch) => {
  try {
    const params = { company_id };
    const response = await axios.get('/api/v3/company_detail/invoices/details', { params });
    const invoiceAddress = camelize(response.data.data);
    dispatch(setCompanyBillingInfoFields({ invoiceAddress }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar.requestInvoice.error'));
  }
};

export const requestGetInvoiceStatusBulk = (prevRecords) => async (dispatch) => {
  try {
    const invoiceIds = prevRecords.filter((d) => d.status !== 1).map((d) => d.id).slice(0, 3);
    for (const invoiceId of invoiceIds) { // eslint-disable-line
      const response = await axios.get(`/api/v3/company_detail/invoices/${invoiceId}/refresh`); // eslint-disable-line
      const updatedRecord = camelize(response.data.data);
      const records = prevRecords.map(
        (record) => (invoiceId === record.id ? updatedRecord : record),
      );
      dispatch(setCompanyBillingInfoFields({ invoicesHistory: { records } }));
    }
  } catch (err) {
    dispatch(setSnackbarError('snackbar.invoices.gettingInvoices.error'));
  }
};

export const requestCompanyInvoicesHistoryRecords = (name, companyId) => async (dispatch, getState) => {
  const { filters, auth } = getState();
  try {
    const params = getFormattedInvoicesReqParams(multiStateGet(name, filters));
    params.company_id = companyId;
    const response = auth.role === userRoles.superAdmin
      ? await axiosNext.get('/api/v3/super_admin/invoices/histories', { params })
      : await axiosNext.get('/api/v3/company_detail/invoices', { params });
    const { records, ...restPagination } = getFormattedCompanyInvoicesHistoryData(response.data.data);
    batch(() => {
      dispatch(requestGetInvoiceStatusBulk(records));
      dispatch(setCompanyBillingInfoFields({ invoicesHistory: { records } }));
      dispatch(setFilters({ ...restPagination, name }));
    });
  } catch (err) {
    dispatch(setSnackbarError('snackbar.invoices.gettingInvoices.error'));
  }
};

export const requestGetInvoiceStatus = (invoiceId) => async (dispatch, getState) => {
  const { auth, profile: { billing: { invoicesHistory } } } = getState();
  try {
    const response = auth.role === userRoles.superAdmin
      ? await axios.get(`/api/v3/super_admin/invoices/${invoiceId}/refresh`)
      : await axios.get(`/api/v3/company_detail/invoices/${invoiceId}/refresh`);
    const updatedRecord = camelize(response.data.data);
    const records = invoicesHistory.records.map(
      (record) => (invoiceId === record.id ? updatedRecord : record),
    );
    dispatch(setCompanyBillingInfoFields({ invoicesHistory: { records } }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar.invoices.gettingInvoices.error'));
  }
};

export const requestGetOverdueUnpaidInvoiceExist = () => async (dispatch) => {
  try {
    const response = await axiosNext.get('/api/v3/company_detail/invoices/overdue_invoice_exists');
    if (response.data.data.overdueInvoicesExist && getGlobalAlertDismissalPeriodExpired()) {
      dispatch(setApplicationFields({
        showGlobalAlert: true,
        globalAlertMessage: (
          <FormattedMessage
            id="global_alert_pay_overdue_invoice_message"
            values={{
              click_here: (
                <Link className="underline" href="/#/settings?viewInvoicesHistory=true">
                  {fm('click_here')}
                </Link>
              ),
            }}
          />
        ),
      }));
      dispatch(setCompanyBillingInfoFields({ overdueUnpaidInvoiceExist: response.data.data.overdueInvoicesExist }));
    } else {
      const { isChrome, isChromium, isEdge, isFirefox, isOpera } = detectBrowser();
      const isBrowserUnsupported = !(isChrome || isChromium || isEdge || isFirefox || isOpera);
      // if cookie expired then show the banner again after 24 hours
      if (!isBrowserUnsupported) return;
      dispatch(setApplicationFields({
        showGlobalAlert: getGlobalAlertDismissalPeriodExpired(),
        globalAlertMessage: fm('supported_browser_message'),
      }));
    }
  } catch (err) {
    dispatch(setSnackbarError('snackbar.invoices.gettingInvoices.error'));
  }
};
