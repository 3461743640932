import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { userRoleHomeRoutes } from 'utils/enum';

const PublicRoute = ({ component: Component, token, ...rest }) => (

  <Route
    {...rest}
    render={(props) => {
      if (token) {
        return <Redirect to={{ pathname: userRoleHomeRoutes[rest.role], search: props.location.search, state: { from: props.location } }} />;
      }

      return <Component {...props} />;
    }}
  />
);

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps, null)(PublicRoute);
