import { makeStyles } from 'components/library';

const useStyles = makeStyles({
  content: { marginTop: '5%' },
  lightGray: { color: '#5d5d5d' },
  freeTrial: { color: '#5d5d5d' },
  rightIcon: { margin: '4px', marginLeft: 0 },
  aboutYourselfText: { color: '#484848' },
  link: {
    textDecoration: 'none',
    color: '#7d7d7d',
    cursor: 'pointer',
    '&:hover': {
      color: '#1891DC',
    },
  },
  messageContainer: {
    backgroundColor: '#efefee',
    borderRadius: '1rem',
    height: 'calc(100% - 64px)',
    maxWidth: '676px',
    padding: '2rem',
  },
  black: { color: '#1E1E1E' },
  grey: { color: '#8a8a8a' },
});

export default useStyles;
