import { isEmpty } from 'utils/lodash';
import { getFormattedPaginationFields } from 'appRedux/selectors/utility';
import { formatDate, formatStrings } from 'utils/dateTime';

export const getFormattedProductUpdateFilterParams = (filters = {}) => ({
  page: filters.page || 1,
  per_page: filters.perPage,
  query: filters.query,
  status: filters.status === 'view_all' ? undefined : filters.status,
  severity: filters.severity === 'view_all' ? undefined : filters.severity,
  update_type: filters.updateType === 'view_all' ? undefined : filters.updateType,
  published: filters.published === 'view_all' ? undefined : filters.published,
});

export const getFormattedProductUpdateRequestBody = (body) => ({
  product_update: {
    date: body.date,
    name: body.name,
    description: body.description,
    update_type: body.updateType,
    severity: body.severity,
    status: body.status,
    published: body.published,
    product_update_modules_attributes: !isEmpty(body.modules)
      ? body.modules.map((module) => ({
        product_module_id: module.productModuleId,
        id: module.productModule ? module.id : undefined,
        product_update_id: body.id,
        _destroy: module.destroy,
      })) : undefined,
    product_update_companies_attributes: !isEmpty(body.productUpdateCompanies)
      ? body.productUpdateCompanies.map((company) => ({
        company_id: company.companyId,
        id: company.id,
        _destroy: company.destroy,
      })) : undefined,
  },
});

export const getFormattedProductUpdateData = (data) => ({
  ...data,
  modules: (data.productUpdateModules || []).map((module) => ({
    ...module,
    title: module.productModule.title,
    productModuleId: module.productModule.id,
    colorId: module.productModule.colorId,
  })),
  productUpdateCompanies: (data.productUpdateCompanies || []).map((company) => ({
    ...company,
    name: company.company.name,
    companyId: company.company.id,
  })),
});

export const getFormattedProductUpdateListData = (response) => ({
  ...getFormattedPaginationFields(response),
  records: response.records.map((record) => ({
    ...record,
    markRead: record.markRead === 1,
  })),
});

export const getFormattedProductUpdateCompaniesListData = (response) => ({
  ...getFormattedPaginationFields(response),
  records: response.records.map((record) => ({
    ...record,
    dateTime: formatDate(record.createdAt, formatStrings.dashDateDashTime),
  })),
});
