import { get, orderBy } from 'utils/lodash';
import camelize from 'camelize';
import { userPermissionTypeFieldNames, bookkeepingIntegrationTypes } from 'utils/enum';
import { getFormattedPaginationFields } from './utility';

export const getCompanyFields = (company) => ({
  ...company,
  breakTimeSettings: orderBy('breakLimit', 'desc')(company.breakTimeSettings),
  vismaIntegrated: get('integration.integrationType')(company) === bookkeepingIntegrationTypes.visma,
  fortnoxIntegrated: get('integration.integrationType')(company) === bookkeepingIntegrationTypes.fortnox,
  extendableLimit: company.extendableLimit?.toString(),
});

export const getFormattedFeedbackRequestBody = (details = {}) => ({
  review_detail: {
    comment: details.comment,
    rating: details.rating,
  },
});

const getFormattedEmployeePermission = (permissions) => permissions.reduce((res, permssion) => {
  res[userPermissionTypeFieldNames[permssion.permissionNumber]] = permssion.permissionStatus !== false;
  return res;
}, {});

export const getFormattedProfileUser = (user) => ({
  ...camelize(user),
  permissions: user.userPermissions ? getFormattedEmployeePermission(user.userPermissions) : {},
});

export const getFormattedProfile = ({ user = {}, company = {}, ...others }) => ({
  ...others,
  user: user ? getFormattedProfileUser(user) : {},
  company: company ? getCompanyFields(company) : {},
});

export const formatProfileDataForApiRequest = ({ user = {} }) => ({
  user_detail: {
    name: user.name,
    address: user.address,
    zip_code: user.zipCode,
    area: user.area,
    mobile: user.mobile,
    email: user.email,
    identification_num: user.identificationNum,
    bank_name: user.bankName,
    account_number: user.accountNumber,
    clearing_number: user.clearingNumber,
  },
});

export const formatCompanyDataForApiRequest = ({ company = {} }) => ({
  company: {
    name: company.name,
    identification_num: company.identificationNum,
    email: company.email,
    address: company.address,
    zip_code: company.zipCode,
    area: company.area,
    mobile: company.mobile,
    phone: company.phone,
    contact_person: company.contactPerson,
    org_type: company.orgType,
  },
});

export const getFormattedFortnoxAccountsData = (response = {}, profile) => {
  const defaultAccount = get('company.defaultAccount')(profile);
  return {
    ...getFormattedPaginationFields(response),
    records: (response.records || []).map((r) => ({ ...camelize(r), isDefault: defaultAccount === r.number })),
  };
};

export const getFormattedUpdateBreakTimeApiRequest = (data) => ({
  break_time_setting: {
    active: data.active,
    break_limit: data.breakLimit,
    break_duration: data.breakDuration,
  },
});

export const getFormattedUpdateInvoiceRowDateApiRequest = (data) => ({
  integration: {
    invoice_row_date: data,
  },
});
