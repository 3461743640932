import { createSlice } from '@reduxjs/toolkit';
import {
  requestOrdersSummary,
  requestProposalsSummary,
  requestInternalTimeSummary,
  requestScheduledHours,
  getWeatherData,
} from './thunk';

const initialState = {
  ordersSummary: {},
  proposalsSummary: {},
  internalTimeSummary: {},
  weatherDetails: {},
  scheduledHours: null,
  loading: false,
  loadingCount: 0,
  showChatPopover: false,
};

const dashboard = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setDashboardFields (state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestOrdersSummary.pending, (state) => {
        state.loadingCount += 1;
      })
      .addCase(requestOrdersSummary.fulfilled, (state, action) => {
        state.ordersSummary = action.payload;
        state.loadingCount -= 1;
        state.loading = state.loadingCount > 0;
      })
      .addCase(requestOrdersSummary.rejected, (state) => {
        state.loadingCount -= 1;
        state.loading = state.loadingCount > 0;
      })
      .addCase(requestProposalsSummary.pending, (state) => {
        state.loadingCount += 1;
      })
      .addCase(requestProposalsSummary.fulfilled, (state, action) => {
        state.proposalsSummary = action.payload;
        state.loadingCount -= 1;
        state.loading = state.loadingCount > 0;
      })
      .addCase(requestProposalsSummary.rejected, (state) => {
        state.loadingCount -= 1;
        state.loading = state.loadingCount > 0;
      })
      .addCase(requestInternalTimeSummary.pending, (state) => {
        state.loadingCount += 1;
      })
      .addCase(requestInternalTimeSummary.fulfilled, (state, action) => {
        state.internalTimeSummary = action.payload;
        state.loadingCount -= 1;
        state.loading = state.loadingCount > 0;
      })
      .addCase(requestInternalTimeSummary.rejected, (state) => {
        state.loadingCount -= 1;
        state.loading = state.loadingCount > 0;
      })
      .addCase(requestScheduledHours.pending, (state) => {
        state.loadingCount += 1;
      })
      .addCase(requestScheduledHours.fulfilled, (state, action) => {
        state.scheduledHours = action.payload.scheduledHours;
        state.loadingCount -= 1;
        state.loading = state.loadingCount > 0;
      })
      .addCase(requestScheduledHours.rejected, (state) => {
        state.loadingCount -= 1;
        state.loading = state.loadingCount > 0;
      })
      .addCase(getWeatherData.fulfilled, (state, action) => ({
        ...state,
        weatherDetails: {
          ...state.weatherDetails,
          ...action.payload,
        },
      }));
  },
});

const { reducer, actions } = dashboard;

export const { setDashboardFields } = actions;

export default reducer;
