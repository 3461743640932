import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  requestAreas,
  requestDeleteArea,
  requestCreateArea,
  requestUpdateArea,
  requestArea,
} from './thunk';

const area = createSlice({
  name: 'area',
  initialState: {
    records: [],
    record: {},
    refreshAllAreas: {},
    showAreaDialog: false,
    selectedAreaSequenceNum: null,
    openedAreaSequenceNum: null,
  },
  reducers: {
    setAreaFields (state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(requestArea.fulfilled, (state, action) => ({
      ...state,
      record: action.payload.record,
    }));
    builder.addCase(requestAreas.fulfilled, (state, action) => ({
      ...state,
      records: action.payload.records,
    }));
    builder.addCase(requestDeleteArea.fulfilled, (state) => ({
      ...state,
      refreshAllAreas: {},
    }));
    builder.addMatcher(
      isAnyOf(requestCreateArea.fulfilled, requestUpdateArea.fulfilled),
      (state) => ({
        ...state,
        openedAreaSequenceNum: null,
        showAreaDialog: false,
        record: {},
        refreshAllAreas: {},
        fsip: false,
      }),
    );
  },
});

const { reducer, actions } = area;

export const { setAreaFields } = actions;

export default reducer;
