import camelize from 'camelize';
import { weekDays } from 'utils/enum';
import { flow, map, sortBy } from 'utils/lodash';
import { getUnixTimeInHoursMinutes } from 'utils/dateTime';
import { getFormattedPaginationFields } from '../../selectors/utility';

export const getFormattedWeeklyWorkPlansQueryParam = (filters) => ({
  page: filters.page,
  per_page: filters.perPage,
  query: filters.query,
});

export const getFormattedWeeklyWorkPlan = (plan) => {
  let planedTimeInUnix = 0;

  const formattedPlan = weekDays.reduce((camelizedPlan, day) => {
    planedTimeInUnix += camelizedPlan[`${day.value}Time`] || 0;
    camelizedPlan[`${day.value}Time`] = getUnixTimeInHoursMinutes(camelizedPlan[`${day.value}Time`]);
    return camelizedPlan;
  }, camelize(plan));

  return {
    ...formattedPlan,
    planedHours: getUnixTimeInHoursMinutes(planedTimeInUnix),
    weeklyTime: getUnixTimeInHoursMinutes(formattedPlan.weeklyTime),
  };
};

export const getFormattedWeeklyWorkPlansListData = (response = {}) => ({
  ...getFormattedPaginationFields(response),
  records: flow(map(getFormattedWeeklyWorkPlan), sortBy('sequenceNum'))(response.records),
});

export const planFormattedData = (values) => ({
  name: values.name,
  weekly_time: values.weeklyTimeSeconds,
  monday: values.monday,
  monday_time: values.mondaytimeSeconds,
  tuesday: values.tuesday,
  tuesday_time: values.tuesdaytimeSeconds,
  wednesday: values.wednesday,
  wednesday_time: values.wednesdaytimeSeconds,
  thursday: values.thursday,
  thursday_time: values.thursdaytimeSeconds,
  friday: values.friday,
  friday_time: values.fridaytimeSeconds,
  saturday: values.saturday,
  saturday_time: values.saturdaytimeSeconds,
  sunday: values.sunday,
  sunday_time: values.sundaytimeSeconds,
});

export const formattedPlans = (plan) => ({
  ...plan,
  weeklyTime: getUnixTimeInHoursMinutes(plan.weeklyTime),
  mondayTime: getUnixTimeInHoursMinutes(plan.mondayTime),
  tuesdayTime: getUnixTimeInHoursMinutes(plan.tuesdayTime),
  wednesdayTime: getUnixTimeInHoursMinutes(plan.wednesdayTime),
  thursdayTime: getUnixTimeInHoursMinutes(plan.thursdayTime),
  fridayTime: getUnixTimeInHoursMinutes(plan.fridayTime),
  saturdayTime: getUnixTimeInHoursMinutes(plan.saturdayTime),
  sundayTime: getUnixTimeInHoursMinutes(plan.sundayTime),
});
