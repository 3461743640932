import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import TablePagination from 'components/library/TablePagination';
import {
  rowsPerPageOptions,
  getLabelDisplayedRows,
  labelRowsPerPage,
} from 'components/library/Table/tableUtils';

export default function SubtablePagination (props) {
  const { row } = props;

  const pagination = props.getSubtablePagination(row);

  const handleChangePage = useCallback((event, newPage) => {
    props.onChangeSubtablePagination(row, { ...pagination, page: newPage + 1 });
  }, [pagination]);

  const handleChangeRowsPerPage = useCallback((event) => {
    const { value } = event.target;
    props.onChangeSubtablePagination(row, { ...pagination, perPage: Number(value), page: 1 });
  }, [pagination]);

  const getLabelDisplayedRowsMsg = useCallback((b) => getLabelDisplayedRows(b, pagination), [pagination]);

  return (
    <TablePagination
      rowsPerPageOptions={rowsPerPageOptions}
      component="div"
      colSpan={props.subtableColumns.length}
      count={pagination.totalRecords}
      rowsPerPage={pagination.perPage}
      page={pagination.page - 1}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      labelDisplayedRows={getLabelDisplayedRowsMsg}
      labelRowsPerPage={labelRowsPerPage}
    />
  );
}

SubtablePagination.propTypes = {
  row: PropTypes.shape({}),
  subtableColumns: PropTypes.shape([]),
  getSubtablePagination: PropTypes.func,
  onChangeSubtablePagination: PropTypes.func,
};
