import { createAsyncThunk } from '@reduxjs/toolkit';
import { keyBy, get } from 'utils/lodash';
import { setSnackbarSuccess, setSnackbarError, setFilters, setProfileStorageFields } from 'appRedux/actions';
import { getFormattedListData } from 'appRedux/utility';
import FileSaver from 'file-saver';
import {
  getFormattedProposalRequestBody,
  getFormattedProposalData,
  getFormattedFilterParams,
  getFormattedProposalActivityResponse,
  getFormattedSendTestEmailRequestBody,
  getFormattedProposalTemplateRequestBody,
  getFormattedProposalTemplateData,
  getFormattedCopyProposalData,
  getFormattedCopyProposalDocuments,
} from './selector';
import { proposalStatusTypes } from 'components/Proposal/utility';
import { setProposalsFields } from '.';

export const requestProposalMessagesForAdmin = createAsyncThunk(
  'proposal/fetchmessages',
  async (proposalId, { extra, dispatch }) => {
    const params = {
      proposal_id: proposalId,
    };
    try {
      const response = await extra.axios.get('/api/v3/proposal_messages', { params });
      return response.data.data;
    } catch (err) {
      dispatch(setSnackbarError('snackbar_proposal_get_message_error'));
    }
  },
);

export const requestPostProposalMessagesForAdmin = createAsyncThunk(
  'proposal/postmessage',
  async ({ proposalId, message }, { extra, dispatch }) => {
    const data = {
      proposal_message: {
        proposal_id: proposalId,
        content: message,
      },
    };
    try {
      const response = await extra.axios.post('/api/v3/proposal_messages', data);
      return response.data.data;
    } catch (err) {
      dispatch(setSnackbarError('snackbar_proposal_post_message_error'));
    }
  },
);

export const requestMarkReadProposalMessageForAdmin = createAsyncThunk(
  'proposal/markreadmessage',
  async (messageId, { extra, dispatch }) => {
    try {
      const response = await extra.axios.patch(`/api/v3/proposal_messages/${messageId}/mark_read`);
      return response.data.data;
    } catch (err) {
      dispatch(setSnackbarError('snackbar_proposal_mark_read_message_error'));
    }
  },
);

export const requestDeleteProposalMessageForAdmin = createAsyncThunk(
  'proposal/deletemessages',
  async (messageId, { extra, dispatch }) => {
    try {
      const response = await extra.axios.delete(`/api/v3/proposal_messages/${messageId}`);
      return response.data.data;
    } catch (err) {
      dispatch(setSnackbarError('snackbar_proposal_delete_message_error'));
    }
  },
);

export const requestProposal = createAsyncThunk(
  'proposal/fetchProposal',
  async (id, { extra, dispatch }) => {
    try {
      const response = await extra.axios.get(`/api/v3/proposals/${id}`);
      return {
        proposal: getFormattedProposalData(response.data.data.proposal),
        signature: response.data.data.signature || [],
        proposalDocuments: { records: response.data.data.documents },
      };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_proposal_get_error'));
    }
  },
);

export const requestStorageStats = createAsyncThunk(
  'storage/getStorageStats',
  async (details, { extra, dispatch }) => {
    try {
      const response = await extra.axios.get('/api/v3/company_settings/document_limit');
      const stats = response.data.data;
      dispatch(setProfileStorageFields({ stats }));
    } catch (err) {
      dispatch(setSnackbarError('snackbar_fetch_storage_stats_error'));
    }
  },
);

export const requestCreateProposal = createAsyncThunk(
  'proposal/createProposal',
  async (data, { extra, rejectWithValue, dispatch }) => {
    try {
      const body = getFormattedProposalRequestBody(data);
      const formData = new FormData();
      formData.append('proposal', JSON.stringify({ ...body }));
      if (data.filesToUpload) {
        for (const file of data.filesToUpload) { // eslint-disable-line
          formData.append('documents[]', file);
        }
      }
      if (data.documentIds) {
        formData.append('document_ids', JSON.stringify(data.documentIds));
      } else {
        formData.append('document_ids', JSON.stringify([]));
      }
      const response = await extra.axios.post('/api/v3/proposals', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      dispatch(setSnackbarSuccess('snackbar_proposal_created'));
      return response.data.data;
    } catch (err) {
      if (get('response.data.errors[0].details')(err)) {
        dispatch(setSnackbarError(get('response.data.errors[0].details')(err)));
      } else {
        dispatch(setSnackbarError('snackbar_proposal_update_error'));
      }
      return rejectWithValue(err);
    }
  },
);

export const requestUpdateProposal = createAsyncThunk(
  'proposal/updateProposal',
  async (data, { extra, rejectWithValue, dispatch }) => {
    try {
      const body = getFormattedProposalRequestBody(data);
      const formData = new FormData();
      formData.append('proposal', JSON.stringify({ ...body }));
      formData.append('send_email_on_update', data.sendEmailOnUpdate);
      if (data.filesToUpload) {
        for (const file of data.filesToUpload) { // eslint-disable-line
          if (!file.id) {
            formData.append('documents[]', file);
          }
        }
      }
      if (data.documentIds) {
        formData.append('document_ids', JSON.stringify(data.documentIds));
      } else {
        formData.append('document_ids', JSON.stringify([]));
      }
      const response = await extra.axios.put(`/api/v3/proposals/${data.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      dispatch(setSnackbarSuccess('snackbar_proposal_updated'));
      return response.data.data;
    } catch (err) {
      if (get('response.data.errors[0].details')(err)) {
        dispatch(setSnackbarError(get('response.data.errors[0].details')(err)));
      } else {
        dispatch(setSnackbarError('snackbar_proposal_update_error'));
      }
      return rejectWithValue(err);
    }
  },
);

export const requestProposals = createAsyncThunk(
  'proposal/fetchProposals',
  async (name, { extra, dispatch, getState }) => {
    try {
      const { filters } = extra.getState(name, getState);
      const params = getFormattedFilterParams(filters);
      const response = await extra.axios.get('/api/v3/proposals/', { params });
      const { records, ...nextFilters } = getFormattedListData(response.data.data.proposals);
      dispatch(setFilters({ ...nextFilters, name }));
      return { records };
    } catch (err) {
      dispatch(setSnackbarError('snackbar.proposal.error'));
    }
  },
);

export const requestDeleteProposals = createAsyncThunk(
  'proposal/deleteProposal',
  async (ids, { extra, dispatch }) => {
    try {
      const params = { proposals: ids };
      await extra.axios.delete('/api/v3/proposals/bulk_delete', { data: params });
      dispatch(setSnackbarSuccess('proposals_deleted_successfully'));
    } catch (err) {
      dispatch(setSnackbarError('snackbar_proposal_delete_error'));
    }
  },
);

export const requestDeleteProposalDocument = createAsyncThunk(
  'proposal/deleteDocument',
  async (ids, { extra, dispatch, getState }) => {
    try {
      const { proposals: { proposalDocuments: { records } } } = getState();
      const params = { document_id: ids.documentId };
      await extra.axios.delete(`/api/v3/proposals/${ids.proposalId}/delete_document`, { params });
      dispatch(setSnackbarSuccess('document_deleted_successfully'));
      return { records: records.filter((record) => record.id !== ids.documentId) };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_document_delete_error'));
    }
  },
);

export const requestCreateProposalPolicies = createAsyncThunk(
  'proposal/createProposalPolicies',
  async (data, { extra, rejectWithValue, dispatch }) => {
    try {
      const body = {
        proposal_policy: data,
      };
      const response = await extra.axios.post('/api/v3/proposal_policies', body);
      dispatch(setSnackbarSuccess('snackbar_proposal_policy_created'));
      return response.data.data;
    } catch (err) {
      dispatch(setSnackbarError('snackbar_proposal_policy_create_error'));
      return rejectWithValue(err);
    }
  },
);

export const requestProposalPolicies = createAsyncThunk(
  'proposal/fetchProposalPolicy',
  async (_, { extra, dispatch }) => {
    try {
      const response = await extra.axios.get('/api/v3/proposal_policies');
      const records = keyBy((r) => r.rule)(response.data.data);
      return { records };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_proposal_policy_get_error'));
    }
  },
);

export const requestUpdateProposalPolicies = createAsyncThunk(
  'proposal/updateProposalPolicy',
  async (data, { extra, rejectWithValue, dispatch }) => {
    try {
      const body = {
        proposal_policy: data,
      };
      const response = await extra.axios.put(`/api/v3/proposal_policies/${data.id}`, body);
      dispatch(setSnackbarSuccess('snackbar_proposal_policy_updated'));
      return response.data.data;
    } catch (err) {
      dispatch(setSnackbarError('snackbar_proposal_policy_update_error'));
      return rejectWithValue(err);
    }
  },
);

export const requestProposalActivity = createAsyncThunk(
  'proposal/fetchProposalActivity',
  async (data, { extra, dispatch, getState }) => {
    const { proposals: { proposalActivity: { records: prevRecords = [] } } } = getState();
    try {
      const { filters } = extra.getState(data.name, getState);
      const params = getFormattedFilterParams(filters);
      const response = await extra.axios.get(`/api/v3/proposals/${data.id}/proposal_activity`, { params });
      const { records, ...nextFilters } = getFormattedProposalActivityResponse(response.data.data.activities);
      dispatch(setFilters({ ...nextFilters, name: data.name }));
      return { records: nextFilters.page > 1 ? [...prevRecords, ...records] : records };
    } catch (err) {
      dispatch(setSnackbarError('snackbar.proposal.error'));
    }
  },
);

export const requestCreateProposalTag = createAsyncThunk(
  'proposal/createProposaltag',
  async (data, { extra, dispatch, getState }) => {
    try {
      const { newTag, oldTags = [] } = data;
      const { proposals } = getState();
      // check if the similar titled tag is already present
      const params = { query: newTag.title };
      const response = await extra.axios.get('/api/v3/tags/search', { params });
      const presentTags = Array.isArray(response.data.data) ? response.data.data : [];
      const presentData = presentTags.find((tag) => tag.title.toLowerCase() === newTag.title.toLowerCase());
      // if tag with title not present create new
      if (!presentData) {
        const reqBody = { tag: newTag };
        const respose = await extra.axios.post('/api/v3/tags', reqBody);
        data = { tagId: respose.data.data.id, title: respose.data.data.title };
        const proposalTags = oldTags.map((tag) => (tag.title === newTag.title ? data : tag));
        return { proposal: { ...proposals.proposal, proposalTags } };
      }
      const tagData = { tagId: presentData.id, title: presentData.title };
      const proposalTags = oldTags.map((tag) => (tag.title === newTag.title ? tagData : tag));
      const proposal = { ...proposals.proposal, proposalTags };
      return { proposal };
    } catch (err) {
      dispatch(setSnackbarError('snackbar.tags.searchTags.error'));
    }
  },
);

export const requestSendTestEmail = createAsyncThunk(
  'proposal/sendTestEmail',
  async (data, { extra, rejectWithValue, dispatch }) => {
    try {
      const body = getFormattedSendTestEmailRequestBody(data);
      const response = await extra.axios.post(`/api/v3/proposals/${data.proposalId}/send_test_email`, body);
      dispatch(setSnackbarSuccess('snackbar.email.sent'));
      return response.data.data;
    } catch (err) {
      dispatch(setSnackbarSuccess('snackbar.email.error'));
      return rejectWithValue(err);
    }
  },
);

export const requestCreateProposalTemplateTag = createAsyncThunk(
  'proposal/createProposalTemplateTag',
  async (data, { extra, dispatch, getState }) => {
    try {
      const { newTag, oldTags = [] } = data;
      const { proposals: { proposalTemplates } } = getState();
      // check if the similar titled tag is already present
      const params = { query: newTag.title };
      const response = await extra.axios.get('/api/v3/tags/search', { params });
      const presentTags = Array.isArray(response.data.data) ? response.data.data : [];
      const presentData = presentTags.find((tag) => tag.title.toLowerCase() === newTag.title.toLowerCase());
      // if tag with title not present create new
      if (!presentData) {
        const reqBody = { tag: newTag };
        const respose = await extra.axios.post('/api/v3/tags', reqBody);
        data = { tagId: respose.data.data.id, title: respose.data.data.title };
        const proposalTags = oldTags.map((tag) => (tag.title === newTag.title ? data : tag));
        return { proposalTemplate: { ...proposalTemplates.proposalTemplate, proposalTags } };
      }
      const tagData = { tagId: presentData.id, title: presentData.title };
      const proposalTags = oldTags.map((tag) => (tag.title === newTag.title ? tagData : tag));
      const proposalTemplate = { ...proposalTemplates.proposalTemplate, proposalTags };
      return { proposalTemplate };
    } catch (err) {
      dispatch(setSnackbarError('snackbar.tags.searchTags.error'));
    }
  },
);

export const requestCreateProposalTemplate = createAsyncThunk(
  'proposal/createProposalTemplate',
  async (data, { extra, rejectWithValue, dispatch }) => {
    try {
      const body = getFormattedProposalTemplateRequestBody(data);
      const formData = new FormData();
      formData.append('proposal_template', JSON.stringify({ ...body }));
      if (data.filesToUpload) {
        for (const file of data.filesToUpload) { // eslint-disable-line
          formData.append('documents[]', file);
        }
      }
      const response = await extra.axios.post('/api/v3/proposal_templates', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      dispatch(setSnackbarSuccess('snackbar_proposal_template_created'));
      return response.data.data;
    } catch (err) {
      if (get('response.data.errors[0].details')(err)) {
        dispatch(setSnackbarError(get('response.data.errors[0].details')(err)));
      } else {
        dispatch(setSnackbarError('snackbar_proposal_template_create_error'));
      }
      return rejectWithValue({});
    }
  },
);

export const requestUpdateProposalTemplate = createAsyncThunk(
  'proposal/updateProposalTemplate',
  async (data, { extra, rejectWithValue, dispatch }) => {
    try {
      const body = getFormattedProposalTemplateRequestBody(data);
      const formData = new FormData();
      formData.append('proposal_template', JSON.stringify({ ...body }));
      if (data.filesToUpload) {
        for (const file of data.filesToUpload) { // eslint-disable-line
          if (!file.id) {
            formData.append('documents[]', file);
          }
        }
      }
      const response = await extra.axios.put(`/api/v3/proposal_templates/${data.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      dispatch(setSnackbarSuccess('snackbar_proposal_template_updated'));
      return response.data.data;
    } catch (err) {
      if (get('response.data.errors[0].details')(err)) {
        dispatch(setSnackbarError(get('response.data.errors[0].details')(err)));
      } else {
        dispatch(setSnackbarError('snackbar_proposal_template_update_error'));
      }
      return rejectWithValue(err);
    }
  },
);

export const requestProposalTemplates = createAsyncThunk(
  'proposal/fetchProposalTemplates',
  async (name, { extra, dispatch, getState }) => {
    try {
      const { filters } = extra.getState(name, getState);
      const params = getFormattedFilterParams(filters);
      const response = await extra.axios.get('/api/v3/proposal_templates', { params });
      const { records, ...nextFilters } = getFormattedListData(response.data.data.proposalTemplate);
      dispatch(setFilters({ ...nextFilters, name }));
      return { records };
    } catch (err) {
      dispatch(setSnackbarError('snackbar.proposal_template.error'));
    }
  },
);

export const requestProposalTemplate = createAsyncThunk(
  'proposal/fetchProposalTemplate',
  async (id, { extra, dispatch }) => {
    try {
      const response = await extra.axios.get(`/api/v3/proposal_templates/${id}`);
      const { proposalTemplate, document } = response.data.data;
      return {
        proposalTemplate: getFormattedProposalTemplateData(proposalTemplate),
        proposalTemplateDocuments: { records: document },
      };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_proposal_template_get_error'));
    }
  },
);

export const requestDeleteProposalTemplates = createAsyncThunk(
  'proposal/deleteProposalTemplates',
  async (ids, { extra, dispatch }) => {
    try {
      const params = { proposal_template_ids: ids };
      await extra.axios.delete('/api/v3/proposal_templates/bulk_delete', { data: params });
      dispatch(setSnackbarSuccess('proposal_templates_deleted_successfully'));
    } catch (err) {
      dispatch(setSnackbarError('snackbar_proposal_template_delete_error'));
    }
  },
);

export const requestProposalTemplateForCreateProposal = createAsyncThunk(
  'proposal/fetchProposalTemplateForProposal',
  async (id, { extra, dispatch }) => {
    try {
      const response = await extra.axios.get(`/api/v3/proposal_templates/${id}`);
      const { proposalTemplate, document } = response.data.data;
      return {
        proposalTemplate: getFormattedProposalTemplateData(proposalTemplate),
        proposalTemplateDocuments: { records: document || [] },
      };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_proposal_template_get_error'));
    }
  },
);

export const requestDeleteProposalTemplateDocument = createAsyncThunk(
  'proposal/deleteTemplateDocument',
  async (ids, { extra, dispatch, getState }) => {
    try {
      const { proposals: { proposalTemplates: { proposalTemplateDocuments: { records } } } } = getState();
      const params = { document_id: ids.documentId };
      await extra.axios.delete(`/api/v3/proposal_templates/${ids.proposalTemplateId}/delete_document`, { params });
      dispatch(setSnackbarSuccess('document_deleted_successfully'));
      return { records: records.filter((record) => record.id !== ids.documentId) };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_document_delete_error'));
    }
  },
);

export const requestProposalToCopy = createAsyncThunk(
  'proposal/fetchProposalToCopy',
  async (id, { extra, dispatch }) => {
    try {
      const response = await extra.axios.get(`/api/v3/proposals/${id}`);
      return {
        proposal: getFormattedCopyProposalData(response.data.data.proposal),
        proposalDocuments: { records: getFormattedCopyProposalDocuments(response.data.data.documents || []) },
      };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_proposal_get_error'));
    }
  },
);

export const requestDownloadProposalDocuments = createAsyncThunk(
  'proposal/downloadDocuments',
  async (attachment, { extra, dispatch }) => {
    try {
      const response = await extra.axios.get(`/api/v3/proposal_documents/${attachment.id}`, {
        responseType: 'blob',
      });
      const contentType = response.headers['content-type'];
      const fileType = contentType || 'application/octet-stream';
      const blob = new Blob([response.data], { type: fileType });
      FileSaver.saveAs(blob, attachment.filename);
    } catch (err) {
      dispatch(setSnackbarError('snackbar.proposal.error'));
    }
  },
);

export const requestUpdateProposalObjectCreated = createAsyncThunk(
  'proposal/postProposalObjectCreated',
  async (data, { dispatch, getState }) => {
    try {
      const { proposals: { proposal } } = getState();
      dispatch(requestUpdateProposal({ ...proposal, status: proposalStatusTypes.approved }));
      dispatch(setProposalsFields({ showProposalDialog: false, refreshAllProposals: {} }));
    } catch (err) {
      dispatch(setSnackbarError('snackbar_proposal_update_error'));
    }
  },
);

export const requestProposalPdf = createAsyncThunk(
  'proposal/downloadProposalPdf',
  async (id, { extra, dispatch }) => {
    try {
      const response = await extra.axios.get(`/api/v3/proposals/${id}/proposal_pdf`);
      fetch(`data:application/pdf;base64,${response.data.data.pdfFile.content}`)
        .then((res) => res.blob())
        .then((blob) => FileSaver.saveAs(blob, response.data.data.pdfFile.name));
    } catch (err) {
      dispatch(setSnackbarError('snackbar_proposal_get_error'));
    }
  },
);
