import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  requestEbokaIntegrationStatus,
  requestCreateEbokaIntegration,
  requestDeleteEbokaIntegration,
} from './thunk';

const ebokaIntegration = createSlice({
  name: 'eboka/integration',
  initialState: {
    integrated: undefined,
    serviceProvider: {},
  },
  reducers: {
    setEbokaIntegrationFields (state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(requestDeleteEbokaIntegration.fulfilled, (state, action) => ({
      ...state,
      integrated: action.payload.status,
    }));
    builder.addMatcher(
      isAnyOf(requestEbokaIntegrationStatus.fulfilled, requestCreateEbokaIntegration.fulfilled),
      (state, action) => ({
        ...state,
        integrated: action.payload.status,
        serviceProvider: action.payload.serviceProvider,
      }),
    );
    builder.addMatcher(
      isAnyOf(requestEbokaIntegrationStatus.rejected, requestCreateEbokaIntegration.rejected),
      (state) => ({ ...state, integrated: false }),
    );
  },
});

const { reducer, actions } = ebokaIntegration;

export const { setEbokaIntegrationFields } = actions;

export default reducer;
