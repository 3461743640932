import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';

export const isTouchEnabled = ('ontouchstart' in window)
  || (navigator.maxTouchPoints > 0)
  || (navigator.msMaxTouchPoints > 0);

export const dndBackend = isTouchEnabled ? TouchBackend : HTML5Backend;

const hasNative = document && (document.elementsFromPoint || document.msElementsFromPoint);

function getDropTargetElementsAtPoint(x, y, dropTargets) {
  return dropTargets.filter((t) => {
    const rect = t.getBoundingClientRect();
    return (
      x >= rect.left && x <= rect.right && y <= rect.bottom && y >= rect.top
    );
  });
}

export const touchBackendOptions = isTouchEnabled ? {
  touchSlop: 8,
  delayTouchStart: 200,
  getDropTargetElementsAtPoint: !hasNative && getDropTargetElementsAtPoint,
} : undefined;
