import React from 'react';

const Internal = () => (
  <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="23" cy="23" r="23" fill="#A887E7" />
    <path fillRule="evenodd" clipRule="evenodd" d="M26 29C26 28.4477 26.4477 28 27 28H33C33.5523 28 34 28.4477 34 29C34 29.5523 33.5523 30 33 30H27C26.4477 30 26 29.5523 26 29Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M28 13C28 12.4477 27.5523 12 27 12C26.4477 12 26 12.4477 26 13V14H20V13C20 12.4477 19.5523 12 19 12C18.4477 12 18 12.4477 18 13V14.0016C17.5245 14.0049 17.109 14.0147 16.7482 14.0442C16.1861 14.0901 15.6694 14.1887 15.184 14.436C14.4314 14.8195 13.8195 15.4314 13.436 16.184C13.1887 16.6694 13.0901 17.1861 13.0442 17.7482C13 18.2894 13 18.9537 13 19.7587V28.2413C13 29.0463 13 29.7106 13.0442 30.2518C13.0901 30.8139 13.1887 31.3306 13.436 31.816C13.8195 32.5686 14.4314 33.1805 15.184 33.564C15.6694 33.8113 16.1861 33.9099 16.7482 33.9558C17.2894 34 17.9537 34 18.7587 34H27.2413C28.0463 34 28.7106 34 29.2518 33.9558C29.8139 33.9099 30.3306 33.8113 30.816 33.564C31.0906 33.4241 31.3464 33.2538 31.5793 33.0573C31.8154 32.8581 31.9334 32.7585 31.9598 32.5687C31.9792 32.4283 31.9042 32.2229 31.7988 32.1282C31.6562 32 31.4636 32 31.0785 32H27C25.3431 32 24 30.6569 24 29C24 27.3431 25.3431 26 27 26H32.2C32.48 26 32.62 26 32.727 25.9455C32.8211 25.8976 32.8976 25.8211 32.9455 25.727C33 25.62 33 25.48 33 25.2V19.7587C33 18.9537 33 18.2894 32.9558 17.7482C32.9099 17.1861 32.8113 16.6694 32.564 16.184C32.1805 15.4314 31.5686 14.8195 30.816 14.436C30.3306 14.1887 29.8139 14.0901 29.2518 14.0442C28.891 14.0147 28.4755 14.0049 28 14.0016V13ZM18 17V16.0018C17.5545 16.0049 17.2046 16.0136 16.911 16.0376C16.4726 16.0734 16.2484 16.1383 16.092 16.218C15.7157 16.4097 15.4097 16.7157 15.218 17.092C15.1383 17.2484 15.0734 17.4726 15.0376 17.911C15.0008 18.3611 15 18.9434 15 19.8V20H31V19.8C31 18.9434 30.9992 18.3611 30.9624 17.911C30.9266 17.4726 30.8617 17.2484 30.782 17.092C30.5903 16.7157 30.2843 16.4097 29.908 16.218C29.7516 16.1383 29.5274 16.0734 29.089 16.0376C28.7954 16.0136 28.4455 16.0049 28 16.0018V17C28 17.5523 27.5523 18 27 18C26.4477 18 26 17.5523 26 17V16H20V17C20 17.5523 19.5523 18 19 18C18.4477 18 18 17.5523 18 17Z" fill="white" />
  </svg>

);

export default Internal;
