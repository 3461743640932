import { useEffect, useRef } from 'react';

export default function useComponentDidMount (effect, dependencies = []) {
  const isFunctionCalled = useRef(false);

  useEffect(() => {
    if (isFunctionCalled.current) return;
    isFunctionCalled.current = true;
    effect();
  }, dependencies);
}
