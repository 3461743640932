import { createSlice } from '@reduxjs/toolkit';
import { getStandardDate } from 'utils/dateTime';
import {
  requestEmployeeRequests,
  requestCreateEmployeeRequest,
  requestUpdateEmployeeRequest,
} from './thunk';

const profile = createSlice({
  name: 'request',
  initialState: {
    filters: {
      startDate: getStandardDate(),
      page: 1,
      perPage: 10,
      totalRecords: 0,
    },
    records: [],
  },
  reducers: {
    setRequestFilters: (state, action) => ({
      ...state,
      filters: {
        ...state.filters,
        ...action.payload,
      },
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(requestEmployeeRequests.fulfilled, (state, action) => ({
      ...state,
      filters: { ...state.filters, ...action.payload.filters },
      records: action.payload.filters.page !== 1
        ? [...state.records, ...action.payload.records]
        : action.payload.records,
    }));
    builder.addCase(requestCreateEmployeeRequest.fulfilled, (state) => ({
      ...state,
      requestModalError: null,
    }));
    builder.addCase(requestCreateEmployeeRequest.rejected, (state, action) => ({
      ...state,
      requestModalError: action.payload,
    }));
    builder.addCase(requestUpdateEmployeeRequest.rejected, (state, action) => ({
      ...state,
      requestModalError: action.payload,
    }));
    builder.addCase(requestUpdateEmployeeRequest.fulfilled, (state) => ({
      ...state,
      requestModalError: null,
    }));
  },
});

const { reducer, actions } = profile;

export const { setRequestFilters } = actions;

export default reducer;
