import { createAsyncThunk } from '@reduxjs/toolkit';
import { setSnackbarError, setSnackbarSuccess } from 'appRedux/actions';
import { getFormattedProfile } from './selector';

export const requestProfile = createAsyncThunk(
  'profile/fetchProfile',
  async (_, { extra, rejectWithValue, dispatch }) => {
    try {
      const response = await extra.axios.get('/api/v1/profile/details');
      return getFormattedProfile(response.data.data);
    } catch (err) {
      dispatch(setSnackbarError('snackbar.profile.gettingProfile.error'));
      return rejectWithValue(err);
    }
  },
);

export const requestAttachProfilePicture = createAsyncThunk(
  'profile/attachDocument',
  async (id, { extra, dispatch, getState }) => {
    const { fileManager: { fileToUpload } } = getState();
    try {
      const formData = new FormData();
      formData.append('document', fileToUpload);
      await extra.axios.put(`/api/v3/employees/${id}/attach`, formData);
      dispatch(setSnackbarSuccess('document_attached_successfully'));
      dispatch(requestProfile());
    } catch (err) {
      dispatch(setSnackbarError('snackbar_profile_document_attach_error'));
    }
  },
);

export const requestDeleteEmployeeProfileImage = createAsyncThunk(
  'profile/deleteProfilePicture',
  async (id, { extra, dispatch }) => {
    try {
      await extra.axios.delete(`/api/v3/employees/${id}/delete_profile_image`);
      dispatch(setSnackbarSuccess('document_removed_successfully'));
      dispatch(requestProfile());
    } catch (err) {
      dispatch(setSnackbarError('snackbar.profile.image.delete.error'));
    }
  },
);
