import React from 'react';
import { arrayOf, string, func, number, oneOfType, object } from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { withStyles, makeStyles } from '@mui/styles';
import color from 'utils/colors';

const DialogTabs = withStyles({
  indicator: {
    backgroundColor: (props) => props.indicatorColor,
    height: (props) => `${props.navTabsIndicatorHeight} !important`,
  },
})(({ navTabsIndicatorHeight, ...rest }) => <Tabs {...rest} />);

const DialogTab = withStyles((theme) => ({
  root: {
    minWidth: 40,
    marginRight: theme.spacing(2),
    color: color.tabColor,
    opacity: 0.6,
    '&:hover': {
      color: color.tabColor,
      opacity: 1,
    },
    '&$selected': {
      color: color.tabColor,
      fontWeight: theme.typography.fontWeightMedium,
      opacity: 1,
    },
    '&:focus': {
      color: color.tabColor,
      opacity: 1,
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles(() => ({ root: { flexGrow: 1 } }));

const TabNavBar = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <DialogTabs
        value={props.activeTab}
        onChange={props.setActiveTab}
        indicatorColor={props.indicatorColor}
        textColor={props.textColor}
        variant={props.variant}
        navTabsIndicatorHeight={props.navTabsIndicatorHeight}
      >
        {props.tabs.map((tab, idx) => <DialogTab label={tab.title} disabled={tab.disabled} key={idx} />)}
      </DialogTabs>
    </div>
  );
};

TabNavBar.defaultProps = {
  indicatorColor: color.tabColor,
  textColor: 'inherit',
};

DialogTabs.defaultProps = {
  navTabsIndicatorHeight: '4px',
};

TabNavBar.propTypes = {
  tabs: arrayOf(oneOfType([string, object])),
  setActiveTab: func,
  activeTab: number,
  indicatorColor: string,
  textColor: string,
  variant: string,
  navTabsIndicatorHeight: string,
};

export default TabNavBar;
