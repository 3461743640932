import { createAsyncThunk } from '@reduxjs/toolkit';
import { setFilters, setSnackbarError, setSnackbarSuccess, setUsersAreaFields } from 'appRedux/actions';
import { getFormattedListData } from 'appRedux/utility';
import { getFormattedAreaRequestBody } from './selector';

export const requestCreateArea = createAsyncThunk(
  'area/createArea',
  async (details, { extra, dispatch }) => {
    try {
      const requestBody = getFormattedAreaRequestBody(details);
      const response = await extra.axios.post('/api/v3/areas', requestBody);
      dispatch(setSnackbarSuccess('snackbar_department_created'));
      return response.data.data;
    } catch (err) {
      dispatch(setSnackbarError('snackbar_department_create_failed'));
    }
  },
);

export const requestUpdateArea = createAsyncThunk(
  'area/updateArea',
  async (details, { extra, dispatch }) => {
    try {
      const requestBody = getFormattedAreaRequestBody(details);
      await extra.axios.put(`/api/v3/areas/${details.sequenceNum}`, requestBody);
      dispatch(setSnackbarSuccess('snackbar_department_updated'));
    } catch (err) {
      dispatch(setSnackbarError('snackbar_department_update_failed'));
    }
  },
);

export const requestArea = createAsyncThunk(
  'area/fetchArea',
  async (departmentSequenceNum, { extra, dispatch }) => {
    try {
      const response = await extra.axios.get(`/api/v3/areas/${departmentSequenceNum}`);
      return { record: response.data.data };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_department_fatch_failed'));
    }
  },
);

export const requestAreas = createAsyncThunk(
  'area/fetchAreas',
  async (name, { extra, dispatch, getState }) => {
    try {
      const { filters } = extra.getState(name, getState);
      const params = { page: filters.page, per_page: filters.perPage };
      const response = await extra.axios.get('/api/v3/areas', { params });
      const { records, ...nextFilters } = getFormattedListData(response.data.data);
      dispatch(setFilters({ ...nextFilters, name }));
      return { records };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_department_list_failed'));
    }
  },
);

export const requestAreasForUsers = createAsyncThunk(
  'area/fetchAreasForUsers',
  async (companyId, { extra, dispatch, getState }) => {
    try {
      const headers = extra.getHeadersForSubCompany(companyId, getState);
      const response = await extra.axios.get('/api/v3/areas', { headers });
      dispatch(setUsersAreaFields({ records: response.data.data.records }));
    } catch (err) {
      dispatch(setSnackbarError('snackbar_department_list_failed'));
    }
  },
);

export const requestDeleteArea = createAsyncThunk(
  'area/deleteArea',
  async (departmentSequenceNum, { extra, dispatch }) => {
    try {
      await extra.axios.delete(`/api/v3/areas/${departmentSequenceNum}`);
      dispatch(setSnackbarSuccess('snackbar_department_deleted'));
    } catch (err) {
      dispatch(setSnackbarError('snackbar_department_delete_failed'));
    }
  },
);
