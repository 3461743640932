export const reviews = [
  {
    id: 1,
    text: 'Programmet är verkligen komplett och mycket användarvänligt. Det har alla funktioner vi behöver.'
            + ' Dessutom är priset väldigt rimligt för vad man får. Jag är supernöjd!',
  },
  {
    id: 2,
    text: 'Väldigt bra att det är integrerat. Sparar så mycket tid!',
  },
  {
    id: 3,
    text: 'Vi är extrem nöjd med BOS Bokning & Schema Online. Har letat och testat olika liknande system.'
            + ' De har otrolig bra kundservice och utvecklar vid önskemål och åtgärda vid feedback om möjligt.',
  },
];
