import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Badge } from 'components/library';
import { MessageIcon } from 'components/library/icons';
import DrawerDetails from './DrawerDetails';

const MessageDrawer = (props) => {
  const {
    proposal,
    openDrawerForAdmin,
    company,
    messages,
    messageForAdmin,
    unreadMessagesCount,
    onPostMessage,
    onMarkReadMessages,
    onDeleteMessage,
  } = props;
  const [openDrawer, setOpenDrawer] = useState(false);

  useEffect(() => {
    if (openDrawerForAdmin) {
      setOpenDrawer(openDrawerForAdmin);
    }
  }, [openDrawerForAdmin]);

  const openDrawerHandler = () => {
    setOpenDrawer(true);
    onMarkReadMessages();
  };

  const closeDrawerHandler = () => {
    setOpenDrawer(false);
  };

  const getBackGroundColorForMessage = (message) => {
    if (messageForAdmin) {
      return message.userId ? '#CBDAFF' : '#F1F1F1';
    }

    return message.userId ? '#F1F1F1' : '#CBDAFF';
  };

  const getIconColor = (message) => {
    if (messageForAdmin) {
      return message.userId ? '#7B1FAD' : '#EB3FC7';
    }

    return message.userId ? '#EB3FC7' : '#7B1FAD';
  };

  return (
    <>
      <Badge badgeContent={unreadMessagesCount} color="primary" overlap="circular">
        <IconButton onClick={openDrawerHandler}>
          <MessageIcon color="#707070" />
        </IconButton>
      </Badge>
      <DrawerDetails
        openDrawer={openDrawer}
        closeDrawerHandler={closeDrawerHandler}
        messages={messages}
        messageForAdmin={messageForAdmin}
        getBackGroundColorForMessage={getBackGroundColorForMessage}
        getIconColor={getIconColor}
        company={company}
        proposal={proposal}
        onPostMessage={onPostMessage}
        onDeleteMessage={onDeleteMessage}
      />
    </>
  );
};

MessageDrawer.propTypes = {
  proposal: PropTypes.shape({
    customerName: PropTypes.string,
  }),
  company: PropTypes.shape({
    contactPerson: PropTypes.string,
  }),
  messages: PropTypes.arrayOf(PropTypes.shape({})),
  messageForAdmin: PropTypes.bool,
  unreadMessagesCount: PropTypes.number,
  onPostMessage: PropTypes.func,
  onMarkReadMessages: PropTypes.func,
  onDeleteMessage: PropTypes.func,
  openDrawerForAdmin: PropTypes.bool,
};

export default MessageDrawer;
