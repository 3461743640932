import { SET_COLOR_FIELDS } from 'appRedux/actions';

const initialState = {
  records: [],
  colorsById: {},
};

export default function color(state = initialState, action) {
  switch (action.type) {
    case SET_COLOR_FIELDS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
