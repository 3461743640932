/* eslint-disable camelcase */
import axios from 'utils/axios';
import { setOrganizationSettingFields, setSnackbarError, setSnackbarSuccess, setProfile } from 'appRedux/actions';
import { requestStorageStats } from 'appRedux/thunks/profile/storage';
import { keyBy } from 'utils/lodash';

export const requestCompanySettingsRecords = () => async (dispatch) => {
  try {
    const response = await axios.get('/api/v3/company_settings');
    const records = keyBy('settingCode')(response.data.data);
    dispatch(setOrganizationSettingFields({ records }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar_fetch_company_documents_error'));
  }
};

export const requestAddSpaceToStorage = (settingDetails, upload_limit) => async (dispatch, getState) => {
  const { profile: { organizationSetting } } = getState();
  try {
    const requeustBody = { company_setting: { upload_limit } };
    await axios.put(`/api/v3/company_settings/${settingDetails.id}`, requeustBody);
    dispatch(setOrganizationSettingFields({
      storageSpaceExtended: true,
      records: { ...organizationSetting.records, [settingDetails.settingCode]: settingDetails },
    }));
    dispatch(requestStorageStats());
  } catch (err) {
    dispatch(setSnackbarError('snackbar_upgrade_copany_storage'));
  }
};

export const requestToggleOrganizationSetting = (record) => async (dispatch, getState) => {
  const { profile: { organizationSetting } } = getState();
  try {
    const requestBody = { company_setting: { enable: record.enable } };
    await axios.put(`/api/v3/company_settings/${record.id}`, requestBody);
    dispatch(setOrganizationSettingFields({
      records: { ...organizationSetting.records, [record.settingCode]: record },
    }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar_error_updating_setting'));
  }
};

export const requestUpdateOrganizationSetting = (record) => async (dispatch, getState) => {
  const { profile: { organizationSetting } } = getState();
  try {
    const requestBody = { company_setting: { enable: record.enable, value: record.value || null } };
    await axios.put(`/api/v3/company_settings/${record.id}`, requestBody);
    dispatch(setOrganizationSettingFields({
      records: { ...organizationSetting.records, [record.settingCode]: record },
    }));
    dispatch(setProfile({ showPunchSettingDialog: false }));
    dispatch(setSnackbarSuccess('snackbar_success_updating_setting'));
  } catch (err) {
    dispatch(setSnackbarError('snackbar_error_updating_setting'));
  }
};
