import { orderBy, pick, isNumber, omit, get, isEmpty } from 'utils/lodash';
import { smsDataTypeAPI, smsDataType } from 'utils/enum';
import { getSmsDescription } from 'appRedux/thunks/utility';
import {
  formatDate,
  formatStrings,
  convertFloatToTimeString,
  getDifferenceBetweenTime,
  timeStringToFloat,
  getStringToDate,
} from 'utils/dateTime';
import { projectStatus } from 'components/Project/ProjectDrawer/utility';
import { getStandardDate } from 'utils/helpers';
import {
  getFormattedPaginationFields,
  getFilterEmployeeId,
  getFilterCustomerId,
} from 'appRedux/selectors/utility';
import snakify from 'utils/snakify';
import { getTotalWorkingHoursForProjectOrderEmployees } from 'appRedux/utility';
import { subDays } from 'date-fns';
import getItemsTotalCosts, { getItemCost } from 'utils/getCost';
import { capitalize, fm } from 'utils/string';

export const getFormattedPredefinedChecklist = (response) => ({
  checklist: {
    taskCategory: response.taskCategory,
    tasks: orderBy('orderNumber', 'asc')(response.tasks.map((task, idx) => ({
      id: task.id,
      title: task.name,
      isCompleted: Boolean(task.is_completed),
      orderNumber: task.order_number ? task.order_number : idx + 1,
      connected: task.connected ?? true,
      checklistId: task.checklistId,
      taskCategoryId: task.taskCategoryId,
    }))),
  },
});

export const formatProjectChecklistApiReq = (projectChecklists = [], projectId, companyId) => {
  let checklist = [];
  (projectChecklists || []).map((projectChecklist) => {
    checklist = [...checklist, ...projectChecklist.tasks.map((task) => ({
      id: projectId && task.projectTaskId,
      task_id: task.id,
      task_category_id: projectChecklist.taskCategory.id,
      _destroy: projectChecklist.taskCategory.destroy || task.destroy,
      completed: task.completed,
      order_number: task.orderNumber,
      company_id: companyId,
    }))];
    return checklist;
  });
  return checklist;
};

export const getFormattedProjectResponse = (response) => ({
  ...getFormattedPaginationFields(response),
  records: (response.records || []).map((record) => ({
    ...record,
    projectEmployees: record.projectEmployees.map((emp) => ({
      ...emp,
      name: emp.employee && emp.employee.name,
      colorCode: emp.employee && emp.employee.colorCode,
    })),
    projectTags: record.projectTags.map((tag) => ({
      ...tag,
      title: tag.name,
    })),
  })),
});

export const getFormattedProjectRequestBody = (project, company) => ({
  id: project.id,
  update_invoice_time: project.updateInvoiceTime,
  company_id: company.id,
  customer_id: project.customerId || project.customer.id,
  service_name: project.serviceName || project.service.name,
  service_id: project.serviceId,
  customer_name: project.customerName || project.customer.name,
  price_type: project.priceType,
  start_date: project.startDate,
  updated_start_date: project.updatedStartDate,
  end_date: project.endDate,
  start_time: project.startTime,
  end_time: project.endTime ? project.endTime : null,
  time_type: project.timeType,
  total_hours: project.totalHours,
  flex_time_type: project.flexTimeType,
  housework_type: project.houseworkType,
  published: project.published,
  employee_confirmation_required: project.employeeConfirmationRequired,
  project_recurring_rule_attributes: project.projectRecurringRule ? {
    id: project.projectRecurringRule?.id,
    project_id: project.id,
    repeat_type: project.projectRecurringRule.repeatType,
    repeat_days: project.projectRecurringRule.repeatDays,
    repeat_interval: project.projectRecurringRule.repeatInterval,
    month_repeat: project.projectRecurringRule.monthRepeat,
    company_id: project.repeatType && company.id,
    _destroy: !project.isRecurring ? true : undefined,
  } : undefined,
  project_employees_attributes: (project.projectEmployees || []).map((emp) => ({
    id: emp.id,
    project_id: emp.id ? project.id : undefined,
    employee_id: emp.employeeId,
    start_time: emp.startTime ?? null,
    end_time: emp.endTime ?? null,
    invoice_time: emp.invoiceTime ? convertFloatToTimeString(emp.invoiceTime) : '00:00',
    break_time: emp.breakTime,
    _destroy: emp.destroy,
    company_id: company.id,
    invoiceable: emp.invoiceable || true,
    calculate_overtime: emp.calculateOvertime,
  })),
  project_items_attributes: project.projectItems && project.projectItems.map((item) => ({
    item_id: item.itemId,
    id: project.id && isNumber(item.id) ? item.id : undefined,
    per_unit: item.perUnit,
    material_cost: item.materialCost,
    housework_type: item.houseworkType,
    is_housework: item.isHousework,
    account_number: item.accountNumber,
    discount: item.discount,
    discount_value: item.itemDiscount,
    per_employee: item.perEmployee,
    discount_type: item.discountType,
    ...(item.integrationDetail && {
      visma_item_id: item.integrationDetail.vismaId,
      material_cost: item.materialCost,
      fortnox_id: item.integrationDetail.fortnoxNumber,
    }),
    _destroy: item.destroy,
    ...pick(['id', 'quantity', 'title', 'vat', 'unit', 'hours'])(item),
    company_id: company.id,
  })),
  project_tags_attributes: project.projectTags && project.projectTags.map((tag) => ({
    id: project.id && tag.id,
    project_id: project.id,
    tag_id: tag.tagId,
    _destroy: tag.destroy,
    company_id: company.id,
  })),
  project_note_attributes: project.notes ? {
    id: project.projectNote?.id,
    project_id: project.id,
    notes: project.notes,
    company_id: company.id,
  } : undefined,
  project_addresses_attributes: project.projectAddresses && project.projectAddresses.map((address) => ({
    id: address?.id,
    project_id: project.id,
    title: address.title,
    address: address.address,
    company_id: company.id,
    _destroy: address.destroy,
  })),
  project_checklists_attributes: project?.projectChecklists
    && formatProjectChecklistApiReq(project.projectChecklists, project.id, company.id),
  status: project.status || projectStatus.active,
});

export const getFormattedFilterParams = (filters = {}) => ({
  page: filters.page || 1,
  per_page: filters.perPage,
  customer_id: getFilterCustomerId(filters),
  service_ids: filters.services?.map((service) => service.id),
  employee_id: getFilterEmployeeId(filters),
  status: filters.status === 'view_all' ? undefined : filters.status,
  start_date: filters.startDate,
  end_date: filters.endDate,
  tag_id: filters.tag ? filters.tag.id : undefined,
  project_id: filters.projectId,
  order_by: snakify(filters.orderBy),
  order: filters.order,
  active: filters.active === 'view_all' ? undefined : filters.active,
  non_recurring: filters.nonRecurring === 'view_all' ? undefined : filters.nonRecurring,
  updated: filters.updated,
  skill_id: filters.skill?.id,
});

export const getFormattedProjectChecklists = (response) => Object.values(response).map((bookingChecklist) => ({
  taskCategory: {
    id: bookingChecklist[0].taskCategoryId,
    title: bookingChecklist[0].taskCategoryTitle,
  },
  tasks: orderBy('orderNumber', 'asc')(bookingChecklist.map((task, idx) => ({
    projectTaskId: task.id,
    id: task.taskId,
    title: task.taskName,
    completed: task.completed,
    orderNumber: task.orderNumber ? task.orderNumber : idx + 1,
    checklistId: task.checklistId,
    taskCategoryId: task.taskCategoryId,
    connected: task.connected ?? true,
  }))),
}));

export const getFormattedScheduledData = (projectSchedule) => ({
  ...projectSchedule,
  messageText: projectSchedule.scheduledMessageDetail
    && getSmsDescription(projectSchedule.scheduledMessageDetail.messageText, smsDataType),
  deliveryTime: projectSchedule.startDate
    ? `${getStandardDate(
      subDays(getStringToDate(projectSchedule.startDate, 'yyyy-MM-dd'), projectSchedule.scheduledType),
    )} ${projectSchedule.startTime}`
    : '-',
});

const formatProjectEmployee = (emp) => {
  const startTime = emp.startTime ? emp.startTime.split('+')[0] : null;
  const endTime = emp.endTime ? emp.endTime.split('+')[0] : null;
  const paidTime = getTotalWorkingHoursForProjectOrderEmployees(startTime, endTime, emp.breakTime);

  return {
    ...emp,
    name: emp.employee && emp.employee.name,
    colorCode: emp.employee.colorCode,
    startTime: startTime ?? null,
    endTime: endTime ?? null,
    paidTime,
    invoiceTime: emp.invoiceTime ? timeStringToFloat(emp.invoiceTime) : null,
    breakTime: emp.breakTime ?? null,
  };
};

export const getFormattedProjectData = (record) => {
  const projectItems = (record.projectItems || [])
    .map((rest) => ({
      ...rest,
      integrationDetail: rest.vismaItem,
      itemDiscount: rest.discountValue,
      account: { number: rest.accountNumber },
    }))
    .map((item) => ({
      ...item,
      ...getItemCost(
        { ...item },
        { ...record, houseworkType: capitalize(record.houseworkType) },
      ),
    }));
  const projectPriceAttributes = getItemsTotalCosts(projectItems, record);
  return {
    ...record,
    customer: {
      ...record.customer,
      customerAddresses: record.customer.customerAddresses.filter((address) => address.showToBookings),
    },
    projectTags: record.projectTags.map((tag) => ({
      ...tag,
      title: tag.name,
    })),
    projectService: {
      ...record.service,
      colorCode: record.service.color.code,
      colorCodeId: record.service.color.id,
    },
    totalHours: record.totalHours,
    projectItems,
    projectPriceAttributes,
    notes: record.projectNote?.notes,
    projectEmployees: record.projectEmployees.map(formatProjectEmployee),
    projectChecklists: getFormattedProjectChecklists(record.projectChecklists),
    isRecurring: Boolean(record.projectRecurringRule),
    scheduledMessage: record.scheduledMessage ? getFormattedScheduledData(record.scheduledMessage) : null,
  };
};

export const getFormattedProjectOrdersResponse = (response) => ({
  ...getFormattedPaginationFields(response),
  records: (response.records || []).map((record) => ({
    ...record,
    orderChecklists: getFormattedProjectChecklists(record.orderChecklists || {}),
    orderService: {
      title: record.service,
      colorCode: record.serviceColorCode,
      id: record.serviceId,
    },
    orderEmployees: record.orderEmployees.map((emp) => ({
      ...emp,
      breakTime: emp.breakTime ?? null,
    })),
    id: record.id ?? `${record.projectSequenceNum}-${formatDate(record.date, formatStrings.customDate)}`,
    virtual: !record.id,
  })),
});

export const getFormattedFieldsToUpdate = (fieldsToUpdate) => ({
  order_service: fieldsToUpdate.orderService,
  customer: fieldsToUpdate.customer,
  order_date_time_fields: fieldsToUpdate.orderDateTimeFields,
  update_all: fieldsToUpdate.updateAll,
  order_tags: fieldsToUpdate.orderTags,
  notes: fieldsToUpdate.notes,
  price: fieldsToUpdate.price,
  order_employees: fieldsToUpdate.orderEmployees,
  order_addresses: fieldsToUpdate.orderAddresses,
  order_checklists: fieldsToUpdate.orderChecklists,
  published: fieldsToUpdate.published,
  invoice_hours: fieldsToUpdate.invoiceHours,
  employee_confirmation_required: fieldsToUpdate.employeeConfirmationRequired,
});

const formatProjectItemsDataAndCalculateCost = (project) => (project.projectItems || [])
  .map(({ unit, ...rest }) => ({
    ...rest,
    itemDiscount: rest.discountValue,
    integrationDetail: rest.vismaItem,
    unit: unit === 'tim' ? 'h' : unit,
  }))
  .map((item) => ({ ...item, ...getItemCost(item, project) }));

// Used to format copy project data
export const getCopyProjectData = (project) => {
  let projectItems = formatProjectItemsDataAndCalculateCost(project);
  projectItems = projectItems.map((d) => ({ ...omit(['id', 'projectId'])(d), isHousework: d.isHousework }));

  const pickRequiredFields = pick(['companyId', 'customer', 'employeeConfirmationRequired', 'customerId',
    'customerName', 'customerMobile', 'flexTimeType', 'endDate', 'endTime', 'startTime',
    'houseworkType', 'priceType', 'published', 'service', 'serviceName', 'serviceId',
    'serviceSequenceNum', 'timeType', 'totalHours', 'startDate',
  ]);

  return {
    ...pickRequiredFields(project),
    updatedStartDate: project.startDate,
    smsScheduled: Boolean(project.smsScheduled),
    isRecurring: Boolean(project.projectRecurringRule),
    projectPriceAttributes: getItemsTotalCosts(projectItems, project),
    projectAddresses: (project.projectAddresses || []).map(
      (projectAddress) => omit(['id', 'createdAt', 'updatedAt', 'projectId'])(projectAddress),
    ),
    notes: project.projectNote?.notes,
    projectService: project.service,
    serviceName: project.serviceName ?? project.service.name,
    projectItems,
    repeatType: project.projectRecurringRule && project.projectRecurringRule.repeatType,
    repeatDays: project.projectRecurringRule && project.projectRecurringRule.repeatDays,
    repeatInterval: project.projectRecurringRule && project.projectRecurringRule.repeatInterval,
    monthRepeat: project.projectRecurringRule && project.projectRecurringRule.monthRepeat,
    projectRecurringRule: omit(['id', 'projectId'])(project.projectRecurringRule),
    projectNote: undefined,
    projectEmployees: (project.projectEmployees || []).map((employee) => ({
      ...omit(['id', 'projectId'])(employee),
      name: employee.employee && employee.employee.name,
      colorCode: employee.employee.colorCode,
      overtimes: [],
      paidTime: employee.startTime && employee.endTime ? getTotalWorkingHoursForProjectOrderEmployees(
        employee.startTime.split('+')[0],
        employee.endTime.split('+')[0],
        employee.breakTime,
      ) : null,
      invoiceTime: employee.invoiceTime ? timeStringToFloat(employee.invoiceTime) : null,
      startTime: employee.startTime ? employee.startTime.split('+')[0] : null,
      endTime: employee.endTime ? employee.endTime.split('+')[0] : null,
      sequenceNum: employee.employee.sequenceNum,
    })),
    projectTags: project.projectTags.map((tag) => ({
      tagId: tag.tagId,
      title: tag.name,
    })),
    projectChecklists: getFormattedProjectChecklists(project.projectChecklists),
  };
};

export const getFormattedSmsBodyForProject = (data) => ({
  project: {
    send_sms: data.sendSmsNow,
    sms_text: getSmsDescription(data.customSms, smsDataTypeAPI),
    sms_on_complete: data.smsOnComplete,
    scheduled_message: data.scheduled ? {
      destroy: data.destroy,
      message_text: getSmsDescription(data.customSms, smsDataTypeAPI),
      scheduled_type: data.scheduledType,
      sender_type: data.role,
      sms_template_id: data.smsTemplateId,
    } : undefined,
  },
});

export const getFormattedCustomerData = (data = {}) => [
  { name: fm('individual', 'Individual'), value: get('individual')(data), fill: '#3d67c5' },
  { name: fm('organisation', 'Organisation'), value: get('organisation')(data), fill: '#cc472e' },
];

export const getFormattedProjectRecurringRulesRequestBody = (body) => ({
  order: {
    updated_start_date: body.updatedStartDate,
    start_date: new Date(body.updatedStartDate) < new Date(body.startDate)
      ? body.updatedStartDate
      : body.startDate,
    end_date: body.endDate,
    project_recurring_rule_attributes: !isEmpty(body.projectRecurringRule) ? {
      id: body.projectRecurringRule.id,
      repeat_type: body.projectRecurringRule.repeatType,
      repeat_days: body.projectRecurringRule.repeatDays,
      repeat_interval: body.projectRecurringRule.repeatInterval,
      month_repeat: body.projectRecurringRule.monthRepeat,
      _destroy: !body.isRecurring ? true : undefined,
    } : undefined,
  },
  remove_past_order: body.removePastOrder,
});
