import { getFormattedPaginationFields } from 'appRedux/selectors/utility';
import { pick } from 'utils/lodash';

export const getTimeCodeRequestBody = (data) => ({
  ...pick(['name', 'code'])(data),
  is_active: data.isActive,
  code_type: data.codeType,
  visma_timecode: data.vismaTimecode,
  show_to_employees: data.showToEmployees,
});

export const getOtherCodeRequestBody = (data) => ({
  salary_code: {
    ...pick(['title', 'code', 'unit', 'amount'])(data),
    is_active: data.isActive,
    salary_type: data.salaryType,
    total_amount: data.totalAmount ? Number(data.totalAmount) : null,
    visma_salarycode: data.vismaSalarycode,
    amount_editable: data.amountEditable,
    show_to_employees: data.showToEmployees,
  },
});

export const getFormattedTimeCodesRequestParams = (filters) => ({
  per_page: filters.perPage,
  page: filters.page,
  code_type: filters.codeType === 'view_all' ? undefined : filters.codeType,
  query: filters.query,
});

export const getFormattedTimeCodeList = (response = {}) => ({
  ...getFormattedPaginationFields(response),
  records: response.records,
});
