import { FormControlLabel } from '@mui/material';
import MuiSwitch from '@mui/material/Switch';
import React from 'react';
import PropTypes from 'prop-types';

const SwitchElement = (props) => (
  <MuiSwitch {...props} />
);

export default function Switch (props) {
  const { label, ...switchProps } = props;

  const switchComponent = <SwitchElement {...switchProps} />;

  return label ? (
    <FormControlLabel
      label={label}
      control={switchComponent}
    />
  ) : switchComponent;
}

Switch.propTypes = {
  label: PropTypes.string,
};
