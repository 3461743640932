/* eslint-disable camelcase */
import axios from 'utils/Api';
import userAxios from 'utils/userAxios';
import axiosNext from 'utils/axios';
import { multiStateGet } from 'appRedux/reducers/utility';
import { getFormattedListData } from 'appRedux/utility';
import {
  setFilters,
  setSuperAdminFields,
  setAuthFields,
  setSnackbarError,
} from 'appRedux/actions';
import { getFormattedRequestUsersQueryParams } from './selector';

export const requestUsers = (name) => async (dispatch, getState) => {
  const { filters } = getState();
  try {
    const userFilters = multiStateGet(name, filters);
    const params = getFormattedRequestUsersQueryParams(userFilters);
    const response = await axiosNext.get('/api/v3/super_admin/users', { params });
    const { records: users, ...rest } = getFormattedListData(response.data.data);
    dispatch(setSuperAdminFields({ users }));
    dispatch(setFilters({ ...rest, name }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar.superAdmin.gettingUsers.error'));
  }
};

export const requestLoginUser = (id) => async (dispatch) => {
  try {
    const { data } = await axios.get(`/api/v1/super_admin/users/${id}/user_sign_in`);

    if (data.success && !data.errors) {
      axios.defaults.headers.Authorization = `Bearer ${data.data.user.token}`;
      userAxios.defaults.headers.Authorization = `Bearer ${data.data.user.token}`;
      localStorage.setItem('token', data.data.user.token);
      localStorage.setItem('role', data.data.user.role);
      localStorage.setItem('user_token', data.data.user.token);
      dispatch(setAuthFields({
        role: data.data.user.role,
        token: data.data.user.token,
        userToken: data.data.user.token,
      }));
      window.location.reload();
    } else {
      dispatch(setSnackbarError('snackbar.superAdmin.loginUser.error'));
      dispatch(setAuthFields({ errors: data.errors }));
    }
  } catch (err) {
    setSnackbarError('snackbar.superAdmin.loginUser.error');
  }
};

export const requestUnlockUserAccount = (userId, name) => async (dispatch) => {
  try {
    await axios.put(`/api/v3/super_admin/users/${userId}/unlock_account`);
    dispatch(requestUsers(name));
  } catch (err) {
    console.log(err);
  }
};
