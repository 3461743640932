import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { timeManagementNextTabs } from 'views/TimeManagement/utility';
import {
  requestPunchesData,
  requestOverviewRecords,
  requestSalaryRecords,
  requestEmployeeTimesheetRecords,
  requestEmployeeCustomerRecords,
  requestEmployeeServicesRecords,
  requestEmployeeProjectsRecords,
  requestSalaryStats,
  requestExportSalaryExcelReport,
  requestExportSalaryPxmlReport,
  requestApproveEmployeeTime,
  requestEmployeeTimesheetSummary,
} from './thunk';

const timeManagement = createSlice({
  name: 'timeManagement',
  initialState: {
    activeTab: timeManagementNextTabs.salary,
    openApproveTimeDialog: false,
    punches: {
      records: [],
      refreshAllPunchRecords: {},
      selectedRecordsIds: {},
      selectedOrderIds: [],
      selectedShiftTimeIds: [],
      virtualOrderIds: [],
    },
    overview: {
      records: [],
      refreshAllOverviewRecords: {},
      selectedVirtualIds: [],
      selectedShiftTimeIds: [],
      selectedRecordsIds: [],
    },
    salary: {
      records: [],
      timecodeSummary: {},
      refreshAllSalaryRecords: {},
      selectedRecordsIds: {},
      selectedOrderIds: [],
      selectedShiftTimeIds: [],
      currentEmployee: {},
      stats: {},
      timesheet: {
        records: [],
      },
      clients: {
        records: [],
      },
      services: {
        records: [],
      },
      projects: {
        records: [],
      },
    },
    invalidEmployeeTimeRecords: [],
  },
  reducers: {
    setTimeManagementNextFields (state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
    setTimeManagementNextOverviewFields (state, action) {
      return {
        ...state,
        overview: {
          ...state.overview,
          ...action.payload,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(requestPunchesData.fulfilled, (state, action) => ({
      ...state,
      punches: {
        ...state.punches,
        records: action.payload.records,
      },
    }));
    builder.addCase(requestOverviewRecords.fulfilled, (state, action) => ({
      ...state,
      overview: {
        ...state.overview,
        records: action.payload.records,
      },
    }));
    builder.addCase(requestSalaryRecords.fulfilled, (state, action) => ({
      ...state,
      salary: {
        ...state.salary,
        records: action.payload.records,
      },
    }));
    builder.addCase(requestEmployeeTimesheetRecords.fulfilled, (state, action) => ({
      ...state,
      salary: {
        ...state.salary,
        timesheet: {
          ...state.salary.timesheet,
          records: action.payload.records,
        },
      },
    }));
    builder.addCase(requestEmployeeTimesheetSummary.fulfilled, (state, action) => ({
      ...state,
      salary: {
        ...state.salary,
        timecodeSummary: action.payload.summary || {},
      },
    }));
    builder.addCase(requestSalaryStats.fulfilled, (state, action) => ({
      ...state,
      salary: {
        ...state.salary,
        stats: action.payload.stats,
      },
    }));
    builder.addCase(requestEmployeeCustomerRecords.fulfilled, (state, action) => ({
      ...state,
      salary: {
        ...state.salary,
        clients: {
          ...state.salary.clients,
          records: action.payload.records,
        },
      },
    }));
    builder.addCase(requestEmployeeServicesRecords.fulfilled, (state, action) => ({
      ...state,
      salary: {
        ...state.salary,
        services: {
          ...state.salary.services,
          records: action.payload.records,
        },
      },
    }));
    builder.addCase(requestEmployeeProjectsRecords.fulfilled, (state, action) => ({
      ...state,
      salary: {
        ...state.salary,
        projects: {
          ...state.salary.projects,
          records: action.payload.records,
        },
      },
    }));
    builder.addMatcher(
      isAnyOf(requestApproveEmployeeTime.fulfilled, requestApproveEmployeeTime.rejected),
      (state) => ({
        ...state,
        openApproveTimeDialog: false,
        punches: {
          ...state.punches,
          selectedOrderIds: [],
          selectedShiftTimeIds: [],
          virtualOrderIds: [],
          virtualOrders: [],
          refreshAllPunchRecords: {},
        },
        overview: {
          ...state.overview,
          selectedVirtualIds: [],
          selectedShiftTimeIds: [],
          selectedRecordsIds: [],
          refreshAllOverviewRecords: {},
        },
      }),
    );
    builder.addMatcher(
      isAnyOf(requestExportSalaryExcelReport.fulfilled, requestExportSalaryPxmlReport.fulfilled),
      (state, action) => ({
        ...state,
        ...action.payload,
        showInvalidOrderEmployeeTimeDialog: !!action.payload?.invalidEmployeeTimeRecords,
        showReportExportConfirmationDialog: false,
      }),
    );
  },
});

const { reducer, actions } = timeManagement;

export const { setTimeManagementNextFields, setTimeManagementNextOverviewFields } = actions;

export default reducer;
