import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from './Pagination';

export default function CollapsibleContent (props) {
  const { subtableColumns, subtableKeyField, row } = props;
  const subTableData = props.getSubtableData(row);

  const onSubtableRowClick = useCallback((subRow) => (event) => {
    if (!['TR', 'TD'].includes(event.target.tagName)) return;
    props.onSubtableRowClick(subRow, row);
  }, [row]);

  return (
    <>
      <Box margin={1}>
        <Table size="small" className="border border-neutral-100">
          <TableHead className="bg-[#DBDBDB]">
            <TableRow>
              {subtableColumns.map((subtableColumn) => (
                <TableCell key={`subtable-col-${subtableColumn.id}`} align={subtableColumn.align}>
                  {subtableColumn.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className="bg-neutral-100">
            {subTableData.map((subtableRow) => (
              <TableRow
                key={subtableRow[subtableKeyField]}
                onClick={props.onSubtableRowClick && (onSubtableRowClick(subtableRow))}
              >
                {subtableColumns.map((subtableColumn) => {
                  const { content } = props.getSubtableCellContent(subtableColumn, subtableRow, row);
                  return (
                    <TableCell scope="row" key={`${subtableRow[subtableKeyField]}-${subtableColumn.id}`}>
                      {content}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
          {props.showSubtablePagination && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  row={row}
                  subtableColumns={props.subtableColumns}
                  getSubtablePagination={props.getSubtablePagination}
                  onChangeSubtablePagination={props.onChangeSubtablePagination}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </Box>
    </>
  );
}

CollapsibleContent.propTypes = {
  showSubtablePagination: PropTypes.bool,
  subtableColumns: PropTypes.arrayOf(PropTypes.shape({})),
  getSubtablePagination: PropTypes.func,
  onChangeSubtablePagination: PropTypes.func,
  getSubtableCellContent: PropTypes.func,
  subtableKeyField: PropTypes.string,
  getSubtableData: PropTypes.func,
  row: PropTypes.shape({}),
  onSubtableRowClick: PropTypes.func,
};
