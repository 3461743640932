import axios from 'utils/Api';
import axiosNext from 'utils/axios';
import camelize from 'camelize';
import { bookingStatus } from 'utils/enum';
import { get, map, orderBy, flow, pick } from 'utils/lodash';
import { add, getStandardDate } from 'utils/dateTime';
import { fm } from 'utils/string';
import { multiStateGet } from 'appRedux/reducers/utility';
import {
  setFilters,
  setHandledRecurringBookingsFields,
  setExtendableRecurringBookingsFields,
  setRecurringBookingsFields,
  setBookingsFields,
  setSnackbarError,
  setSnackbarSuccess,
  setExtendableBookingsFields,
} from 'appRedux/actions';
import {
  formatRecurringListResponse,
  getFormattedRecurringFilterParams,
  getFormattedRecurringBookingFilterParams,
  getFormatedRecurringBookingPaginatedData,
  getFormattedBookingDataForApiRequest,
  getFormatedBookingProgressPaginatedData,
  getFormatedRecurringBookingFilterParams,
  formatRecurringListResponseDetails,
} from 'appRedux/thunks/bookings/selector';
import snakify from 'utils/snakify';
import { getFormattedListData } from 'appRedux/utility';

export const requestUpdateRecurringBooking = (bookingDetails) => async (dispatch, getState) => {
  const { bookings: { originalBooking } } = getState();
  try {
    dispatch(setBookingsFields({ fsip: true }));
    const bookingExtendedDate = originalBooking.endDate !== bookingDetails.endDate && bookingDetails.endDate;
    const booking = getFormattedBookingDataForApiRequest(bookingDetails);
    await axios.put(`/api/v3/bookings/${bookingDetails.sequenceNum}/booking_update`, booking);
    // extend booking if endDate is updated.
    if (bookingExtendedDate) {
      await axios.put(`/api/v1/bookings/${bookingDetails.sequenceNum}/extend`, { end_date: bookingExtendedDate });
    }
    dispatch(setSnackbarSuccess('snackbar.booking.updated'));
    dispatch(setBookingsFields({
      showRecurringUpdateOptionsModal: false,
      refreshAllBookings: {},
      showBookingModal: false,
      openedBookingId: null,
      bookingModalError: null,
      booking: {},
      fsip: false,
    }));
  } catch (err) {
    dispatch(setBookingsFields({ fsip: false }));
    if (err.name === 'ValidationError') {
      return dispatch(setBookingsFields({ bookingModalError: err.message }));
    }
    dispatch(setSnackbarError('snackbar.recurringBooking.update.error'));
  }
};

export const requestUpdateBookingDays = (details) => async (dispatch) => {
  try {
    dispatch(setBookingsFields({ fsip: true }));
    const booking = getFormattedBookingDataForApiRequest(details);
    await axios.put(`/api/v3/bookings/${details.sequenceNum}/days_update`, booking);
    dispatch(setSnackbarSuccess('snackbar.booking.updated'));
    dispatch(setBookingsFields({
      showRecurringUpdateOptionsModal: false,
      refreshAllBookings: {},
      showBookingModal: false,
      openedBookingId: null,
      bookingModalError: null,
      booking: {},
      fsip: false,
    }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar.recurringBooking.daysUpdate.error'));
  }
};

export const requestRecurringsBookings = (name, resetPage) => async (dispatch, getState) => {
  const { filters } = getState();
  try {
    const parameters = getFormattedRecurringFilterParams(multiStateGet(name, filters));
    const response = await axios.get('/api/v3/bookings/customer_bookings', { params: parameters });
    const result = get('data.data')(response) || { records: [] };
    const { records, ...restFields } = formatRecurringListResponse(result);
    dispatch(setHandledRecurringBookingsFields({ records, expandedRowsIndexes: {} }));
    if (resetPage) {
      return dispatch(setFilters({ ...restFields, page: 1, name }));
    }
    dispatch(setFilters({ ...restFields, name }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar.recurringBooking.getBookings.error'));
  }
};

export const requestRecurringsBookingsModal = (name) => async (dispatch, getState) => {
  const { filters } = getState();
  try {
    const parameters = getFormattedRecurringFilterParams(multiStateGet(name, filters));
    const response = await axios.get('/api/v1/bookings/recurring_instances', { params: parameters });
    const result = get('data.data')(response) || { records: [] };
    const { records, ...restFields } = formatRecurringListResponse(result);
    dispatch(setHandledRecurringBookingsFields({ records }));
    dispatch(setFilters({ ...restFields, name }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar.recurringBooking.getBookings.error'));
  }
};

export const requestRecurringBookingDetails = (name, customer, advancefilter) => async (dispatch, getState) => {
  const { filters, recurrings: { customerIdBookingsMap = {} } } = getState();
  try {
    const parameters = advancefilter
      ? getFormattedRecurringBookingFilterParams(advancefilter)
      : getFormattedRecurringBookingFilterParams(multiStateGet(name, filters));
    const response = await axios.get(
      `/api/v3/customers/${customer.customerId}/recurrings_last_child`, { params: parameters },
    );
    const result = get('data.data')(response) || { records: [] };
    const { records } = formatRecurringListResponseDetails(result, customer);
    const newCustomerIdBookingsMap = { ...customerIdBookingsMap, [customer.customerId]: records || [] };
    if (advancefilter) {
      dispatch(setHandledRecurringBookingsFields({
        customerIdBookingsMap: newCustomerIdBookingsMap,
        expandedRowsIndexes: {},
      }));
    } else {
      dispatch(setHandledRecurringBookingsFields({ customerIdBookingsMap: newCustomerIdBookingsMap }));
    }
  } catch (err) {
    dispatch(setSnackbarError('snackbar.recurringBooking.getBookings.error'));
  }
};

export const requestExtendableRecurringBookings = () => async (dispatch) => {
  try {
    dispatch(setExtendableBookingsFields({ fsip: true }));
    const response = await axios.get('/api/v1/bookings/extendable_recurrences');
    const result = get('data.data')(response);
    if (Array.isArray(result)) {
      const extendableBookings = flow(
        camelize,
        orderBy(['extendable', 'extendError', 'customerName'], ['desc', 'asc', 'asc']),
        map((booking) => ({
          ...booking,
          extendDate: getStandardDate(add(booking.endDate, { years: 1 })),
        })),
      )(result);
      dispatch(setExtendableBookingsFields({ records: extendableBookings }));
    }
  } catch (err) {
    dispatch(setSnackbarError('snackbar.recurringBooking.getBookings.error'));
  } finally {
    dispatch(setExtendableBookingsFields({ fsip: false }));
  }
};

export const requestUnextendedBookings = (name) => async (dispatch, getState) => {
  const { filters } = getState();
  try {
    dispatch(setExtendableBookingsFields({ fsip: true }));
    const listFilters = multiStateGet(name, filters);
    const params = snakify(pick(['startDate', 'endDate', 'page', 'perPage'])(listFilters));
    const response = await axiosNext.get('/api/v1/bookings/unextended_bookings', { params });
    const { records, ...restPagination } = getFormattedListData(response.data.data);
    dispatch(setExtendableBookingsFields({ records }));
    dispatch(setFilters({ ...restPagination, name }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar.recurringBooking.getBookings.error'));
  } finally {
    dispatch(setExtendableBookingsFields({ fsip: false }));
  }
};

export const requestRecurringBookings = (parentBookingId, name) => async (dispatch, getState) => {
  const { filters } = getState();
  try {
    const parameters = getFormatedRecurringBookingFilterParams(multiStateGet(name, filters));
    const response = await axiosNext.get(`/api/v3/bookings/${parentBookingId}/recurrences`, { params: parameters });
    const { recurring, recurrenceStartDate, ...rest } = getFormatedRecurringBookingPaginatedData(response.data.data);
    dispatch(setRecurringBookingsFields(recurring));
    dispatch(setBookingsFields({ recurrenceStartDate }));
    dispatch(setFilters({ ...rest, name }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar.recurringBooking.getRecurrings.error'));
  }
};

export const requestDeleteRecurringBookings = (data, callback) => async (dispatch) => {
  try {
    const body = { all_bookings: data.all, future_bookings: data.future, auto_extend: data.autoExtend };
    await axios.delete(`/api/v3/bookings/${data.bookingId}`, { data: body });
    dispatch(setBookingsFields({
      refreshAllBookings: {},
      showConfirmationModal: false,
      bookingId: null,
      confirmationType: null,
      showBookingModal: false,
      openedBookingId: null,
    }));
    dispatch(setSnackbarSuccess('snackbar.booking.deleted'));
    return callback && callback();
  } catch (err) {
    dispatch(setSnackbarError('snackbar.recurringBooking.delete.error'));
  }
};

export const requestCompleteRecurringBookings = (data, callback) => async (dispatch, getState) => {
  const { bookings } = getState();
  try {
    const body = { all_bookings: data.all, future_bookings: data.future };
    await axios.put(`/api/v3/bookings/${data.bookingId}/status/completed`, body);
    let { booking } = bookings;
    if (bookings.showBookingModal) {
      booking = { ...booking, status: bookingStatus.completed };
    }
    dispatch(setBookingsFields({
      showConfirmationModal: false,
      refreshAllBookings: {},
      bookingId: null,
      confirmationType: null,
      booking,
    }));

    dispatch(setSnackbarSuccess('snackbar.booking.completed'));
    return callback && callback();
  } catch (err) {
    const errorCode = get('response.data.errors[0].details.code')(err);
    if (errorCode) {
      return dispatch(setSnackbarError(fm(errorCode)));
    }
    dispatch(setSnackbarError('snackbar.recurringBooking.complete.error'));
  }
};

export const requestCancelRecurringBookings = (data, callback) => async (dispatch, getState) => {
  const { bookings } = getState();
  try {
    const body = { all_bookings: data.all, future_bookings: data.future };
    await axios.put(`/api/v3/bookings/${data.bookingId}/status/canceled`, body);
    let { booking } = bookings;
    if (bookings.showBookingModal) {
      booking = { ...booking, status: bookingStatus.cancelled };
    }
    dispatch(setBookingsFields({
      showConfirmationModal: false,
      refreshAllBookings: {},
      bookingId: null,
      confirmationType: null,
      booking,
    }));
    dispatch(setSnackbarSuccess('snackbar.booking.cancelled'));
    return callback && callback();
  } catch (err) {
    const errorCode = get('response.data.errors[0].details.code')(err);
    if (errorCode) {
      return dispatch(setSnackbarError(fm(errorCode)));
    }
    dispatch(setSnackbarError('snackbar.recurringBooking.cancelled.error'));
  }
};

export const requestExtendBooking = (details, bookingId) => async (dispatch, getState) => {
  const {
    bookings: {
      extendableRecurringBookings = [],
      extendableBooking,
    },
    recurrings: {
      records = [],
    },
  } = getState();
  try {
    await axios.put(`/api/v3/bookings/${bookingId}/extend`, { end_date: details.booking.endDate });
    const updateFun = (booking) => (booking.sequenceNum !== bookingId ? booking
      : ({ ...booking, endDate: details.booking.endDate, isExtended: true }));
    // for all recurring dialog
    const extendableBookings = extendableRecurringBookings.map(updateFun);
    dispatch(setExtendableRecurringBookingsFields(extendableBookings));
    // for bookings page handleRecurring tab
    const handleRecurringBookings = records.filter((b) => b.sequenceNum !== bookingId);
    dispatch(setHandledRecurringBookingsFields({ records: handleRecurringBookings }));
    // selected booking in recurring Dialog
    if (extendableBooking) {
      dispatch(setBookingsFields({ extendableBooking: updateFun(extendableBooking) }));
    }
    dispatch(setSnackbarSuccess('snackbar.recurringBooking.extended'));
  } catch (err) {
    dispatch(setSnackbarError('snackbar.recurringBooking.extended.error'));
  }
};

export const requestSetAutoExtendFieldForBooking = (autoExtend) => async (dispatch, getState) => {
  const { bookings: { originalBooking, booking } } = getState();
  try {
    dispatch(setBookingsFields({ fsip: true }));
    const reqBody = getFormattedBookingDataForApiRequest({ ...originalBooking, autoExtend });
    await axios.put(`/api/v3/bookings/${originalBooking.sequenceNum}/booking_update`, reqBody);
    dispatch(setBookingsFields({ booking: { ...booking, autoExtend }, refreshAllBookings: {} }));
    dispatch(setSnackbarSuccess('snackbar.recurringBooking.auto_extend_updated'));
  } catch (err) {
    dispatch(setSnackbarError('snackbar.recurringBooking.auto_extend_updated.error'));
  } finally {
    dispatch(setBookingsFields({ fsip: false }));
  }
};

export const requestBookingProgress = (todaysDate, name) => async (dispatch, getState) => {
  const { filters } = getState();
  try {
    const { page, perPage } = multiStateGet(name, filters);
    const params = { page, per_page: perPage };
    const response = await axiosNext.get('/api/v3/ongoing_bookings', { params: { ...params, date: todaysDate } });
    const { bookingProgress, ...rest } = getFormatedBookingProgressPaginatedData(response.data.data);
    dispatch(setBookingsFields({ bookingProgress }));
    dispatch(setFilters({ ...rest, name }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar.recurringBooking.extended.error'));
  }
};

export const requestUpdateBookingExtendableStatus = (sequenceNum, status) => async (dispatch) => {
  try {
    await axios.put(`/api/v3/bookings/${sequenceNum}/extendable_update`, { booking: { extendable: status } });
    dispatch(requestExtendableRecurringBookings());
  } catch (err) {
    dispatch(setSnackbarError('snackbar.recurringBooking.extended.error'));
  }
};

export const requestReActivateBookings = (data) => async (dispatch, getState) => {
  const { bookings } = getState();
  try {
    await axios.put(
      `/api/v3/bookings/${data.bookingId}/status/active`,
      { future_bookings: data.future, all_bookings: data.all },
    );
    let { booking } = bookings;
    if (bookings.showBookingModal) {
      booking = { ...booking, status: bookingStatus.active };
    }
    dispatch(setBookingsFields({
      refreshAllBookings: {},
      showConfirmationModal: false,
      bookingId: null,
      confirmationType: null,
      booking,
    }));
    dispatch(setSnackbarSuccess('snackbar_recurring_booking_reactivate_success'));
  } catch (error) {
    const errorCode = get('response.data.errors[0].details.code')(error);
    if (errorCode) {
      return dispatch(setSnackbarError(fm(errorCode)));
    }
    dispatch(setSnackbarError('snackbar_recurring_booking_reactivate_error'));
  }
};
