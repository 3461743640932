import {
  SET_SNACKBAR_ERROR,
  SET_SNACKBAR_INFO,
  SET_SNACKBAR_SUCCESS,
  SET_SNACKBAR_WARNING,
  RESET_SNACKBAR,
} from '../actions';

const initialState = { open: false, message: '' };

const getOpenFields = (state, message, severity) => ({ ...state, message, severity, open: true });

function snackbar (state = initialState, action) {
  switch (action.type) {
    case SET_SNACKBAR_ERROR:
      return getOpenFields(state, action.message, 'error');

    case SET_SNACKBAR_INFO:
      return getOpenFields(state, action.message, 'info');

    case SET_SNACKBAR_SUCCESS:
      return getOpenFields(state, action.message, 'success');

    case SET_SNACKBAR_WARNING:
      return getOpenFields(state, action.message, 'warning');

    case RESET_SNACKBAR:
      return { ...state, ...initialState };

    default:
      return state;
  }
}

export default snackbar;
