import * as Yup from 'yup';
import { fm, deepTrim } from 'utils/string';
import {
  getFormattedTaskCategoryDataForApiRequest,
  getFormattedTaskCreateApiReq,
} from 'appRedux/thunks/checklists/selector';

const taskCategorySchema = Yup.object().shape({
  title: Yup.string().required(fm('taskCategory.title.required'))
    .test('len', fm('taskCategoryTitleLengthValidationError'), (val) => val.toString().length <= 140),
});

const taskSchema = Yup.object().shape({
  title: Yup.string().required(fm('task.name.required'))
    .test('len', fm('taskTitleLengthValidationError'), (val) => val.toString().length <= 255),
});

export const validateAndGetTaskCategory = async (obj) => {
  await taskCategorySchema.validate(obj);
  return deepTrim(getFormattedTaskCategoryDataForApiRequest(obj));
};

export const validateAndGetTask = async (obj) => {
  await taskSchema.validate(obj);
  return deepTrim(getFormattedTaskCreateApiReq(obj));
};
