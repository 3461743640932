import { userPermissionTypeFieldNames, bookkeepingIntegrationTypes } from 'utils/enum';
import { orderBy, get } from 'utils/lodash';

const getFormattedEmployeePermission = (permissions) => permissions.reduce((res, permssion) => {
  res[userPermissionTypeFieldNames[permssion.permissionNumber]] = permssion.permissionStatus !== false;
  return res;
}, {});

export const getCompanyFields = (company) => ({
  ...company,
  breakTimeSettings: orderBy('breakLimit', 'desc')(company.breakTimeSettings),
  vismaIntegrated: get('integration.integrationType')(company) === bookkeepingIntegrationTypes.visma,
  fortnoxIntegrated: get('integration.integrationType')(company) === bookkeepingIntegrationTypes.fortnox,
  extendableLimit: company.extendableLimit?.toString(),
});

export const getFormattedProfile = ({ user, company, ...others }) => ({
  ...others,
  user: {
    ...user,
    permissions: user.userPermissions ? getFormattedEmployeePermission(user.userPermissions) : {},
  },
  company: getCompanyFields(company),
});
