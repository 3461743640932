import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  props: {
    MuiButtonBase: {
      /*
       * The properties to apply
       * No more ripple, on the whole application 💣!
       */
      disableRipple: true,
    },
  },
  typography: {
    // In Chinese and Japanese the characters are usually larger,
    // so a smaller fontsize may be appropriate.
    // fontSize: 13,
    body1: '0.875rem',
  },

  palette: {
    primary: {
      light: '#61c1ff',
      main: '#1891DC',
      dark: '#0064aa',
      contrastText: '#ffffff',
    },
    success: {
      light: '#81c784',
      main: '#4caf50',
      dark: '#388e3c',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    text: {
      primary: '#363D46',
      secondary: '#7E8791',
      white: '#FFFF',
      link: '#0057B7',
    },
    bigIndicator: {
      height: 5,
    },
  },
});

export default theme;
