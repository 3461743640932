import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { setCustomerSuggestions } from 'appRedux/actions';
import { Checkbox, Grid, ClickAwayListener, Paper, Box } from 'components/library';
import { fm } from 'utils/string';

const CustomerPaperComponent = ({ children, handleClosePopper, ...restProps }) => {
  const dispatch = useDispatch();
  const { showAdditionalInfo } = useSelector((state) => state.autocomplete.customer);

  const onClosePoppup = () => {
    handleClosePopper();
  };

  const onChangeCheckbox = useCallback((e) => {
    const { checked } = e.target;
    dispatch(setCustomerSuggestions({ showAdditionalInfo: checked }));
    localStorage.setItem('_showCustomerSuggestionsAdditionInfo', JSON.stringify(checked));
  }, []);

  return (
    <Paper {...restProps}>
      <ClickAwayListener mouseEvent="onMouseDown" onClickAway={onClosePoppup}>
        <div>
          <Grid container ml={1}>
            <Grid item={12}>
              <Box pl={2}>
                <Checkbox
                  name="showInfo"
                  id="show-info"
                  value={showAdditionalInfo}
                  checked={showAdditionalInfo}
                  onChange={onChangeCheckbox}
                  color="primary"
                  label={fm('show_info')}
                />
              </Box>
            </Grid>
          </Grid>
          {children}
        </div>
      </ClickAwayListener>
    </Paper>
  );
};

CustomerPaperComponent.propTypes = {
  children: PropTypes.arrayOf(PropTypes.shape({})),
  handleClosePopper: PropTypes.func,
};

export default CustomerPaperComponent;
