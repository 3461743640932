import { createAsyncThunk } from '@reduxjs/toolkit';
import { setSnackbarError } from 'appRedux/actions';
import { getFormattedProductModuleRequestBody, getFormattedProductModulesData } from './selector';

async function getModules (axios, query) {
  const params = { query };
  const response = await axios.get('/api/v3/product_modules/search', { params });
  return Array.isArray(response.data.data) ? response.data.data : [];
}

export const requestCreateProductModule = createAsyncThunk(
  'productModules/createModule',
  async (details, { extra, dispatch }) => {
    try {
      const { newModule, oldModules = [] } = details;
      const presentModules = await getModules(extra.axios, newModule.title);
      let data = presentModules.find((tag) => tag.title.toLowerCase() === newModule.title.toLowerCase());
      // if module with title not present create new
      if (!data) {
        const requestBody = getFormattedProductModuleRequestBody(details.newModule);
        const response = await extra.axios.post('/api/v3/product_modules', requestBody);
        data = response.data.data;
      }
      details.onChange(
        [...oldModules.filter((d) => d.title !== data.title),
          { ...data, productModuleId: data.id },
        ],
      );
    } catch (err) {
      dispatch(setSnackbarError('snackbar_product_module_create_failed'));
    }
  },
);

export const requestUpdateProductModule = createAsyncThunk(
  'productModules/updateModule',
  async (details, { extra, dispatch }) => {
    try {
      const requestBody = getFormattedProductModuleRequestBody(details);
      const response = await extra.axios.put(`/api/v3/product_modules/${details.productModuleId}`, requestBody);
      return response.data.data;
    } catch (err) {
      dispatch(setSnackbarError('snackbar_product_module_update_failed'));
    }
  },
);

export const requestProductModulesSuggestions = createAsyncThunk(
  'productModules/fetchRecords',
  async (query, { extra, dispatch }) => {
    try {
      const params = { query };
      const response = await extra.axios.get('/api/v3/product_modules/search', { params });
      const formattedData = Array.isArray(response.data.data)
        ? getFormattedProductModulesData(response.data.data) : [];
      return { records: formattedData };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_product_modules_error'));
    }
  },
);
