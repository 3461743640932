import { getFormattedListData } from 'appRedux/utility';

export const getFormattedCompanyInvoicesHistoryData = (response) => getFormattedListData(response);

export const getFormattedInvoicesReqParams = (filters) => ({
  page: filters.page || 1,
  per_page: filters.perPage || 10,
  status: filters.invoiceStatus === 'view_all' ? undefined : filters.invoiceStatus,
});

export const getFormattedInvoiceAddressRequestBody = (details) => ({
  invoice_detail: {
    address: details.address,
    address2: details.address2,
    area: details.area,
    zip_code: details.zipCode,
    email: details.email,
    invoice_email: details.invoiceEmail,
    invoice_delivery: Number(details.invoiceDelivery),
  },
});
