import { SET_CHECKLIST_FIELDS, SET_TASKS_FIELDS } from '../actions';

const initialState = {
  records: [],
};

function checklists (state = initialState, action) {
  switch (action.type) {
    case SET_CHECKLIST_FIELDS:
      return {
        ...state,
        ...action.payload,
      };
    case SET_TASKS_FIELDS:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          ...action.payload,
        },
      };
    default:
      return state;
  }
}

export default checklists;
