import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import MuiPaper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  root: (props) => ({
    padding: props.padding,
    backgroundColor: props.bgColor,
    marginTop: props.marginTop,
    minWidth: props.minWidth,
    borderRadius: '8px !important',
  }),
});

const Paper = forwardRef((props, ref) => {
  const classNames = useStyles(props);
  const {
    padding = '8px !important',
    ...restProps
  } = props;

  return (
    <MuiPaper
      classes={classNames}
      padding={padding}
      {...restProps}
      ref={ref}
    >
      {props.children}
    </MuiPaper>
  );
});

Paper.propTypes = {
  children: PropTypes.node,
  elevation: PropTypes.number,
  square: PropTypes.bool,
  onClick: PropTypes.func,
  onMouseOver: PropTypes.func,
  ariaLabel: PropTypes.string,
  color: PropTypes.string,
  padding: PropTypes.string,
  backgroundColor: PropTypes.string,
};

export default Paper;