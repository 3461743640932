import {
  SET_PROFILE,
  SET_FORTNOX_ACCOUNTS_FIELDS,
  SET_BREAK_TIME_SETTING_FIELDS,
  RESET_AUTH_FIELDS,
  SET_COMPANY_BILLING_INFO_FIELDS,
  SET_CONSTITUTION_FIELDS,
  SET_PROFILE_STORAGE_FIELDS,
  SET_ORGANIZATION_SETTING_FIELDS,
  SET_USER_FEEDBACK_FIELDS,
} from '../actions';
import { getLanguage } from '../../utils/language';

const initialState = {
  activeTab: 0,
  language: getLanguage(),
  user: {},
  company: {},
  contract: {},
  userFeedbackFields: {
    comment: {},
    rating: {},
    showFeedbackDialog: false,
  },
  fortnoxAccounts: {
    records: [],
  },
  breakTimeSettings: {},
  permissionRecords: {},
  companyPermissionRecords: [],
  constitution: {
    records: {},
  },
  billing: {
    overdueUnpaidInvoiceExist: false,
    invoicesHistory: {
      records: [],
    },
  },
  storage: {
    stats: {
      remainingSize: 0,
      usedSize: 0,
      totalSize: 0,
    },
    showStorageDialog: false,
    records: [],
  },
  organizationSetting: {
    showDialog: false,
    records: {},
  },
};

function profile (state = initialState, action) {
  switch (action.type) {
    case SET_PROFILE:
      return {
        ...state,
        ...action.payload,
      };

    case SET_FORTNOX_ACCOUNTS_FIELDS:
      return {
        ...state,
        fortnoxAccounts: {
          ...state.fortnoxAccounts,
          ...action.payload,
        },
      };

    case SET_BREAK_TIME_SETTING_FIELDS:
      return {
        ...state,
        breakTimeSettings: {
          ...state.breakTimeSettings,
          ...action.payload,
        },
      };

    case SET_COMPANY_BILLING_INFO_FIELDS:
      return {
        ...state,
        billing: {
          ...state.billing,
          ...action.payload,
        },
      };

    case SET_CONSTITUTION_FIELDS:
      return {
        ...state,
        constitution: {
          ...state.constitution,
          ...action.payload,
        },
      };

    case SET_PROFILE_STORAGE_FIELDS:
      return {
        ...state,
        storage: {
          ...state.storage,
          ...action.payload,
        },
      };

    case SET_ORGANIZATION_SETTING_FIELDS: {
      return {
        ...state,
        organizationSetting: {
          ...state.organizationSetting,
          ...action.payload,
        },
      };
    }

    case SET_USER_FEEDBACK_FIELDS: {
      return {
        ...state,
        userFeedbackFields: {
          ...state.userFeedbackFields,
          ...action.payload,
        },
      };
    }

    case RESET_AUTH_FIELDS: {
      return initialState;
    }

    default:
      return state;
  }
}

export default profile;
