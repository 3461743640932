import { SET_INVOICES_FIELDS, SET_INVOICES_HISTORY_FIELDS } from '../actions';

const initialState = {
  activeTab: 0,
  invoiceHistory: {},
};

function invoices (state = initialState, action) {
  switch (action.type) {
    case SET_INVOICES_FIELDS:
      return {
        ...state,
        ...action.payload,
      };
    case SET_INVOICES_HISTORY_FIELDS:
      return {
        ...state,
        invoiceHistory: {
          ...state.invoiceHistory,
          ...action.payload,
        },
      };
    default:
      return state;
  }
}

export default invoices;
