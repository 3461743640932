import FileSaver from 'file-saver';
import get from 'lodash/fp/get';

export const fileTypes = {
  pdf: 1,
};

export const getPages = (state, payload) => {
  if (!payload.totalRecords) return state.pages;

  return Math.ceil(payload.totalRecords / payload.perPage);
};

export const saveFile = (response) => new Promise((resolve) => {
  fetch(`data:image/png;base64,${response.data.data.pdf_file.content}`)
    .then((res) => res.blob())
    .then((blob) => {
      FileSaver.saveAs(blob, response.data.data.pdf_file.name);
      resolve();
    });
});

const multiStateGetInitialState = { page: 1, perPage: 10 };

export const multiStateGet = (name, state, prop) => {
  if (prop) return get(`names.${name}.${prop}`)(state);
  return get(`names.${name}`)(state) || multiStateGetInitialState;
};

export const multiStateSet = (name, state, obj) => {
  const newState = {
    ...state,
    names: {
      ...state.names,
      [name]: {
        ...state.names[name],
      },
    },
  };
  newState.names[name] = Object.assign(newState.names[name], obj);
  return newState;
};
