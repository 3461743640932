import axios from 'utils/axios';
import { multiStateGet } from 'appRedux/reducers/utility';
import { getFormattedListData } from 'appRedux/utility';
import {
  setFilters,
  setSnackbarError,
  setSuperAdminBookingAutoExtendErrorFields,
  setSuperAdminErrors,
} from 'appRedux/actions';

export const requestErrors = (name) => async (dispatch, getState) => {
  const { filters } = getState();
  try {
    const pagination = multiStateGet(name, filters);
    const params = { page: pagination.page, per_page: pagination.perPage };
    const { data } = await axios.get('/api/v1/super_admin/integration_errors', { params });
    const { records, ...rest } = getFormattedListData(data.data.invoices);
    dispatch(setSuperAdminErrors({ records }));
    dispatch(setFilters({ ...rest, name }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar.superAdmin.gettingErrors.error'));
  }
};

export const requestBookingAutoExtendErrors = (name) => async (dispatch, getState) => {
  const { filters } = getState();
  try {
    const pagination = multiStateGet(name, filters);
    const params = { page: pagination.page, per_page: pagination.perPage };
    const { data } = await axios.get('/api/v3/super_admin/booking_extend_errors', { params });
    const { records, ...rest } = getFormattedListData(data.data);
    dispatch(setSuperAdminBookingAutoExtendErrorFields({ records }));
    dispatch(setFilters({ ...rest, name }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar.superAdmin.gettingErrors.error'));
  }
};
