import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { AppBar, Toolbar, Grid, Container } from 'components/library';
import MessageDrawer from 'components/MessageDrawer';
import AppBarStyles from 'components/styles/navbar.module.scss';
import urls from 'utils/urls';
import axios from 'axios';
import { getFormattedMessagesForCustomer } from '../selector';
import Image from './CompanyImage';

const Header = (props) => {
  const { company, proposal } = props;
  const { companyDetails } = company;
  const appBarClasses = { colorPrimary: AppBarStyles.Appbar };
  const [messages, setMessages] = useState([]);
  const [unreadMessages, setUnreadMessages] = useState([]);
  const url = window.location.href;
  const proposalToken = url.split('/').pop();

  const customAxios = axios.create({
    baseURL: urls.getApiBaseUrl(),
    headers: {
      Authorization: proposalToken,
      'Content-Type': 'application/json',
    },
  });

  const requestGetMessagesForCustomer = async () => {
    try {
      const response = await customAxios.get('/api/v3/proposal_messages/get_messages');
      const formattedCustomerMessages = getFormattedMessagesForCustomer(response.data.data);
      setMessages(formattedCustomerMessages);
    } catch (err) {
      console.error(err);
    }
  };

  const requestPostMessageForCustomer = async (message) => {
    const data = {
      proposal_message: {
        content: message,
      },
    };
    try {
      await customAxios.post('/api/v3/proposal_messages/post_message', data);
      await requestGetMessagesForCustomer();
    } catch (err) {
      console.error(err);
    }
  };

  const requestMarkReadMessageForCustomer = async (messageId) => {
    try {
      await customAxios.patch(`/api/v3/proposal_messages/${messageId}/message_read`);
      await requestGetMessagesForCustomer();
    } catch (err) {
      console.error(err);
    }
  }

  const requestDeleteMessage = async (messageId) => {
    try {
      await customAxios.delete(`/api/v3/proposal_messages/${messageId}/delete_message`);
      await requestGetMessagesForCustomer();
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    requestGetMessagesForCustomer();
  }, []);

  useEffect(() => {
    setUnreadMessages(messages?.filter((message) => message.isReadByCustomer === false));
  }, [messages]);

  const onMarkReadMessages = () => {
    for (const unreadMessage of unreadMessages) {
      requestMarkReadMessageForCustomer(unreadMessage.id);
    }
  }

  return (
    <>
      <AppBar id="main-app-bar" position="relative" elevation={0} classes={appBarClasses}>
        <Toolbar variant="dense" disableGutters>
          <Container maxWidth="lg">
            <Grid container justifyContent="space-between" alignItems="center" direction="row">
              <Grid item>
                <Image companyDetails={companyDetails} />
              </Grid>
              <Grid item />
              <Grid item>
                <MessageDrawer
                  proposal={proposal}
                  company={companyDetails}
                  messages={messages}
                  unreadMessagesCount={unreadMessages.length}
                  onPostMessage={requestPostMessageForCustomer}
                  onMarkReadMessages={onMarkReadMessages}
                  onDeleteMessage={requestDeleteMessage}
                  messageForAdmin={false}
                />
              </Grid>
            </Grid>
          </Container>
        </Toolbar>
      </AppBar>
    </>
  );
};

Header.propTypes = {
  company: PropTypes.shape({
    companyDetails: PropTypes.shape({
      logoUrl: PropTypes.string,
    }),
  }),
  proposal: PropTypes.shape({}),
};

export default Header;
