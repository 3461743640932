import { createSlice } from '@reduxjs/toolkit';
import { requestSuperAdminFailedBookings, requestSuperAdminInProgressBookings } from './thunk';

const area = createSlice({
  name: 'bookingIssues',
  initialState: {
    inProgressBookings: {
      records: [],
      record: {},
      refreshAllBookingInProgress: {},
    },
    failedBookings: {
      records: [],
      record: {},
      refreshAllBookingInProgress: {},
    },
  },
  reducers: {
    setSuperAdminInProgressBookingsFields (state, action) {
      return {
        ...state,
        inProgressBookings: {
          ...state.bookingsInProgress,
          ...action.payload,
        },
      };
    },
    setSuperAdminFailedBookingsFields (state, action) {
      return {
        ...state,
        failedBookings: {
          ...state.bookingsFailed,
          ...action.payload,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(requestSuperAdminInProgressBookings.fulfilled, (state, action) => ({
      ...state,
      inProgressBookings: {
        ...state.inProgressBookings,
        records: action.payload.records,
      },
    }));
    builder.addCase(requestSuperAdminFailedBookings.fulfilled, (state, action) => ({
      ...state,
      failedBookings: {
        ...state.failedBookings,
        records: action.payload.records,
      },
    }));
  },
});

const { reducer, actions } = area;

export const { setSuperAdminInProgressBookingsFields, setSuperAdminFailedBookingsFields } = actions;

export default reducer;
