import { formatDate, formatStrings } from 'utils/dateTime';
import { getFormattedPaginationFields } from 'appRedux/utility';

export const getFormattedProfileDocuments = (response) => ({
  ...getFormattedPaginationFields(response),
  records: (response.records || []).map((data) => ({
    ...data,
    name: data.filename.substring(0, data.filename.lastIndexOf('.')),
    type: data.filename.substring(data.filename.indexOf('.') + 1),
    dateTime: formatDate(data.createdAt, formatStrings.dashDateDashTime),
  })),
});
