import { createAsyncThunk } from '@reduxjs/toolkit';
import FileSaver from 'file-saver';
import { setSnackbarError } from 'appRedux/actions';
import snakify from 'utils/snakify';
import { getFormattedOrderData, getFormattedVirtualOrderData } from './selector';

export const requestExportOrdersPdf = createAsyncThunk(
  'order/pdfDownload',
  async (data, { extra, dispatch }) => {
    try {
      const params = {
        orders: data.orders,
        virtual_orders: data.virtualOrders || [],
        fields_to_include: snakify(data.fieldsToInclude),
      };
      const response = await extra.axios.get('/api/v3/orders/download_multiple_pdf', { params });
      fetch(`data:application/pdf;base64,${response.data.data.pdfFile.content}`)
        .then((res) => res.blob())
        .then((blob) => FileSaver.saveAs(blob, response.data.data.pdfFile.name));
    } catch (err) {
      dispatch(setSnackbarError('snackbar_orders_pdf_error'));
    }
  },
);

export const requestPdfOrder = createAsyncThunk(
  'order/fetchPdfOrder',
  async ({ orderId, companyId }, { extra, rejectWithValue, dispatch, getState }) => {
    try {
      const headers = extra.getHeadersForSubCompany(companyId, getState);
      const response = await extra.axios.get(`/api/v3/orders/${orderId}`, { headers });
      const { order } = response.data.data;
      return {
        order: getFormattedOrderData(order),
        orderDocuments: { records: order.documents || [] },
      };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_order_get_error'));
      return rejectWithValue(err);
    }
  },
);

export const requestPdfVirtualOrder = createAsyncThunk(
  'order/fetchPdfVirtualOrder',
  async (data, { extra, dispatch, getState }) => {
    try {
      const headers = extra.getHeadersForSubCompany(data.companyId, getState);
      const response = await extra.axios.get(`/api/v3/projects/${data.projectId}`, { headers });
      const { project, projectChecklists, documents } = response.data.data;
      return {
        order: getFormattedVirtualOrderData({ ...project, projectChecklists, date: data.date }),
        orderDocuments: { records: documents },
      };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_order_get_error'));
    }
  },
);
