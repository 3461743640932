import { createSlice } from '@reduxjs/toolkit';
import { groupBy } from 'utils/lodash';
import {
  requestPushNotificationSettings,
  requestUpdatePushNotificationSetting,
} from './thunk';
import initialPushNotificationData from './pushNotificationInitialData.json';

const pushNotification = createSlice({
  name: 'pushNotification',
  initialState: {
    selectedSettingsIndex: null,
    ...initialPushNotificationData,
  },
  reducers: {
    setPushNotificationFields (state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(requestPushNotificationSettings.fulfilled, (state, action) => {
      const updatedOrderSettingsMap = groupBy('subModule')(action.payload.orders);
      const updatedMessageSettingsMap = groupBy('subModule')(action.payload.message);
      const updatedEmployeeRequestSettingsMap = groupBy('subModule')(action.payload.employeeRequest);
      const updatedInternalTimeSettingsMap = groupBy('subModule')(action.payload.internalTime);

      return {
        ...state,
        ...(action.payload.orders.length > 0 && {
          orders: state.orders.map((setting) => {
            const updatedSetting = updatedOrderSettingsMap[setting.subModule];
            return updatedSetting ? { ...setting, ...updatedSetting[0] } : setting;
          }),
        }),
        ...(action.payload.message.length > 0 && {
          message: state.message.map((setting) => {
            const updatedSetting = updatedMessageSettingsMap[setting.subModule];
            return updatedSetting ? { ...setting, ...updatedSetting[0] } : setting;
          }),
        }),
        ...(action.payload.employeeRequest.length > 0 && {
          employeeRequest: state.employeeRequest.map((setting) => {
            const updatedSetting = updatedEmployeeRequestSettingsMap[setting.subModule];
            return updatedSetting ? { ...setting, ...updatedSetting[0] } : setting;
          }),
        }),
        ...(action.payload.internalTime.length > 0 && {
          internalTime: state.internalTime.map((setting) => {
            const updatedSetting = updatedInternalTimeSettingsMap[setting.subModule];
            return updatedSetting ? { ...setting, ...updatedSetting[0] } : setting;
          }),
        }),
      };
    });
    builder.addCase(requestUpdatePushNotificationSetting.fulfilled, (state, action) => {
      const createdSettings = action.payload;
      switch (createdSettings.module) {
        case 'orders':
          return {
            ...state,
            orders: state.orders.map(
              (setting, index) => (index === state.selectedSettingsIndex
                ? { ...setting, ...createdSettings }
                : setting
              ),
            ),
          };
        case 'message':
          return {
            ...state,
            message: state.message.map(
              (setting, index) => (index === state.selectedSettingsIndex
                ? { ...setting, ...createdSettings }
                : setting
              ),
            ),
          };
        case 'employee_request':
          return {
            ...state,
            employeeRequest: state.employeeRequest.map(
              (setting, index) => (index === state.selectedSettingsIndex
                ? { ...setting, ...createdSettings }
                : setting
              ),
            ),
          };
        case 'internal_time':
          return {
            ...state,
            internalTime: state.internalTime.map(
              (setting, index) => (index === state.selectedSettingsIndex
                ? { ...setting, ...createdSettings }
                : setting
              ),
            ),
          };
        default:
          return {
            ...state,
          };
      }
    });
  },
});

const { reducer, actions } = pushNotification;

export const { setPushNotificationFields } = actions;

export default reducer;
