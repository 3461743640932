import axios from 'utils/Api';
import axiosNext from 'utils/axios';
import camelize from 'camelize';
import { batch } from 'react-redux';
import { get, has } from 'utils/lodash';
import { multiStateGet } from 'appRedux/reducers/utility';
import { validatedAndGetFormattedSmsTemplate } from 'appRedux/thunks/sms/validator';
import { requestBooking } from 'appRedux/thunks/bookings';
import {
  setSnackbarError,
  setSmsHistoryFields,
  setFilters,
  setSmsScheduleFields,
  setBookingsFields,
  setSnackbarSuccess,
  setSmsTemplateFields,
  setBookingSmsHistoryFields,
} from 'appRedux/actions';
import {
  getFormattedSmsHistoryListData,
  getFormattedSmsHistoryParams,
  getFormattedSmsScheduleListData,
  getFormattedSmsScheduleParams,
  getFormattedSmsTemplate,
  getFormattedUpdatedTemplateData,
  getFormattedTemplateRequestBody,
  getSMSBodyForAPIRequest,
} from './selector';

export const requestSendSms = (data) => async (dispatch) => {
  try {
    const { bookingId: bookingSequenceNum, smsData, future, all } = data;
    const requestBody = getSMSBodyForAPIRequest({ ...smsData, future, all });
    await axios.post(`/api/v3/bookings/${bookingSequenceNum}/send_message`, requestBody);

    batch(() => {
      if (has('scheduled')(smsData) || has('smsOnComplete')(smsData)) {
        dispatch(setBookingsFields({
          refreshAllBookings: {},
          showSMSModal: false,
          showConfirmationModal: false,
        }));
      }
      dispatch(requestBooking(data.bookingId));
      dispatch(setSnackbarSuccess('snackbar.booking.sms.sent'));
    });
  } catch (err) {
    dispatch(setSnackbarError('snackbar.booking.sms.error'));
  }
};

export const requestFilteredSmsHistory = (name) => async (dispatch, getState) => {
  const { filters } = getState();
  try {
    const params = getFormattedSmsHistoryParams(multiStateGet(name, filters));
    const response = await axiosNext.get('/api/v3//scheduled_messages/message_histories', { params });
    const result = get('data.data')(response) || { records: [] };
    const { records, ...rest } = getFormattedSmsHistoryListData(result);
    dispatch(setSmsHistoryFields({ records }));
    dispatch(setFilters({ ...rest, name }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar_reports_get_sms_history_error'));
  }
};

export const requestFilteredSmsSchedule = (name) => async (dispatch, getState) => {
  const { filters } = getState();
  try {
    const params = getFormattedSmsScheduleParams(multiStateGet(name, filters));
    const response = await axiosNext.get('/api/v3/scheduled_messages', { params });
    const result = get('data.data')(response) || { records: [] };
    const { records, ...rest } = getFormattedSmsScheduleListData(result);
    dispatch(setSmsScheduleFields({ records }));
    dispatch(setFilters({ ...rest, name }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar_reports_get_sms_schedule_error'));
  }
};

export const removeSmsSchedule = ({ smsData, all, future, bookingId }) => async (dispatch, getState) => {
  const { bookings: { booking } } = getState();
  try {
    const params = { all_bookings: all, future_bookings: future, booking_sequence_num: bookingId };
    await axios.delete(`/api/v3/scheduled_messages/${smsData.smsScheduleId}`, { params });

    const updatedBooking = {
      ...booking,
      smsSchedule: undefined,
      smsScheduled: false,
      isScheduled: false,
      scheduledMessage: undefined,
    };

    batch(() => {
      dispatch(setBookingsFields({ refreshAllBookings: {}, booking: updatedBooking, showConfirmationModal: false }));
      dispatch(setSnackbarSuccess('snackbar.booking.smsReminder.removed'));
    });
  } catch (err) {
    dispatch(setSnackbarError('snackbar.booking.smsReminder.remove.error'));
  }
};

export const requestSmsTemplates = () => async (dispatch) => {
  try {
    const response = await axios.get('/api/v3/sms_templates');
    const result = get('data.data.sms_templates')(response) || { records: [] };
    const templatesData = getFormattedSmsTemplate(result);
    dispatch(setSmsTemplateFields(templatesData));
  } catch (err) {
    dispatch(setSnackbarError('snackbar_reports_get_sms_templates'));
  }
};

export const requstUpdateDefaultActiveTemplate = (templateId) => async (dispatch, getState) => {
  const { sms: { templates } } = getState();
  try {
    await axios.put(`/api/v3/sms_templates/${templateId}/mark_default`);
    const updatedDefaultTemplateData = getFormattedUpdatedTemplateData(templateId, templates);
    if (templates.showSmsTemplateModal) {
      updatedDefaultTemplateData.selectedTemplate = {
        ...templates.selectedTemplate,
        default: true,
      };
    }
    dispatch(setSmsTemplateFields(updatedDefaultTemplateData));
  } catch (err) {
    dispatch(setSnackbarError('snackbar_sms_template_set_default_error'));
  }
};

export const requestUpdateSmsTemplate = (template) => async (dispatch) => {
  try {
    dispatch(setSmsTemplateFields({ fsip: true }));
    const requestBody = await validatedAndGetFormattedSmsTemplate(template);
    await axios.put(`/api/v3/sms_templates/${template.id}`, requestBody);
    dispatch(setSnackbarSuccess('snackbar.booking.smstemplate.updated'));
    return dispatch(setSmsTemplateFields({ showSmsTemplateModal: false, smsTemplateErrors: {}, fsip: false }));
  } catch (err) {
    dispatch(setSmsTemplateFields({ fsip: false }));
    if (err.name === 'ValidationError') {
      const smsTemplateErrors = { [template.templateType]: err.errors[0] };
      return dispatch(setSmsTemplateFields({ smsTemplateErrors }));
    }
    return dispatch(setSnackbarError('snackbar_sms_template_update_failed'));
  }
};

export const requestCreateSmsTemplate = (template) => async (dispatch) => {
  try {
    dispatch(setSmsTemplateFields({ fsip: true }));
    const requestBody = getFormattedTemplateRequestBody(template);
    await axios.post('/api/v3/sms_templates', requestBody);
    dispatch(setSnackbarSuccess('snackbar_smstemplate_created'));
    dispatch(setSmsTemplateFields({ showSmsTemplateModal: false, refreshAllSmsTemplates: {}, fsip: false }));
  } catch (err) {
    dispatch(setSmsTemplateFields({ fsip: false }));
    return dispatch(setSnackbarError('snackbar_sms_template_create_failed'));
  }
};

export const requestBookingSmsHistory = ({ sequenceNum }) => async (dispatch) => {
  try {
    const response = await axios.get(`/api/v3/bookings/${sequenceNum}/customer_message_histories`);
    const records = camelize(response.data.data);
    dispatch(setBookingSmsHistoryFields({ records, bookingSequenceNum: sequenceNum }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar_sms_history_failed'));
  }
};

export const requestUpdateSmsStatus = (historyId) => async (dispatch, getState) => {
  const { sms: { history } } = getState();
  try {
    const params = { history_id: historyId };
    const response = await axiosNext.get('/api/v3/scheduled_messages/message_status', { params });
    const newRecords = history.records.map(
      (record) => (record.id === historyId ? response.data.data : record),
    );
    dispatch(setSmsHistoryFields({ records: newRecords }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar_sms_history_failed'));
  }
};

export const requestGetSmsStatusForProposal = () => async (dispatch) => {
  try {
    const response = await axiosNext.get('/api/v3/company/proposal_sms_status');
    dispatch(setSmsTemplateFields(response.data.data));
  } catch (err) {
    dispatch(setSnackbarError('snackbar_get_sms_status_failed'));
  }
};

export const requestActivateSmsForProposal = () => async (dispatch) => {
  try {
    await axiosNext.put('/api/v1/company/activate_proposal_sms');
    dispatch(setSnackbarSuccess('snackbar_proposal_sms_activated'));
    dispatch(requestGetSmsStatusForProposal());
  } catch (err) {
    dispatch(setSnackbarError('snackbar_sms_activation_failed'));
  }
};

export const requestDeactivateSmsForProposal = () => async (dispatch) => {
  try {
    await axiosNext.put('/api/v1/company/deactivate_proposal_sms');
    dispatch(setSnackbarSuccess('snackbar_proposal_sms_deactivated'));
    dispatch(requestGetSmsStatusForProposal());
  } catch (err) {
    dispatch(setSnackbarError('snackbar_sms_deactivation_failed'));
  }
};
