import React from 'react';
import PropTypes from 'prop-types';
import parseHtml from 'html-react-parser';
import { Grid, Container, Typography, Box } from 'components/library';
import { fm } from 'utils/string';
import formatting from 'utils/formatting';
import getItemsTotalCosts from 'components/Booking/BookingModal/PriceTab/getTotalCosts';
import { proposalPolicyTypes } from 'utils/enum';
import Attachments from './Attachments';
import ProposalFooter from './Footer';
import PriceSection from './PriceSection';

const ProposalDetails = (props) => {
  const { proposal, company, documents } = props;
  const { companyDetails = {}, companyPolicies = [] } = company;
  const { proposalItems = [] } = proposal;
  const introText = companyPolicies.find((policy) => policy.rule === proposalPolicyTypes.introText);
  const cancellationPolicy = companyPolicies.find((policy) => policy.rule === proposalPolicyTypes.cancellationPolicy);

  const proposalPriceAttributes = getItemsTotalCosts(
    proposalItems.map((item) => ({ ...item, isHouseWork: item?.isHousework })),
    proposal,
  );

  return (
    <>
      <Container maxWidth="lg">
        <Box mt={2} mb={8}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography>
                {fm('customer_proposal')}
              </Typography>
              <Typography>
                {fm('proposal_expiry_date')}: {proposal.expiryDate}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h3">
                {proposal.proposalService.title}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                <Box component="span" fontWeight={600}>
                  {fm('created_for')}
                </Box>
              </Typography>
              <Typography>
                {proposal.customerName}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography>
                <Box component="span" fontWeight={600}>
                  {fm('created_by')}
                </Box>
              </Typography>
              <Typography>
                {proposal.companyOwner}
              </Typography>
              <Typography>
                {companyDetails.name}
              </Typography>
              <Typography>
                {companyDetails.mobile}
              </Typography>
              <Typography>
                {companyDetails.email}
              </Typography>
            </Grid>

            {/* Intro text */}
            <Grid item xs={12}>
              <Typography>
                {fm('hello')} {proposal.customerName.split(' ')[0]},
              </Typography><br />
              <Typography>
                {introText?.description}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography>
                <Box component="span" fontWeight={600}>
                  {fm('proposal_date_time')}
                </Box>
              </Typography>
              <Typography>
                {proposal.date} | {proposal.startTime}
                - {proposal.endTime}
              </Typography>
            </Grid>
            {/* Recurring rules */}

            {proposal.recurring && (
            <Grid item xs={12}>
              <Typography>
                <Box component="span" fontWeight={600}>
                  {fm('proposal_recurring')}
                </Box>
              </Typography>
              <Typography>
                {fm('proposal_month_week')}: {fm(proposal.repeatType)}
              </Typography>
              <Typography>
                {fm('proposal_repeat_interval')}: {proposal.repeatInterval}
              </Typography>
              <Typography>
                {fm('proposal_repeat')}: {fm(proposal.repeatDays)}
              </Typography>
            </Grid>
            )}

            <Grid item xs={12}>
              <Typography>
                <Box component="span" fontWeight={600}>
                  {fm('proposal_price')}
                </Box>
              </Typography>
              <PriceSection
                proposalItems={proposalItems}
                companyDetails={companyDetails}
                proposal={proposal}
              />
            </Grid>

            <Grid item xs={12}>
              <Box textAlign="end">
                <Typography>
                  {fm('house_reduction')}: {' '} {formatting.currency(proposalPriceAttributes.taxReductionPrice, 2, 0)}
                </Typography>
                <Typography>
                  {fm('vat')}: {' '} {formatting.currency(proposalPriceAttributes.vat, 2, 0)}
                </Typography>
                <Typography>
                  {fm('total')}: {' '} {formatting.currency(proposalPriceAttributes.total, 2, 0)}
                </Typography>
                {proposalPriceAttributes.discount > 0 && (
                <Typography>
                  {fm('discount')}: {' '} {formatting.currency(proposalPriceAttributes.discount, 2, 0)}
                </Typography>
                )}
                <Typography>
                  <Box component="span" fontWeight={600}>
                    {fm('to_pay')}: {' '} {formatting.currency(proposalPriceAttributes.toPay, 2, 0)}
                  </Box>
                </Typography>
              </Box>
            </Grid>

            {/* Notes */}
            <Grid item xs={12}>
              <Typography className="text-base">
                {proposal.notes ? parseHtml(proposal.notes) : ''}
              </Typography>
            </Grid>

            {/* Cancellation policy */}
            <Grid item xs={12}>
              <Typography>
                <Box component="span" fontWeight={600}>
                  {fm('cancellation_policy')}
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                {cancellationPolicy.description}
              </Typography>
            </Grid>

            {/* Documents */}
            <Grid item xs={12}>
              <Attachments documents={documents} />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box mt={4} />
            <Grid container justifyContent="center">
              <Typography variant="caption" color="gray">
                {fm('powered_by')}: Bokning & Schema Online
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <ProposalFooter
        requestUpdateProposalFromCustomer={props.requestUpdateProposalFromCustomer}
        proposal={proposal}
        company={company}
      />
    </>
  );
};

ProposalDetails.propTypes = {
  proposal: PropTypes.shape({
    proposalService: PropTypes.shape({
      title: PropTypes.string,
    }),
    proposalItems: PropTypes.arrayOf(PropTypes.shape({})),
    recurring: PropTypes.bool,
    repeatType: PropTypes.string,
    repeatDays: PropTypes.string,
    repeatInterval: PropTypes.string,
    startTime: PropTypes.number,
    endTime: PropTypes.number,
    date: PropTypes.string,
    customerName: PropTypes.string,
    expiryDate: PropTypes.string,
    companyOwner: PropTypes.string,
    notes: PropTypes.string,
  }),
  requestUpdateProposalFromCustomer: PropTypes.func.isRequired,
  documents: PropTypes.arrayOf(PropTypes.shape({})),
  company: PropTypes.shape({
    companyPolicies: PropTypes.arrayOf(PropTypes.shape({})),
    companyDetails: PropTypes.shape({
      logoUrl: PropTypes.string,
    }),
  }),
};

export default ProposalDetails;
