const { isArray } = Array;
const { keys: objectKeys } = Object;

const camelToSnakeCase = (str) => str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

const isDate = (obj) => Object.prototype.toString.call(obj) === '[object Date]';
const isRegex = (obj) => Object.prototype.toString.call(obj) === '[object RegExp]';

function map (xs, f) {
  if (xs.map) return xs.map(f);
  const res = [];
  for (let i = 0; i < xs.length; i++) {
    res.push(f(xs[i], i));
  }
  return res;
}

function reduce (xs, f, acc) {
  if (xs.reduce) return xs.reduce(f, acc);
  for (let i = 0; i < xs.length; i++) {
    acc = f(acc, xs[i], i);
  }
  return acc;
}

function walk (obj) {
  if (!obj || typeof obj !== 'object') return obj;
  if (isDate(obj) || isRegex(obj)) return obj;
  if (isArray(obj)) return map(obj, walk);
  return reduce(objectKeys(obj), (acc, key) => {
    const snake = camelToSnakeCase(key);
    acc[snake] = walk(obj[key]);
    return acc;
  }, {});
}

module.exports = (obj) => {
  if (typeof obj === 'string') return camelToSnakeCase(obj);
  return walk(obj);
};
