import { styled } from '@mui/material';
import MuiSlider from '@mui/material/Slider';
import { bool, func, number } from 'prop-types';
import React from 'react';
import { getUnixTimeInHoursMinutes } from 'utils/dateTime';

const SliderContainer = styled('div')({ width: '100%' });

const Slider = (props) => {
  const { onChange, value, min = 0, max = 12, step = 1800 } = props;

  return (
    <SliderContainer>
      <MuiSlider
        disabled={props.disabled}
        value={value}
        min={min}
        step={step}
        max={max}
        onChange={onChange}
        aria-labelledby="continuous-slider"
        marks={[
          { value: max, label: `${getUnixTimeInHoursMinutes(max)} h` },
          { value, label: `${value === min || value === max ? null : getUnixTimeInHoursMinutes(value)} h` },
        ]}
      />
    </SliderContainer>
  );
};

Slider.propTypes = {
  onChange: func,
  value: number,
  min: number,
  max: number,
  step: number,
  disabled: bool,
};

export default Slider;
