import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  setSnackbarError,
  setSnackbarSuccess,
  setFilters,
} from 'appRedux/actions';
import { getFormattedListData } from 'appRedux/utility';
import {
  getFormattedOvertimeData,
  getFormattedOvertimeSettingRequestBody,
  getFormattedOvertimeListResponse,
} from './selector';

export const requestOvertimeSettings = createAsyncThunk(
  'overtime/fetchOvertimeSettings',
  async (name, { extra, dispatch, getState }) => {
    try {
      const { filters } = extra.getState(name, getState);
      const params = { page: filters.page, per_page: filters.perPage };
      const response = await extra.axios.get('/api/v3/overtime_settings', { params });
      const { records, ...nextFilters } = getFormattedOvertimeListResponse(response.data.data);
      dispatch(setFilters({ ...nextFilters, name }));
      return { records };
    } catch (err) {
      dispatch(setSnackbarError('snackbar.overtime.gettingItems.error'));
    }
  },
);

export const requestOvertimeSetting = createAsyncThunk(
  'overtime/fetchOvertimeSetting',
  async (id, { extra, dispatch }) => {
    try {
      const response = await extra.axios.get(`/api/v3/overtime_settings/${id}`);
      return { overtime: getFormattedOvertimeData(response.data.data) };
    } catch (err) {
      dispatch(setSnackbarError('snackbar.overtime.gettingItems.error'));
    }
  },
);

export const requestCreateOvertimeSetting = createAsyncThunk(
  'overtime/createOvertimeSetting',
  async (data, { extra, rejectWithValue, dispatch }) => {
    try {
      const body = getFormattedOvertimeSettingRequestBody(data);
      const response = await extra.axios.post('/api/v3/overtime_settings', body);
      dispatch(setSnackbarSuccess('snackbar_overtime_created'));
      return response.data.data;
    } catch (err) {
      dispatch(setSnackbarError('snackbar_overtime_create_error'));
      return rejectWithValue(err);
    }
  },
);

export const requestUpdateOvertimeSetting = createAsyncThunk(
  'overtime/updateOvertimeSetting',
  async (data, { extra, rejectWithValue, dispatch }) => {
    try {
      const body = getFormattedOvertimeSettingRequestBody(data);
      await extra.axios.put(`/api/v3/overtime_settings/${data.id}`, body);
      dispatch(setSnackbarSuccess('snackbar_overtime_updated'));
    } catch (err) {
      dispatch(setSnackbarError('snackbar_overtime_update_error'));
      return rejectWithValue(err);
    }
  },
);

export const requestDeleteOvertimeSettings = createAsyncThunk(
  'overtime/deleteOvertimeSetting',
  async (sequenceNum, { extra, dispatch }) => {
    try {
      await extra.axios.delete(`/api/v3/overtime_settings/${sequenceNum}`);
      dispatch(setSnackbarSuccess('snackbar.overtime.setting.deleted'));
    } catch (err) {
      dispatch(setSnackbarError('snackbar.overtime.delete.error'));
    }
  },
);

export const requestOvertimeRedDays = createAsyncThunk(
  'overtime/fetchOvertimeRedDays',
  async (name, { extra, dispatch, getState }) => {
    try {
      const { filters } = extra.getState(name, getState);
      const params = {
        start_date: filters.startDate,
        end_date: filters.endDate,
        page: filters.page,
        per_page: filters.perPage,
      };
      const response = await extra.axios.get('/api/v3/public_holidays', { params });
      const { records, ...nextFilters } = getFormattedListData(response.data.data);
      dispatch(setFilters({ ...nextFilters, name }));
      return { redDays: records };
    } catch (err) {
      dispatch(setSnackbarError('snackbar.overtime.redDays.error'));
    }
  },
);
