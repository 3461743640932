import { pick } from 'utils/lodash';
import snakify from 'utils/snakify';
import {
  getFormattedPaginationFields,
  parseJson,
  getFilterCustomerId,
  getFilterEmployeeId,
} from 'appRedux/selectors/utility';

export const getFormattedKeyRequestBody = (key) => snakify({
  ...pick(['keyNumber', 'keyNotes', 'internalNotes', 'externalNotes'])(key),
  keyStatus: Number(key.keyStatus),
  ...(key.keyCustomer && {
    customerKeyManagementAttributes: {
      id: key.keyCustomer.id,
      customerId: key.keyCustomer.customerId,
      _destroy: key.keyCustomer.destroy,
    },
  }),
  ...(key.keyEmployee && {
    employeeKeyManagementAttributes: {
      id: key.keyEmployee.id,
      employeeId: key.keyEmployee.employeeId,
      _destroy: key.keyEmployee.destroy,
    },
  }),
});

export const getFormattedKeyRecords = (response) => ({
  ...getFormattedPaginationFields(response),
  records: response.records.map((record) => ({
    ...record,
    keyCustomer: parseJson(record.customerKeyManagementData),
    keyEmployee: parseJson(record.employeesKeyManagementData),
  })),
});

export const getFormattedKeyData = (response) => ({
  ...response,
  ...(response.customerKeyManagement && {
    keyCustomer: {
      id: response.customerKeyManagement.id,
      customerId: response.customerKeyManagement.customer.id,
      name: response.customerKeyManagement.customer.name,
    },
  }),
  ...(response.employeeKeyManagement && {
    keyEmployee: {
      id: response.employeeKeyManagement.id,
      employeeId: response.employeeKeyManagement.employee.id,
      name: response.employeeKeyManagement.employee.name,
    },
  }),
});

export const getFormattedListPageParams = (filters) => snakify({
  perPage: filters.perPage,
  page: filters.page,
  query: filters.query,
  customerId: getFilterCustomerId(filters),
  employeeId: getFilterEmployeeId(filters),
  keyStatus: filters.customerKeyStatus === 'view_all' ? undefined : filters.customerKeyStatus,
});
