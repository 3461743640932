import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import TableCell from '@mui/material/TableCell';
import { Checkbox } from 'components/library';

export default function SelectAllHeaderCell (props) {
  const { data = [], selectedRecords = [], rowType, onToggleSelectAllCheckbox } = props;
  const selectableCount = props.getSelectableRecordsCount(data);
  const selectedCount = props.getSelectedRecordsCount(selectedRecords);

  const paddingCell = useMemo(() => (rowType === 'collapsible' ? <TableCell /> : null), [rowType]);

  return props.hideSelectAllCheckbox ? (
    <>
      {paddingCell}
      <TableCell />
    </>
  ) : (
    <>
      {paddingCell}
      <TableCell key="select-all" align="left">
        <Checkbox
          color="primary"
          checked={Boolean(selectedCount && selectableCount === selectedCount)}
          indeterminate={Boolean(selectedCount && selectableCount !== selectedCount)}
          onChange={onToggleSelectAllCheckbox}
          disabled={selectableCount < 1}
        />
      </TableCell>
    </>
  );
}

SelectAllHeaderCell.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedRecords: PropTypes.arrayOf(PropTypes.shape({})),
  onToggleSelectAllCheckbox: PropTypes.func,
  getSelectableRecordsCount: PropTypes.func,
  getSelectedRecordsCount: PropTypes.func,
  rowType: PropTypes.oneOf(['normal', 'collapsible']),
  hideSelectAllCheckbox: PropTypes.bool,
};

SelectAllHeaderCell.defaultProps = {
  rowType: 'normal',
  hideSelectAllCheckbox: false,
  getSelectableRecordsCount: (records) => records.length,
  getSelectedRecordsCount: (records) => records.length,
};
