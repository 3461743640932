import React from 'react';
import { Typography, Box, Button, Dialog, DialogContent } from 'components/library';
import { fm } from 'utils/string';
import PropTypes from 'prop-types';

const DeleteMessageDialog = (props) => {
  const { openDialog, closeDialogHandler, onDeleteMessageHandler } = props;

  return (
    <Dialog
      open={openDialog}
      fullWidth
      maxWidth="xs"
      onClose={closeDialogHandler}
    >
      <DialogContent>
        <Typography>{fm('delete_message_dialog_text')}</Typography>
        <Box mt={3} display="flex" flexDirection="row">
          <Button
            variant="outlined"
            onClick={onDeleteMessageHandler}
          >
            {fm('yes')}
          </Button>
          <Button
            variant="outlined"
            onClick={closeDialogHandler}
          >
            {fm('no')}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteMessageDialog;

DeleteMessageDialog.propTypes = {
  openDialog: PropTypes.bool,
  closeDialogHandler: PropTypes.func,
  onDeleteMessageHandler: PropTypes.func,
};
