import { multiStateGet } from 'appRedux/reducers/utility';
import { getFilterCompanyId } from 'appRedux/selectors/utility';
import { batch } from 'react-redux';
import axios from 'utils/axios';
import { getFormattedListData } from 'appRedux/utility';
import {
  setFilters,
  setSnackbarError,
  setSuperAdminStorageFields,
} from 'appRedux/actions';

export const requestCompaniesStorageRecords = (name) => async (dispatch, getState) => {
  const { filters } = getState();
  try {
    const pageFilters = multiStateGet(name, filters);
    const params = {
      per_page: pageFilters.perPage,
      page: pageFilters.page,
      company_id: getFilterCompanyId(pageFilters),
      storage_type: pageFilters.storageType === 'view_all' ? undefined : pageFilters.storageType,
    };
    const response = await axios.get('/api/v3/super_admin/booking_documents/totals', { params });
    const { records, ...restPagination } = getFormattedListData(response.data.data);
    batch(() => {
      dispatch(setSuperAdminStorageFields({ records, companyIdStorageRecordsMap: {} }));
      dispatch(setFilters({ ...restPagination, name }));
    });
  } catch (err) {
    dispatch(setSnackbarError('snackbar_fetch_all_companies_records'));
  }
};

export const requestCompanyStorageRecords = (name, companyId, pagination) => async (dispatch, getState) => {
  const { superAdmin: { storage } } = getState();
  const {
    companyIdStorageRecordsMap: prevCompanyIdStorageRecordsMap,
    companyIdStoragePaginationMap: prevCompanyIdStoragePaginationMap,
  } = storage;
  try {
    const params = {
      per_page: pagination.perPage,
      page: pagination.page,
      company_id: companyId,
    };
    const response = await axios.get('/api/v3/super_admin/booking_documents', { params });
    const { records, ...restPaginationParams } = getFormattedStandardResponse(response.data.data);
    const companyIdStorageRecordsMap = { ...prevCompanyIdStorageRecordsMap, [companyId]: records };
    const companyIdStoragePaginationMap = { ...prevCompanyIdStoragePaginationMap, [companyId]: restPaginationParams };
    dispatch(setSuperAdminStorageFields({ companyIdStorageRecordsMap, companyIdStoragePaginationMap }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar_fetch_company_attachments_error'));
  }
};
