import { createSlice } from '@reduxjs/toolkit';
import { getLanguage } from 'utils/language';
import { get } from 'utils/lodash';
import { requestProfile } from './thunk';

const profile = createSlice({
  name: 'profile',
  initialState: {
    language: getLanguage(),
    user: {},
  },
  reducers: {
    setProfileFields: (state, action) => ({
      ...state,
      ...action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(requestProfile.fulfilled, (state, action) => ({
      ...state,
      ...action.payload,
    }));
    builder.addCase(requestProfile.rejected, (state, action) => ({
      ...state,
      companyActive: get('response.data.errors[0].details.company_active')(action.payload),
    }));
  },
});

const { reducer, actions } = profile;

export const { setProfileFields } = actions;

export default reducer;
