export const getFormattedBodyForPunchInOut = (data) => {
  const body = {
    employee_punches: {
      company_id: data.companyId,
      employee_id: data.employeeId,
      punch_in_time: data.punchInTime,
      punch_out_time: data.punchOutTime,
      punch_in_latitude: data.punchInLatitude,
      punch_in_longitude: data.punchInLongitude,
      punch_in_device: data.punchInDevice,
      punch_out_latitude: data.punchOutLatitude,
      punch_out_longitude: data.punchOutLongitude,
      punch_out_device: data.punchOutDevice,
    },
    order_id: data.orderId,
  };
  return body;
};

export const getFormattedProjectFilterParams = (filters = {}) => ({
  start_date: filters.startDate,
  end_date: filters.endDate,
});
