import { createSlice } from '@reduxjs/toolkit';
import {
  createWeeklyWorkPlan,
  deleteWeeklyWorkPlan,
  fetchWeeklyWorkPlan,
  fetchWeeklyWorkPlans,
  searchWeeklyWorkPlans,
  updateWeeklyWorkPlan,
} from './thunk';

const initialState = {
  records: [],
  selectedWeeklyWorkPlanIds: [],
  selectedBookingIds: [],
  filters: {},
  plan: {},
  fsip: false,
  showWeeklyWorkPlanDialog: false,
  planModalError: null,
};

const weeklyWorkPlans = createSlice({
  name: 'weeklyWorkPlans',
  initialState,
  reducers: {
    setWeeklyWorkPlansFields: (state, action) => ({ ...state, ...action.payload }),
    setFilters: (state, action) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    setSelectedWeeklyWorkPlanIds: (state, action) => {
      state.selectedWeeklyWorkPlanIds = action.payload;
    },
    setshowWeeklyWorkPlanDialog: (state, action) => {
      state.showWeeklyWorkPlanDialog = action.payload;
    },
    setPlanModalError: (state, action) => {
      state.planModalError = action.payload;
    },
    closeScheduleModal: (state) => ({
      ...state,
      showWeeklyWorkPlanDialog: false,
      planModalError: null,
      plan: {},
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWeeklyWorkPlans.pending, (state) => ({
        ...state,
        fsip: true,
      }))
      .addCase(fetchWeeklyWorkPlans.fulfilled, (state, action) => ({
        ...state,
        records: action.payload.records,
        fsip: false,
      }))
      .addCase(fetchWeeklyWorkPlans.rejected, (state) => ({
        ...state,
        fsip: false,
      }))
      .addCase(searchWeeklyWorkPlans.fulfilled, (state, action) => ({
        ...state,
        records: action.payload.records,
        filters: {
          ...state.filters,
          page: 1,
          pages: 1,
          totalRecords: action.payload.records.length,
          name: action.payload.name,
        },
      }))
      .addCase(fetchWeeklyWorkPlan.fulfilled, (state, action) => ({
        ...state,
        plan: action.payload,
      }))
      .addCase(createWeeklyWorkPlan.pending, (state) => ({
        ...state,
        fsip: true,
      }))
      .addCase(createWeeklyWorkPlan.fulfilled, (state, action) => ({
        ...state,
        refreshAllWorkPlans: {},
        fsip: false,
        showWeeklyWorkPlanDialog: false,
        planModalError: {},
        plan: {},
      }))
      .addCase(createWeeklyWorkPlan.rejected, (state, action) => ({
        ...state,
        fsip: false,
        planModalError: action.payload,
      }))
      .addCase(updateWeeklyWorkPlan.pending, (state) => ({
        ...state,
        fsip: true,
      }))
      .addCase(updateWeeklyWorkPlan.fulfilled, (state, action) => ({
        ...state,
        planModalError: {},
        plan: {},
        fsip: false,
        showWeeklyWorkPlanDialog: false,
        refreshAllWorkPlans: {},
      }))
      .addCase(updateWeeklyWorkPlan.rejected, (state, action) => ({
        ...state,
        fsip: false,
        planModalError: action.payload,
      }))
      .addCase(deleteWeeklyWorkPlan.fulfilled, (state, action) => ({
        ...state,
        refreshAllWorkPlans: {},
        selectedWeeklyWorkPlanIds: [],
      }));
  },
});
const { actions, reducer } = weeklyWorkPlans;
export const {
  setFilters,
  setSelectedWeeklyWorkPlanIds,
  setshowWeeklyWorkPlanDialog,
  setWeeklyWorkPlansFields,
  closeScheduleModal,
  setPlanModalError,
} = actions;

export default reducer;
