import React from 'react';
import PropTypes from 'prop-types';
import Wrapper from 'components/common/LoginSignUp/Wrapper';
import LoginForm from './LoginForm';

export default function RegisterAccount (props) {
  return (
    <Wrapper>
      <LoginForm history={props.history} location={props.location} />
    </Wrapper>
  );
}

RegisterAccount.propTypes = {
  history: PropTypes.shape({}),
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};
