import React, { useRef, useState } from 'react';
import { Container, Grid, Typography, Box, Paper, IconButton, alert, Button } from 'components/library';
import WiredOutlinedPdfAnimGif from 'images/wired_ouline_pdf_anim.gif';
import {
  VerifiedUserOutlinedIcon,
  FileUploadOutlinedIcon,
  GppBadOutlinedIcon,
} from 'components/library/icons';
import bosLogo from 'images/Logo-bos.png';
import axios from 'utils/axios';
import colors from 'utils/colors';
import { fm } from 'utils/string';

export default function VerifyProposalPdfSignature () {
  const inputRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState(null);

  const handleSubmit = async (selectedFile) => {
    setIsLoading(true);
    setResponse(null);

    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      const apiResponse = await axios.post('/api/v3/proposals/verify_signed_pdf', formData);
      setResponse(apiResponse.data.data);
    } catch (error) {
      console.error(error);
      alert('Error uploading file!');
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;
    if (file.type !== 'application/pdf') {
      alert('Please select a PDF file!');
      return;
    }
    if (file.size > 50000000) {
      return alert('File exceeds maximum upload limit');
    }
    handleSubmit(file);
  };

  const handleClickUpload = () => {
    inputRef.current.click();
  };

  return (
    <Container maxWidth="sm" sx={{ textAlign: 'center', mt: 8 }}>
      <Box>
        <img src={bosLogo} alt="Logo" style={{ width: '200px', marginBottom: '20px' }} />
      </Box>
      <Typography variant="caption" gutterBottom textAlign="center">{fm('venture_of_bird_vision')}</Typography>
      {response?.valid === false && (
        <Box display="flex" flexDirection="column" alignItems="center" mt={8}>
          <GppBadOutlinedIcon fontSize="large" color="error" />
          <Typography mt={2} variant="h5" color="error" gutterBottom>{fm('could_not_verify')}</Typography>
          <Typography mt={4} variant="h6" gutterBottom>
            {fm('verification_failed_doc_altered_or_not_authentic')}
          </Typography>
          <Box mt={4} />
          <Button variant="text" color="primary" onClick={() => window.location.reload()}>
            {fm('verify_another_doc')}
          </Button>
        </Box>
      )}
      {response?.valid === true && (
        <Box display="flex" flexDirection="column" alignItems="center" mt={8}>
          <VerifiedUserOutlinedIcon fontSize="large" color="success" />
          <Typography mt={2} variant="h5" gutterBottom>{fm('verified')}</Typography>
          <Typography mt={4} variant="h6" gutterBottom>
            {fm('document_verified_successfully')}
            {fm('no_changes_made_to_contract')}
          </Typography>
          <Box mt={4} />
          <Button variant="text" color="primary" onClick={() => window.location.reload()}>
            {fm('verify_another_doc')}
          </Button>
        </Box>
      )}
      {isLoading && (
        <Box display="flex" flexDirection="column" alignItems="center" mt={10}>
          Loading
        </Box>
      )}
      {!response && (
        <Box display="flex" flexDirection="column" alignItems="center" mt={10}>
          <VerifiedUserOutlinedIcon fontSize="large" />
          <Typography variant="h5" gutterBottom>{fm('verification')}</Typography>
          <Typography variant="body1" mt={4} gutterBottom>
            {fm('uploading_document_here_verify_authenticity')}
          </Typography>
          <Grid container display="flex" justifyContent="center" mt={4}>
            <Grid item xs={10}>

              <Paper elevation={0} sx={{ background: colors.gray200.value }}>
                <Box m={4}>
                  <input type="file" ref={inputRef} accept=".pdf" onChange={handleFileChange} hidden />
                  <Box
                    onClick={handleClickUpload}
                    display="flex"
                    alignItems="center"
                    flexDirection="column"
                    className="pointer"
                  >
                    <IconButton color="primary" aria-label="upload document">
                      <img src={WiredOutlinedPdfAnimGif} alt="pdf" width="60" />
                    </IconButton>
                    <Box mt={1} />
                    <IconButton color="primary" aria-label="upload document">
                      <FileUploadOutlinedIcon color="disabled" sx={{ fontSize: 20 }} />
                    </IconButton>
                  </Box>
                  <Typography variant="body1" gutterBottom>{fm('upload_document')}</Typography>
                  <Typography variant="caption" display="block">{fm('max_size_50_mb')}</Typography>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      )}
    </Container>
  );
}
