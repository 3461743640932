/* eslint-disable camelcase */
import axios from 'utils/Api';
import camelize from 'camelize';
import { multiStateGet } from 'appRedux/reducers/utility';
import { setSuperAdminFields, setSnackbarError, setFilters } from 'appRedux/actions';
import { getFormattedPaginationFields } from 'appRedux/selectors/utility';

export const requestDatabaseBackupRecords = (name) => async (dispatch, getState) => {
  const { filters } = getState();
  try {
    const { page, perPage: per_page } = multiStateGet(name, filters);
    const response = await axios.get('/api/v1/super_admin/db_dumps', { params: { page, per_page } });
    const { records } = camelize(response.data.data);
    const databaseBackup = { records };
    dispatch(setSuperAdminFields({ databaseBackup }));
    dispatch(setFilters({ ...getFormattedPaginationFields(response.data.data), name }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar.superAdmin.gettingDatabaseBackups.error'));
  }
};
