import { getUnixTimeInHoursMinutes, getHourMinutesInUnixTime } from 'utils/dateTime';
import { employeeAvailabilityFiltersWeekDays } from 'components/Booking/EmployeeAvailabilityDialog/Filters';
import { getFormattedPaginationFields } from 'appRedux/utility';

export const getFormattedWeekDaysForApiRequest = (data) => {
  const otherWeekDays = employeeAvailabilityFiltersWeekDays.filter(
    (day) => !data[day.value],
  ).map((d) => ({ [d.value]: false }));
  const mergedDays = { ...data, ...Object.assign({}, ...otherWeekDays) };
  return mergedDays;
};

export const getFormattedOvertimeSettingRequestBody = (data) => ({
  overtime_setting: {
    title: data.title,
    start_time: getHourMinutesInUnixTime(data.startTime),
    end_time: getHourMinutesInUnixTime(data.endTime),
    time_code_id: data.timeCodeId,
    red_days_only: data.redDaysOnly,
    weekdays: data.weekdays && getFormattedWeekDaysForApiRequest(data.weekdays),
  },
});

export const getFormattedOvertimeListResponse = (response) => ({
  ...getFormattedPaginationFields(response),
  records: (response.records || []).map((data) => ({
    ...data,
    startTime: getUnixTimeInHoursMinutes(data.startTime),
    endTime: getUnixTimeInHoursMinutes(data.endTime),
    weekdays: employeeAvailabilityFiltersWeekDays.filter(
      (weekDay) => data.weekdays[weekDay.value],
    ).map((day) => day.textValue),
  })),
});

export const getFormattedOvertimeData = (response) => ({
  ...response,
  startTime: getUnixTimeInHoursMinutes(response.startTime),
  endTime: getUnixTimeInHoursMinutes(response.endTime),
});
