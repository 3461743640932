import * as Yup from 'yup';
import { map } from 'utils/lodash';
import { fm, emojisRegex, deepTrim, htmlStringToString, forbiddenInputCharsRegex } from 'utils/string';
import { bookingFlexiblePriceType, bookingTimeTypes } from 'utils/enum';
import { getDifferenceBetweenTime, differenceInYears, getHourMinutesInUnixTime } from 'utils/dateTime';
import { getFormattedBookingDataForApiRequest } from 'appRedux/thunks/bookings/selector';
import { bookingNotesAllowedCharsRegex } from 'components/Booking/BookingModal/utility';

const validateBookingSchema = Yup.object().shape({
  bookingService: Yup.object().shape({ sequenceNum: Yup.number().required(fm('booking.service')) }),
  customer: Yup.object().shape({ sequenceNum: Yup.number().required(fm('booking.customer')) }),
  startDate: Yup.string().required(fm('booking.start_date')),
  endDate: Yup.date().nullable()
    .when(
      'isRecurring',
      {
        is: true,
        then: Yup.date()
          .when(
            'startDate',
            (startDate, schema) => (startDate && schema.min(startDate, fm('booking.end_date.greater'))),
          )
          .test(
            'endDate',
            fm('booking.end_date.greater'),
            function (endDate) { // eslint-disable-line
              return Boolean(this.parent.endDate);
            },
          )
          .test(
            'endDate',
            fm('booking_recurring_duration_greater_then_year'),
            function (endDate) { // eslint-disable-line
              return (!this.parent.startDate && !this.parent.endDate)
                || differenceInYears(endDate, this.parent.startDate) <= 1;
            },
          ),
      },
    ),
  timeType: Yup.number().required(fm('booking_time_type_required')),
  totalHours: Yup.string().nullable()
    .when(
      'timeType',
      {
        is: bookingTimeTypes.totalHours,
        then: Yup.string().required(fm('booking_total_hours_required')),
      },
    ),
  startTime: Yup.string().nullable()
    .when(
      'timeType',
      {
        is: bookingTimeTypes.specificTime,
        then: Yup.string().required(fm('booking.start_time')).test(
          'startTime',
          fm('booking.start_time.greater'),
          function (startTime) { // eslint-disable-line
            const { endTime } = this.parent;
            return !endTime || getHourMinutesInUnixTime(startTime) < getHourMinutesInUnixTime(endTime);
          },
        ),
      },
    ),
  flexiblePriceType: Yup.ref('$flexiblePriceType'),
  bookingEmployees: Yup.array().of(Yup.object().shape({
    employeeId: Yup.number().required(fm('booking.employee.employee_id')).test(
      'uniqueEmployeeId',
      fm('booking_employee_unique_employee_id'),
      function (employeeId) { // eslint-disable-line
        const { bookingEmployees } = this.options.context;
        const employeeRecords = bookingEmployees.filter((emp) => !emp.destroy && emp.employeeId === employeeId).length;
        return !employeeRecords || employeeRecords === 1;
      },
    ),
    startTime: Yup.string().nullable().test(
      'startTime',
      fm('booking.employee.start_time.greater'),
      function (startTime) { // eslint-disable-line
        if (startTime) {
          const { endTime } = this.parent;
          return getHourMinutesInUnixTime(endTime) > getHourMinutesInUnixTime(startTime);
        }
        return (!this.parent.startTime && !this.parent.endTime) || false;
      },
    ),
    endTime: Yup.string().nullable(),
    invoiceTime: Yup.number().nullable().test(
      'invoiceTime',
      fm('booking.employee.invoice_time_required'),
      function (invoiceTime) { // eslint-disable-line
        return (!this.parent.startTime && !this.parent.endTime)
          || this.parent.flexiblePriceType !== bookingFlexiblePriceType.perEmployeeHourly
          || (this.parent.flexiblePriceType === bookingFlexiblePriceType.perEmployeeHourly && invoiceTime > 0);
      },
    ),
    breakTime: Yup.string().nullable().test(
      'breakTime',
      fm('booking_break_time_smaller'),
      function (breakTime) { // eslint-disable-line
        const { startTime, endTime } = this.parent;
        if (breakTime && startTime && endTime) {
          const time = getDifferenceBetweenTime(getDifferenceBetweenTime(endTime, startTime), breakTime);
          return breakTime === '00:00' || !time.includes('-');
        }
        return (!this.parent.startTime && !this.parent.endTime) || false;
      },
    ),
  })),
  bookingItems: Yup.array().of(Yup.object().shape({
    discount: Yup.mixed()
      .test(
        'discount',
        fm('booking.discount'),
        function (discount) { // eslint-disable-line
          const discountNumber = Number(discount);
          return !discountNumber || (discountNumber >= 0 && discountNumber <= 100);
        },
      ),
  })),
  repeatInterval: Yup.number().nullable().when('isRecurring', {
    is: true,
    then: Yup.number().required(fm('booking.recurring.timeInterval.required')),
  }),
  notes: Yup.mixed().nullable().test(
    'notes',
    fm('booking.notes.special.characters.not.allowed'),
    (comment) => !emojisRegex.test(JSON.stringify(comment)),
  ),
});

export const getValidatedFormattedBooking = async (obj) => {
  const formattedObject = {
    ...obj,
    bookingEmployees: map((emp) => ({
      ...emp,
      invoiceTime: Number(emp.invoiceTime || null),
      flexiblePriceType: obj.flexiblePriceType,
    }))(obj.bookingEmployees),
  };
  await validateBookingSchema.validate(formattedObject, { context: formattedObject });
  return deepTrim(getFormattedBookingDataForApiRequest(formattedObject));
};
