import { createSlice } from '@reduxjs/toolkit';
import {
  requestGetOrders,
  requestCustomersLocation,
  requestUpdateCustomersLocation,
} from './thunk';

const customerLocation = createSlice({
  name: 'customerLocation',
  initialState: {
    records: [],
    customer: {},
    orders: [],
  },
  reducers: {
    setCustomerLocationFields (state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
    setCustomerLocationFilters (state, action) {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(requestCustomersLocation.fulfilled, (state, action) => ({
      ...state,
      records: action.payload,
    }));
    builder.addCase(requestUpdateCustomersLocation.fulfilled, (state, action) => ({
      ...state,
      records: state.records.map((record) => (record.sequenceNum === action.payload.details.sequenceNum
        ? { ...record, ...action.payload.details }
        : record)),
      showUpdateCustomerLocationModal: false,
      customer: {},
    }));
    builder.addCase(requestGetOrders.fulfilled, (state, action) => ({
      ...state,
      orders: action.payload.records,
    }));
  },
});

const { reducer, actions } = customerLocation;

export const { setCustomerLocationFields, setCustomerLocationFilters } = actions;

export default reducer;
