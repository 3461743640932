import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Grid,
  Button,
} from 'components/library';
import { InfoIcon } from 'components/library/icons';
import { connect } from 'react-redux';
import { fm } from 'utils/string';
import { setInfoModalFields } from '../../appRedux/actions';

class InfoModal extends React.Component {
  onClickCancel = () => {
    if (this.props.callBack) this.props.callBack();
    this.props.setInfoModalFields({ reset: true });
  }

  render () {
    const { open = false, message, title } = this.props;

    return (
      <Dialog
        onClose={this.onClickCancel}
        aria-labelledby="info-dialog"
        maxWidth="sm"
        fullWidth
        open={open}
      >
        <DialogTitle id="info-dialog-title" onClose={this.onClickCancel}>
          <Grid container alignItems="center">
            <InfoIcon className="mr-1" color="info" /> {title}
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Typography gutterBottom>
            {message}
          </Typography>
        </DialogContent>
        <DialogActions className="spacing-2">
          <Grid container justifyContent="flex-start">
            <Button
              onClick={this.onClickCancel}
              size="medium"
            >
              {fm('close', 'Close')}
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => state.infoModal;

export default connect(mapStateToProps, { setInfoModalFields })(InfoModal);
