import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import color from 'utils/colors';
import { withStyles } from '@mui/styles';

const CustomButtonLink = withStyles(() => ({
  root: {
    color: `${color.buttonLink} !important`,
    cursor: (props) => props.cursor,
    fontWeight: (props) => props.fontWeight,
    fontSize: (props) => props.fontSize,
    lineHeight: (props) => props.lineHeight,
    letterSpacing: (props) => props.letterSpacing,
    display: (props) => props.display,
    textDecoration: (props) => props.textDecoration,
    outline: (props) => props.outline,
    textTransform: (props) => props.textTransform,
    padding: (props) => props.padding,
    backgroundColor: (props) => props.backgroundColor,
    '&:hover': {
      backgroundColor: (props) => props.hoverBackgroundColor,
    },
  },
}))(({ hoverBackgroundColor, backgroundColor, textTransform, lineHeight, ...props }) => <Button {...props} />);

const ButtonLink = (props) => {
  const { children } = props;

  return (
    <CustomButtonLink
      {...props}
      disableRipple={props.disableRipple}
    >
      {children}
    </CustomButtonLink>
  );
};

export default ButtonLink;

ButtonLink.defaultProps = {
  cursor: 'pointer !important',
  fontWeight: 'normal !important',
  fontSize: '14px !important',
  lineHeight: '1.29 !important',
  letterSpacing: '-0.2px !important',
  display: 'flex !important',
  textDecoration: 'underline !important',
  outline: 'none !important',
  textTransform: 'none !important',
  padding: '0px !important',
  backgroundColor: 'none !important',
  hoverBackgroundColor: 'transparent !important',
  disableRipple: true,
};

ButtonLink.propTypes = {
  children: PropTypes.string,
  cursor: PropTypes.string,
  fontSize: PropTypes.string,
  lineHeight: PropTypes.string,
  letterSpacing: PropTypes.string,
  display: PropTypes.string,
  textDecoration: PropTypes.string,
  outline: PropTypes.string,
  textTransform: PropTypes.string,
  padding: PropTypes.string,
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  fontWeight: PropTypes.string,
  hoverBackgroundColor: PropTypes.string,
  disableRipple: PropTypes.bool,
};
