import axios from 'utils/axios';
import { multiStateGet } from 'appRedux/reducers/utility';
import { setSuperAdminSMSFields, setSnackbarError, setFilters } from 'appRedux/actions';
import { getFormattedListData } from 'appRedux/utility';
import { getSuperAdminSMSReqParams } from './selector';

export const requestSuperAdminSMS = (name) => async (dispatch, getState) => {
  const { filters } = getState();
  try {
    const params = getSuperAdminSMSReqParams(multiStateGet(name, filters));
    const response = await axios.get('/api/v3/super_admin/sms/totals', { params });
    const { records, ...restPaginationParams } = getFormattedListData(response.data.data);
    dispatch(setSuperAdminSMSFields({ records }));
    dispatch(setFilters({ ...restPaginationParams, name }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar_super_admin_sms_get_error_all_records'));
  }
};

export const requestSuperAdminCompanySMS = (name, companyId, pagination) => async (dispatch, getState) => {
  const { filters, superAdmin: { superAdminSMS } } = getState();
  const {
    companyIdSMSRecordsMap: prevIdSMSRecordMap,
    companyIdSMSPaginationMap: prevIdSMSPaginationMap,
  } = superAdminSMS;
  try {
    const params = getSuperAdminSMSReqParams({
      ...multiStateGet(name, filters),
      ...pagination,
      companyId,
    });
    const response = await axios.get('/api/v3/super_admin/sms/details', { params });
    const { records, ...restPaginationParams } = getFormattedListData(response.data.data);
    const companyIdSMSRecordsMap = { ...prevIdSMSRecordMap, [companyId]: records };
    const companyIdSMSPaginationMap = { ...prevIdSMSPaginationMap, [companyId]: restPaginationParams };
    dispatch(setSuperAdminSMSFields({ companyIdSMSRecordsMap, companyIdSMSPaginationMap }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar_super_admin_sms_get_error_single_company'));
  }
};
