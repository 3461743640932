import React from 'react';
import Proptypes from 'prop-types';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

export const Option = MenuItem;

export default function ComposedTextField (props) {
  const { max, min } = props;

  return (
    <TextField
      InputLabelProps={props.InputLabelProps}
      InputProps={{
        ...props.InputProps,
        inputProps: { min, max },
      }}
      {...props}
    >
      {props.children}
    </TextField>
  );
}

ComposedTextField.defaultProps = {
  variant: 'outlined',
  fullWidth: true,
  InputLabelProps: { shrink: true },
};

ComposedTextField.propTypes = {
  min: Proptypes.string,
  max: Proptypes.string,
  inputRef: Proptypes.shape({}),
  children: Proptypes.node,
  InputProps: Proptypes.shape({}),
  variant: Proptypes.string,
  fullWidth: Proptypes.bool,
  InputLabelProps: Proptypes.shape({}),
};
