import React, { useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';

export default function ToggleList (props) {
  const [anchorElem, setAnchorElem] = useState(false);
  const { data, max, getLabel } = props;
  const excessCount = data.length - max;

  const toggleAnchorElem = useCallback((event) => {
    if (data.length <= max) return;
    setAnchorElem(anchorElem ? null : event.target);
    event.stopPropagation();
  }, [anchorElem]);

  const { transformOrigin, anchorOrigin } = useMemo(() => ({
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
  }));

  return (
    <>
      {data.length > 0 && (
        <div onClick={toggleAnchorElem} role="button" tabIndex={0}>
          {data.slice(0, max).map((record) => (
            <span>
              {getLabel(record)}
            </span>
          ))}
          {' '}
          {excessCount > 0 && <b>+{excessCount}</b>}
        </div>
      )}
      {data.length > max && (
        <Popover
          open={Boolean(anchorElem)}
          anchorEl={anchorElem}
          onClose={toggleAnchorElem}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
        >
          <Box m={1}>
            {data.map((d) => <div>{getLabel(d)}</div>)}
          </Box>
        </Popover>
      )}
    </>
  );
}

ToggleList.defaultProps = {
  data: [],
  max: 5,
  getLabel: (d) => d,
};

ToggleList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  max: PropTypes.number,
  getLabel: PropTypes.func,
};
