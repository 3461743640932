import { useMediaQuery } from '@mui/material';
import theme from 'components/Theme';

export default function application () {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });

  return {
    isMobile,
  };
}
