import { createSlice } from '@reduxjs/toolkit';
import { requestMigrateBookingToProject, requestMigratedBookingsHistory, requestRecurringBookingsToMigrate, requestUnmigratedBookingRecurrences } from './thunk';

const bookingProjectMigration = createSlice({
  name: 'bookingProjectMigration',
  initialState: {
    records: [],
    record: {},
    recurrences: [],
    refreshAllBookingsToMigrate: {},
    migratedHistory: {
      records: [],
    },
  },
  reducers: {
    setBookingProjectMigrationFields (state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
    setBookingProjectMigratedHistoryFields (state, action) {
      return {
        ...state,
        migratedHistory: {
          ...state.migratedHistory,
          ...action.payload,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(requestRecurringBookingsToMigrate.fulfilled, (state, action) => ({
      ...state,
      records: action.payload.records,
    }));
    builder.addCase(requestUnmigratedBookingRecurrences.fulfilled, (state, action) => ({
      ...state,
      recurrences: action.payload.records,
    }));
    builder.addCase(requestMigratedBookingsHistory.fulfilled, (state, action) => ({
      ...state,
      migratedHistory: {
        ...state.migratedHistory,
        records: action.payload.records,
      },
    }));
    builder.addCase(requestMigrateBookingToProject.fulfilled, (state) => ({
      ...state,
      record: {},
      refreshAllBookingsToMigrate: {},
      fsip: false,
    }));
  },
});

const { reducer, actions } = bookingProjectMigration;

export const { setBookingProjectMigrationFields, setBookingProjectMigratedHistoryFields } = actions;

export default reducer;
