import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import makeStyles from '@mui/styles/makeStyles';
import LoaderLogo from '../../../images/bos-loader.gif';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function SimpleBackdrop () {
  const classes = useStyles();
  return (
    <div>
      <Backdrop className={classes.backdrop} open invisible>
        <img
          height={40}
          width="auto"
          src={LoaderLogo}
          alt="Bokning och Schema Online Loader"
        />
      </Backdrop>
    </div>
  );
}
