import React from 'react';
import { useDispatch } from 'react-redux';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { requestGoogleSignIn } from 'appRedux/thunks/auth/googleSignIn';
import { useHistory } from 'react-router-dom';
import { setSnackbarError, setAuthFields } from 'appRedux/actions';
import urls from 'utils/urls';

const LoginWithGoogle = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSuccess = (response) => {
    dispatch(setAuthFields({ loginType: 'google' }));
    dispatch(requestGoogleSignIn(response.credential, history));
  };

  const handleFailure = () => {
    dispatch(setSnackbarError('snackbar_google_account_fetch_failed'));
  };

  return (
    <GoogleOAuthProvider clientId={urls.getGoogleClientId()}>
      <GoogleLogin
        onSuccess={handleSuccess}
        onError={handleFailure}
      />
    </GoogleOAuthProvider>
  );
};

export default LoginWithGoogle;
