import camelize from 'camelize';
import { orderBy } from 'utils/lodash';
import { getFormattedPaginationFields, parseJson } from 'appRedux/selectors/utility';

export const getFormattedTaskCategoryDataForApiRequest = (data) => ({
  title: data.title,
});

export const getFormattedTaskCreateApiReq = (data) => ({
  name: data.title,
});

export const getFormattedTasksListData = (response) => ({
  checklist: {
    checklistCollections: response.checklist_collections?.map((collection) => ({
      id: collection.id,
      collectionId: collection.checklist_collection.id,
      collectionSequenceNum: collection.checklist_collection.sequence_num,
      title: collection.checklist_collection.title,
      colorId: collection.checklist_collection.color_id,
    })),
    taskCategory: response.task_category,
    tasks: orderBy('orderNumber', 'asc')(response.tasks.map((task, idx) => ({
      id: task.id,
      title: task.name,
      orderNumber: task.order_number ? task.order_number : idx + 1,
      checklistId: task.checklist_id,
    }))),
  },
});

export const getFormattedChecklistData = (response) => ({
  ...getFormattedPaginationFields(response),
  predefinedChecklists: (response.records || []).map((record) => ({
    ...camelize(record),
    checklistCollections: parseJson(record.checklist_collections_data),
  })),
});

export const getFormattedChecklistCreateApiReq = (data) => ({
  task_category_id: data.taskCategory.id,
  task_ids: data.tasks.filter((task) => !task.destroy).map((task) => ({
    id: task.id,
    order_number: task.orderNumber,
  })),
  checklist_collection_ids: data?.checklistCollections?.map((collection) => collection.collectionId) || [],
});

export const getFormattedChecklistUpdateApiReq = (data) => ({
  task_category_id: data.taskCategory.id,
  new_task_ids: data.newTaskIds?.map((task) => ({
    id: task.id,
    order_number: task.orderNumber,
  })),
  removable_task_ids: data.removableTaskIds,
  editable_checklist_tasks: data.editableChecklistTasks?.map((task) => ({
    task_id: task.id,
    name: task.title,
    order_number: task.orderNumber,
  })),
  new_checklist_collection_ids: data?.checklistCollections
    .filter((collection) => !collection.id)
    .map((collection) => collection.collectionId),
  removable_checklist_collection_ids: data?.checklistCollections
    .filter((collection) => collection.destroy)
    .map((collection) => collection.id),
  task_checklists: data.tasks.map((task) => ({
    order_number: task.orderNumber,
    checklist_id: task.checklistId,
  })),
});

export const getFormattedChecklistFilterApiReq = (filters, taskCategoryId) => ({
  page: filters.page,
  per_page: filters.perPage,
  task_category_id: taskCategoryId,
  query: filters.query,
});

export const getFormattedPredefinedEditTasks = (data = []) => ({
  tasks: data.map((d) => ({
    id: d.id,
    title: d.name,
  })),
});

export const getFormattedChecklistCollectionUpdateBody = (collection) => ({
  checklist_collection: {
    title: collection.title,
    color_id: collection.colorId,
  },
});
