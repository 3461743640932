/* eslint-disable camelcase */
import axios from 'utils/axios';
import { multiStateGet } from 'appRedux/reducers/utility';
import { batch } from 'react-redux';
import { setFilters, setSuperAdminWebAppVersionFields, setSnackbarError } from 'appRedux/actions';
import { getFormattedListData } from 'appRedux/utility';

export const requestWebAppVersionsUsers = (name) => async (dispatch, getState) => {
  const { filters } = getState();
  try {
    const { perPage: per_page, page } = multiStateGet(name, filters);
    const params = { per_page, page };
    const response = await axios.get('/api/v3/super_admin/web_app_versions/version_details', { params });
    const { records, ...paginationFields } = getFormattedListData(response.data.data);
    batch(() => {
      dispatch(setSuperAdminWebAppVersionFields({ records }));
      dispatch(setFilters({ ...paginationFields, name }));
    });
  } catch (err) {
    dispatch(setSnackbarError('snackbar_superAdmin_createItem_error'));
  }
};

export const requestWebAppVersion = () => async (dispatch) => {
  try {
    const response = await axios.get('/api/v3/super_admin/web_app_versions');
    const [versionData] = response.data.data.webAppVersions;
    dispatch(setSuperAdminWebAppVersionFields({ versionData }));
  } catch (err) {
    console.log(err); // eslint-disable-line
  }
};

export const requestUpdateWebAppVersion = (details, name) => async (dispatch) => {
  try {
    const web_app_version = {
      app_version: details.appVersion,
      description: details.description,
      update_all: details.updateAll,
      visibility: true,
    };
    const response = await axios.put(`/api/v3/super_admin/web_app_versions/${details.id}`, { web_app_version });
    const versionData = response.data.data;
    dispatch(requestWebAppVersionsUsers(name));
    dispatch(setSuperAdminWebAppVersionFields({ versionData, showVersionModal: false }));
  } catch (err) {
    dispatch(setSnackbarError('snackbar_superAdmin_createItem_error'));
  }
};
