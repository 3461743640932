import { setAuthFields } from 'appRedux/actions';
import { requestSignInOtp } from 'appRedux/thunks/auth';
import cx from 'classnames';
import LoadingButton from 'components/common/LoadingButton';
import { Alert, Box, Button, ComposedTextField, Grid, Typography } from 'components/library';
import OTPInput from 'components/library/Input/OtpInput';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { fm } from 'utils/string';
import { useEmailFormik, useOtpFormik } from './formUtils';

const LoginWithCode = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [wait, setWait] = useState(20);
  const [showOtpInput, setShowOtpInput] = useState(false);
  const errorMessage = useSelector((state) => state.auth.errorMessage);
  const hasError = Boolean(errorMessage);
  const application = useSelector((state) => state.application);
  const resendOtpEnabled = wait === 0;

  useEffect(() => {
    let timer;
    if (wait > 0) {
      timer = setTimeout(() => { setWait(wait - 1); }, 1000);
    }
    return () => clearTimeout(timer);
  }, [wait]);

  useEffect(() => {
    dispatch(setAuthFields({ errorMessage: null }));
  }, []);

  const emailFormik = useEmailFormik(dispatch, setShowOtpInput);
  const otpFormik = useOtpFormik(dispatch, emailFormik, history);

  const onClickResendEmail = () => {
    if (!resendOtpEnabled) return;
    setWait(20);
    dispatch(setAuthFields({ errorMessage: null }));
    dispatch(requestSignInOtp(emailFormik.values.email));
  };

  return (
    <>
      {/* Email Form */}
      {!showOtpInput && (
        <form onSubmit={emailFormik.handleSubmit}>
          <Grid container alignItems="center" justifyContent="center" spacing={2}>
            <Grid item xs={12}>
              <ComposedTextField
                size="small"
                type="email"
                id="username"
                name="email"
                placeholder={fm('email', 'Email')}
                value={emailFormik.values.email}
                onChange={emailFormik.handleChange}
                error={Boolean(emailFormik.errors.email)}
                helperText={emailFormik.errors.email}
                autoFocus
                fullWidth
                label={fm('email', 'Email')}
              />
            </Grid>
            <Grid item xs={12}>
              <Button fullWidth type="submit" disabled={!emailFormik.isValid || application.isLoading}>
                {fm('send_code', 'Send 6-digit code')}
              </Button>
            </Grid>
          </Grid>
        </form>
      )}

      {/* OTP Form */}
      {showOtpInput && (
        <form onSubmit={otpFormik.handleSubmit}>
          <Grid container alignItems="center" justifyContent="center" spacing={2}>
            <Grid item xs={12}>
              <OTPInput
                onChange={(otp) => otpFormik.setFieldValue('otp', otp)}
              />
              {otpFormik.touched.otp && otpFormik.errors.otp ? (
                <Typography color="error" variant="caption">{otpFormik.errors.otp}</Typography>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                fullWidth
                type="submit"
                id="login-form-submit"
                disabled={otpFormik.isSubmitting || !otpFormik.isValid}
              >
                {fm('login', 'Access Account')}
              </LoadingButton>
            </Grid>
            <Grid item xs={12}>
              <Box textAlign="center">
                <Typography variant="caption">{fm('not_recieved_email')}</Typography>
                <Typography
                  ml={1}
                  color={resendOtpEnabled ? 'primary' : 'disabled'}
                  className={cx('underline', resendOtpEnabled ? 'pointer' : '')}
                  variant="caption"
                  onClick={onClickResendEmail}
                >
                  {fm('click_to_resend')}
                </Typography>
                {!resendOtpEnabled && (
                  <Typography variant="caption">
                    {' '} ({fm('wait_to_send', undefined, { seconds: wait })})
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
        </form>
      )}

      {/* Error Alert */}
      {hasError && (
        <Grid item xs={12}>
          <Alert severity="error">
            {errorMessage}
          </Alert>
        </Grid>
      )}
    </>
  );
};

export default LoginWithCode;
