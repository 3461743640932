export const getFormattedDepartmentRequestBody = (details) => ({
  department: {
    title: details.title,
    department_users_attributes: details.departmentUsers?.map((user) => ({
      user_id: user.userId,
      id: user.departmentUsersId,
      _destroy: user.destroy,
    })),
  },
});
