import {
  SET_SUPER_ADMIN_FIELDS,
  SET_SUPER_ADMIN_ERRORS,
  SET_SUPER_ADMIN_APP_VERSION_FIELDS,
  SET_SUPER_ADMIN_PERMISSIONS_FIELDS,
  SET_SUPER_ADMIN_MOBILE_LOGIN_HISTORY_FIELDS,
  SET_SUPER_ADMIN_RED_DAY_FIELDS,
  SET_SUPER_ADMIN_SMS_FIELDS,
  SET_SUPER_ADMIN_CUSTOMERS_FIELDS,
  SET_SUPER_ADMIN_ITEMS_FIELDS,
  SET_SUPER_ADMIN_INVOICES_FIELDS,
  SET_SUPER_ADMIN_BOOKING_AUTO_EXTEND_ERROR_FIELDS,
  SET_SUPER_ADMIN_WEB_APP_VERSION_FIELDS,
  SET_SUPER_ADMIN_STORAGE_FIELDS,
  SET_SUPER_ADMIN_COMPANY_SETTINGS_FIELDS,
  SET_SUPER_ADMIN_WEB_LOGS_FIELDS,
} from '../actions';

const initialState = {
  companies: [],
  users: [],
  openApiIntegrationRecords: [],
  integrationErrors: {},
  appVersion: {},
  permissions: {},
  mobileLoginHistory: {},
  redDays: { records: [] },
  superAdminSMS: {
    records: [],
    expandedRowsIndexes: {},
  },
  customers: {
    records: [],
  },
  items: {
    records: [],
  },
  bookingAutoExtendErrors: {
    records: [],
  },
  oldUIinvoices: [],
  invoices: {
    records: [],
    historyRecords: [],
    selectedCustomerIds: [],
    companyIdInvoiceItemsMap: {},
  },
  webAppVersion: {
    records: [],
  },
  storage: {
    records: [],
    expandedRowsIndexes: {},
    companyIdStoragePaginationMap: {},
  },
  companySettings: {
    records: [],
    companySetting: {},
    companySettingId: null,
    showCompanySettingDialog: false,
    refreshAllCompanySettings: {},
  },
  webLogs: {
    records: [],
  },
};

function superAdmin (state = initialState, action) {
  switch (action.type) {
    case SET_SUPER_ADMIN_FIELDS:
      return {
        ...state,
        ...action.payload,
      };

    case SET_SUPER_ADMIN_ERRORS:
      return {
        ...state,
        integrationErrors: {
          ...state.integrationErrors,
          ...action.payload,
        },
      };

    case SET_SUPER_ADMIN_PERMISSIONS_FIELDS:
      return {
        ...state,
        permissions: {
          ...state.permissions,
          ...action.payload,
        },
      };

    case SET_SUPER_ADMIN_APP_VERSION_FIELDS:
      return {
        ...state,
        appVersion: {
          ...state.appVersion,
          ...action.payload,
        },
      };

    case SET_SUPER_ADMIN_MOBILE_LOGIN_HISTORY_FIELDS:
      return {
        ...state,
        mobileLoginHistory: {
          ...state.mobileLoginHistory,
          ...action.payload,
        },
      };

    case SET_SUPER_ADMIN_RED_DAY_FIELDS:
      return {
        ...state,
        redDays: {
          ...state.redDays,
          ...action.payload,
        },
      };

    case SET_SUPER_ADMIN_SMS_FIELDS:
      return {
        ...state,
        superAdminSMS: {
          ...state.superAdminSMS,
          ...action.payload,
        },
      };

    case SET_SUPER_ADMIN_CUSTOMERS_FIELDS:
      return {
        ...state,
        customers: {
          ...state.customers,
          ...action.payload,
        },
      };

    case SET_SUPER_ADMIN_ITEMS_FIELDS:
      return {
        ...state,
        items: {
          ...state.items,
          ...action.payload,
        },
      };

    case SET_SUPER_ADMIN_INVOICES_FIELDS:
      return {
        ...state,
        invoices: {
          ...state.invoices,
          ...action.payload,
        },
      };

    case SET_SUPER_ADMIN_BOOKING_AUTO_EXTEND_ERROR_FIELDS:
      return {
        ...state,
        bookingAutoExtendErrors: {
          ...state.bookingAutoExtendErrors,
          ...action.payload,
        },
      };

    case SET_SUPER_ADMIN_WEB_APP_VERSION_FIELDS:
      return {
        ...state,
        webAppVersion: {
          ...state.webAppVersion,
          ...action.payload,
        },
      };

    case SET_SUPER_ADMIN_STORAGE_FIELDS:
      return {
        ...state,
        storage: {
          ...state.storage,
          ...action.payload,
        },
      };

    case SET_SUPER_ADMIN_COMPANY_SETTINGS_FIELDS:
      return {
        ...state,
        companySettings: {
          ...state.companySettings,
          ...action.payload,
        },
      };

    case SET_SUPER_ADMIN_WEB_LOGS_FIELDS:
      return {
        ...state,
        webLogs: {
          ...state.webLogs,
          ...action.payload,
        },
      };

    default:
      return state;
  }
}

export default superAdmin;
