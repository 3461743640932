import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers/DatePicker';

export default function DatePicker(props) {
  const { onChange, ...restProps } = props;
  const [value, setValue] = useState(props.value);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const handleChange = (nextValue, validation) => {
    setValue(nextValue);
    if (!validation.validationError) onChange(nextValue);
  };

  return (
    <MUIDatePicker
      {...restProps}
      value={value}
      onChange={handleChange}
      maxDate={new Date(props.maxDate)}
      minDate={new Date(props.minDate)}
      displayWeekNumber
    />
  );
}

DatePicker.propTypes = {
  value: PropTypes.instanceOf(Date),
  maxDate: PropTypes.string,
  minDate: PropTypes.string,
  onChange: PropTypes.func,
};
