export function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

export function setCookie(name, value, options = {}) {
  const { expires } = options;
  document.cookie = `${name}=${value}; Path=${window.origin}; Expires=${expires};`;
}

export function deleteCookie(name) {
  document.cookie = `${name}=; Path=${window.origin}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}

export const getGlobalAlertDismissalPeriodExpired = () => !getCookie('_hide_global_alert');
