import { createAsyncThunk } from '@reduxjs/toolkit';
import { setSnackbarError, setSnackbarSuccess } from 'appRedux/actions';
import { get } from 'utils/lodash';
import history from 'utils/history';
import { fm } from 'utils/string';
import { getFormattedEmployeeRequestsList } from './selector';
import {
  validateAndGetRequest,
} from './validator';

export const requestEmployeeRequests = createAsyncThunk(
  'requests/fetchRequests',
  async (filters, { extra, rejectWithValue, dispatch }) => {
    try {
      const params = {
        page: filters.page,
        per_page: filters.perPage,
        start_date: filters.startDate,
      };
      const response = await extra.axios.get('/api/v4/employee_requests', { params });
      const { records, ...nextFilters } = getFormattedEmployeeRequestsList(response.data.data.requests);
      return { records, filters: nextFilters };
    } catch (err) {
      dispatch(setSnackbarError('snackbar.employeeView.gettingRequests.error'));
      return rejectWithValue(err);
    }
  },
);

export const requestCreateEmployeeRequest = createAsyncThunk(
  'requests/createEmployeeRequest',
  async (request, { extra, rejectWithValue, dispatch, getState }) => {
    try {
      const body = await validateAndGetRequest(request);
      body.employee_id = getState().employee.profile.user.id;
      await extra.axios.post('/api/v4/employee_requests', { employee_request: body });
      dispatch(setSnackbarSuccess('snackbar.employeeView.request.created'));
      history.goBack();
      return {};
    } catch (err) {
      if (err.name === 'ValidationError') {
        return rejectWithValue(err.message);
      }
      if (get('response.status')(err) === 409) {
        return rejectWithValue(err.response.data.errors[0].details.error);
      }
      return rejectWithValue(err);
    }
  },
);

export const requestUpdateEmployeeRequest = createAsyncThunk(
  'requests/updateEmployeeRequest',
  async (request, { extra, rejectWithValue, dispatch }) => {
    try {
      const body = await validateAndGetRequest(request);
      await extra.axios.put(`/api/v4/employee_requests/${request.sequenceNum}`, body);
      dispatch(setSnackbarSuccess('snackbar.requests.updated'));
      history.goBack();
      return { request: {}, resfreshAllRequests: {} };
    } catch (err) {
      if (err.name === 'ValidationError') {
        return rejectWithValue(err.message);
      }
      if (get('response.status')(err) === 409) {
        dispatch(setSnackbarError('snackbar.requests.duplicateRequest'));
      }
      dispatch(setSnackbarError('snackbar.requests.update.error'));
      return rejectWithValue(err);
    }
  },
);

export const requestDeleteEmployeeRequest = createAsyncThunk(
  'requests/deleteEmployeeRequest',
  async (requestIds, { extra, rejectWithValue, dispatch }) => {
    try {
      const data = { employee_requests: requestIds };
      await extra.axios.delete('/api/v4/employee_requests/bulk_delete', { data });
      history.goBack();
      dispatch(setSnackbarSuccess('snackbar.requests.delete'));
    } catch (err) {
      dispatch(setSnackbarError('snackbar.employeeView.requestUpdate.error'));
      return rejectWithValue(err);
    }
  },
);
