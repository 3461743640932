import { requestSearchCompanies } from 'appRedux/thunks/superAdmin';
import { requestCreateCuystomerKey } from 'appRedux/thunks/keys';
import {
  requestCustomerSuggestions,
  requestSupervisorSuggestions,
  requestEmployeeSuggestions,
  requestServiceSuggestions,
  requestItemSuggestions,
  requestSearchTaskCategory,
  requestKeysSuggestions,
  requestVismaItemNumberSuggestions,
  requestUserGroupSuggestions,
  requestDepartmentSuggestions,
  requestAreaSuggestions,
  requestIntegratedItemSuggestions,
  requestSuperAdminUserSuggestions,
  requestProposalTemplateSuggestions,
  requestCustomerAreaSuggestions,
  requestCustomerZipcodeSuggestions,
  requestSkillsSuggestions,
} from 'appRedux/thunks/autocomplete';
import {
  setVismaItemSuggestions,
  setServiceSuggestions,
  setCustomerSuggestions,
  setEmployeeSuggestions,
  setItemSuggestions,
  setSuperAdminFields,
  setSupervisorSuggestions,
  setTasksFields,
  setKeySuggestions,
  setVismaItemNumberSuggestions,
  setAutoCompleteFields,
  setUserGroupSuggestions,
  setDepartmentSuggestions,
  setAreaSuggestions,
  setCustomerAreaSuggestions,
  setFortnoxItemSuggestions,
  setSuperAdminUserSuggestions,
  setSkillsSuggestions,
  setCustomerZipcodeSuggestions,
} from 'appRedux/actions';
import { setProposalTemplateFields } from 'appRedux/owner/proposals';
import { renderCustomerOption } from './renderOptions';
import { CustomerPaper } from './paperComponents';

export const objectTypes = {
  service: 1,
  customer: 2,
  employee: 3,
  item: 4,
  company: 5,
  supervisor: 6,
  vismaItem: 7,
  taskCategory: 8,
  key: 9,
  vismaItemNumber: 10,
  userGroup: 11,
  fortnoxItem: 12,
  department: 13,
  superAdminUser: 14,
  area: 15,
  bookingId: 16,
  timeCode: 17,
  tags: 18,
  proposalTemplates: 19,
  projectId: 20,
  fortnoxAccounts: 21,
  employees: 22,
  services: 23,
  customerArea: 25,
  skills: 26,
  customerZipcode: 27,
};

const createObjectFuns = {
  [objectTypes.key]: requestCreateCuystomerKey,
};

const searchFuns = {
  [objectTypes.service]: requestServiceSuggestions,
  [objectTypes.customer]: requestCustomerSuggestions,
  [objectTypes.employee]: requestEmployeeSuggestions,
  [objectTypes.item]: requestItemSuggestions,
  [objectTypes.company]: requestSearchCompanies,
  [objectTypes.supervisor]: requestSupervisorSuggestions,
  [objectTypes.vismaItem]: requestIntegratedItemSuggestions,
  [objectTypes.taskCategory]: requestSearchTaskCategory,
  [objectTypes.key]: requestKeysSuggestions,
  [objectTypes.vismaItemNumber]: requestVismaItemNumberSuggestions,
  [objectTypes.userGroup]: requestUserGroupSuggestions,
  [objectTypes.department]: requestDepartmentSuggestions,
  [objectTypes.area]: requestAreaSuggestions,
  [objectTypes.fortnoxItem]: requestIntegratedItemSuggestions,
  [objectTypes.superAdminUser]: requestSuperAdminUserSuggestions,
  [objectTypes.proposalTemplates]: requestProposalTemplateSuggestions,
  [objectTypes.employees]: requestEmployeeSuggestions,
  [objectTypes.services]: requestServiceSuggestions,
  [objectTypes.customerArea]: requestCustomerAreaSuggestions,
  [objectTypes.skills]: requestSkillsSuggestions,
  [objectTypes.customerZipcode]: requestCustomerZipcodeSuggestions,
};

const resetFuns = {
  [objectTypes.service]: setServiceSuggestions,
  [objectTypes.userGroup]: setUserGroupSuggestions,
  [objectTypes.customer]: setCustomerSuggestions,
  [objectTypes.employee]: setEmployeeSuggestions,
  [objectTypes.item]: setItemSuggestions,
  [objectTypes.company]: setSuperAdminFields,
  [objectTypes.supervisor]: setSupervisorSuggestions,
  [objectTypes.vismaItem]: setVismaItemSuggestions,
  [objectTypes.taskCategory]: setTasksFields,
  [objectTypes.key]: setKeySuggestions,
  [objectTypes.vismaItemNumber]: setVismaItemNumberSuggestions,
  [objectTypes.department]: setDepartmentSuggestions,
  [objectTypes.area]: setAreaSuggestions,
  [objectTypes.fortnoxItem]: setFortnoxItemSuggestions,
  [objectTypes.superAdminUser]: setSuperAdminUserSuggestions,
  [objectTypes.proposalTemplates]: setProposalTemplateFields,
  [objectTypes.employees]: setEmployeeSuggestions,
  [objectTypes.services]: setServiceSuggestions,
  [objectTypes.customerArea]: setCustomerAreaSuggestions,
  [objectTypes.customerZipcode]: setCustomerZipcodeSuggestions,
  [objectTypes.skills]: setSkillsSuggestions,
};

export const suggestionStateMapFn = (state, ownProps) => {
  const objectTypeData = {
    [objectTypes.service]: state.autocomplete.service,
    [objectTypes.userGroup]: state.autocomplete.userGroup,
    [objectTypes.customer]: state.autocomplete.customer,
    [objectTypes.employee]: state.autocomplete.employee,
    [objectTypes.item]: state.autocomplete.item,
    [objectTypes.company]: state.superAdmin,
    [objectTypes.supervisor]: state.autocomplete.supervisor,
    [objectTypes.vismaItem]: state.autocomplete.vismaItem,
    [objectTypes.taskCategory]: state.autocomplete.taskCategories,
    [objectTypes.key]: state.autocomplete.key,
    [objectTypes.vismaItemNumber]: state.autocomplete.vismaItemNumber,
    [objectTypes.department]: state.autocomplete.department,
    [objectTypes.area]: state.autocomplete.area,
    [objectTypes.fortnoxItem]: state.autocomplete.fortnoxItem,
    [objectTypes.superAdminUser]: state.autocomplete.superAdminUser,
    [objectTypes.proposalTemplates]: state.proposals.proposalTemplates.templateSuggestions,
    [objectTypes.employees]: state.autocomplete.employee,
    [objectTypes.services]: state.autocomplete.service,
    [objectTypes.customerArea]: state.autocomplete.customerArea,
    [objectTypes.customerZipcode]: state.autocomplete.customerZipcode,
    [objectTypes.skills]: state.autocomplete.skills,
  }[ownProps.objectType] || {};
  return {
    objectTypeData,
    suggestions: objectTypeData.records,
    application: state.application,
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => ({
  searchText: (...args) => dispatch(searchFuns[ownProps.objectType](...args)),
  resetRecords: (...args) => dispatch(resetFuns[ownProps.objectType](...args)),
  createObject: (...args) => dispatch(createObjectFuns[ownProps.objectType](...args)),
  setAutoCompleteFields: (...args) => dispatch(setAutoCompleteFields(...args)),
});

export const renderOptions = {
  [objectTypes.customer]: renderCustomerOption,
};

export const paperComponents = {
  [objectTypes.customer]: CustomerPaper,
};
