import { fm } from 'utils/string';
import { reportTimeTypeNames } from 'utils/enum';
import {
  LabelIcon,
  DateRangeIcon,
  PersonIcon,
  LensIcon,
  CategoryIcon,
  ReceiptIcon,
  ScheduleIcon,
  DoneAllRoundedIcon,
  AvTimerIcon,
  BusinessCenterIcon,
  GroupIcon,
  PublishIcon,
  PeopleIcon,
  AssignmentIndIcon,
  TitleIcon,
  LocationCityIcon,
  SortIcon,
  LoopIcon,
  RepeatIcon,
  PsychologyIcon,
  DrawIcon,
  LocationOnIcon,
} from 'components/library/icons';

export const bookingStatusLabelFormatter = (key) => ({
  view_all: fm('view_all'),
  active: fm('active', 'Active'),
  completed: fm('completed', 'Completed'),
  canceled: fm('cancelled', 'Cancelled'),
})[key];

const customerTypeLabelFormatter = (key) => ({
  type: 'View All',
  organization: 'Organization',
  private: 'Private',
})[key];

const customerTypeEnumLabelFormatter = (key) => ({
  1: 'Private',
  2: 'Organization',
})[key];

const bookingPriceLabelFormatter = (key) => ({
  view_all: fm('view_all'),
  hasItem: fm('booking_items_exist'),
  noItem: fm('booking_items_missing'),
})[key];

const invoiceStatusLabelFormatter = (key) => ({
  view_all: fm('view_all'),
  sent: fm('sent'),
  notSent: fm('not_sent'),
  noItem: fm('missing_items'),
})[key];

export const orderStatusLabelFormatter = (key) => ({
  view_all: fm('view_all'),
  active: fm('active', 'Active'),
  freeze: fm('freeze', 'freeze'),
  completed: fm('completed', 'Completed'),
  canceled: fm('cancelled', 'Cancelled'),
})[key.toLowerCase()];

export default {
  startDate: { Icon: DateRangeIcon, accessor: 'startDate', name: 'startDate', indelible: true },
  endDate: { Icon: DateRangeIcon, accessor: 'endDate', name: 'endDate', indelible: true },
  customerType: {
    Icon: BusinessCenterIcon,
    accessor: 'customerType',
    name: 'customerType',
    formatter: (d) => customerTypeLabelFormatter(d) || customerTypeEnumLabelFormatter(d),
  },
  bookingStatus: {
    Icon: LensIcon,
    accessor: 'bookingStatus',
    name: 'bookingStatus',
    title: fm('booking_status', 'Booking Status'),
    indelible: true,
    formatter: (records) => (
      (Array.isArray(records) && records.length)
        ? `${records[0].label} ${records.length > 1 ? `+ ${records.length - 1}` : ''}`
        : bookingStatusLabelFormatter(records)
    ),
  },
  bookingPrice: {
    Icon: CategoryIcon,
    accessor: 'bookingPrice',
    name: 'bookingPrice',
    title: fm('booking_items_missing_or_not'),
    indelible: true,
    formatter: bookingPriceLabelFormatter,
  },
  invoiceStatus: {
    Icon: ReceiptIcon,
    accessor: 'invoiceStatus',
    name: 'invoiceStatus',
    title: fm('filter.invoice-status'),
    formatter: invoiceStatusLabelFormatter,
  },
  customer: { Icon: PersonIcon, accessor: 'customer.name', name: 'customer', title: fm('customer', 'Customer') },
  employee: { Icon: PeopleIcon, accessor: 'employee.name', name: 'employee', title: fm('employee', 'Employee') },
  userGroup: { Icon: GroupIcon, accessor: 'userGroup.title', name: 'userGroup', title: fm('user_group', 'User group') },
  employees: {
    Icon: PeopleIcon,
    accessor: 'employees',
    name: 'employees',
    formatter: (records) => (
      (records && records.length)
        ? `${records[0].name} ${records.length > 1 ? `+ ${records.length - 1}` : ''}`
        : null
    ),
    title: fm('employees', 'Employees'),
  },
  service: { Icon: TitleIcon, accessor: 'service.name', name: 'service', title: fm('service', 'Service') },
  services: {
    Icon: TitleIcon,
    accessor: 'services',
    name: 'services',
    defaultValue: [],
    formatter: (records) => (
      (records && records.length)
        ? `${records[0].title} ${records.length > 1 ? `+ ${records.length - 1}` : ''}`
        : null
    ),
    title: fm('services', 'Services'),
  },
  bookingId: { Icon: AssignmentIndIcon, accessor: 'bookingId', name: 'bookingId' },
  tag: { Icon: LabelIcon, accessor: 'tag.title', name: 'tag', title: fm('tag', 'Tag') },
  publishStatus: {
    Icon: PublishIcon,
    accessor: 'publishStatus',
    name: 'publishStatus',
    title: fm('published'),
    formatter: (d) => ({ true: fm('published_only'), false: fm('unpublished_only') })[d],
  },
  vismaNumber: {
    Icon: AssignmentIndIcon,
    accessor: 'vismaNumber.vismaNumber',
    name: 'vismaNumber',
  },
  timeCodes: {
    Icon: AvTimerIcon,
    accessor: 'timeCodes',
    name: 'timeCodes',
    title: fm('time_code'),
    defaultValue: [],
    formatter: (timeCodes = []) => (
      timeCodes.length > 3
        ? `${timeCodes.slice(0, 2).map((timeCode) => timeCode.title || timeCode.name).join(' | ')}...`
        : timeCodes.map((timeCode) => timeCode.title || timeCode.name).join(' | ')
    ),
  },
  codeType: {
    Icon: ScheduleIcon,
    accessor: 'codeType',
    name: 'codeType',
    defaultValue: 'view_all',
    title: fm('type', 'Type'),
    formatter: (d) => reportTimeTypeNames[d],
  },
  approved: {
    Icon: DoneAllRoundedIcon,
    accessor: 'approved',
    name: 'approved',
    defaultValue: 'view_all',
    title: fm('approved', 'Approved Status'),
    formatter: (d) => ({
      true: fm('approved', 'Approved'),
      false: fm('not_approved', 'Not Approved'),
    })[d],
  },
  shiftType: {
    Icon: ScheduleIcon,
    accessor: 'shiftType',
    name: 'shiftType',
    defaultValue: 'view_all',
    title: fm('shift_type', 'Shift Type'),
    formatter: (d) => ({
      booking: fm('booking_time', 'Booking time'),
      shift_time: fm('internal_time', 'Internal Time'),
      order_time: fm('working_time', 'Working Time'),
    })[d],
  },
  employeePunches: {
    Icon: ScheduleIcon,
    accessor: 'employeePunches',
    name: 'employeePunches',
    defaultValue: 'view_all',
    title: fm('employee_punches', 'Employee punches'),
    formatter: (d) => ({
      missing: fm('punch_in_out_missing'),
      less_then_scheduled: fm('punch_in_out_less'),
      location_missing: fm('punch_in_out_location_missing'),
    })[d],
  },
  department: {
    Icon: CategoryIcon,
    accessor: 'department.title',
    name: 'department',
    defaultValue: {},
    title: fm('departments'),
  },
  area: {
    Icon: LocationCityIcon,
    accessor: 'area.title',
    name: 'area',
    defaultValue: {},
    title: fm('area'),
  },
  userActiveStatus: {
    Icon: PersonIcon,
    accessor: 'userActiveStatus',
    name: 'userActiveStatus',
    defaultValue: 'view_all',
    title: fm('user_status'),
    formatter: (d) => ({ true: fm('active'), false: fm('deactivated') })[d],
  },
  projectId: {
    Icon: AssignmentIndIcon,
    accessor: 'projectId',
    name: 'projectId',
    defaultValue: '',
    title: fm('project_id'),
  },
  orderStatus: {
    Icon: LensIcon,
    accessor: 'orderStatus',
    name: 'orderStatus',
    title: fm('order_status', 'Order Status'),
    indelible: true,
    formatter: (records) => (
      (Array.isArray(records) && records.length)
        ? `${records[0].label} ${records.length > 1 ? `+ ${records.length - 1}` : ''}`
        : orderStatusLabelFormatter(records)
    ),
  },
  orderId: { Icon: AssignmentIndIcon, accessor: 'orderId', name: 'orderId' },
  active: {
    Icon: SortIcon,
    accessor: 'active',
    name: 'active',
    formatter: (d) => ({ true: fm('active_projects'), false: fm('past_projects') })[d],
    defaultValue: 'view_all',
  },
  nonRecurring: {
    Icon: LoopIcon,
    accessor: 'nonRecurring',
    name: 'nonRecurring',
    formatter: (d) => ({ true: fm('without_recurrence') })[d],
    defaultValue: 'view_all',
  },
  projectOrders: {
    Icon: RepeatIcon,
    accessor: 'projectOrders',
    name: 'projectOrders',
    formatter: (d) => ({ true: fm('repeating_orders'), false: fm('non_repeating_orders') })[d],
    defaultValue: 'view_all',
  },
  customerArea: {
    Icon: LocationCityIcon,
    accessor: 'customerArea.title',
    name: 'customerArea',
    defaultValue: '',
    title: fm('customer_area'),
  },
  customerZipcode: {
    Icon: LocationOnIcon,
    accessor: 'customerZipcode.title',
    name: 'customerZipcode',
    defaultValue: '',
    title: fm('customer_zipcode'),
  },
  skills: {
    Icon: PsychologyIcon,
    accessor: 'skill.title',
    name: 'skill',
    defaultValue: '',
    title: fm('skills'),
  },
  proposalSignedStatus: {
    Icon: DrawIcon,
    accessor: 'signedStatus',
    name: 'signedStatus',
    defaultValue: 'view_all',
    title: fm('signedMethod', 'Signed method'),
    formatter: (d) => ({ name: fm('name'), bankid: 'BankID' })[d],
  },
};
