import { createAsyncThunk } from '@reduxjs/toolkit';
import { setSnackbarError } from 'appRedux/actions';
import { getCurrentPosition } from 'utils/helpers';
import { getStandardDate, startOfMonth, endOfMonth } from 'utils/dateTime';

const createReportThunk = (endpointName, endpoint) => createAsyncThunk(
  `dashboard/${endpointName}`,
  async (name, { extra, dispatch, getState }) => {
    try {
      const { filters } = extra.getState(name, getState);
      const params = {
        start_date: filters.startDate ?? getStandardDate(startOfMonth(new Date())),
        end_date: filters.endDate ?? getStandardDate(endOfMonth(new Date())),
      };
      const response = await extra.axios.get(`/api/v3/reports/${endpoint}`, { params });
      return response.data.data;
    } catch (err) {
      dispatch(setSnackbarError(`snackbar_dashboard_${endpoint}_error`));
      throw err;
    }
  },
);

export const requestOrdersSummary = createReportThunk('ordersSummary', 'orders_summary');
export const requestProposalsSummary = createReportThunk('proposalsSummary', 'proposals_summary');
export const requestInternalTimeSummary = createReportThunk('internalTimeSummary', 'internal_time_summary');
export const requestScheduledHours = createReportThunk('scheduledHours', 'scheduled_hours');

export const getWeatherData = createAsyncThunk(
  'dashboard/getWeatherData',
  async (name, { extra, dispatch, getState }) => {
    try {
      const { profile: { user } } = getState();
      const getLocation = async () => {
        try {
          const coords = await getCurrentPosition();
          return coords;
        } catch (err) {
          return { city: user.city };
        }
      };
      const { city, coords } = await getLocation();
      const params = {
        lang: localStorage.getItem('language'),
        ...(city && { q: city }),
        ...(coords && {
          lat: coords.latitude.toString(),
          lon: coords.longitude.toString(),
        }),
      };

      if (!params.q && !params.lat) return;
      const response = await extra.axios.get('https://avalon.bokningoschema.se/api/v1/weather', { params });
      const weatherDetails = response.data;
      const weatherTemperature = Math.floor(weatherDetails.main.temp - 273.15);
      const weatherDetail = weatherDetails.weather[0].description;
      const weatherIcon = weatherDetails.weather[0].icon;
      return { weatherTemperature, weatherDetail, weatherIcon };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_dashboard_weather_error'));
    }
  },
);
