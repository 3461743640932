import { createAsyncThunk } from '@reduxjs/toolkit';
import { setSnackbarError, setSnackbarSuccess, setFilters } from 'appRedux/actions';
import { get } from 'utils/lodash';

export const requestOpenShifts = createAsyncThunk(
  'openShifts/fetchShifts',
  async (name, { extra, dispatch, getState }) => {
    const { filters } = extra.getState(name, getState);
    try {
      const params = {
        page: filters.page,
        per_page: filters.perPage,
        statuses: filters.status,
      };
      const response = await extra.axios.get('/api/v3/employee/open_shifts', { params });
      const { records, ...nextFilters } = response.data.data;
      dispatch(setFilters({ ...nextFilters, name }));
      return { records };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_available_shifts_error'));
    }
  },
);

export const requestClaimOpenShift = createAsyncThunk(
  'openShifts/claimOpenShift',
  async (data, { extra, dispatch }) => {
    try {
      const params = {
        open_shift_id: data.id,
        open_shift_status: data.status,
      };
      await extra.axios.post('/api/v3/employee/claim_open_shift', params);
      dispatch(setSnackbarSuccess('claim_open_shift_success'));
      data.history.goBack();
    } catch (err) {
      const error = get('response.data.errors[0].status')(err);
      if (error === 422) {
        return dispatch(setSnackbarError('snackbar_open_shift_claim_deny_error'));
      }
      dispatch(setSnackbarError('snackbar_open_shift_claim_error'));
    }
  },
);

export const requestOpenShift = createAsyncThunk(
  'openShifts/fetchShift',
  async (openShiftId, { extra, dispatch }) => {
    try {
      const params = { open_shift_id: openShiftId };
      const response = await extra.axios.get('/api/v3/employee/single_open_shift', { params });
      const { openShift } = response.data.data;
      return { openShift };
    } catch (err) {
      dispatch(setSnackbarError('snackbar_available_shifts_error'));
    }
  },
);
