/* eslint-disable camelcase */
import axios from 'utils/axios';
import { getFormattedListData } from 'appRedux/utility';
import { omit } from 'utils/lodash';
import { multiStateGet } from 'appRedux/reducers/utility';
import { setFilters, setSuperAdminCompanySettingsFields, setSnackbarError } from 'appRedux/actions';
import snakify from 'utils/snakify';

export const requestCompanySettings = (name) => async (dispatch, getState) => {
  const { filters } = getState();
  try {
    const { page, perPage: per_page } = multiStateGet(name, filters);
    const params = { page, per_page };
    const response = await axios.get('/api/v3/super_admin/settings', { params });
    const { records, ...filtersFields } = getFormattedListData(response.data.data);
    dispatch(setSuperAdminCompanySettingsFields({ records }));
    dispatch(setFilters({ name, ...filtersFields }));
  } catch (err) {
    dispatch(setSnackbarError('something_went_wrong'));
  }
};

export const requestCreateCompanySetting = (details) => async (dispatch) => {
  try {
    const requestBody = { setting: { ...snakify(omit(['value'])(details)) }, value: details.value };
    await axios.post('/api/v3/super_admin/settings', requestBody);
    dispatch(setSuperAdminCompanySettingsFields({
      refreshAllCompanySettings: {},
      companySetting: {},
      companySettingId: null,
      showCompanySettingDialog: false,
    }));
  } catch (err) {
    dispatch(setSnackbarError('something_went_wrong'));
  }
};

export const requestUpdateCompanySetting = (details) => async (dispatch) => {
  try {
    const requestBody = { setting: snakify(details) };
    await axios.put(`/api/v3/super_admin/settings/${details.id}`, requestBody);
    dispatch(setSuperAdminCompanySettingsFields({
      refreshAllCompanySettings: {},
      companySetting: {},
      companySettingId: null,
      showCompanySettingDialog: false,
    }));
  } catch (err) {
    dispatch(setSnackbarError('something_went_wrong'));
  }
};

export const requestDeleteCompanySetting = (companySettingId) => async (dispatch) => {
  try {
    await axios.delete(`/api/v3/super_admin/settings/${companySettingId}`);
    dispatch(setSuperAdminCompanySettingsFields({
      refreshAllCompanySettings: {},
      companySetting: {},
      companySettingId: null,
      showCompanySettingDialog: false,
    }));
  } catch (err) {
    dispatch(setSnackbarError('something_went_wrong'));
  }
};
