import * as dateFns from 'date-fns';
import camelize from 'camelize';
import { bookingTimeTypes, projectTimeTypesString } from './enum';
import { fm } from './string';

export const camelToSnakeCase = (str) => str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const parseJson = (d) => (d ? camelize(JSON.parse(d)) : null);

export const getStandardDate = (d) => dateFns.format(d, 'yyyy-MM-dd');

export const getTimeFromNumber = (seconds) => {
  if (isNaN(seconds)) return '00:00';
  const endDate = dateFns.addSeconds(new Date(0), seconds);
  const minutes = dateFns.differenceInMinutes(endDate, new Date(0)) % 60 || 0;
  const hours = dateFns.differenceInHours(endDate, new Date(0)) || 0;
  return `${hours.toString().length === 1
    ? `0${hours}`
    : hours}:${minutes.toString().length === 1
    ? `0${minutes}`
    : minutes}`;
};

export const getParsedTime = (d, t) => Date.parse(`${d} ${t}`);

export const getErrors = (propsError, stateError) => {
  if (Object.keys(stateError || {}).length) {
    return stateError;
  }
  return { ...propsError, ...{ isValid: Object.keys(propsError || {}).length < 1 } };
};

export const isMobileNumber = (n = '') => (!n ? false
  : !isNaN(n) && ((n.startsWith('0') && n.length === 10) || n.length === 9));

export const delay = (time) => new Promise((resolve) => setTimeout(resolve, time));

export const checkIsTimeCodeInvalid = (code) => {
  const invalidCodes = new Set([
    'SJK_KAR',
    'SJK_LÖN',
    'SJK_ERS',
    'SJK_PEN',
    'NÄR',
    'SEM_BET',
    'SEM_SPA',
    'SEM_OBE',
    'SEM_FÖR',
    'FAC',
    'ATK',
    'KON',
    'FR',
    'NVX',
    'FRX',
  ]);
  return invalidCodes.has(code);
};

const getBookingTimeForFormatSmsPreview = (booking) => ({
  [bookingTimeTypes.flexTime]: booking.flexTimeType && fm(`flex_time_type_${booking.flexTimeType}`),
  [bookingTimeTypes.totalHours]: `${booking.totalHours} h`,
  [bookingTimeTypes.specificTime]: booking.startTime,
})[booking.timeType];

export const formatSmsPreview = (sms, company, booking) => {
  let templateDescription = '';
  let customerAddress = '';

  if (booking && booking.customer && booking.customer.address) {
    customerAddress = booking.customer.address;

    if (booking.customer.zipCode) {
      customerAddress += `, ${booking.customer.zipCode}`;

      if (booking.customer.area) {
        customerAddress += ` ${booking.customer.area}`;
      }
    }
  }

  templateDescription = sms.replaceAll('{CUSTOMER_NAME}', booking ? booking.customerName : 'CUSTOMER NAME')
    .replaceAll('{BOOKING_DATE}', booking ? booking.startDate : getStandardDate(new Date()))
    .replaceAll('{BOOKING_TIME}', booking ? getBookingTimeForFormatSmsPreview(booking) : 'BOOKING_TIME')
    .replaceAll('{COMPANY_NAME}', company.name ? company.name : 'COMPANY NAME')
    .replaceAll('{COMPANY_EMAIL}', company.email ? company.email : 'COMPANY EMAIL')
    .replaceAll('{COMPANY_MOBILE}', company.mobile ? company.mobile : 'COMPANY MOBILE')
    .replaceAll('{CUSTOMER_ADDRESS}', customerAddress !== '' ? customerAddress : 'CUSTOMER ADDRESS')
    .replaceAll('{SERVICE_NAME}', `${booking ? booking.service : 'SERVICE NAME'}`);
  return templateDescription;
};

const getOrderTimeForFormatSmsPreview = (order) => ({
  [projectTimeTypesString.flexTime]: order.flexTimeType && fm(`flex_time_type_${order.flexTimeType}`),
  [projectTimeTypesString.totalHours]: `${order.totalHours} h`,
  [projectTimeTypesString.specificTime]: `${order.startTime} - ${order.endTime}`,
})[order.timeType];

export const formatOrderSmsPreview = (sms, company, order) => {
  let templateDescription = '';
  let customerAddress = '';

  if (order && order.customer && order.customer.address) {
    customerAddress = order.customer.address;

    if (order.customer.zipCode) {
      customerAddress += `, ${order.customer.zipCode}`;

      if (order.customer.area) {
        customerAddress += ` ${order.customer.area}`;
      }
    }
  }

  templateDescription = sms.replaceAll('{CUSTOMER_NAME}', order ? order.customer?.name : 'CUSTOMER NAME')
    .replaceAll('{BOOKING_DATE}', order ? order.date : getStandardDate(new Date()))
    .replaceAll('{ORDER_DATE}', order ? order.date : getStandardDate(new Date()))
    .replaceAll('{BOOKING_TIME}', order ? getOrderTimeForFormatSmsPreview(order) : 'BOOKING_TIME')
    .replaceAll('{ORDER_TIME}', order ? getOrderTimeForFormatSmsPreview(order) : '-')
    .replaceAll('{COMPANY_NAME}', company.name ? company.name : 'COMPANY NAME')
    .replaceAll('{START_TIME}', order.startTime ? order.startTime : getOrderTimeForFormatSmsPreview(order))
    .replaceAll('{END_TIME}', order.endTime ? order.endTime : '-')
    .replaceAll('{COMPANY_EMAIL}', company.email ? company.email : 'COMPANY EMAIL')
    .replaceAll('{COMPANY_MOBILE}', company.mobile ? company.mobile : 'COMPANY MOBILE')
    .replaceAll('{CUSTOMER_ADDRESS}', customerAddress !== '' ? customerAddress : 'CUSTOMER ADDRESS')
    .replaceAll('{SERVICE_NAME}', `${order ? order.orderService?.title : 'SERVICE NAME'}`);
  return templateDescription;
};

export const formatProjectSmsPreview = (sms, company, project) => {
  let templateDescription = '';
  let customerAddress = '';

  if (project && project.customer && project.customer.address) {
    customerAddress = project.customer.address;

    if (project.customer.zipCode) {
      customerAddress += `, ${project.customer.zipCode}`;

      if (project.customer.area) {
        customerAddress += ` ${project.customer.area}`;
      }
    }
  }

  templateDescription = sms.replaceAll('{CUSTOMER_NAME}', project ? project.customer?.name : 'CUSTOMER NAME')
    .replaceAll('{BOOKING_DATE}', project ? project.startDate : getStandardDate(new Date()))
    .replaceAll('{ORDER_DATE}', project ? project.startDate : getStandardDate(new Date()))
    .replaceAll('{BOOKING_TIME}', project ? getOrderTimeForFormatSmsPreview(project) : 'BOOKING_TIME')
    .replaceAll('{ORDER_TIME}', project ? getOrderTimeForFormatSmsPreview(project) : '-')
    .replaceAll('{COMPANY_NAME}', company.name ? company.name : 'COMPANY NAME')
    .replaceAll('{START_TIME}', project.startTime ? project.startTime : getOrderTimeForFormatSmsPreview(project))
    .replaceAll('{END_TIME}', project.endTime ? project.endTime : '-')
    .replaceAll('{COMPANY_EMAIL}', company.email ? company.email : 'COMPANY EMAIL')
    .replaceAll('{COMPANY_MOBILE}', company.mobile ? company.mobile : 'COMPANY MOBILE')
    .replaceAll('{CUSTOMER_ADDRESS}', customerAddress !== '' ? customerAddress : 'CUSTOMER ADDRESS')
    .replaceAll('{SERVICE_NAME}', `${project ? project.projectService?.title : 'SERVICE NAME'}`);
  return templateDescription;
};

export const getCurrentPosition = (options) => {
  if (!navigator.geolocation) return null;
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, options);
  });
};

export const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());

    if (a === b) continue; // eslint-disable-line

    return a > b || Number.isNaN(b);
  }
  return false;
};

export function throttle(wait, fn) {
  let lastCallTime = 0;

  return function throttleInner (...args) {
    const now = new Date().getTime();
    if (now - lastCallTime >= wait) {
      lastCallTime = now;
      fn.apply(this, args);
    }
  };
}
