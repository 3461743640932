import pick from 'lodash/fp/pick';
import camelize from 'camelize';
import { names } from 'utils/enum';
import { parseJson, getFormattedPaginationFields } from './utility';

const pickFieldWithCommonNames = pick(['country', 'address2', 'id', 'name', 'remarks', 'key', 'longitude', 'latitude', 'email', 'email2', 'mobile', 'telephone', 'address', 'area', 'city']);

export const getFormattedCustomerData = (customer) => ({
  ...camelize(customer),
  keys: customer?.customer_key_managements?.map(
    (key) => camelize({ ...key.key_management, keyId: key.key_management.id, id: key.id }),
  ),
  customerAddresses: customer?.customer_addresses?.map((customerAddress) => ({
    ...customerAddress,
    addressType: customerAddress.address_type,
    zipCode: customerAddress.zip_code,
    showToBookings: customerAddress.show_to_bookings,
  })),
  taxPayersAttributes: customer?.tax_payers?.map((taxPayer) => camelize(taxPayer)),
  ...(customer.visma_customers && { vismaCustomers: parseJson(customer.visma_customers) }),
  ...(customer.visma_customer && { vismaCustomer: camelize(customer.visma_customer) }),
});

const getFortnoxId = (customer) => {
  if (customer.fortnoxId && !customer.customerId) {
    return { fortnox_id: customer.fortnoxId };
  }
};

export const getFormattedCustomerDataForAPIRequest = (customer, profile) => ({
  export: customer.export,
  fields_to_export: customer.fieldsToExport && {
    export_all: customer.fieldsToExport.exportAll,
    name: customer.fieldsToExport.name,
    customer_number: customer.fieldsToExport.customerNumber,
    invoice_address: customer.fieldsToExport.invoiceAddress,
    zip_code: customer.fieldsToExport.zipCode,
    our_reference: customer.fieldsToExport.ourReference,
    your_reference: customer.fieldsToExport.yourReference,
    invoice_admin_fee: customer.fieldsToExport.invoiceAdminFee,
    invoice_delivery_method: customer.fieldsToExport.invoiceDeliveryMethod,
    remarks: customer.fieldsToExport.remarks,
    vat_type: customer.fieldsToExport.vatType,
    payment_terms: customer.fieldsToExport.paymentTerms,
    vat_number: customer.fieldsToExport.vatNumber,
    vat_included: customer.fieldsToExport.vatIncluded,
    additional_info: customer.fieldsToExport.additionalInfo,
    telephone: customer.fieldsToExport.telephone,
    mobile: customer.fieldsToExport.mobile,
    email: customer.fieldsToExport.email,
    invoice_email: customer.fieldsToExport.invoiceEmail,
    is_organisation: customer.fieldsToExport.isOrganisation,
    invoice_address2: customer.fieldsToExport.invoiceAddress2,
    invoice_city: customer.fieldsToExport.invoiceCity,
    delivery_address: customer.fieldsToExport.deliveryAddress,
    visiting_address: !profile.fortnoxIntegrated && customer.fieldsToExport.visitingAddress,
    area: customer.fieldsToExport.area,
    customer_reference: customer.fieldsToExport.customerReference,
    sales_account: profile?.vismaIntegrated && customer.fieldsToExport.salesAccount,
  },
  fortnox_id: customer.customFortnoxId,
  customer: {
    show_to_bookings: customer.showToBookings,
    customer_number: customer.customerNumber,
    reference_number: customer.referenceNumber,
    is_organisation: customer.isOrganisation,
    contact_name: customer.contactName,
    zip_code: customer.zipCode,
    door_code: customer.doorCode,
    additional_info: customer.additionalInfo,
    interest_included: customer.interestIncluded,
    vat_included: customer.vatIncluded,
    payment_terms: customer.paymentTerms,
    vat_number: customer.vatNumber,
    vat_type: customer.vatType,
    our_reference: customer.ourReference,
    your_reference: customer.yourReference,
    invoice_delivery_method: customer.invoiceDeliveryMethod,
    invoice_email: customer.invoiceEmail,
    invoice_type: customer.invoiceType,
    warning_icon: customer.warningIcon,
    email_invoice_bcc: customer.emailInvoiceBcc,
    rot_property_number: customer.rotPropertyNumber,
    rot_cin: customer.rotCin,
    invoice_admin_fee: customer.invoiceAdminFee,
    visma_payment_term_id: customer.vismaPaymentTermId,
    customer_key_managements_attributes: customer?.keys?.map((key) => ({
      id: key.id,
      key_management_id: key.keyId,
      _destroy: key.destroy,
    })),
    customer_addresses_attributes: customer?.customerAddresses?.map((address) => ({
      latitude: address.latitude,
      longitude: address.longitude,
      address: address.address,
      area: address.area,
      address_type: address.addressType,
      zip_code: address.zipCode,
      show_to_bookings: address.showToBookings,
      title: address.title,
      address2: address.address2,
      id: address.id,
      _destroy: address.destroy,
    })),
    ...(customer.vismaCustomer && {
      visma_customer_attributes: {
        id: customer.vismaCustomer.id,
        visma_id: customer.vismaCustomer.vismaId,
        existing_customer: customer.existingCustomer,
        visma_number: customer.vismaCustomer.vismaNumber,
        payment_term_id: customer.vismaCustomer.paymentTermId,
      },
    }),
    tax_payers_attributes: customer?.taxPayersAttributes?.map((taxPayer) => ({
      name: taxPayer.name,
      personal_number: taxPayer.personalNumber,
      _destroy: taxPayer.destroy,
      id: taxPayer.id,
    })),
    ...pickFieldWithCommonNames(customer),
    ...getFortnoxId(customer),
  },
});

export const getFormattedCustomerLogForAPIRequest = (customer) => ({
  customer_log: {
    log_date: customer.releaseDate || customer.logDate,
    log_text: customer.logText,
    internal: customer.internal ? customer.internal : false,
    customer_id: customer.customerId,
  },
});

export const getFormattedCustomerLog = (customer) => camelize(customer);

export const getFormattedCustomerRecords = (customers = []) => customers.map(getFormattedCustomerData);

export const formatCustomerList = (response) => ({
  ...getFormattedPaginationFields(response),
  records: getFormattedCustomerRecords(response.records),
});

export const formatCustomerListResponse = (response) => ({
  ...getFormattedPaginationFields(response),
  records: getFormattedCustomerRecords(response.records),
});

export const formatCustomerLogsList = (customerLogs = []) => camelize(customerLogs);

export const formatCustomerLogsListResponse = (response) => ({
  ...getFormattedPaginationFields(response),
  records: formatCustomerLogsList(response.records),
});

export const getFormattedCustomerSearchParams = (text, customerFilters) => ({
  query: text,
  inactive: customerFilters.customersListName === names.inactiveCustomersList ? true : null,
  page: customerFilters.page,
  per_page: customerFilters.perPage,
});

export const getFormattedCustomerBookingFilterParams = (filters = {}, customerId) => ({
  start_date: filters.startDate,
  end_date: filters.endDate,
  per_page: filters.perPage,
  page: filters.page,
  customer_id: customerId,
});

export const getFormattedCustomerRecurringBookingFilterParams = (filters = {}, customerId) => ({
  per_page: filters.perPage,
  page: filters.page,
  customer_id: customerId,
  advanced_filter: 'current',
});

export const getFormattedCustomersDocumentsRecords = (records) => records.map((record) => ({
  ...record,
  showOnBookings: record.showOnBookings === '1',
}));

const getFormattedListBookingData = (bookings = []) => bookings.map((booking) => {
  const employeeData = parseJson(booking.employeeData) || [];
  return { ...booking, employeeData };
}, []);

export const getformattedCustomerBookingListResponse = (response = {}) => ({
  ...response,
  records: response.records ? getFormattedListBookingData(response.records) : [],
});

export const getFormattedCustomerDocumentsRecords = (response) => ({
  ...getFormattedPaginationFields(response),
  records: response.records.map((record) => ({
    ...record,
    showOnBookings: record.showOnBookings === '1',
  })),
});

export const getFormattedcustomersDocumentsQueryParams = (filters) => ({
  page: filters.page,
  per_page: filters.perPage,
  query: filters.query,
});
