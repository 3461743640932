import { SET_REQUESTS_FIELDS } from '../actions';

const initialState = {
  records: [],
  activities: [],
  selectedRequestIds: [],
  pendingEmployeeRequestsCount: null,
};

function requests (state = initialState, action) {
  switch (action.type) {
    case SET_REQUESTS_FIELDS:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}

export default requests;
