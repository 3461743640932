import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { KeyboardArrowUpIcon, KeyboardArrowDownIcon } from 'components/library/icons';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import { getSelectColumnsElem } from 'components/library/Table/tableUtils';
import CollapsibleContent from './CollapsibleContent';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

export default function CollapsibleRow (props) {
  const { row, columns, dataElementKey, expanded, index } = props;
  const rowExpanded = Boolean(expanded[index]);
  const classes = useRowStyles();

  const onExpandedChange = useCallback(() => {
    props.onExpandedChange({ ...expanded, [index]: !expanded[index] }, index, row);
  }, [expanded]);

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton size="small" onClick={onExpandedChange}>
            {rowExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {props.showRowCheckbox && getSelectColumnsElem(props, row)}
        {columns.map((column) => {
          const { content } = props.getCellContent(column, row);
          return (
            <TableCell key={`${dataElementKey}-${column.id}`} align={column.align}>
              {content}
            </TableCell>
          );
        })}
      </TableRow>
      <TableRow>
        <TableCell className="pb-0 pt-0" colSpan={12}>
          <Collapse in={rowExpanded} timeout="auto" unmountOnExit>
            <CollapsibleContent
              row={row}
              subtableColumns={props.subtableColumns}
              subtableKeyField={props.subtableKeyField}
              getSubtableData={props.getSubtableData}
              onSubtableRowClick={props.onSubtableRowClick}
              getSubtableCellContent={props.getSubtableCellContent}
              showSubtablePagination={props.showSubtablePagination}
              getSubtablePagination={props.getSubtablePagination}
              onChangeSubtablePagination={props.onChangeSubtablePagination}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

CollapsibleRow.propTypes = {
  subtableColumns: PropTypes.arrayOf(PropTypes.shape({})),
  row: PropTypes.shape({}),
  columns: PropTypes.shape([]),
  dataElementKey: PropTypes.number,
  expanded: PropTypes.bool,
  onExpandedChange: PropTypes.func,
  showRowCheckbox: PropTypes.bool,
  getCellContent: PropTypes.func,
  subtableKeyField: PropTypes.number,
  getSubtableCellContent: PropTypes.func,
  getSubtableData: PropTypes.func,
  onSubtableRowClick: PropTypes.func,
  showSubtablePagination: PropTypes.bool,
  getSubtablePagination: PropTypes.func,
  onChangeSubtablePagination: PropTypes.func,
  index: PropTypes.number,
};
