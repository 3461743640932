import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
  requestDepartments,
  requestDeleteDepartment,
  requestCreateDepartment,
  requestUpdateDepartment,
  requestDepartment,
} from './thunk';

const department = createSlice({
  name: 'department',
  initialState: {
    records: [],
    record: {},
    refreshAllDepartments: {},
    showDepartmentDialog: false,
    selectedDepartmentSequenceNum: null,
    openedDepartmentSequenceNum: null,
  },
  reducers: {
    setDepartmentFields (state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(requestDepartment.fulfilled, (state, action) => ({
      ...state,
      record: action.payload.record,
    }));
    builder.addCase(requestDepartments.fulfilled, (state, action) => ({
      ...state,
      records: action.payload.records,
    }));
    builder.addCase(requestDeleteDepartment.fulfilled, (state) => ({
      ...state,
      refreshAllDepartments: {},
    }));
    builder.addMatcher(
      isAnyOf(requestCreateDepartment.fulfilled, requestUpdateDepartment.fulfilled),
      (state) => ({
        ...state,
        openedDepartmentSequenceNum: null,
        showDepartmentDialog: false,
        record: {},
        refreshAllDepartments: {},
        fsip: false,
      }),
    );
  },
});

const { reducer, actions } = department;

export const { setDepartmentFields } = actions;

export default reducer;
