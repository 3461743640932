import { parseJson, getFormattedPaginationFields } from 'appRedux/selectors/utility';
import { flow, sortBy } from 'utils/lodash';
import {
  epochTimeToFloat,
  getTimeFromNumber,
} from 'utils/dateTime';

export const getFormattedBookingChecklists = (response) => {
  const bookingChecklists = Object.values(response).map((bookingChecklist) => ({
    taskCategory: {
      id: bookingChecklist[0].taskCategoryId,
      title: bookingChecklist[0].taskCategoryTitle,
    },
    tasks: bookingChecklist.map((task) => ({
      bookingTaskId: task.id,
      id: task.taskId,
      title: task.taskName,
      completed: task.completed,
    })),
  }));
  return bookingChecklists;
};

export const getFormattedBookingData = (booking) => {
  const parsedNotes = parseJson(booking.notes, booking.notes);
  const notesString = (parsedNotes && typeof parsedNotes === 'object')
    ? parsedNotes.blocks.map((b) => b.data.text).join(' ') : parsedNotes;
  return {
    ...booking,
    notes: notesString,
    customerKeys: booking?.customerKeys?.map((key) => key.keyManagement),
    bookingChecklists: getFormattedBookingChecklists(booking.bookingChecklists || []),
    bookingEmployees: (booking.bookingsEmployees || []).map((employee) => ({
      ...employee,
      breakTime: getTimeFromNumber(employee.breakTime),
      startDate: booking.startDate,
      invoiceTime: employee.invoiceTime ? epochTimeToFloat(employee.invoiceTime) : null,
      employeePunch: booking.punches
        ? booking.punches.filter((punch) => punch.employeeId === employee.employeeId)[0] : [],
    })),
  };
};

const getSortedFormattedEmployeeBookings = (bookingsRecords = {}) => Object.keys(bookingsRecords)
  .reduce((res, acc) => {
    res[bookingsRecords[acc][0].startDate] = flow(
      sortBy((e) => new Date(`${e.startDate} ${e.startTime}`)),
    )(bookingsRecords[acc]);
    return res;
  }, {});

export const getFormattedEmployeeBookingsList = (response = {}) => ({
  ...getFormattedPaginationFields(response),
  records: getSortedFormattedEmployeeBookings(response.records),
});

export const getFormattedPunchInRequestBody = (data) => ({
  booking_id: data.bookingId,
  punch_in_latitude: data.punchInLatitude,
  punch_in_longitude: data.punchInLongitude,
  punch_date: data.punchDate,
  user_agent: data.userAgent,
});

export const getFormattedPunchOutRequestBody = (data) => ({
  out_punch: data.outPunch,
  punch_out_latitude: data.punchOutLatitude,
  punch_out_longitude: data.punchOutLongitude,
  user_agent: data.userAgent,
});
