import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import { number, string } from 'prop-types';
import { Tooltip } from '@mui/material';
import { fm } from 'utils/string';

export default function UpdatedOrder (props) {
  return (
    <Tooltip title={props.title}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        backgroundColor={props.backgroundColor}
        borderRadius="50%"
        width={props.width}
        height={props.height}
        fontSize={props.fontSize}
        color="#fff"
      >
        <EditIcon fontSize="inherit" />
      </Box>
    </Tooltip>
  );
}

UpdatedOrder.defaultProps = {
  width: 12,
  height: 12,
  backgroundColor: 'primary.light',
  fontSize: '10px',
  title: fm('this_recurring_order_has_been_updated'),
};

UpdatedOrder.propTypes = {
  backgroundColor: string,
  width: number,
  height: number,
  fontSize: string,
  title: string,
};
