import {
  SET_TIME_MANAGEMENT_OVERVIEW_FIELDS,
  SET_TIME_MANAGEMENT_PAYROLL_FIELDS,
  SET_TIME_MANAGEMENT_FIELDS,
  SET_TIME_REPORTS_FIELDS,
  SET_SALARY_CODE_FIELDS,
  SET_TIME_MANAGEMENT_SUMMARY_FIELDS,
  SET_TIME_MANAGEMENT_SUMMARY_BOOKING_TIME_FIELDS,
  SET_TIME_MANAGEMENT_SUMMARY_INTERNAL_TIME_FIELDS,
} from '../actions';

const initialState = {
  activeTab: 0,
  timeReports: {},
  salaryCodes: { records: [] },
  activeTimeCodes: {},
  overview: {
    selectedShiftIds: [],
    employeeIdShiftsMap: {},
  },
  payroll: {
    employeeIdShiftsPaginationMap: {},
    invalidBookingEmployeeTimeRecords: [],
    showInvalidBookingEmployeeTimeDialog: false,
  },
  summary: {
    records: [],
    bookingTime: {
      records: [],
    },
    internalTime: {
      records: [],
    },
  },
};

function timeManagement (state = initialState, action) {
  switch (action.type) {
    case SET_TIME_MANAGEMENT_FIELDS:
      return {
        ...state,
        ...action.payload,
      };
    case SET_TIME_REPORTS_FIELDS:
      return {
        ...state,
        timeReports: {
          ...state.timeReports,
          ...action.payload,
        },
      };
    case SET_TIME_MANAGEMENT_OVERVIEW_FIELDS:
      return {
        ...state,
        overview: {
          ...state.overview,
          ...action.payload,
        },
      };
    case SET_TIME_MANAGEMENT_PAYROLL_FIELDS:
      return {
        ...state,
        payroll: {
          ...state.payroll,
          ...action.payload,
        },
      };
    case SET_TIME_MANAGEMENT_SUMMARY_FIELDS:
      return {
        ...state,
        summary: {
          ...state.summary,
          ...action.payload,
        },
      };
    case SET_TIME_MANAGEMENT_SUMMARY_BOOKING_TIME_FIELDS:
      return {
        ...state,
        summary: {
          ...state.summary,
          bookingTime: {
            ...state.summary.bookingTime,
            ...action.payload,
          },
        },
      };
    case SET_TIME_MANAGEMENT_SUMMARY_INTERNAL_TIME_FIELDS:
      return {
        ...state,
        summary: {
          ...state.summary,
          internalTime: {
            ...state.summary.internalTime,
            ...action.payload,
          },
        },
      };
    case SET_SALARY_CODE_FIELDS:
      return {
        ...state,
        salaryCodes: {
          ...state.salaryCodes,
          ...action.payload,
        },
      };
    default:
      return state;
  }
}

export default timeManagement;
