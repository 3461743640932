import * as Yup from 'yup';
import { fm } from 'utils/string';

const messageFormSchema = Yup.object().shape({
  message: Yup.string().shouldNotContainEmoji(
    fm('message_should_not_contain_emojis'),
  ),
});

export default messageFormSchema;
