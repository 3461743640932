import { createTheme } from '@mui/material';

export const proposalTheme = createTheme({
  typography: {
    fontFamily: 'Inter',
  },
  button: {
    fontFamily: 'Inter',
  },
  menuList: {
    fontFamily: 'Inter',
  },
});
