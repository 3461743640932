/* eslint-disable camelcase */
// import { getFormattedPaginationFields } from 'appRedux/selectors/utility';
import { isEmpty, get, sortBy } from 'utils/lodash';
import { shiftTimeTypes } from 'utils/enum';
import { parseJson } from 'appRedux/selectors/utility';
import {
  formatDate,
  formatStrings,
  getStandardDate,
  getTimeFromNumber,
  getHourMinutesInUnixTime,
} from 'utils/dateTime';

export const getFormattedDayShiftData = (shiftData) => {
  if (!isEmpty(shiftData)) {
    const camelizedShiftData = shiftData;
    return {
      ...camelizedShiftData,
      addedDate: getStandardDate(camelizedShiftData.addedDate),
      shiftPunchInTime: {
        ...camelizedShiftData.shiftPunchInTime,
        punchInTime: getTimeFromNumber(camelizedShiftData.shiftPunchInTime.punchInTime),
        punchInDate: getStandardDate(camelizedShiftData.shiftPunchInTime.punchInDate),
      },
      ...(camelizedShiftData.shiftPunchOutTime && {
        shiftPunchOutTime: {
          ...camelizedShiftData.shiftPunchOutTime,
          punchOutTime: getTimeFromNumber(camelizedShiftData.shiftPunchOutTime.punchOutTime),
          punchOutDate: getStandardDate(camelizedShiftData.shiftPunchOutTime.punchOutDate),
        },
        shiftDetails: !camelizedShiftData.shiftDetails
          ? []
          : [
            {
              ...camelizedShiftData.shiftDetails[0],
              startTime: getTimeFromNumber(camelizedShiftData.shiftDetails[0].startTime),
              endTime: getTimeFromNumber(camelizedShiftData.shiftDetails[0].endTime),
              breakTime: getTimeFromNumber(camelizedShiftData.shiftDetails[0].breakTime),
              workingTime: getTimeFromNumber(camelizedShiftData.shiftDetails[0].workingTime),
              shiftCodeDetails: [
                {
                  ...camelizedShiftData.shiftDetails[0].shiftCodeDetails[0],
                  startTime: getTimeFromNumber(camelizedShiftData.shiftDetails[0].startTime),
                  endTime: getTimeFromNumber(camelizedShiftData.shiftDetails[0].endTime),
                  approved: camelizedShiftData.shiftTimeApprovals.length,
                },
                ...camelizedShiftData.shiftDetails[0].shiftCodeDetails.slice(1),
              ],
            },
            ...camelizedShiftData.shiftDetails.slice(1),
          ],
      }),
    };
  }
};

export const getFormattedEmployeeDayShifts = (records = []) => sortBy(record => record.totalPunchInTime || get('shiftDetailsData.startTime')(record))( // eslint-disable-line
  records.filter((record) => record.shiftDetailsData || record.salaryDetailsData),
).map((record) => {
  if (record.salaryDetailsData) {
    const salaryDetailsData = record.salaryDetailsData
      ? parseJson(record.salaryDetailsData)[0]
      : undefined;

    return {
      ...salaryDetailsData,
      id: record.id,
      timeCodeType: salaryDetailsData.codeType,
    };
  }

  const shiftDetailsData = record.shiftDetailsData
    ? parseJson(record.shiftDetailsData)[0]
    : undefined;
  const approvalData = parseJson(record.approvalData, []);

  return {
    timeCodeType: shiftDetailsData.codeType,
    adminApproved: Boolean(approvalData.find((approver) => approver.approverRole === 'Owner')),
    punchedShift: record.punchedIn && record.punchedOut,
    addedDate: record.addedDate,
    id: record.id,
    shiftDetailsData: shiftDetailsData && {
      codeType: shiftDetailsData.codeType,
      endTime: getTimeFromNumber(shiftDetailsData.endTime),
      workingTime: getTimeFromNumber(shiftDetailsData.workingTime),
      startTime: getTimeFromNumber(shiftDetailsData.startTime),
    },
    totalPunchInTime: record.totalPunchInTime
      ? getTimeFromNumber(record.totalPunchInTime)
      : null,
    totalPunchOutTime: record.totalPunchOutTime
      ? getTimeFromNumber(record.totalPunchOutTime)
      : null,
  };
});

export const getFormattedDayShiftPunchInBody = (data) => ({
  shift_time: {
    employee_id: data.employeeId,
    added_date: getStandardDate(),
    shift_punch_in_time_attributes: {
      punch_in: true,
      punch_in_date: getStandardDate(),
      punch_in_time: getHourMinutesInUnixTime(formatDate(new Date(), formatStrings.defaulTime)),
      punch_in_latitude: data.punchInLatitude,
      punch_in_longitude: data.punchInLongitude,
    },
  },
});

export const getFormattedDayShiftPunchOutBody = (data) => ({
  shift_time: {
    shift_punch_out_time_attributes: {
      punch_out: true,
      punch_out_date: getStandardDate(),
      punch_out_time: getHourMinutesInUnixTime(formatDate(new Date(), formatStrings.defaulTime)),
      punch_out_latitude: data.punchOutLatitude,
      punch_out_longitude: data.punchOutLongitude,
    },
  },
});

export const getFormattedSalaryCodeUpdateApiReq = (data) => ({
  shift_time: {
    salary_details_attributes:
    [
      {
        salary_code_id: data.salaryDetail.salaryCodeId,
        amount: data.amount,
        quantity: data.quantity,
        total_amount: data.totalAmount,
        comment: data.comment,
      },
    ],
  },
});

export const getFormattedSalaryCodeCreateApiReq = (data) => ({
  employee_id: data.employeeId,
  added_date: data.addedDate,
  shift_detail: {
    salary_code_id: data.salaryDetail.salaryCodeId,
    amount: data.amount,
    quantity: data.quantity,
    total_amount: data.totalAmount,
    comment: data.comment,
  },
});

export const getFormattedShiftTimeBody = (data) => {
  const start_time = getHourMinutesInUnixTime(data.startTime);
  const end_time = getHourMinutesInUnixTime(data.endTime);
  const break_time = getHourMinutesInUnixTime(data.breakTime);
  const working_time = getHourMinutesInUnixTime(data.workingTime);
  return {
    added_date: getStandardDate(data.addedDate),
    employee_id: data.employeeId,
    shift_detail: {
      start_time,
      end_time,
      break_time,
      working_time: working_time || end_time - start_time - break_time,
      comment: data.comment,
      shift_code_details_attributes: [
        {
          time_code_id: data.shiftCodeDetails.timeCodeId,
          time_code_period: getHourMinutesInUnixTime(data.workingTime),
        },
      ],
    },
  };
};

export const getFormattedExtraTimeBody = (data) => ({
  shift_time: {
    added_date: data.addedDate,
    shift_details_attributes: [
      {
        id: data.id,
        start_time: getHourMinutesInUnixTime(data.startTime),
        end_time: getHourMinutesInUnixTime(data.endTime),
        working_time: getHourMinutesInUnixTime(data.workingTime),
        break_time: getHourMinutesInUnixTime(data.breakTime),
        comment: data.comment,
      },
    ],
    shift_code_details_attributes: [
      {
        time_code_id: data.shiftCodeDetails.timeCodeId,
        time_code_period: getHourMinutesInUnixTime(data.workingTime),
      },
    ],
  },
});

export const getFormattedPunchedData = (shiftData = {}) => {
  const camelizedShiftData = shiftData;
  return {
    addedDate: getStandardDate(camelizedShiftData.addedDate),
    punchedShift: true,
    salaryCodeId: get('shiftDetails[0].shiftCodeDetails[0].timeCodeId')(camelizedShiftData),
    timeCodeType: get('shiftDetails[0].shiftCodeDetails[0].timeCode.codeType')(camelizedShiftData),
    ...camelizedShiftData.shiftDetails[0],
    startTime: getTimeFromNumber(camelizedShiftData.shiftDetails[0].startTime),
    endTime: getTimeFromNumber(camelizedShiftData.shiftDetails[0].endTime),
    breakTime: getTimeFromNumber(camelizedShiftData.shiftDetails[0].breakTime),
    workingTime: getTimeFromNumber(camelizedShiftData.shiftDetails[0].workingTime),
    shiftCodeDetails: {
      timeCodeId: get('shiftDetails[0].shiftCodeDetails[0].timeCodeId')(camelizedShiftData),
    },
    shiftId: camelizedShiftData.id,
    shiftTimeApprovals: camelizedShiftData.shiftTimeApprovals[0],
    ...camelizedShiftData.shiftDetails.slice(1),
  };
};

export const getFormattedShiftData = (data) => (
  get('salaryDetails[0].salaryCode.codeType')(data) === shiftTimeTypes.other
    ? {
      id: data.id,
      salaryDetailId: get('salaryDetails[0].id')(data),
      timeCodeType: get('salaryDetails[0].salaryCode.codeType')(data),
      amount: get('salaryDetails[0].amount')(data),
      comment: get('salaryDetails[0].comment')(data),
      quantity: get('salaryDetails[0].quantity')(data),
      totalAmount: get('salaryDetails[0].totalAmount')(data),
      employeeId: get('employee.id')(data),
      addedDate: data.addedDate,
      salaryDetail: { salaryCodeId: get('salaryDetails[0].salaryCodeId')(data) },
    } : {
      shiftId: data.id,
      id: get('shiftDetails[0].id')(data),
      addedDate: data.addedDate,
      employeeId: data.employee.id,
      shiftCodeDetails: { timeCodeId: get('shiftDetails[0].shiftCodeDetails[0].timeCodeId')(data) },
      startTime: getTimeFromNumber(get('shiftDetails[0].startTime')(data)),
      endTime: getTimeFromNumber(get('shiftDetails[0].endTime')(data)),
      workingTime: getTimeFromNumber(get('shiftDetails[0].workingTime')(data)),
      breakTime: getTimeFromNumber(get('shiftDetails[0].breakTime')(data)),
      comment: get('shiftDetails[0].comment')(data),
      timeCodeType: get('shiftDetails[0].shiftCodeDetails[0].timeCode.codeType')(data),
      approved: data.shiftTimeApprovals && Boolean(data.shiftTimeApprovals.length),
    }
);

export const getFormattedDayShiftApproveBody = (data) => {
  const start_time = getHourMinutesInUnixTime(data.startTime);
  const end_time = getHourMinutesInUnixTime(data.endTime);
  const break_time = getHourMinutesInUnixTime(data.breakTime);
  return {
    shift_time: {
      shift_time_approvals_attributes: [
        {
          approved: true,
        },
      ],
      shift_details_attributes: [
        {
          id: data.id,
          start_time,
          end_time,
          break_time,
          working_time: end_time - start_time - break_time,
          comment: data.comment,
        },
      ],
    },
  };
};

export const getFormattedShiftTimesData = (data = []) => {
  const camelizedResponse = data;
  const records = {};
  let totalPresence = 0;
  let totalAbsence = 0;
  let totalSalary = 0;
  camelizedResponse.reduce((result, shiftTime) => {
    const date = getStandardDate(shiftTime.addedDate);
    totalPresence += shiftTime.totalPresenceTime;
    totalAbsence += shiftTime.totalAbsenceTime;
    totalSalary += parseFloat(shiftTime.totalSalaryAmount);
    records[date] = [
      {
        ...shiftTime,
        addedDate: date,
        totalWorkingTime: getTimeFromNumber(shiftTime.totalWorkingTime),
      },
    ];
    return result;
  }, {});
  return { records, totals: { totalPresence, totalAbsence, totalSalary } };
};
