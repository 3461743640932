import { createAsyncThunk } from '@reduxjs/toolkit';
import snakify from 'utils/snakify';
import { setSnackbarError, setSnackbarSuccess, setBookingsFields } from 'appRedux/actions';

export const requestMigrateBookingToOrder = createAsyncThunk(
  'bookingOrderMigration/createOrderFromBooking',
  async (data, { extra, dispatch, rejectWithValue }) => {
    try {
      const requestBody = snakify(data);
      await extra.axios.post('/api/v3/orders/migrate_booking_order', requestBody);
      dispatch(setSnackbarSuccess('snackbar_booking_order_created'));
      dispatch(setBookingsFields({ showBookingModal: false, refreshAllBookings: true, booking: {} }));
    } catch (err) {
      dispatch(setSnackbarError('snackbar_booking_order_migration_failed'));
      return rejectWithValue();
    }
  },
);
