import { userRoles } from 'utils/enum';
import camelize from 'camelize';
import { get } from 'utils/lodash';
import {
  formatDate,
  formatStrings,
  getHourMinutesInUnixTime,
  getUnixTimeInHoursMinutes,
  epochTimeToFloat,
} from 'utils/dateTime';
import { getFormattedPaginationFields, getFilterEmployeeId, parseJson } from 'appRedux/selectors/utility';

export const getFormattedSalaryCodeReport = (detail) => ({
  added_date: detail.addedDate,
  employee_id: getFilterEmployeeId(detail),
  shift_detail: {
    salary_code_id: detail.salaryCodeId,
    quantity: detail.quantity,
    amount: detail.amount,
    total_amount: detail.totalAmount,
    comment: detail.comment,
  },
});

export const getFormattedSummaryFields = (summary = {}) => ({
  totalAbsenceTime: getUnixTimeInHoursMinutes(summary.total_absence_time),
  totalAmount: summary.total_amount,
  totalBookingTime: getUnixTimeInHoursMinutes(summary.total_booking_time),
  totalPresenceTime: getUnixTimeInHoursMinutes(summary.total_presence_time),
  totalTime: getUnixTimeInHoursMinutes((summary.total_absence_time || 0)
    + (summary.total_booking_time || 0)
    + (summary.total_presence_time || 0)),
});

export const formatPayrollEmplpoyeeRecords = (response) => ({
  ...getFormattedPaginationFields(response.payroll_data),
  summary: getFormattedSummaryFields(response.summary),
  records: response.payroll_data.records.map((record) => ({
    ...camelize(record),
    totalBookingTime: getUnixTimeInHoursMinutes(record.total_booking_time) || 0,
    totalPresenceTime: getUnixTimeInHoursMinutes(record.total_presence_time) || 0,
    totalAbsenceTime: getUnixTimeInHoursMinutes(record.total_absence_time) || 0,
    totalAmount: record.total_amount || 0,
  })),
});

export const getFormattedSalaryCodeReportForUpdate = (detail) => ({
  shift_time: {
    employee_id: getFilterEmployeeId(detail),
    added_date: detail.addedDate,
    salary_details_attributes: [
      {
        id: detail.salaryCodeDetailId,
        salary_code_id: detail.salaryCodeId,
        quantity: detail.quantity,
        amount: detail.amount,
        total_amount: detail.totalAmount,
        comment: detail.comment,
      },
    ],
  },
});

const getShiftTimeApproved = (shift) => (shift.shiftTimeApprovals
  ? Boolean(shift.shiftTimeApprovals.find((d) => [userRoles.owner, userRoles.admin].includes(d.approverRole)))
  : false);

export const getFormattedSalaryCodeDetailsForModal = (salaryCodeData) => ({
  id: salaryCodeData.id,
  salaryCodeDetailId: get('salary_details[0].id')(salaryCodeData),
  salaryCodeId: get('salary_details[0].salary_code_id')(salaryCodeData),
  addedDate: salaryCodeData.added_date,
  employee: salaryCodeData.employee,
  comment: get('salary_details[0].comment')(salaryCodeData),
  amount: get('salary_details[0].amount')(salaryCodeData),
  quantity: get('salary_details[0].quantity')(salaryCodeData),
  totalAmount: get('salary_details[0].total_amount')(salaryCodeData),
  timeCodeType: get('salary_details[0].salary_code.code_type')(salaryCodeData),
  adminApproved: getShiftTimeApproved(camelize(salaryCodeData)),
  companyId: salaryCodeData.company_id,
});

export const getFormattedShiftDetailsForModal = (shiftData) => {
  const camelizedShiftData = camelize(shiftData);
  const shiftCodeDetail = get('shiftDetails[0].shiftCodeDetails[0]')(camelizedShiftData);

  return {
    id: camelizedShiftData.id,
    timeCodeType: get('shiftDetails[0].shiftCodeDetails[0].timeCode.codeType')(camelizedShiftData),
    addedDate: camelizedShiftData.addedDate,
    employee: camelizedShiftData.employee,
    startTime: getUnixTimeInHoursMinutes(get('shiftDetails[0].startTime')(camelizedShiftData)),
    endTime: getUnixTimeInHoursMinutes(get('shiftDetails[0].endTime')(camelizedShiftData)),
    breakTime: getUnixTimeInHoursMinutes(get('shiftDetails[0].breakTime')(camelizedShiftData)) || '00:00',
    workingTime: getUnixTimeInHoursMinutes(get('shiftDetails[0].workingTime')(camelizedShiftData)),
    comment: get('shiftDetails[0].comment')(camelizedShiftData),
    ignoreOvertime: get('shiftDetails[0].ignoreOvertime')(camelizedShiftData),
    overtimes: camelizedShiftData.overtimes && camelizedShiftData.overtimes.map((overtime) => ({
      ...overtime,
      startTime: getUnixTimeInHoursMinutes(overtime.startTime),
      endTime: getUnixTimeInHoursMinutes(overtime.endTime),
    })),
    autoCalculateOvertimePermission: camelizedShiftData.autoCalculateOvertimePermission,
    shiftDetailId: get('shiftDetails[0].id')(camelizedShiftData),
    shiftCodeDetails: {
      timeCodePeriod: getUnixTimeInHoursMinutes(get('timeCodePeriod')(shiftCodeDetail)),
      code: get('timeCode.code')(shiftCodeDetail),
      id: get('id')(shiftCodeDetail),
      timeCodeId: get('timeCodeId')(shiftCodeDetail),
      codeType: get('timeCode.codeType')(shiftCodeDetail),
      name: get('timeCode.name')(shiftCodeDetail),
    },
    adminApproved: getShiftTimeApproved(camelizedShiftData),
    punchInDetails: {
      id: get('shiftPunchInTime.id')(camelizedShiftData),
      punchInTime: Number.isNaN(get('shiftPunchInTime.punchInTime')(camelizedShiftData))
        ? null
        : getUnixTimeInHoursMinutes(get('shiftPunchInTime.punchInTime')(camelizedShiftData), formatStrings.defaulTime),
      punchInLatitude: get('shiftPunchInTime.punchInLatitude')(camelizedShiftData),
      punchInLongitude: get('shiftPunchInTime.punchInLongitude')(camelizedShiftData),
    },
    punchOutDetails: {
      id: get('shiftPunchOutTime.id')(camelizedShiftData),
      punchOutTime: get('shiftPunchOutTime.punchOutTime')(camelizedShiftData)
        ? getUnixTimeInHoursMinutes(get('shiftPunchOutTime.punchOutTime')(camelizedShiftData), formatStrings.defaulTime)
        : null,
      punchOutLatitude: get('shiftPunchOutTime.punchOutLatitude')(camelizedShiftData),
      punchOutLongitude: get('shiftPunchOutTime.punchOutLongitude')(camelizedShiftData),
    },
    companyId: camelizedShiftData.companyId,
  };
};

export const getFormattedCreateShiftRequestBody = (shiftData) => ({
  added_date: formatDate(shiftData.addedDate, formatStrings.filtersDate),
  start_date: formatDate(shiftData.addedDate, formatStrings.filtersDate),
  end_date: shiftData.endDate ? formatDate(shiftData.endDate, formatStrings.filtersDate) : null,
  employee_id: shiftData.employee.id,
  shift_detail: {
    start_time: getHourMinutesInUnixTime(shiftData.startTime),
    end_time: getHourMinutesInUnixTime(shiftData.endTime),
    working_time: getHourMinutesInUnixTime(shiftData.workingTime),
    break_time: getHourMinutesInUnixTime(shiftData.breakTime),
    comment: shiftData.comment,
    ignore_overtime: shiftData.ignoreOvertime,
    shift_code_details_attributes: [
      {
        time_code_id: shiftData.shiftCodeDetails.timeCodeId,
        time_code_period: getHourMinutesInUnixTime(shiftData.workingTime),
      },
    ],
  },
  employee_request_shift_time_junction_attributes: shiftData.employeeRequestId
    ? {
      employee_request_id: shiftData.employeeRequestId,
    }
    : undefined,
});

export const getFormattedCreateShiftRequestBulkBody = (shiftData) => {
  const formattedData = getFormattedCreateShiftRequestBody(shiftData);
  delete formattedData.addedDate;
  return formattedData;
};

export const getFormattedShiftDetailsRequestBody = (shiftData) => ({
  shift_time: {
    shift_details_attributes: [
      {
        id: shiftData.shiftDetailId,
        start_time: getHourMinutesInUnixTime(shiftData.startTime),
        end_time: getHourMinutesInUnixTime(shiftData.endTime),
        working_time: getHourMinutesInUnixTime(shiftData.workingTime),
        break_time: getHourMinutesInUnixTime(shiftData.breakTime),
        comment: shiftData.comment,
        ignore_overtime: shiftData.ignoreOvertime,
        shift_code_details_attributes: [
          {
            id: get('shiftCodeDetails.id')(shiftData),
            time_code_id: get('shiftCodeDetails.timeCodeId')(shiftData),
            time_code_period: getHourMinutesInUnixTime(shiftData.workingTime),
          },
        ],
      },
    ],
  },
});

export const getFormattedTimeCodeList = (response = {}) => ({
  ...getFormattedPaginationFields(response),
  records: camelize(response.records),
});

export const getFormattedTimeReportBody = (data) => {
  const start_time = getHourMinutesInUnixTime(data.startTime); // eslint-disable-line
  const end_time = getHourMinutesInUnixTime(data.endTime); // eslint-disable-line
  return {
    employee_time: {
      employee_id: data.employeeId,
      employee_name: data.employeeName,
      time_code_id: data.timeCodeId,
      added_on: data.addedOn,
      start_time,
      end_time,
      working_time: end_time - start_time, // eslint-disable-line
      invoice_time: getHourMinutesInUnixTime(data.invoiceTime),
      comment: data.comment,
    },
  };
};

export const getFormattedTimeHistoryData = (history) => ({
  id: history.id,
  bookingStartTime: history.booking_start_time,
  bookingEndTime: history.booking_end_time,
  paidTime: history.paid_time,
  comment: history.comment,
  startDate: history.start_date,
  createdAt: history.created_at,
  createdByName: history.created_by_name,
});

export const getFormattedEmployeeTimeRecordsData = (data = {}) => ({
  ...getFormattedPaginationFields(data),
  records: (data.records || []).map((record) => {
    const camelized = {
      ...record,
      ...(record.shiftDetailsData && parseJson(record.shiftDetailsData)[0]),
      ...(record.salaryDetailsData && parseJson(record.salaryDetailsData)[0]),
    };

    const shiftTiming = parseJson(camelized.shiftTiming);
    const adminApproved = camelized.approvalData && parseJson(camelized.approvalData)
      .find((d) => [userRoles.owner, userRoles.admin].includes(d.approverRole));
    const employeeApproved = camelized.approvalData && camelized.approvalData.length > 0 && !adminApproved;
    const approved = Boolean(camelized.approved || adminApproved);
    const [punchInData, punchOutData] = [parseJson(camelized.punchInData), parseJson(camelized.punchOutData)];
    return {
      ...camelized,
      approved,
      commented: Boolean(camelized.commented),
      status: camelized.customerName ? camelized.status : camelized.code ? 'Submitted' : 'In-Progress', // eslint-disable-line
      disabled: Boolean(!camelized.code || camelized.approveInprogress || camelized.updateInProgress || approved),
      punchInTime: camelized.punchInTime,
      punchOutTime: camelized.punchOutTime,
      employeeBreakTime: getUnixTimeInHoursMinutes(camelized.employeeBreakTime),
      employeeEndTime: getUnixTimeInHoursMinutes(camelized.employeeEndTime),
      employeeStartTime: getUnixTimeInHoursMinutes(camelized.employeeStartTime),
      employeeWorkingTime: getUnixTimeInHoursMinutes(camelized.employeeWorkingTime),
      employeeInvoiceTime: getUnixTimeInHoursMinutes(camelized.employeeInvoiceTime),
      invoiceTime: camelized.invoiceTime ? epochTimeToFloat(camelized.invoiceTime) : null,
      employeeName: camelized.employeeName,
      ...(punchInData && {
        punchInTime: punchInData.punchInTime,
        punchInLatitude: punchInData.latitude,
        punchInLongitude: punchInData.longitude,
      }),
      ...(punchOutData && {
        punchOutTime: punchOutData.punchOutTime,
        punchOutLatitude: punchOutData.latitude,
        punchOutLongitude: punchOutData.longitude,
      }),
      ...(shiftTiming && {
        employeeApproved,
        employeeEndTime: getUnixTimeInHoursMinutes(shiftTiming.endTime),
        employeeStartTime: getUnixTimeInHoursMinutes(shiftTiming.startTime),
        employeeBreakTime: getUnixTimeInHoursMinutes(shiftTiming.breakTime),
        employeeWorkingTime: getUnixTimeInHoursMinutes(shiftTiming.workingTime),
      }),
    };
  }),
});

export const getBookingTimeUpdateFormattedApiRequestData = (data) => ({
  booking_start_time: data.bookingStartTime,
  booking_end_time: data.bookingEndTime,
  paid_time: data.paidTime,
  comment: data.comment,
  break_time: getHourMinutesInUnixTime(data.breakTime),
});

export const getFormattedEmployeeShiftReqParams = (filters) => ({
  start_date: filters.startDate,
  end_date: filters.endDate,
  page: filters.page,
  per_page: filters.perPage,
  code_type: filters.codeType === 'view_all' ? undefined : filters.codeType,
  shift_type: filters.shiftType === 'view_all' ? undefined : filters.shiftType,
  approved: filters.approved === 'view_all' ? undefined : filters.approved,
  status: filters.bookingStatus === 'view_all' ? undefined : filters.bookingStatus,
  employee_id: getFilterEmployeeId(filters),
  code_ids: filters.timeCodes.map((timeCode) => timeCode.id),
  user_active_status: filters.userActiveStatus === 'view_all' ? undefined : filters.userActiveStatus,
  request_for: filters.requestFor,
});

export const getFormattedSummaryFilterParams = (filters) => ({
  start_date: filters.startDate,
  end_date: filters.endDate,
  page: filters.page,
  per_page: filters.perPage,
  code_type: filters.codeType === 'view_all' ? undefined : filters.codeType,
  shift_type: filters.shiftType === 'view_all' ? undefined : filters.shiftType,
  approved: filters.approved === 'view_all' ? undefined : filters.approved,
  employee_id: getFilterEmployeeId(filters),
  status: filters.bookingStatus === 'view_all' ? undefined : filters.bookingStatus,
  code_ids: filters.timeCodes.map((timeCode) => timeCode.id),
  user_active_status: filters.userActiveStatus === 'view_all' ? undefined : filters.userActiveStatus,
});

export const getFormattedBookingTimeRecords = (response) => response.map((record) => ({
  ...record,
  totalWorkingTime: getUnixTimeInHoursMinutes(record.totalWorkingTime),
}));

export const getFormattedBookingTimeEmployeeRecords = (response = {}) => ({
  ...getFormattedPaginationFields(response),
  records: (response.records || []).map((record) => ({
    ...record,
    totalWorkingTime: getUnixTimeInHoursMinutes(record.totalWorkingTime),
  })),
});

export const getFormattedEmployeeBookingTimeSummaryRecords = (response = {}) => ({
  ...getFormattedPaginationFields(response),
  records: (response.records || []).map((record) => ({
    ...record,
    employeeEndTime: getUnixTimeInHoursMinutes(record.employeeEndTime),
    employeeStartTime: getUnixTimeInHoursMinutes(record.employeeStartTime),
    employeeWorkingTime: getUnixTimeInHoursMinutes(record.employeeWorkingTime),
    punchInTime: getUnixTimeInHoursMinutes(record.punchInTime),
    punchOutTime: getUnixTimeInHoursMinutes(record.punchOutTime),
  })),
});

export const getFormattedEmployeeInternalTimeSummaryRecords = (response = {}) => ({
  ...getFormattedPaginationFields(response),
  records: (response.records || []).map((record) => {
    const shiftDetailsData = record.shiftDetailsData && parseJson(record.shiftDetailsData);
    const shiftTiming = record.shiftTiming && parseJson(record.shiftTiming);
    const salaryDetailsData = record.salaryDetailsData && parseJson(record.salaryDetailsData)[0];
    const adminApproved = record.approvalData && parseJson(record.approvalData)
      .find((d) => [userRoles.owner, userRoles.admin].includes(d.approverRole));
    const employeeApproved = record.approvalData && record.approvalData.length > 0 && !adminApproved;
    return {
      ...record,
      ...(shiftTiming && {
        employeeEndTime: getUnixTimeInHoursMinutes(shiftTiming.endTime),
        employeeStartTime: getUnixTimeInHoursMinutes(shiftTiming.startTime),
        employeeBreakTime: getUnixTimeInHoursMinutes(shiftTiming.breakTime),
        employeeWorkingTime: getUnixTimeInHoursMinutes(shiftTiming.workingTime),
        comment: shiftTiming.comment,
      }),
      ...(shiftDetailsData) && {
        code: shiftDetailsData.code,
        codeType: shiftDetailsData.codeType,
      },
      ...(record.punchInData && {
        punchInTime: getUnixTimeInHoursMinutes(record.punchInData.punchInTime),
        punchInLatitude: record.punchInData.latitude,
        punchInLongitude: record.punchInData.longitude,
      }),
      ...(record.punchOutData && {
        punchOutTime: getUnixTimeInHoursMinutes(record.punchOutData.punchOutTime),
        punchOutLatitude: record.punchOutData.latitude,
        punchOutLongitude: record.punchOutData.longitude,
      }),
      ...(salaryDetailsData) && {
        code: salaryDetailsData.code,
        comment: salaryDetailsData.comment,
        quantity: salaryDetailsData.quantity,
        codeType: salaryDetailsData.codeType,
        totalAmount: salaryDetailsData.totalAmount,
      },
      approved: adminApproved,
      employeeApproved,
    };
  }),
});

export const getFormattedSummaryEmployeeTimeFilterParams = (filters, summaryFilters) => ({
  start_date: summaryFilters.startDate,
  end_date: summaryFilters.endDate,
  page: filters.page,
  per_page: filters.perPage,
  code_type: summaryFilters.codeType === 'view_all' ? undefined : summaryFilters.codeType,
  shift_type: summaryFilters.shiftType === 'view_all' ? undefined : summaryFilters.shiftType,
  approved: summaryFilters.approved === 'view_all' ? undefined : summaryFilters.approved,
  status: summaryFilters.bookingStatus === 'view_all' ? undefined : filters.bookingStatus,
  code_ids: summaryFilters.timeCodes.map((timeCode) => timeCode.id),
  user_active_status: summaryFilters.userActiveStatus === 'view_all' ? undefined : summaryFilters.userActiveStatus,
});
