import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Typography, Footer, Box, Button } from 'components/library';
import { organizationSettingTypes, proposalRequestStatusTypes } from 'utils/enum';
import { get } from 'utils/lodash';
import { fm } from 'utils/string';
import { proposalStatusTypes } from 'components/Proposal/utility';
import { setConfirmModalFields } from 'appRedux/actions';
import BankIdDialog from 'views/Proposal/CustomerProposal/ProposalDetails/BankIdDialog';
import { getStandardDate } from 'utils/dateTime';
import AcceptProposalDialog from './AcceptProposalDialog';
import ProposalSignedMessage from './ProposalSigned';

const ProposalFooter = (props) => {
  const dispatch = useDispatch();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const { proposal, company } = props;

  const requiresBankIdSign = useMemo(() => {
    const companySettings = get('companyDetails.companySettings')(company);
    if (!companySettings) return {};
    const proposalBankidSignSetting = companySettings.find(
      (d) => d.settingCode === organizationSettingTypes.proposalBankIdSignature,
    );
    return proposalBankidSignSetting.enable && proposal.requiresBankidSign;
  }, [company.companySettings]);

  const onClickAccept = () => {
    setShowConfirmDialog(true);
  };

  const onClickAcceptDenyOffer = ({ status, signature }) => {
    const url = window.location.href;
    const token = url.split('/').pop();
    dispatch(setConfirmModalFields({
      callBack: () => {
        props.requestUpdateProposalFromCustomer({ status, token, signature });
        setShowConfirmDialog(false);
      },
      message: status === proposalRequestStatusTypes.signed
        ? fm('proposal_approve_confirm_msg')
        : fm('proposal_deny_confirm_msg'),
      open: true,
      title: status === proposalRequestStatusTypes.signed
        ? fm('approve_proposal')
        : fm('decline_proposal'),
    }));
  };

  if (proposal.proposalSignature && !proposal.proposalSignature.staled && requiresBankIdSign) {
    return (
      <Footer fullWidth>
        <BankIdDialog proposal={proposal} />
      </Footer>
    );
  }

  if (new Date(getStandardDate()) > new Date(proposal.expiryDate)) return <Footer fullWidth />;

  return (
    <Footer fullWidth>
      <Box display="flex" alignItems="center" justifyContent="center" width="100%">
        {!requiresBankIdSign
          && (proposal.status === proposalStatusTypes.signed || proposal.status === proposalStatusTypes.cancelled)
          ? (
            <Box display="flex" alignItems="center" justifyContent="center" width="100%">
              {proposal.status === proposalStatusTypes.signed && (
                <ProposalSignedMessage signeeName={proposal.customerName} />
              )}
              {proposal.status === proposalStatusTypes.cancelled && (
                <Typography>{fm('proposal_deny')}</Typography>
              )}
            </Box>
          ) : (
            <Box display="flex">
              <Button
                size="medium"
                variant="text"
                onClick={() => onClickAcceptDenyOffer({ status: proposalRequestStatusTypes.cancelled })}
              >
                {fm('decline')}
              </Button>
              {requiresBankIdSign && <BankIdDialog proposal={proposal} />}
              {!requiresBankIdSign && (
                <Button
                  size="medium"
                  onClick={onClickAccept}
                  variant="contained"
                  color="primary"
                >
                  {fm('accept_offer')}
                </Button>
              )}
            </Box>
          )}
      </Box>
      <AcceptProposalDialog
        onClickAcceptDenyOffer={onClickAcceptDenyOffer}
        open={showConfirmDialog}
        setOpen={setShowConfirmDialog}
      />
    </Footer>
  );
};

export default ProposalFooter;

ProposalFooter.propTypes = {
  requestUpdateProposalFromCustomer: PropTypes.func.isRequired,
  company: PropTypes.shape({
    companySettings: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  proposal: PropTypes.shape({
    requiresBankidSign: PropTypes.bool,
    status: PropTypes.string,
    expiryDate: PropTypes.string,
    customerName: PropTypes.string,
    proposalSignature: PropTypes.shape({
      staled: PropTypes.bool,
    }),
  }),
};
