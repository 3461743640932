import { createAsyncThunk } from '@reduxjs/toolkit';
import { getStandardDate } from 'utils/dateTime';
import { setTimeManagementFields, setSnackbarSuccess, setSnackbarError } from 'appRedux/actions';
import {
  validateAndGetFormattedEmployeeBookingTimeUpdateRequestBody,
} from 'appRedux/thunks/TimeManagement/validationSchemas';
import {
  getFormattedPunchOutRequestBody,
  getFormattedBookingData,
  getFormattedEmployeeBookingsList,
  getFormattedPunchInRequestBody,
} from './selector';

export const requestBookings = createAsyncThunk(
  'booking/fetchBookings',
  async (filters, { extra, dispatch }) => {
    try {
      const params = {
        start_date: filters.startDate || getStandardDate(),
        end_date: filters.endDate,
        supervisor_only: filters.supervisorOnly,
      };
      const response = await extra.axios.get('/api/v1/bookings/employee_bookings', { params });
      const { records, ...nextFilters } = getFormattedEmployeeBookingsList(response.data.data);
      return { records, filters: nextFilters };
    } catch (err) {
      dispatch(setSnackbarError('snackbar.employeeView.gettingBookings.error'));
    }
  },
);

export const requestBooking = createAsyncThunk(
  'booking/fetchBooking',
  async (bookingSequenceNum, { extra }) => {
    const response = await extra.axios.get(`api/v3/employee/bookings/${bookingSequenceNum}`);
    return getFormattedBookingData(response.data.data);
  },
);

export const requestMarkBookingChecklistTaskComplete = createAsyncThunk(
  'booking/markBookingChecklistTaskComplete',
  async (task, { extra, dispatch, getState }) => {
    const { employee: { booking: { record } } } = getState();
    const params = { booking_checklist: { completed: !task.completed } };
    await extra.axios.put(`/api/v3/booking_checklists/${task.bookingTaskId}`, params);
    dispatch(requestBooking(record.sequenceNum));
  },
);

export const requestMarkBookingComplete = createAsyncThunk(
  'booking/markBookingComplete',
  async (bookingSequenceNum, { extra, dispatch }) => {
    try {
      await extra.axios.put(`/api/v3/employee/bookings/${bookingSequenceNum}/complete`);
      dispatch(requestBooking(bookingSequenceNum));
      dispatch(setSnackbarSuccess('snackbar.employeeView.markCompleted'));
    } catch (err) {
      dispatch(setSnackbarError('snackbar.booking.complete.error'));
    }
  },
);

export const requestPunchIn = createAsyncThunk(
  'booking/employeeBookingPunchIn',
  async (details, { extra, dispatch }) => {
    try {
      const requestBody = getFormattedPunchInRequestBody(details);
      await extra.axios.post('/api/v1/punches', requestBody);
      dispatch(requestBooking(details.bookingSequenceNum));
      dispatch(setSnackbarSuccess('snackbar.employeeView.punchedIn'));
    } catch (err) {
      dispatch(setSnackbarError('snackbar.employeeView.punch.error'));
    }
  },
);

export const requestPunchOut = createAsyncThunk(
  'booking/employeeBookingPunchOut',
  async (details, { extra, dispatch }) => {
    try {
      const requestBody = getFormattedPunchOutRequestBody(details);
      await extra.axios.put(`/api/v1/punches/${details.punchId}`, requestBody);
      dispatch(requestBooking(details.bookingSequenceNum));
      dispatch(setSnackbarSuccess('snackbar.employeeView.punchedOut'));
    } catch (err) {
      dispatch(setSnackbarError('snackbar.employeeView.punch.error'));
    }
  },
);

export const requestUpdateBookingTime = createAsyncThunk(
  'booking/updateBookingTime',
  async (data, { extra, dispatch, getState }) => {
    try {
      const { employee: { booking: { record } } } = getState();
      const formattedData = await validateAndGetFormattedEmployeeBookingTimeUpdateRequestBody(data);
      await extra.axios.put(`/api/v1/bookings_employees/${data.bookingsEmployeesId}`, formattedData);
      dispatch(requestBooking(record.sequenceNum));
      dispatch(setTimeManagementFields({ showEditTimeModal: false }));
    } catch (err) {
      if (err.name === 'ValidationError') dispatch(setTimeManagementFields({ bookingTimeErrors: err.message }));
      dispatch(setSnackbarError('snackbar.timeManagement.timeUpdate.error'));
    }
  },
);

export const requestUpdateBookingApprovalStatus = createAsyncThunk(
  'booking/approveBooking',
  async (bookingSequenceNum, { extra, dispatch }) => {
    try {
      await extra.axios.put(`/api/v3/employee/bookings/${bookingSequenceNum}/confirm`);
      dispatch(requestBooking(bookingSequenceNum));
    } catch (err) {
      dispatch(setSnackbarError('snackbar.employeeView.bookingApproval.error'));
    }
  },
);
