import { parseJson } from 'appRedux/selectors/utility';
import getItemsTotalCosts, { getItemCost } from 'components/Booking/BookingModal/PriceTab/getTotalCosts';
import { getStringForJsonBookingNotes } from '../../utility';

export const getFormattedPreviewBooking = (booking) => {
  const bookingItems = booking.bookingItems.map((item) => ({ ...item, ...getItemCost(item, booking) }));
  const bookingPrice = getItemsTotalCosts(bookingItems, booking);
  return {
    ...booking,
    bookingItems,
    bookingPrice,
    notes:getStringForJsonBookingNotes(parseJson(booking.notes, booking.notes)) || '',
  };
};
