import { get, groupBy } from 'utils/lodash';

const rutMultiplier = 0.5;
const rotMultiplier = 0.3;

export const getItemCost = (item, order) => {
  if (item.quantity === undefined || item.perUnit === undefined) return { total: 0, totalWithRut: 0 };
  const quantity = Number(item.quantity ?? 1);
  const discount = Number(item.discount ?? 0);
  const perUnit = Number(item.perUnit ?? 0);
  const reductionEligible = order.houseworkType === 'Rut' || order.houseworkType === 'Rot';
  const reductionMultiplier = order.houseworkType === 'Rut' ? rutMultiplier : rotMultiplier;
  const itemVat = Number(item.vat);
  const itemVatMultiplier = itemVat / 100;
  const vatIncluded = get('customer.vatIncluded')(order);
  const isItemHouseWork = item.isHousework && reductionEligible;

  if (!vatIncluded && !isItemHouseWork) {
    const netPrice = ((1 - (discount) / 100) * ((perUnit) * quantity)).toFixed(2);
    const itemDiscount = (perUnit * quantity * (discount / 100)).toFixed(2);
    return {
      vatPrice: (netPrice * itemVatMultiplier).toFixed(2),
      total: ((1 + itemVatMultiplier) * netPrice).toFixed(2),
      itemDiscount,
      taxReductionPrice: 0,
      taxReductionType: 'None',
      toPay: ((1 + itemVatMultiplier) * netPrice).toFixed(2),
      inclVat: Math.abs(perUnit * (itemVatMultiplier + 1)).toFixed(2),
      exclVat: perUnit,
      discount,
    };
  }

  if (vatIncluded && !isItemHouseWork) {
    const netPrice = ((1 - (discount) / 100) * ((perUnit / (1 + itemVatMultiplier)) * quantity)).toFixed(2);
    const itemDiscount = (perUnit * quantity * (discount / 100)).toFixed(2);
    return {
      vatPrice: (netPrice * itemVatMultiplier).toFixed(2),
      itemDiscount,
      total: ((1 + itemVatMultiplier) * netPrice).toFixed(2),
      taxReductionPrice: 0,
      taxReductionType: 'None',
      toPay: ((1 + itemVatMultiplier) * netPrice).toFixed(2),
      inclVat: perUnit,
      exclVat: Math.abs(perUnit - ((perUnit * itemVat) / (itemVat + 100))).toFixed(2),
      discount,
    };
  }
  if (!vatIncluded && isItemHouseWork) {
    const netPrice = ((1 - (discount) / 100) * ((perUnit) * quantity)).toFixed(2);
    const taxReductionPrice = ((itemVatMultiplier + 1) * netPrice) * reductionMultiplier;
    const itemDiscount = (perUnit * quantity * (discount / 100)).toFixed(2);
    return {
      vatPrice: (netPrice * itemVatMultiplier).toFixed(2),
      total: ((1 + itemVatMultiplier) * netPrice).toFixed(2),
      itemDiscount,
      taxReductionPrice,
      taxReductionType: order.houseworkType,
      toPay: Math.abs(((1 + itemVatMultiplier) * netPrice) - taxReductionPrice).toFixed(2),
      inclVat: Math.abs(perUnit * (itemVatMultiplier + 1)).toFixed(2),
      exclVat: perUnit,
      discount,
    };
  }
  if (vatIncluded && isItemHouseWork) {
    const netPrice = ((1 - (discount) / 100) * ((perUnit / (1 + itemVatMultiplier)) * quantity)).toFixed(2);
    const itemDiscount = (perUnit * quantity * (discount / 100)).toFixed(2);
    const taxReductionPrice = ((itemVatMultiplier + 1) * netPrice) * reductionMultiplier;
    return {
      vatPrice: (Number(netPrice) * itemVatMultiplier).toFixed(2),
      total: ((1 + itemVatMultiplier) * Number(netPrice)).toFixed(2),
      itemDiscount,
      taxReductionPrice,
      taxReductionType: order.houseworkType,
      toPay: Math.abs(((1 + itemVatMultiplier) * Number(netPrice)) - taxReductionPrice).toFixed(2),
      inclVat: perUnit,
      exclVat: Math.abs(perUnit - ((perUnit * itemVat) / (itemVat + 100))).toFixed(2),
      discount,
    };
  }
  return {
    vatPrice: 0,
    total: 0,
    taxReductionPrice: 0,
    toPayPrice: 0,
    taxReductionType: 'None',
    exclVat: 0,
    inclVat: 0,
    itemDiscount: 0,
    discount: 0,
  };
};

export const getCalculatedItemsTotal = (items = [], order) => items.reduce((res, item) => {
  if (item.destroy) return res;
  const {
    total = 0,
    vatPrice = 0,
    taxReductionPrice = 0,
    toPay = 0,
    inclVat = 0,
    exclVat = 0,
    itemDiscount = 0,
  } = item;
  return {
    taxReductionType: order.houseworkType,
    taxReductionPrice: Number(taxReductionPrice) + res.taxReductionPrice,
    total: Number(total) + res.total,
    vat: Number(vatPrice) + res.vat,
    toPay: Number(toPay) + res.toPay,
    inclVat: Number(inclVat) + res.inclVat,
    exclVat: Number(exclVat) + res.exclVat,
    discount: Number(itemDiscount) + res.discount,
  };
}, { total: 0, vat: 0, taxReductionPrice: 0, toPay: 0, inclVat: 0, exclVat: 0, discount: 0 });

export default function getItemsTotalCosts (items = [], order) {
  const groupedItems = groupBy(
    (item) => (order !== 'None' && item.isHousework ? 'taxReduction' : 'nonTaxReduction'),
  )(items);

  const reductionPrice = getCalculatedItemsTotal(groupedItems.taxReduction, order);
  const actualReductionToPay = reductionPrice.total - Math.floor(reductionPrice.taxReductionPrice);
  const nonTaxReductionTotalPrice = getCalculatedItemsTotal(groupedItems.nonTaxReduction, order);
  const total = Math.round(reductionPrice.total + nonTaxReductionTotalPrice.total);

  const totalPrice = {
    total,
    taxReductionType: order.houseworkType,
    taxReductionPrice: Math.floor(reductionPrice.taxReductionPrice),
    roundOff: total - (reductionPrice.total + nonTaxReductionTotalPrice.total),
    vat: reductionPrice.vat + nonTaxReductionTotalPrice.vat,
    toPay: Math.round(actualReductionToPay + nonTaxReductionTotalPrice.toPay),
    inclVat: reductionPrice.inclVat + nonTaxReductionTotalPrice.inclVat,
    exclVat: reductionPrice.exclVat + nonTaxReductionTotalPrice.exclVat,
    discount: reductionPrice.discount + nonTaxReductionTotalPrice.discount,
  };
  return totalPrice;
}
