import React from 'react';
import PropTypes from 'prop-types';

const Image = ({ companyDetails }) => {
  const mimeType = companyDetails.logoUrl && companyDetails.logoUrl.split(';')[0].split(':')[1];
  return (
    <img
      height={68}
      width="auto"
      src={`data:${mimeType};base64,${companyDetails?.logoUrl}`}
      alt="logo"
    />
  );
};

Image.propTypes = {
  companyDetails: PropTypes.shape({
    logoUrl: PropTypes.string,
  }),
};

export default Image;
