import {
  SET_BOOKING_DOCUMENTS_FIELDS,
  SET_BOOKINGS_FIELDS,
  SET_EXTENDABLE_RECURRING_BOOKINGS_FIELDS,
  SET_RECURRING_BOOKINGS,
  SET_BOOKINGS_CUSTOMER_REQUESTS,
  SET_BOOKINGS_ITEMS_FIELDS,
  SET_BOOKING_INVOICE_ID_FIELDS,
  SET_BOOKINGS_PDF_FIELDS,
  SET_EXTENDABLE_BOOKINGS_FIELDS,
} from '../actions';

const initialState = {
  activeTab: 0,
  bookingLocalUpdates: null,
  bookingsIdsAttachmentStatus: {},
  extendableRecurringBookings: [],
  recurringBookings: [],
  selectedBookingIds: [],
  bookingsPdf: {
    open: false,
    selectedBookingIds: [],
    booking: {},
  },
  customerRequests: {
    records: [],
  },
  bookingDocuments: {
    showDocumentsModal: false,
    bookingId: null,
    records: [],
    imageViewer: {},
  },
  bookingItems: {
    records: [],
    itemsTitleBookingsMap: {},
    expandedRowsIndexes: {},
    selectedItemTitle: '',
  },
  bookingInvoiceId: {
    showInvoiceIdDialog: false,
  },
  extendableBookings: {
    records: [],
    fsip: false,
    openExtendableBookingsDialog: false,
  },
};

function bookings (state = initialState, action) {
  switch (action.type) {
    case SET_BOOKINGS_FIELDS:
      return {
        ...state,
        ...action.payload,
        ...(action.payload.showBookingModal === false && { booking: {} }),
      };

    case SET_EXTENDABLE_RECURRING_BOOKINGS_FIELDS:
      return {
        ...state,
        ...action.payload,
        extendableRecurringBookings: action.payload,
      };

    case SET_RECURRING_BOOKINGS:
      return {
        ...state,
        ...action.payload,
        recurringBookings: action.payload,
      };

    case SET_BOOKINGS_PDF_FIELDS:
      return {
        ...state,
        bookingsPdf: {
          ...state.bookingsPdf,
          ...action.payload,
        },
      };

    case SET_BOOKINGS_CUSTOMER_REQUESTS:
      return {
        ...state,
        customerRequests: {
          ...state.customerRequests,
          ...action.payload,
        },
      };

    case SET_BOOKING_DOCUMENTS_FIELDS:
      return {
        ...state,
        bookingDocuments: {
          ...state.bookingDocuments,
          ...action.payload,
        },
      };

    case SET_BOOKINGS_ITEMS_FIELDS:
      return {
        ...state,
        bookingItems: {
          ...state.bookingItems,
          ...action.payload,
        },
      };

    case SET_BOOKING_INVOICE_ID_FIELDS:
      return {
        ...state,
        bookingInvoiceId: {
          ...state.bookingInvoiceId,
          ...action.payload,
        },
      };

    case SET_EXTENDABLE_BOOKINGS_FIELDS:
      return {
        ...state,
        extendableBookings: {
          ...state.extendableBookings,
          ...action.payload,
        },
      };

    default:
      return state;
  }
}

export default bookings;
