import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import React, { useState, useCallback, useEffect } from 'react';
import { fm } from 'utils/string';

export default function TagsInput (props) {
  const [text, setText] = useState('');
  // this is to fix the api call on mount usually issue in mutliple false
  // for eg: open booking to update on open calls api to search service
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  const onChange = (event, value, reason, options) => { // eslint-disable-line
    setText('');
    const selectedTag = props.multiple ? value[value.length - 1] : value;
    if (reason === 'selectOption' && !selectedTag.id) {
      const title = selectedTag.title.match(/"([^"\\]*(\\.[^"\\]*)*)"/)[1]; // eslint-disable-line
      const onChangeValue = props.multiple ? value.map((v, idx) => (idx === value.length - 1 ? title : v)) : title;
      return props.onChange(event, onChangeValue, 'create-option');
    }
    props.onChange(event, value, reason, options);
  };

  const onInputChange = (event, value) => {
    setText(() => value);
    if (!value || !loaded) return;
    props.onInputChange(event, value);
  };

  const options = (text && props.showCreateOption)
    ? [{ title: `${fm('create_new')} "${text}"` }, ...props.options]
    : props.options;

  const renderInput = useCallback((params) => (
    <TextField
      {...params}
      inputProps={{ ...params.inputProps, ...props.inputProps }}
      variant={props.variant}
      label={fm(props.labelId, props.labelDefaultMessage)}
      placeholder={props.placeholder}
      autoFocus={props.autoFocus}
      error={props.error}
      helperText={props.helperText}
    />), [props.labelId, props.error, props.helperText]);

  return (
    <Autocomplete
      loading={props.loading}
      multiple={props.multiple}
      freeSolo={props.freeSolo}
      limitTags={props.limitTags}
      size={props.size}
      id={props.id}
      options={options}
      onChange={onChange}
      groupBy={props.groupBy}
      onInputChange={onInputChange}
      getOptionLabel={props.getOptionLabel}
      value={props.value}
      renderTags={props.renderTags}
      renderOption={props.renderOption}
      blurOnSelect={props.blurOnSelect}
      renderInput={renderInput}
      disabled={props.disabled}
      onFocus={props.onFocus}
    />
  );
}

TagsInput.defaultProps = {
  defaultValue: {},
  showCreateOption: true,
  limitTags: 2,
  size: 'small',
  freeSolo: true,
  multiple: true,
  inputProps: {},
  getOptionLabel: (option) => option.title || '',
  // Please remove the style props and create styles.js for styling
  renderOption: (props, option) => (
    <div {...props} key={option.id} style={{ fontWeight: !option.id ? 'bold' : 'normal' }}>{option.title}</div>
  ),
};

TagsInput.propTypes = {
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  showCreateOption: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  variant: PropTypes.string,
  labelId: PropTypes.string,
  labelDefaultMessage: PropTypes.string,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  error: PropTypes.string,
  freeSolo: PropTypes.bool,
  autoFocus: PropTypes.bool,
  loading: PropTypes.bool,
  size: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.shape({}),
  getOptionLabel: PropTypes.func,
  renderOption: PropTypes.func,
  blurOnSelect: PropTypes.bool,
  inputProps: PropTypes.shape({}),
  onFocus: PropTypes.func,
  limitTags: PropTypes.number,
  defaultValue: PropTypes.shape({}),
  disabled: PropTypes.bool,
  renderTags: PropTypes.func,
  groupBy: PropTypes.func,
};
